<template>
    <div class="progress-container  xx">
        <div class="form-group text-center message">
            Vent venligst, mens vi indhenter oplysningerne
        </div>

        <div class="progress">
            <div role="progressbar" :style="progressStyleObject" ></div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.progress-container {
    .message {
        color: #454545;
        font-size: 9pt;
    }
}
</style>

<script>
    export default {
        name: 'Progress',

        props: ['estimated', 'seconds'],

        data() {
            return {
                internalSeconds: 0,
                speed: 250
            }
        },

        watch: {
            seconds() {
                this.internalSeconds = seconds;

                this.plan()
            }
        },

        mounted() {
            this.plan()
        },

        computed: {
            progressStyleObject() {
                return {
                    width: (100 - ((this.estimated - this.internalSeconds) * 100 / this.estimated)) + '%',
                    backgroundColor: $org('colors.standard.secondary.hex')
                }
            }
        },

        methods: {
            reset() {
                this.seconds = 0
            },

            plan() {
                setTimeout(this.tick, this.speed)
            },

            tick() {
                this.internalSeconds += (this.speed / 1000);

                if (this.internalSeconds === this.estimated) {
                    return
                }

                this.plan()
            }
        }
    }
</script>
<template>
  <div class="loader" :class="{inline: inline}">
    <span class="fa fa-spin fa-circle-o-notch"></span>
  </div>
</template>

<style lang="scss" scoped>
.loader {
  text-align: center;

  &.inline {
    display: inline-block;
    text-align: initial;
  }
}
</style>

<script>
export default {
  props: {
    size: {
      type: String,
      default: 'small'
    },

    inline: {
      type: Boolean,
      default: false
    }
  }
}
</script>

export default [
  {
    path: '/unsubscribe',
    name: 'unsubscribe',
    component: () => import(/* webpackChunkName: "Unsubscribe" */ './Unsubscribe'),
    meta: {
      layout: false,
      allowAccessBeforeOnboarding: true,
      tracking: 'Unsubscribe - Page view'
    }
  }
]

<template>
    <div class="create-user">
        <div class="alert alert-danger" v-if="errors.length" v-for="(error, key) in errors" :key="key">
            {{error | capitalize}}
        </div>

        <div class="form-group" :class="{'has-error': errors.length}">
            <label for="createUserEmail">E-mail</label>
            <input type="text" v-model="email" id="createUserEmail" class="form-control">
        </div>

        <button class="btn btn-success btn-block btn-round" @click="create">
            <template v-if="hasLoaded">Opret brugeren, og send velkomst e-mail</template>
            <loader v-else></loader>
        </button>
    </div>
</template>

<script>
import Loader from '@/app/shared/components/Loader'

const ClientService = require('@/services/users/ClientService')

export default {
    props: {
        customerId: [String, Number]
    },

    data() {
        return {
            email: null,
            errors: [],
            hasLoaded: true
        }
    },

    watch: {
        email() {
            this.errors = []
        }
    },

    methods: {
        create() {
            if (!this.hasLoaded) {
                return
            }

            this.errors = []

            if (!this.email) {
                this.hasLoaded = true
                return;
            }

            this.hasLoaded = false

            const data = {
                email: this.email,
                customer_ids: [this.customerId]
            };

            ClientService.create(data, () => {
                this.email = null
                this.hasLoaded = true

                this.$emit('created')
            }, (response) => {
                const res = response.response
                const data = res.data

                data.errors.forEach((error) => {
                    const title = error.title
                    const fields = Object.keys(title)

                    this.errors.push(title[fields[0]][0])
                })

                this.hasLoaded = true
            });
        }
    },

    components: {
        Loader
    }
}
</script>

import LeadEnhancerService from './lead-enhancer.service'

const defaultState = () => ({})

const mutations = {}

const actions = {
  async fetchLeads(ctx, payload) {
    const response = await LeadEnhancerService.search(payload)
    return {
      results: response.data,
      total: response.header.totalCount
    }
  },
  async mailLeads (ctx, payload){
    return await LeadEnhancerService.sendMail(payload)
  }
}

export default {
  namespaced: true,
  state: defaultState(),
  mutations,
  actions
}

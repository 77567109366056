const BaseService = require('@/services/BaseService')

const namespace = 'billing/features'

const features = (body, cb, errorCb) => {
  BaseService.get(namespace, body, cb, errorCb)
}

export default {
  features
}

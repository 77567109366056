import store from '@/store'
import bugsnagClient from '@/services/bugsnag/BugsnagService'

const env = process.env

const track = function (name, properties = {}, callback = function() {}) {
    bugsnagClient.leaveBreadcrumb("Mixpanel track (new): " + name)

    if (env.NODE_ENV !== 'production') {
        callback()

        return
    }

    if (store.getters['identity/isGhostIdentity']) {
        callback()

        return
    }

    mixpanel.track(name, properties, callback)
}

const setup = function (id, email, domain, organization, source) {
    if (env.NODE_ENV !== 'production') {
        return
    }

    if (store.getters['identity/isGhostIdentity']) {
        return
    }

    mixpanel.identify(id)

    mixpanel.people.set({
        '$first_name': email,
        '$email': email,
        'Domain': domain,
        '$last_login': new Date(),
        'Organization': organization,
        'Source': source,
    })

    eventHub.$on('keyword.created', () => {
        track('SEO - Keyword create')
    })

    if (!domain || !email) {
        return
    }

    bugsnagClient.leaveBreadcrumb("Domain: " + domain)
    bugsnagClient.leaveBreadcrumb("Email: " + email)
}

export default {
    track,
    setup,
}

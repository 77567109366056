<template>
      <div class="input-group">
          <input v-model="text" @keyup.enter="create" type="text" :placeholder="inputPlaceholder" class="form-control" :class="{'error': hasError}" :disabled="isDisabled" v-bind="options"/>

          <span class="input-group-btn">
              <div class="clear" @click="clear()" :disabled="isDisabled" :class="{ 'clear--visible': text !== '' }">
                  <i class="pe-7s-close"></i>
              </div>

              <button class="btn btn-fill btn-primary" @click="create" :disabled="isDisabled" :style="organizationColor">
                  <template v-if="isLoading">
                      <span class="fa fa-circle-o-notch fa-spin fa-fw"></span>&nbsp;
                  </template>

                  {{buttonText}}
              </button>
          </span>
      </div>
</template>

<style lang="scss" scoped>
    .input-group {
        margin-bottom: 10px;
    }

    .btn {
        height: 40px;
    }
    .clear {
        display: inline-block;
        font-size: 35px;
        position: absolute;
        float: right;
        height: 20px;
        width: 20px;
        top: -1px;
        left: -40px;
        border-radius: 20px;
        color: #A2A2A2;
        font-weight: bold;
        text-align: center;
        z-index: 9;
        cursor: pointer;
        opacity: 0;
        transition: opacity 0.3s linear;
        &.clear--visible {
            opacity: 1;
        }

        &:hover {
            color: #AAAAAA;
        }
        &:disabled {
            cursor: not-allowed;
        }

    }
</style>

<script>
    export default {
        props: {
            setValue: {
                type: String
            },

            buttonText: {
                type: String,
                required: true
            },

            inputPlaceholder: String,

            isLoading: {
                type: Boolean,
                required: false,
                default: false
            },

            errors: {
                required: false,
                default: []
            },

            clearOnCreate: {
                required: false,
                default: false
            },

            maxLength: {
                required: false,
            },

            disabled: {
                required: false,
                default: false
            }
        },

        data () {
            return {
                text: ''
            }
        },

        computed: {
            hasError () {
                const errors = this.errors

                return errors.length > 0
            },

            organizationColor() {
                return {background:$org('colors.standard.link.primary.hex')}
            },

            isDisabled () {
                return this.isLoading || this.disabled
            },

            options () {
                return this.maxLength ? {maxLength: this.maxLength} : {}
            }
        },

        watch: {
            text: 'clearError',
            setValue: 'setValueToText'
        },

        methods: {
            setValueToText () {
                this.text = this.setValue
            },

            clearError () {
                this.$emit('clearError')
            },

            clear () {
                this.text = ''
            },

            create () {
                this.$emit('create', this.text)

                if (this.clearOnCreate && !this.hasError) {
                    this.clear()
                }
            }
        }
    }
</script>

<template>
	<div class="element">
		<div class="row" v-if="!hasLoaded" style="min-height: 355px">
			<div class="col-lg-12">
				<logo-loader :height="355" />
			</div>
		</div>

		<div class="row generate-report-form" v-else>
			<div v-if="customer.country.code === 'SE'" class="col-lg-12">
				<h4>Send monthly performance reports</h4>

				<button type="button"
						@click="toggleStatus"
						:disabled="status === null"
						class="reports" :class="{ enabled: status === true, disabled: status === false }">
					<template v-if="status === true">
						<i class="far fa-check fa-fw" />
						<span>Enabled</span>
					</template>

					<template v-else-if="status === false">
						<i class="far fa-times fa-fw" />
						<span>Disabled</span>
					</template>

					<template v-else>
						<i class="far fa-spinner-third fa-spin fa-fw" />
						<span>Loading</span>
					</template>
				</button>
			</div>

			<div class="col-lg-12">
				<h4>
					{{ $t("customers.meta.generateReport", { customer: customer.name }) }}
				</h4>

				<div v-if="errors.length" class="alert alert-danger">Error</div>
				<div v-if="success" class="alert alert-success">Success</div>

				<div class="row d-flex align-items-end">
					<div class="col col-lg-6 d-flex">
						<div class="form-group">
							<label>{{ $t("customers.reports.fromDate") }}</label>
							<br />
							<date-picker
								:editable="false"
								:clearable="false"
								v-model="fromDate"
								valueType="format"
							></date-picker>
						</div>
					</div>
					<div class="col col-lg-6 d-flex">
						<div class="form-group">
							<label>{{ $t("customers.reports.toDate") }}</label>
							<br />
							<date-picker
								:editable="false"
								:clearable="false"
								v-model="toDate"
								valueType="format"
							></date-picker>
						</div>
					</div>
				</div>
				<div class="row d-flex align-items-end">
					<div class="col col-lg-8 d-flex">
						<div class="form-group">
							<label>{{ $t("customers.reports.emailField") }}</label>
							<input
								v-model="emailVal"
								class="form-control"
								type="email"
								required
								:placeholder="$t('customers.reports.emailField')"
							/>
						</div>
					</div>
					<div class="col col-lg-4 d-flex">
						<div class="form-group">
							<button @click="submitForm" class="btn btn-success">
								{{ $t("customers.reports.submit") }}
							</button>
						</div>
					</div>
				</div>
				<div class="row d-flex align-items end">
					<div class="col col-lg-12 d-flex">
						<div class="form-group">
							<label>{{ $t("customers.reports.includeComparisonData") }}</label>
							<switch-toggle v-model="toggleComparison" />
						</div>
					</div>
				</div>
				<p class="generate-report-form__notice">
					<small>*{{ $t("customers.reports.delayNotice") }}</small>
				</p>

				<hr />

				<div>
					<h3>Additional e-mails to receive the report</h3>

					<div class="description">Enter email addresses below to receive the monthly report.</div>

					<div class="emails">
						<div v-for="(email, index) in reportEmails"
							 :key="`email-${index}`"
							 class="email">
							<input v-model="reportEmails[index]"
								   type="text"
								   class="form-control"
								   :placeholder="`Email (${index + 1})`"
							/>
						</div>
					</div>

					<button type="button"
							@click="onUpdateEmailReports"
							class="btn btn-primary">
						<i v-if="isUpdatingEmailReports"
						   class="fal fa-spinner-third fa-spin fa-fw" />
						Save e-mails
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.generate-report-form {
  .align-items-end {
    align-items: flex-end;
  }
  .d-flex {
    display: flex;
  }
  .form-group,
  .mx-datepicker {
    width: 100%;
  }
  &__notice {
    margin: 0;
    font-style: italic;
    opacity: 0.7;
  }
}

.emails {
	margin: 25px 0;

	.email {
		margin-bottom: 10px;
	}
}

button.reports {
	display: inline-flex;
	align-items: center;

	height: 34px;

	border: 0;
	outline: 0;
	box-shadow: none;

	padding: 0;
	margin: 0;

	background-color: #eee;

	border-radius: 34px;

	> i {
		display: flex;
		align-items: center;
		justify-content: center;

		width: 34px;
		height: 34px;
		border-radius: 34px;

		font-size: 16px;

		color: #2d2d2d;
		background-color: #ccc;
	}

	> span {
		padding: 0 15px;
		font-weight: 500;
	}

	&.enabled {
		> i {
			color: #14960b;
			background-color: #c3ecc2;
		}
	}

	&.disabled {
		> i {
			color: #960b0b;
			background-color: #ecc2c2;
		}
	}
}
</style>

<script>
import LogoLoader from "@/app/shared/components/LogoLoader";
import SwitchToggle from "@/app/shared/components/SwitchToggle";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

const CustomerService = require("@/services/customers/CustomerService");
const ReportService = require("@/services/reports/ReportService");
import { mapGetters } from "vuex";

export default {
	data() {
		return {
			hasLoaded: false,
			customer: {},
			emailVal: "",
			toggleComparison: false,
			errors: [],
			success: false,
			fromDate: null,
			toDate: null,

			reportEmails: [''],
			isUpdatingEmailReports: false,

			status: null,
		};
	},

	computed: {
		...mapGetters("identity", {
			identity: "getIdentity",
		}),
		customerId() {
			return this.$route.params.id;
		},
	},

	watch: {
		reportEmails() {
			this.sanitizeReportEmails()
		}
	},

	mounted() {
		this.loadCustomer();
	},

	methods: {
		sanitizeReportEmails() {
			const value = [
				...this.reportEmails.filter(val => String(val || '').length > 0),
				''
			]

			if (JSON.stringify(value) === JSON.stringify(this.reportEmails)) {
				return
			}

			this.reportEmails = value
		},

		loadCustomer() {
			this.hasLoaded = false;
			this.customer = null;

			CustomerService.find(
				this.customerId,
				{
					includes: ['country']
				},
				({ data }) => {
					this.customer = data;
					this.status = !! data.seReports
					this.hasLoaded = true;
					this.reportEmails = (data.reportEmails || [])
				},
				() => {
					this.hasLoaded = true;
				}
			);
		},

		submitForm() {
			this.errors = [];
			this.success = false;
			this.hasLoaded = false;

			if (!this.fromDate || !this.toDate) {
				this.errors.push("No dates!");
				this.hasLoaded = true;
				return;
			}

			ReportService.sendReport(
				this.customerId,
				{
					email: this.emailVal,
					fromDate: this.fromDate,
					toDate: this.toDate,
					includeComparisonData: this.toggleComparison,
				},
				(result) => {
					this.success = true;
					this.hasLoaded = true;
				},
				(error) => {
					this.errors = [...error.response.data.errors];
					this.hasLoaded = true;
				}
			);
		},

		onUpdateEmailReports() {
			if (this.isUpdatingEmailReports) {
				return
			}

			this.isUpdatingEmailReports = true

			CustomerService.put(
				this.customerId,
				{
					reportEmails: this.reportEmails.filter(val => String(val || '').length > 0)
				},
				({ data }) => {
					this.isUpdatingEmailReports = false
				},
				() => {
					this.isUpdatingEmailReports = false
				}
			);
		},

		toggleStatus() {
			if (this.status === null) {
				return
			}

			const seReports = ! this.status

			this.status = null

			CustomerService.put(this.customer.id, {
				seReports
			}, () => {
				this.loadCustomer()
			})
		}
	},

	components: {
		LogoLoader,
		SwitchToggle,
		DatePicker,
	},
}
</script>

<template>
	<div class="adversus">
		<card>
			<h1>OP'N: Onboard a New Client from Adversus</h1>
			<div class="description">Fill out the form to add a new User from Adversus</div>

			<form @submit.prevent="create">
				<div class="items">
					<div class="item-6">
						<div class="field"
							 :class="{ error: !! formatErrors('name'), 'has-content': String(model.name || '').length > 0 }">
							<input id="name"
								   type="text"
								   v-model="model.name"
								   :disabled="creating"
							/>

							<label for="domain">Customer Name <span class="required">*</span></label>

							<div v-if="!! formatErrors('name')"
								 class="error-message"
								 v-text="formatErrors('name')"
							/>
						</div>
					</div>

					<div class="item-6">
						<div class="field"
							 :class="{ error: !! formatErrors('language_id'), 'has-content': String(model.language_id || '').length > 0 }">
							<select v-if="languages !== null"
									id="languages"
									v-model="model.language_id"
									:disabled="creating">
								<option v-for="language in languages"
										:key="`language-${language.id}`"
										:value="language.value"
										v-text="language.label"
								/>
							</select>

							<label for="language">Language <span class="required">*</span></label>

							<div v-if="!! formatErrors('language_id')"
								 class="error-message"
								 v-text="formatErrors('language_id')"
							/>
						</div>
					</div>

					<div class="item-6">
						<div class="field"
							 :class="{ error: !! formatErrors('domain'), 'has-content': String(model.domain || '').length > 0 }">
							<input id="domain"
								   type="text"
								   v-model="model.domain"
								   :disabled="creating"
							/>

							<label for="domain">Domain <span class="required">*</span></label>

							<div v-if="!! formatErrors('domain')"
								 class="error-message"
								 v-text="formatErrors('domain')"
							/>

							<template v-if="String(model.domain || '').length > 3">
								<div v-if="availability === null"
									 class="availability">
									<div>
										<i class="fa fa-spinner fa-spin fa-fw" />
										<span>Loading...</span>
									</div>
								</div>

								<div v-else-if="availability === false"
									 class="availability success">
									<div>
										<i class="fa fa-check fa-fw" />
										<span>The domain is available</span>
									</div>
								</div>

								<div v-else
									 class="availability danger">
									<div>
										<i class="fa fa-exclamation-triangle fa-fw" />
										<span>The domain is already registered</span>
									</div>

									<button type="button"
											:disabled="impersonating"
											@click="onImpersonate">
										<i v-if="impersonating"
										   class="fa fa-spinner fa-spin fa-fw"
										/>

										Impersonate
									</button>
								</div>
							</template>
						</div>
					</div>

					<div class="item-6">
						<div class="field"
							 :class="{ error: !! formatErrors('vat_number'), 'has-content': String(model.vat_number || '').length > 0 }">
							<input id="vat_number"
								   type="text"
								   v-model="model.vat_number"
								   :disabled="creating"
							/>

							<label for="vat_number">Vat number <span class="required">*</span></label>

							<div v-if="!! formatErrors('vat_number')"
								 class="error-message"
								 v-text="formatErrors('vat_number')"
							/>
						</div>
					</div>

					<div class="item-6">
						<div class="field"
							 :class="{ error: !! formatErrors('email'), 'has-content': String(model.email || '').length > 0 }">
							<input id="email"
								   type="email"
								   v-model="model.email"
								   :disabled="creating"
							/>

							<label for="email">E-mail <span class="required">*</span></label>

							<div v-if="!! formatErrors('email')"
								 class="error-message"
								 v-text="formatErrors('email')"
							/>
						</div>
					</div>

					<div class="item-6">
						<div class="field"
							 :class="{ error: !! formatErrors('telephone'), 'has-content': String(model.telephone || '').length > 0 }">
							<input id="telephone"
								   type="tel"
								   v-model="model.telephone"
								   :disabled="creating"
							/>

							<label for="telephone">Telephone number</label>

							<div v-if="!! formatErrors('telephone')"
								 class="error-message"
								 v-text="formatErrors('telephone')"
							/>
						</div>
					</div>

					<div class="item-6">
						<div class="field"
							 :class="{ error: !! formatErrors('industry_id'), 'has-content': String(model.industry_id || '').length > 0 }">
							<select id="industries"
									v-model="model.industry_id"
									:disabled="creating">
								<option v-for="industry in industries"
										:key="`industry-${industry.id}`"
										:value="industry.id"
										v-text="industry.title"
								/>
							</select>

							<label for="industries">Industry <span class="required">*</span></label>

							<div v-if="!! formatErrors('industry_id')"
								 class="error-message"
								 v-text="formatErrors('industry_id')"
							/>
						</div>
					</div>

					<div class="item-6">
						<div class="field"
							 :class="{ error: !! formatErrors('contact_person'), 'has-content': String(model.contact_person || '').length > 0 }">
							<input id="contact_person"
								   type="text"
								   v-model="model.contact_person"
								   :disabled="creating"
							/>

							<label for="contact_person">Contact person</label>

							<div v-if="!! formatErrors('contact_person')"
								 class="error-message"
								 v-text="formatErrors('contact_person')"
							/>
						</div>
					</div>

					<div v-for="(competitor, index) in model.competitors"
						 :key="`competitor-${index}`"
						 class="item-12">
						<div class="field"
							 :class="{ error: !! formatErrors(`competitors.${index}`), 'has-content': String(competitor || '').length > 0 }">
							<input :id="`competitor-${index}`"
								   type="text"
								   v-model="model.competitors[index]"
								   :disabled="creating"
							/>

							<label :for="`competitor-${index}`">Competitor Domain ({{ index + 1 }})</label>

							<div v-if="!! formatErrors(`competitors.${index}`)"
								 class="error-message"
								 v-text="formatErrors(`competitors.${index}`)"
							/>
						</div>
					</div>

					<div v-for="(keyword, index) in model.keywords"
						 :key="`keyword-${index}`"
						 class="item-12">
						<div class="field"
							 :class="{ error: !! formatErrors(`keywords.${index}`), 'has-content': String(keyword || '').length > 0 }">
							<input :id="`keyword-${index}`"
								   type="text"
								   v-model="model.keywords[index]"
								   :disabled="creating"
							/>

							<label :for="`keyword-${index}`">Keyword ({{ index + 1 }})</label>

							<div v-if="!! formatErrors(`keywords.${index}`)"
								 class="error-message"
								 v-text="formatErrors(`keywords.${index}`)"
							/>
						</div>
					</div>

					<div class="item-12">
						<button type="submit"
								:disabled="! canSubmit || creating"
								class="submit">
							<i v-if="creating"
							   class="fa fa-spinner fa-spin fa-fw"
							   style="margin-right: 8px;"
							/>

							Add client
						</button>
					</div>
				</div>
			</form>
		</card>
	</div>
</template>

<style lang="scss" scoped>
.items {
	display: flex;
	align-items: flex-start;
	gap: 20px;
	flex-wrap: wrap;

	.item-6 {
		flex-basis: calc(50% - 10px);
	}

	.item-12 {
		flex-basis: 100%;
	}
}

.adversus {
	border: 1px solid #eee;
	width: 800px;
	max-width: calc(100vw - 50px);
	margin: 50px auto;

	h1 {
		color: #3d4852;
		font-size: 24px;
		font-weight: 800;
		margin: 0 0 4px 0;
		padding: 0;
	}

	div.description {
		color: #626c73;
		font-weight: 500;
		font-size: 14px;
	}

	form {
		margin-top: 25px;

		display: flex;
		flex-direction: column;

		.submit {
			color: #fff !important;
			border: 0;
			outline: 0;
			box-shadow: none;
			font-size: 16px;
			font-weight: 600;
			padding: 16px 32px;
			border-radius: 6px !important;
			-webkit-font-smoothing: antialiased;
			background-color: #413df6;
			margin-top: 15px;

			transition: opacity .25s ease-in-out;

			&:disabled {
				opacity: .5;
			}
		}

		.availability {
			margin-top: 6px;

			display: flex;
			justify-content: space-between;
			align-items: center;

			font-size: 13px;

			> div {
				display: flex;
				align-items: center;
				gap: 5px;
			}

			&.danger {
				color: #ea4d4d !important;
			}

			&.success {
				color: #2ba441 !important;
			}

			button {
				color: #000 !important;
				padding: 2px 7px;
				border-radius: 3px !important;
				border: 1px solid #ccc !important;
				background-color: #fff;

				&:disabled {
					opacity: .5;
				}
			}
		}

		.field {
			position: relative;

			&.error {
				label {
					color: #ea4d4d !important;
				}

				input, select {
					border-color: #ea4d4d !important;
				}
			}

			.error-message {
				color: #ea4d4d;
				font-size: 13px;
				font-weight: 400;
				margin-top: 4px;
			}

			.competitors > .competitor {
				position: relative;
			}

			label {
				position: absolute;

				color: #7f888f;
				font-size: 15px;
				font-weight: 500;

				top: 31px;
				left: 0;

				transform: translateY(-50%);

				transition: all .25s ease-in-out;
			}

			input,
			select {
				width: 100%;
				color: #3d4852;
				padding: 20px 0 6px 0;
				border: 0;
				border-bottom: 2px solid #d8dbe0;
				font-size: 15px;
				font-weight: 500;
				background-color: #fff;
				border-radius: 0;
				outline: 0;
				box-shadow: none;
				-webkit-appearance: none;
				-moz-appearance: none;
				appearance: none;
			}

			&.has-content {
				label {
					top: 0;
					font-weight: 500;
					font-size: 12px;
					transform: translateY(0);
				}
			}

			input:focus,
			select:focus {
				border-color: #4340f6;
			}

			input:focus + label,
			select:focus + label {
				color: #4340f6;
				top: 0;
				font-weight: 500;
				font-size: 12px;

				transform: translateY(0);
			}
		}
	}
}
</style>

<script>
import * as IndustryService from '@/services/customers/IndustryService'
import AdversusService from '@/services/adversus/AdversusService'

import debounce from 'debounce'
import {mapActions, mapGetters} from "vuex";
import * as LanguageService from '@/services/i18n/LanguageService'

export default {
	data: () => ({
		creating: false,
		errors: {},

		industries: null,
		languages: null,

		model: {
			name: '',
			email: '',
			domain: '',
			vat_number: '',
			telephone: '',
			contact_person: '',
			competitors: [''],
			keywords: [''],
			industry_id: null,
			language_id: null,
			send: 1,
		},

		availability: null,
		availabilityDebounce: null,
		impersonating: false
	}),

	computed: {
		...mapGetters('identity', {
			identity: 'getIdentity'
		}),

		canSubmit() {
			const {
				name,
				email,
				domain,
				industry_id,
				vat_number
			} = this.model

			return !! name && !! email && !! domain && !! industry_id && !! vat_number
		},

		domain() {
			return this.model.domain || ''
		}
	},

	watch: {
		model: {
			deep: true,

			handler() {
				const competitors = [
					...this.model.competitors.filter(competitor => String(competitor || '').length > 0),
					''
				]

				const keywords = [
					...this.model.keywords.filter(keyword => String(keyword || '').length > 0),
					''
				]

				if (JSON.stringify(this.model.competitors) !== JSON.stringify(competitors)) {
					this.$set(this.model, 'competitors', competitors)
				}

				if (JSON.stringify(this.model.keywords) !== JSON.stringify(keywords)) {
					this.$set(this.model, 'keywords', keywords)
				}
			}
		},

		domain() {
			this.availability = null

			if (String(this.domain).length === 0) {
				return
			}

			this.availabilityDebounce()
		}
	},

	mounted() {
		this.availabilityDebounce = debounce(this.checkAvailability, 500)

		this.setDefaults()
		this.loadIndustries()
		this.loadLanguages()
	},

	methods: {
		...mapActions("ghost", ["logInAsGhost"]),

		checkAvailability() {
			AdversusService.checkDomain(
				{ domain: this.domain },
				({ data }) => {
					this.availability = data
				},
				() => {
					this.availability = false
				}
			)
		},

		loadLanguages() {
			LanguageService.findAll({}, response => {
				const body = response.data

				this.languages = body.rows.map(language => {
					return {
						label: language.name,
						value: language.id
					}
				})
			})
		},

		async onImpersonate() {
			if (! this.availability || this.impersonating) {
				return
			}

			this.impersonating = true

			try {
				const { id } = this.availability

				await this.logInAsGhost({
					customerId: id
				})

				this.$router.push({ name: 'frontend.overview' });

				this.impersonating = false
			} catch (e) {
				this.impersonating = false
				throw e;
			}
		},

		setDefaults() {
			const query = this.$route.query || {}

			const {
				email,
				customerName,
				domain,
				vatNumber,
				telephone,
				contactPerson
			} = query

			this.$set(this.model, 'email', email || '')
			this.$set(this.model, 'name', customerName || '')
			this.$set(this.model, 'domain', domain || '')
			this.$set(this.model, 'vat_number', vatNumber || '')
			this.$set(this.model, 'telephone', telephone || '')
			this.$set(this.model, 'contact_person', contactPerson || '')
			this.$set(this.model, 'language_id', ((this.identity || {}).language || {}).id)
		},

		create() {
			if (this.creating || ! this.canSubmit) {
				return
			}

			this.creating = true
			this.errors = {}

			const data = JSON.parse(JSON.stringify(this.model))

			data.competitors = data.competitors.filter(item => String(item || '').length > 0)
			data.keywords = data.keywords.filter(item => String(item || '').length > 0)

			AdversusService.create(
				{
					...data,
					source: 'adversus'
				},
				({ data }) => {
					this.creating = false
					this.$router.replace(`/backend/customers/view/${data.id}/users`)
				},
				(error) => {
					this.creating = false

					const data = (error.response || {}).data

					if (data) {
						const errors = ((data.errors || [])[0] || {}).title
						this.errors = errors
					}
				}
			)
		},

		formatErrors(key) {
			const errors = (this.errors || {})

			if (! errors[key]) {
				return null
			}

			return errors[key][0]
		},

		loadIndustries() {
			IndustryService.findAll({
				sort: [{
					key: 'title',
					direction: 'ASC'
				}]
			}, ({ data }) => {
				this.industries = data.rows
			}, () => {
				// @TODO: Something went horribly wrong
			})
		}
	}
}
</script>

import Vue from 'vue'

// Locales
import 'moment/locale/da'
import moment from 'moment'
import VueMoment from 'vue-moment'
Vue.use(VueMoment, { moment })

// Local storage
import VueLocalStorage from 'vue-localstorage'
Vue.use(VueLocalStorage)

// Clipboards<
import VueClipboards from 'vue-clipboards'
Vue.use(VueClipboards)

// V-TOOLTIP
import VTooltip from 'v-tooltip'
Vue.use(VTooltip)

// Sweet alert
import VueSweetAlert from 'vue-sweetalert'
Vue.use(VueSweetAlert)

// VueHighlighter
require('@spotonmarketing/vue-highlighter/dist/@spotonmarketing/vue-highlighter.css')
import VueHighlighter from '@spotonmarketing/vue-highlighter'
Vue.use(VueHighlighter)

// Infinite scroll
import VueInfiniteScroll from 'vue-infinite-scroll'
Vue.use(VueInfiniteScroll)

// Charts
import HighchartsVue from 'highcharts-vue'
Vue.use(HighchartsVue)

// Flags
import FlagIcon from 'vue-flag-icon'
Vue.use(FlagIcon);

// Notifications
import Notifications from 'vue-notification'
Vue.use(Notifications)
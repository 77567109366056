<template>
    <div class="freespee">
        <template v-if="step == 1">
            <div class="form-group">
                <label>{{$t('integrations.backend.account')}}</label>

                <loader v-if="!hasLoaded" size="small"></loader>

                <multiselect v-if="hasLoaded"
                             v-model="account"
                             :options="accounts"
                             track-by="title"
                             label="title"
                             :close-on-select="true"
                             :show-labels="false"
                             :placeholder="$t('integrations.backend.selectAccount')"
                             :selectLabel="$t('multiSelect.selectLabel')">
                    <p slot="noResult">{{ $t('multiSelect.noResultsFound') }}</p>
                </multiselect>
            </div>

            <button type="button" class="btn btn-success btn-block" @click="setAccount">
                <span v-if="updatingAccount" class="fa fa-spin fa-spinner"></span>
                {{$t('integrations.backend.next')}}
            </button>
        </template>

        <template v-if="step == 2">
            <div class="form-group">
                <label>{{$t('integrations.backend.id')}}</label>

                <loader v-if="fetchingCustomers" size="small"></loader>

                <multiselect v-if="!fetchingCustomers"
                             v-model="accountCustomer"
                             :options="accountCustomers"
                             track-by="name"
                             label="name"
                             :close-on-select="true"
                             :show-labels="false"
                             :placeholder="$t('integrations.backend.selectAccount')"
                             :selectLabel="$t('multiSelect.selectLabel')">
                    <p slot="noResult">{{ $t('multiSelect.noResultsFound') }}</p>
                </multiselect>
            </div>

            <button type="button" class="btn btn-success btn-block" @click="create">
                <span v-if="creatingIntegration" class="fa fa-spin fa-spinner"></span>
                {{$t('integrations.backend.add')}}
            </button>
        </template>
    </div>
</template>

<script>
    const IntegrationService = require('@/services/integrations/IntegrationService')

    const CustomerService = require('@/services/customers/CustomerService')
    import CallService from '@/services/_app/inquiries/CallService'

    export default {
        props: {
            service: Object,
            customer: Object
        },
        data() {
            return {
                hasLoaded: false,
                accountCustomer: null,
                account: null,
                step: 1,
                accounts: [],
                existingCustomerFreeSpeeAccount: null,
                updatingAccount: false,
                fetchingCustomers: false,
                creatingIntegration: false,
                accountCustomers: [],
                id: null
            }
        },
        mounted() {
            this.load();
        },
        methods: {
            setAccount() {
                var data = {
                    freespeeAccount: this.account.id
                };
                this.updatingAccount = true;
                CustomerService.put(this.customer.id, data, (response) => {
                    this.updatingAccount = false;

                    this.step = 2;
                }, (error) => console.error(error));
                // fetch available accounts
                this.fetchingCustomers = true;
                this.accountCustomers = this.loadCustomers();
                this.fetchingCustomers = false;
            },

            create() {
                if (!this.accountCustomer) {
                    return;
                }

                this.creatingIntegration = true;

                var data = {
                    service: this.service.id,
                    customer: this.customer.id,
                    data: this.accountCustomer.id,
                    connectedAccount: this.account.title + ' > ' + this.accountCustomer.id
                };

                IntegrationService.create(data, (response) => {
                    this.creatingIntegration = false;

                    eventHub.$emit('integration.created', response.data);
                });
            },

            async loadCustomers() {
                this.fetchingCustomers = true;
                this.accountCustomers = await CallService.accountCustomers(this.account.id);
                this.fetchingCustomers = false;
            },

            async load() {
              try {
                this.accounts = await CallService.accounts();
                await CustomerService.find(this.customer.id, { includes: ['freespeeAccount']}, (result) => {
                    this.existingCustomerFreeSpeeAccount = result.data.freespeeAccount
                    this.account = result.data.freespeeAccount
                });
                this.hasLoaded = true;
              }catch (e) {
                this.hasLoaded = true;
              }
            }
        },
    }
</script>

window.jQuery = window.$ = require('jquery')
require('@/assets/theme/js/bootstrap.min')

import '@/plugins/mixpanel'
import '@/plugins/fontawesome'
import '@/plugins/filters'
import '@/plugins/organizations'
import '@/plugins/validation'
import '@/plugins/bugsnag'
import '@/plugins/plugins'

import TrafficSourcesService from '@/services/_app/traffic-sources/TrafficSourcesService'

const state = {
  trafficSources: null
}

const getters = {
  getSources: state => state.trafficSources
}

const mutations = {
  resetState (state) {
    state.trafficSources = null
  },
  setSources (state, trafficSources) {
    state.trafficSources = trafficSources
  }
}

const actions = {
  async setSources ({ commit, rootState }) {

    const options = {
		filter_groups: []
	}

	if (rootState.customer.customer) {
		options.filter_groups.push({
			filters: [{
				key: 'customer',
				operator: 'eq',
				value: rootState.customer.customer.id
			}]
		})
	}

    const response = await TrafficSourcesService.findAll(options)
    commit('setSources', response.rows)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

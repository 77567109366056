import ApiService from '@/services/_app/ApiService'
import TokenService from '@/services/_app/storage/TokenService'
import StorageService from '@/services/_app/storage/StorageService'

export default class GhostService {
  static async issueToken (email) {
    return await ApiService.get(`issue-token/${email}`);
  }

  static temporaryAccount (customerId) {
    return ApiService.get(`temporary-account/${customerId}`);
  }

  static async login ({ email, customerId, language }, route) {
    let response;

    if (customerId) {
      response = await GhostService.temporaryAccount(customerId);
    } else {
      response = await GhostService.issueToken(email);
    }

    await TokenService.saveGhostToken(response);

    TokenService.saveToken(response);

    await StorageService.set('ghost-redirect', route);

    return response;
  }

  static logout () {
    const ghostToken = TokenService.getGhostToken();
    TokenService.saveToken(ghostToken);
    TokenService.removeGhostToken();

    const redirect = StorageService.get('ghost-redirect');
    StorageService.delete('ghost-redirect');

    return redirect;
  }
}

<template>
    <div class="call-trakcing">
        <template v-if="customerId">
            <code-snippet :customerId="customerId" />

            <h1 class="lead" style="margin: 0 0 15px;">{{$t('backend.callTracking.numbers')}}</h1>

            <phone-numbers :customerId="customerId" />
        </template>
    </div>
</template>

<script>
    import CodeSnippet from '@/app/integrations/components/calltracking/CodeSnippet'
    import PhoneNumbers from '@/app/integrations/components/calltracking/PhoneNumbers'

    export default {
        computed: {
            customerId() {
                return this.$route.params.id
            }
        },

        components: {
            CodeSnippet,
            PhoneNumbers
        }
    }
</script>

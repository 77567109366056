export default [
  {
    path: '*',
    name: 'errors.404',
    component: () => import(/* webpackChunkName: "Errors" */ './404'),
    meta: {
      guest: true
    }
  }
]

<template>
    <div class="overview">
        <!-- Integration Check -->
        <check-for-missing-integrations :integrations="['MailChimp']">
            <div slot="no-integrations"></div>

            <!-- Has integration -->
            <div class="container-fluid">
                <!-- Intro & Datepicker -->
                <div class="row">
                    <div class="col-lg-12 col-sm-12">
                        <div class="row">
                            <div class="col-lg-12">
                                <card>
                                    <div class="lead">{{$t('mailChimp.campaigns')}}</div>

                                    <table class="table table-striped">
                                        <thead>
                                            <tr>
                                                <th class="col-lg-4">{{$t('mailChimp.campaign')}}</th>
                                                <th class="col-lg-3">{{$t('mailChimp.emails')}}</th>
                                                <th class="col-lg-2">{{$t('mailChimp.opens')}}</th>
                                                <th class="col-lg-2">{{$t('mailChimp.clicks')}}</th>
                                                <th class="col-lg-1" style="text-align: right">
                                                    MailChimp
                                                </th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            <newsletter-item :campaign="campaign" v-for="(campaign, i) in sortedCampaigns" :key="i"/>
                                        </tbody>
                                    </table>

                                    <div class="text-center" v-if="!hasLoaded">
                                        <span class="fa fa-circle-o-notch fa-spin fa-2x"></span>
                                    </div>
                                </card>
                            </div>
                        </div>
                    </div>
                </div>

                <infinite-loading @infinite="load" v-if="hasLoaded && !reachedEnd" :distance="250" />
            </div>
        </check-for-missing-integrations>
    </div>
</template>

<script>
    import Card from '@/app/shared/components/Card'
    import InfoBox from '@/app/layout/components/InfoBox'
    import Datepicker from '@/app/datepicker/components/Datepicker'
    import CheckForMissingIntegrations from '@/app/integrations/components/CheckForMissingIntegrations'
    import NewsletterItem from '@/app/newsletters/components/NewsletterItem'
    import InfiniteLoading from 'vue-infinite-loading'

    import hasIntegration from '@/mixins/integrations/hasIntegration'

    import MailChimpService from '@/services/mail-chimp/MailChimpService'

    const moment = require('moment')

    export default {
        mixins: [hasIntegration],

        data() {
            return {
                campaigns: [],
                hasLoaded: true,
                data: {},
                perPage: 50,
                offset: 0,
                reachedEnd: false
            }
        },

        computed: {
            sortedCampaigns() {
                return this.campaigns.sort((a, b) => {
                    return moment(b.create_time).unix() - moment(a.create_time).unix()
                })
            }
        },

        methods: {
            load() {
                if (!this.hasLoaded || this.reachedEnd) {
                    return
                }

                if (!this.hasHealthyIntegration('MailChimp')) {
                    return
                }

                this.hasLoaded = false

                MailChimpService.campaigns({
                    count: this.perPage,
                    offset: this.offset
                }, (response) => {
                    const data = response.data
                    const campaigns = data.campaigns

                    this.hasLoaded = true

                    if (campaigns.length === 0) {
                        this.reachedEnd = true

                        return
                    }

                    campaigns.forEach(campaign => {
                        this.campaigns.push(campaign)
                    })

                    this.offset += this.perPage
                })
            }
        },

        components: {
            Card,
            InfoBox,
            Datepicker,
            CheckForMissingIntegrations,
            NewsletterItem,
            InfiniteLoading
        }
    }
</script>

const BaseService = require('@/services/BaseService')

const namespace = 'customers'

const create = (customerId, name, cb, errorCb) => {
  BaseService.post(`${namespace}/${customerId}/tokens`, {name: name}, cb, errorCb)
}

const findAll = (customerId, options, cb, errorCb) => {
  BaseService.get(`${namespace}/${customerId}/tokens`, options, cb, errorCb)
}

const remove = (customerId, id, cb, errorCb) => {
  BaseService.remove(`${namespace}/${customerId}/tokens/${id}`, {}, cb, errorCb)
}

const getApiToken = (options, cb, errorCb) => {
    BaseService.get(`${namespace}/api/token`, options, cb, errorCb)
}

export {
  create,
  findAll,
  remove,
  getApiToken
}

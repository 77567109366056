import i18n from '@/plugins/i18n'
import Vue from 'vue'
import App from './App'
import store from '@/store'
import router from '@/router'
import ApiService from '@/services/_app/ApiService'
import { DEBUG_MODE, DEV_TOOLS } from '@/config/env'
import { mapGetters } from 'vuex'
//====== Plugins and tools
import moment from 'moment'
import '@/plugins/axios'
import '@/plugins'
import '@/plugins/bootstrapvue'

// Shared Components
import '@/app/shared/shared.components'

import VueAnalytics from 'vue-analytics'

//====== Global Conf
ApiService.setHeader()

//====== Vue
// Event hub
window.eventHub = new Vue()

// Config
Vue.config.debug = DEBUG_MODE
Vue.config.devtools = DEV_TOOLS
Vue.router = router

if (!DEBUG_MODE) {
  Vue.use(VueAnalytics, {
    id: 'UA-22606062-2',
    router
  })
}

const app = new Vue({
  store,
  i18n,
  router,

  computed: mapGetters({
    language: 'getLanguage',
    isGhost: 'ghost/isGhost',
	identity: 'ghost/getGhostUserIdentity'
  }),

  watch: {
    language: {
      handler: 'setLanguage',
      immediate: true
    },
	identity: {
      handler: 'setLanguage',
      immediate: true
    }
  },

  methods: {
    setLanguage() {
      if (!this.isGhost) {
        this.$i18n.locale = this.language.short
        moment.locale(this.language.code)
        this.$validator.locale = this.language.short
      } else if (!! this.identity) {
		  this.$i18n.locale = this.identity.language.short
		  moment.locale(this.identity.language.code)
		  this.$validator.locale = this.identity.language.short
	  }
    }
  },

  render: h => h(App)
}).$mount('#app')

store.$app = app

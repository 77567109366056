import {mapGetters} from 'vuex'

import {DEMO_INTEGRATIONS} from "@/app/users/ghost.store";

export default {
    computed: {
        ...mapGetters('identity', {
            identity: 'getIdentity'
        }),
        ...mapGetters('customer', {
            customer: 'getCustomer'
        }),
        ...mapGetters('ghost', {
            isDemo: 'isDemo'
        }),

        hiCustomer() {
            if (!this.identity) {
                return null
            }

            return this.customer
        },

        hiIntegrations() {
            if (!this.hiCustomer) {
                return []
            }

            return this.hiCustomer.integrations
        },

        hiServices() {
            return this.hiIntegrations.map(integration => {
                return integration.service.name
            })
        },

        hiProducts() {
            if (!this.hiCustomer) {
                return []
            }

            return this.hiCustomer.products.map(product => {
                return product.discr
            })
        },

		hasAnalytics() {
			return this.hasIntegration('Google analytics')
		},

		showGoogleAnalytics() {
			const integration = this.getIntegration('Google analytics')

			return ! this.hasIntegration('Google analytics') ||
				(!! integration && (integration.additional || {}).type !== 'matomo')
		},

		showMatomo() {
			const integration = this.getIntegration('Google analytics')

			return ! this.hasIntegration('Google analytics') ||
				(!! integration && (integration.additional || {}).type === 'matomo')
		}
    },

    methods: {
        hasIntegration(names) {
			if (this.isDemo) {
				if (typeof names === 'string') {
					if (DEMO_INTEGRATIONS.includes(names)) {
						return true
					}
				}

				if (Array.isArray(names)) {
					for(let i = 0; i < names.length; i++) {
						if (DEMO_INTEGRATIONS.includes(names[i])) {
							return true
						}
					}
				}
			}

            if (typeof names === 'string') {
                const matches = this.hiServices.filter(service => {
                    return service === names
                }).length

                return matches > 0
            }

            if (Array.isArray(names)) {
                for (let i = 0; i < names.length; i++) {
                    let integration = names[i]
                    const index = this.hiServices.indexOf(integration)

                    if (index >= 0) {
                        return true
                    }
                }

                return false
            }
        },

        hasHealthyIntegration(names) {
			if (this.isDemo) {
				if (typeof names === 'string') {
					if (DEMO_INTEGRATIONS.includes(names)) {
						return true
					}
				}

				if (Array.isArray(names)) {
					for(let i = 0; i < names.length; i++) {
						if (DEMO_INTEGRATIONS.includes(names[i])) {
							return true
						}
					}
				}
			}

            if (typeof names === 'string' && ! this.hiUnhealthy(names)) {
                const matches = this.hiServices.filter(service => {
                    return service === names
                }).length

                return matches
            }

            if (Array.isArray(names)) {
                for (let i = 0; i < names.length; i++) {
                    let integration = names[i]
                    const index = this.hiServices.indexOf(integration)

                    if (index >= 0 && !this.hiUnhealthy(integration)) {
                        return true
                    }
                }

                return false
            }
        },

        hiUnhealthy(req) {
            const badHealthIntegrations = this.hiIntegrations.filter(integration => {
                return integration.service.name === req && !integration.healthy
            })

            return badHealthIntegrations.length
        },

        getIntegration(name) {
            if (!this.hasIntegration(name)) {
                return null
            }

            return this.hiIntegrations.find(integration => {
                if (integration.service.name === name) {
                    return integration
                }
            })
        },

        hasProduct(name) {
            const matches = this.hiProducts.filter(product => {
                return (product === name)
            }).length

            return (matches)
        }
    }
}

<template>
    <div class="inquiry-table">

        <search class="search"
                :options="filters"
                @filters-updated="updateFilters"
                v-if="!isGhostIdentity" />

        <table class="table">
            <thead>
            <tr>
                <th class="col-lg-6 col-md-6">{{$t('inquiries.contact')}}</th>
                <th class="col-lg-3 col-md-2 hidden-sm">{{$t('contacts.table.status')}}</th>
                <th class="col-lg-2 col-md-2 hidden-sm">{{$t('inquiries.date')}}</th>
                <th class="col-lg-1 col-md-2 hidden-xs"></th>
            </tr>
            </thead>

            <tbody>
            <tr v-for="n in limit" v-if="!hasLoaded" class="mock-row">
                <td :data-title="$t('inquiries.contact')" class="col-lg-4 col-md-6">
                    <span class="mock">Icon</span>
                    <span class="mock">{{mock()}}</span>
                </td>

                <td :data-title="$t('contacts.table.status')" class="col-lg-3 col-md-2 hidden-sm">
                    <span class="mock">status</span>
                </td>

                <td :data-title="$t('inquiries.date')" class="col-lg-3 col-md-2 hidden-sm">
                    <span class="mock">1 week ago</span>
                </td>

                <td class="col-lg-2 col-md-4 hidden-xs">
                </td>
            </tr>

            <template v-for="(inquiry, index) in inquiries" v-if="hasLoaded">
                <tr class="list-item">
                    <td @click="inspect(index)" :data-title="$t('inquiries.contact')">
                        <span class="icon icon pe-7s-phone calls" :class="{unanswered: !inquiry.answered}" v-if="inquiry.discr == 'call'"></span>
                        <span class="icon pe-7s-mail-open-file forms" v-else></span>

                        <div class="contact-information">
                            <span v-if="inquiry.contact">
                                {{standardContactInformation(inquiry)}}
                            </span>

                            <span v-else>
                                {{secondaryContactInformation(inquiry)}}
                            </span>

                            <br>

                            <span class="text-muted"
                                  v-if="inquiry.source && !isGhostIdentity"
                                  v-html="$t('inquiries.source.via', {source: $t('inquiries.source.' + inquiry.source)})" />
                        </div>
                    </td>

                    <td :data-title="$t('contacts.table.status')" class="text-muted">
                        <status :contact="inquiry.contact"></status>
                    </td>

                    <td :data-title="$t('inquiries.date')" class="text-muted" @click="inspect(index)">
                        {{inquiry.date.date | moment($t('i18n.dateTimeFormat')) | capitalize}}
                    </td>

                    <td @click="inspect(index)">
                        <p class="angle pull-right">
                            <i class="fa fa-angle-up" v-if="index == inspected"></i>
                            <i class="fa fa-angle-down" v-else></i>
                        </p>
                    </td>
                </tr>

                <tr class="expanded" v-if="index == inspected">
                    <td colspan="4">
                        <div class="container-fluid">
                            <div class="row">
                                <div class="col-md-6 col-lg-8">
                                    <div class="inquiry-content">
                                        <div class="audio-wrapper" v-if="inquiry.discr == 'call'">
                                            <span class="headline">{{$t('inquiries.info.conversation')}}</span>

                                            <item-call :inquiry="inquiry"></item-call>

                                            <strong>{{$t('inquiries.info.duration')}}: </strong>{{inquiry.duration | duration}}
                                        </div>

                                        <div class="form-wrapper" v-else>
                                            <span class="headline">{{$t('inquiries.info.email')}}</span>
                                            <item-form :inquiry="inquiry"></item-form>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-6 col-lg-4" v-if="inquiry.contact">
                                    <div class="contact">
                                        <router-link :to="{name: 'frontend.contacts.view', params: {id: inquiry.contact.id}}" class="contact-link text-muted pull-right">
                                            <small>{{$t('inquiries.contactLink')}} <i class="fa fa-angle-right"></i></small>
                                        </router-link>

                                        <span class="headline">{{$t('inquiries.info.contacts')}}</span>

                                        <div class="info" v-if="inquiry.contact.email">
                                            <span class="icon pe-7s-mail-open-file forms"></span>&nbsp;
                                            <span>{{inquiry.contact.email}}</span>
                                        </div>

                                        <div class="clearfix"></div>

                                        <div class="info" v-if="inquiry.contact.phone">
                                            <span class="icon pe-7s-phone calls"></span>&nbsp;
                                            <span>{{inquiry.contact.phone}}</span>
                                        </div>
                                    </div>

                                    <div class="address" v-if="inquiry.contact && inquiry.contact.address">
                                        <span class="headline">{{$t('inquiries.info.address')}}</span>
                                        {{inquiry.contact.name}}<br>
                                        {{inquiry.contact.address.street}}<br>
                                        {{inquiry.contact.address.zipCode}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </td>
                </tr>
            </template>
            </tbody>
        </table>

        <div class="footer">
            <div class="row">
                <div class="col-md-12">
                    <hr />
                </div>
            </div>

            <div class="row">
                <div class="col-sm-3 hidden-xs">
                    <range :from="from" :to="to" :includeDivider="false"></range>
                </div>

                <div class="col-xs-12 col-sm-9">
                    <div class="pagination-wrapper">
                        <pagination :total="total" :limit="limit" :page="page" :ticks="1" v-on:paginate="paginate"></pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  import Range from '@/app/layout/components/Range'
  import Pagination from '@/app/layout/components/paginator/Pagination'
  import ItemCall from '@/app/inquiries/components/list/Call'
  import ItemForm from '@/app/inquiries/components/list/Form'
  import Status from '@/app/contacts/components/Status'
  import Search from '@/app/shared/global/search/Search'
  import datepickerMixin from '@/app/datepicker/datepicker.mixin'

  import {mapGetters} from 'vuex'
  import moment from 'moment'

  // TODO: Extract to state
  import InquiryService from '@/services/_app/inquiries/InquiryService'

  // TODO: services/filters
  import * as FilterService from '@/services/filters/FilterService'
  import ContactStatusFilter from '@/services/filters/custom/ContactStatus'

  export default {
    mixins: [datepickerMixin],
    props: {
      limit: {
        type: Number,
        default: 10
      },
    },

    watch: {
      page: {
        handler: 'load',
        immediate: true
      }
    },

    mounted() {
      this.loadFilters()
    },

    data() {
      return {
        page: 1,
        inquiries: [],
        hasLoaded: false,
        total: 0,
        inspected: -1,

        filters: {
          query: null,
          filters: []
        }
      }
    },

    computed: {
      ...mapGetters('ghost', {
        isGhostIdentity: 'isGhost'
      }),
      ...mapGetters('customer', {
        customer: 'getCustomer'
      })
    },

    methods: {
      async load() {
        this.hasLoaded = false

        let to = moment(this.to)
        to.add(1, 'day')

        let filterGroups = FilterService.toRequest(this.filters, [
          'contact.name',
          'contact.phone',
          'contact.email',
        ])

        filterGroups.push({
          filters: [
            {
              key: 'date',
              value: this.from.format('YYYY-MM-DD'),
              operator: 'gt',
            },
            {
              key: 'date',
              value: to.format('YYYY-MM-DD'),
              operator: 'lte',
            },
            {
              key: 'customer',
              value: this.customer.id,
              operator: 'eq',
            }
          ]
        })

        const body = await InquiryService.findAll({
          includes: [
            'contact',
            'contact.address',
            'contact.status'
          ],
          limit: this.limit,
          page: this.page,
          filter_groups: filterGroups,
          sort: [{
            key: 'date',
            direction: 'DESC'
          }]
        })
        this.total = body.total
        this.inquiries = body.rows

        this.hasLoaded = true
      },

      inspect(index) {
        if (this.isGhostIdentity) {
          return
        }

        if (this.inspected === index) {
          this.inspected = null
          return
        }

        this.inspected = index
      },

      paginate(page) {
        this.page = page
        this.inspected = -1
      },

      mock() {
        var mock = 'a'.repeat(Math.floor(Math.random() * 20).toFixed(0))

        mock += ' '
        mock += 'a'.repeat(Math.floor(Math.random() * 20).toFixed(0))

        return mock
      },

      firstSource(contact) {
        if (!contact || (!contact.inquiries && !contact.inquiries.length)) {
          return
        }

        const sortedByDateInquiries = contact.inquiries.sort((a, b) => b.date.date - a.date.date)

        return sortedByDateInquiries[0].sourceSlug
      },

      updateFilters(filters) {
        this.filters = filters

        this.page = 1

        this.load()
      },

      loadFilters() {
        ContactStatusFilter((options, value) => {
          this.filters.filters.push({
            key: 'contact.status',
            empty: $t('filters.empty'),
            value: value,
            prefix: 'Status',
            options: options
          })
        }, null)
      },

      secondaryContactInformation(inquiry) {
        if (this.isGhostIdentity) {
          return inquiry.discr === 'call' ?
            $t('inquiries.call') :
            $t('inquiries.email')
        }

        return inquiry.anum ?
          inquiry.anum :
          $t('inquiries.unlistedNumber')
      },

      standardContactInformation(inquiry) {
        if (this.isGhostIdentity) {
          return inquiry.discr === 'call' ?
            $t('inquiries.call') :
            $t('inquiries.email')
        }

        if (inquiry.contact.name) {
          return inquiry.contact.name
        }

        return inquiry.contact.phone ?
          inquiry.contact.phone :
          inquiry.contact.email
      }
    },

    components: {
      Range,
      ItemCall,
      ItemForm,
      Pagination,
      Status,
      Search
    }
  }
</script>

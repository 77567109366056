import Customers from '@/app/customers/Customers'
import CustomersView from '@/app/customers/pages/CustomerView'

import Users from '@/app/customers/views/Users'
import Integrations from '@/app/customers/views/Integrations'
import Keywords from '@/app/customers/views/Keywords'
import Settings from '@/app/customers/views/Settings'
import BackendReports from '@/app/customers/views/BackendReports'
import Subscriptions from '@/app/customers/views/Subscriptions'
import Copilot from '@/app/customers/views/Copilot'
import Xtract from '@/app/customers/views/Xtract'

export default {
  backend: [
    {
      path: '/backend/customers',
      name: 'backend.customers',
      component: Customers
    },
    {
      path: '/backend/customers/view/:id',
      name: 'backend.customers.view',
      component: CustomersView,
      children: [
        {
          path: 'users',
          name: 'backend.customers.view.users',
          component: Users
        },
        {
          path: 'integrations',
          name: 'backend.customers.view.integrations',
          component: Integrations
        },
        {
          path: 'keywords',
          name: 'backend.customers.view.keywords',
          component: Keywords
        },
        {
          path: 'settings',
          name: 'backend.customers.view.settings',
          component: Settings
        },
        {
          path: 'reports',
          name: 'backend.customers.view.reports',
          component: BackendReports
        },
        {
          path: 'subscriptions',
          name: 'backend.customers.view.subscriptions',
          component: Subscriptions
        },
        {
          path: 'copilot',
          name: 'backend.customers.view.copilot',
          component: Copilot
        },
        {
          path: 'xtract',
          name: 'backend.customers.view.xtract',
          component: Xtract
        }
      ]
    },
  ]
}

const StatusService = require('@/services/contacts/StatusService')

export default function (cb, preset) {
  let value = null;

  const data = {
    sort: [{
      key: 'title',
      direction: 'ASC'
    }],
  }

    StatusService.findAll(data, (response) => {
    const body = response.data

    const results = body.rows.map((status) => {
      return {
        label: status.title,
        value: status.id
      }
    })

    for (var i = 0; i < results.length; i++) {
      if (results[i].value != preset) {
        continue;
      }

      value = preset;
    }

    cb(results, value);
  });
}

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-lg-12 actions"},[(
            _vm.customer.supportConsent ||
              (_vm.customer.organization && !_vm.customer.organization.freemium)
        )?_c('button',{staticClass:"btn btn-login",on:{"click":_vm.signIn}},[_vm._v(" "+_vm._s(_vm.$t("enterAs"))+" "),_c('strong',[_vm._v(_vm._s(_vm.customer.domain))])]):[(!_vm.customer.onboarded)?_c('span',{staticClass:"attention"},[_vm._v(_vm._s(_vm.$t("customers.didNotOnboard")))]):_vm._e(),(
                !_vm.customer.supportConsent &&
                    _vm.customer.organization &&
                    !_vm.customer.organization.freemium
            )?_c('span',{staticClass:"attention"},[_vm._v(" "+_vm._s(_vm.$t("customers.noSupportConsent"))+" ")]):_vm._e(),(!_vm.users.length)?_c('span',{staticClass:"attention"},[_vm._v(_vm._s(_vm.$t("customers.noUser")))]):_vm._e()]],2)}
var staticRenderFns = []

export { render, staticRenderFns }
const toRequest = (options, queryKeys) => {
    let filterGroups = [];

    // Filters
    let filters = [];

    for(var i = 0; i < options.filters.length; i++) {
        const filter = options.filters[i];

        if (filter.value == null || filter.value == undefined) {
            continue;
        }

        if (filter.key === 'caseStatuses') {
            let filterToAdd = {
                key: filter.key,
                value: filter.value,
                operator: 'ct'
            }

            if (filter.not !== undefined) {
                filterToAdd.not = filter.not
            }

            filters.push(filterToAdd)

            continue
        }

        if (filter.key === 'products') {
            let filterToAdd = {
                key: filter.key,
                value: Array.isArray(filter.value) ? filter.value : [filter.value],
                operator: 'in'
            }

            filters.push(filterToAdd)

            continue
        }

        if (filter.key === 'hasIntegrations') {
            let filterToAdd = {
                key: filter.key,
                value: Array.isArray(filter.value) ? filter.value : [filter.value],
                operator: 'in'
            }

            filters.push(filterToAdd)

            continue
        }

        filters.push({
            key: filter.key,
            value: filter.value,
            operator: 'eq'
        });
    }

    if (filters.length) {
        filterGroups.push({
            filters: filters
        });
    }

    // Query
    if (options.query) {
        let queryFilters = [];

        for(var i = 0; i < queryKeys.length; i++) {
            const key = queryKeys[i]

            queryFilters.push({
                key: key,
                value: options.query,
                operator: 'ct'
            });
        }

        if (queryFilters.length) {
            filterGroups.push({
                or: true,
                filters: queryFilters
            });
        }
    }

    return filterGroups
}

export {
    toRequest
}

const BaseService = require('@/services/BaseService')

const namespace = 'inquiries/forms'

const find = (id, options, cb, errorCb) => {
  BaseService.get(namespace + '/' + id, options, cb, errorCb)
}

const findAll = (options, cb, errorCb) => {
  BaseService.get(namespace, options, cb, errorCb)
}

const list = (options, cb) => {
  BaseService.get(namespace + '/list', options, cb)
}

const integrate = (body, cb, errorCb) => {
  BaseService.post(namespace + '/integrate', body, cb, errorCb)
}

const countBySources = (options, cb, errorCb) => {
    BaseService.get(namespace + '/count/sources', options, cb, errorCb)
}

export {
  find,
  findAll,
  list,
  integrate,
  countBySources,
}

export default [
  {
    path: '/reviews',
    name: 'frontend.reviews',
    component: () => import(/* webpackChunkName: "Reviews" */ './Reviews'),
    meta: {
      headline: () => $t('navigation.reputation'),
      description: () => $t('topBar.descriptions.reviews'),
      tracking: 'Reviews - Page view - Main page'
    }
  },
]

<template>
    <card class="service">
        <img :src="integrationLogo"
                width="20"
                alt=""
                style="float: left; margin-right: 10px;"
        />
        <h1>{{ service.name }}</h1>

        <component v-if="oauthSlug"
                   :is="oauthSlug.slug"
                   :customerId="customer.id"
        />

        <integration v-for="(integration, i) in integrations"
                     :key="i"
                     :integration="integration"
                     :has-instagram="hasInstagram"
        />

        <span class="new"
              @click="create"
              v-if="service.name === 'Freespee' || service.name === 'Formstack'"
        >
            <span class="icon pe-7s-plus"></span>&nbsp;
            {{ $t('customers.integrations.newIntegration') }}
        </span>

        <hr/>

        <modal ref="modal">
            <create-integration :service="service" :customer="customer"/>
        </modal>
    </card>
</template>

<style lang="scss" scoped>
  .service {
    h1 {
        font-size: 14pt;
        font-weight: 400;
    }

    .new {
        cursor: pointer;
        color: #8d8d8d;

        .icon {
            float: left;
            font-size: 15pt;
        }
    }

    &:last-child {
        hr {
            display: none;
        }
    }
    .beta{
      position:absolute;
      top:0;
      right:0;
      display: inline-block;
      text-transform: uppercase;
      letter-spacing: 1px;
      font-size: 10px;
      padding-left: 2px;
      font-weight: 400;
      opacity: 0.8;
    }
  }
</style>

<script>
  import Integration from '@/app/integrations/components/Integration'
  import Modal from '@/app/layout/components/Modal'
  import CreateIntegration from '@/app/integrations/components/Create'

  import GoogleAnalytics from '@/app/google-ads/components/cached-accounts/GoogleAnalytics'
  import GoogleSearchConsole from '@/app/google-ads/components/cached-accounts/GoogleSearchConsole'

  //New google api
  import GoogleBusinessProfile from '@/app/google-ads/components/cached-accounts/GoogleBusinessProfile'
  import GoogleAds from '@/app/google-ads/components/cached-accounts/GoogleAds'

  import FacebookAds from '@/app/meta/components/cached-accounts/FacebookAds'
  import FacebookPages from '@/app/meta/components/cached-accounts/FacebookPages'

  export default {
    name: 'IntegrationService',
    props: {
      service: Object,
      customer: Object,
      integrationsList: Object | Array
    },

    data () {
      return {
        customerId: null,
        quickIntegrations: [
          {
            title: 'Google analytics',
            id: 'f55e7c6d-6928-4ad2-8951-80f371d4847f',
            slug: 'google-analytics'
          },
          {
            title: 'Google Search Console',
            id: 'd3f30dd7-1b78-4c0b-828d-09e440821550',
            slug: 'google-search-console'
          },
          {
            title: 'Google Business Profile',
            id: '2c9c2681-5ace-4526-854c-5f291b045b80',
            slug: 'google-business-profile'
          },
          {
            title: 'Google Ads',
            id: '57152d61-3919-4540-9905-765bae200d47',
            slug: 'google-ads'
          },
          {
            title: 'Facebook ads',
            id: '70d800f7-131e-43e7-8ca3-9f9ad2eaf7a7',
            slug: 'facebook-ads'
          },
          {
            title: 'Facebook pages',
            id: '94421467-7a40-41db-9e98-d8eec580dc31',
            slug: 'facebook-pages'
          },
          {
            title: 'Trustpilot',
            id: '94421467-7a40-41db-9e98-d8eec580dc31',
            slug: 'trustpilot'
          }
        ]
      }
    },

    computed: {
      integrations () {
        return this.customer.integrations.filter(
          integration => integration.service.id === this.service.id
        )
      },

      hasInstagram () {
        if (this.service.name.toLowerCase() === 'facebook pages') {
          return !!this.integrationsList.instagram
        }
      },

      oauthSlug () {
        return this.quickIntegrations.find(
          integration => integration.title === this.service.name
        )
      },

      integrationLogo () {
        const service = this.quickIntegrations.find(
          integration => integration.title === this.service.name
        )

        if (!service) {
          return
        }

        return `/images/onboarding/${service.slug}.svg`
      }
    },

    mounted () {
      this.customerId = this.$route.params.id

      eventHub.$on('integration.created', this.hideModal)
    },

    destroyed () {
      eventHub.$off('integration.created', this.hideModal)
    },

    methods: {
      create () {
        jQuery(this.$refs.modal.$el).modal('show')
      },

      hideModal () {
        if (this.$refs.modal.$el) {
          jQuery(this.$refs.modal.$el).modal('hide')
        }
      },
    },

    components: {
      Integration,
      Modal,
      CreateIntegration,
      GoogleAnalytics,
      GoogleSearchConsole,
      GoogleBusinessProfile,
      GoogleAds,
      FacebookAds,
      FacebookPages
    }
  }
</script>

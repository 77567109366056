import ApiService from '@/services/_app/ApiService'
import moment from 'moment'
import * as CustomerService from '@/services/customers/CustomerService'

const namespace = 'instagram'
const namespaceAds = 'instagram-ads'

export default class InstagramService {

	static async integration(id) {
		const {data} = await CustomerService.integrations(id)

		return !!data.instagram;
	}

	static async fetchData() {
		const from = moment().subtract(29, 'days')
		const to = moment()

		const dates = await ApiService.formatToUrl(from, to)

		const response = await ApiService.get(`${namespace}/data/${dates}/day`)

		if (response) {
			if (!response.data) {
				return response
			}

			return response.data
		}

		throw {status: 404, message: 'Data not found'}
	}

	static async loadMorePosts(next) {
		try {
			return await ApiService.get(next)
		} catch (e) {
			throw e
		}
	}

	static async fetchMediaObjectInsights({id, token, metric}) {
		try {
			const url = new URL(`https://graph.facebook.com/v18.0/${id}/insights`)

			url.searchParams.set('access_token', token)
			url.searchParams.set('metric', metric)

			const data = {method: "GET", url: url.toString()};
			const response = await ApiService.request(data);

			if (!response.data) {
				return response
			}

			if (!response.data.data) {
				return response.data
			}

			return response.data.data
		} catch (e) {
			throw e
		}
	}

	static campaigns (options) {
		return ApiService.get(namespaceAds + '/campaigns', options)
	}

	static adSets (campaignId) {
		return ApiService.get(namespaceAds + '/campaigns/' + campaignId + '/ad-sets' )
	}

	static ads (adSetId, campaignId, options) {
		return ApiService.get(namespaceAds + '/campaigns/' + campaignId + '/ad-sets/' + adSetId + '/ads', options)
	}

	static events ( options) {
		return ApiService.get(namespaceAds + '/events', options)
	}

	static previews (adId) {
		return ApiService.get(namespaceAds + '/ad/' + adId + '/previews' )
	}

	static adInsights (adId, options) {
		return ApiService.get(namespaceAds + '/ad/' + adId + '/insights' , options)
	}

	static adSetInsights (campaignId, adSetId, options) {
		return ApiService.get(namespaceAds + '/campaigns/' + campaignId + '/ad-sets/' + adSetId + '/insights', options)
	}

	static campaignInsights (campaignId, options) {
		return ApiService.get(namespaceAds + '/campaigns/' + campaignId + '/insights' , options)
	}

	static accountInsights (options) {
		return ApiService.get(namespaceAds + '/account-insights/', options)
	}

	static campaignsTotals (options) {
		return ApiService.get(namespaceAds + '/campaigns-totals/', options)
	}

	static cpc (from, to) {
		return ApiService.get(namespaceAds + '/cpc/' + ApiService.formatToUrl(from, to))
	}

	static ctr (from, to) {
		return ApiService.get(namespaceAds + '/ctr/' + ApiService.formatToUrl(from, to))
	}

	static spend (from, to) {
		return ApiService.get(namespaceAds + '/spend/' + ApiService.formatToUrl(from, to))
	}

	static clicks (from, to) {
		return ApiService.get(namespaceAds + '/clicks/' + ApiService.formatToUrl(from, to))
	}

	static integrate = (options, cb, errorCb) => {
		return ApiService.post(namespaceAds + '/integrate', options, cb, errorCb)
	}
}

// TODO: Replace old with new
import ApiService from '@/services/_app/ApiService'
import BaseService from '@/services/BaseService';

const namespace = 'inquiries/calls'

export default class CallService {
  static find (id, options) {
    return ApiService.get(namespace + '/' + id, options)
  }

  static findAll (options) {
    return ApiService.get(namespace, options)
  }

  static audio (id, options) {
    return ApiService.get(namespace + '/' + id + '/audio', options)
  }

  static accounts () {
    return ApiService.get(namespace + '/accounts')
  }

  static accountCustomers = (accountId, options) => {
    return ApiService.get(namespace + '/accounts/' + accountId + '/customers', options)
  }

  static sum (field, options) {
    return ApiService.get(namespace + '/sum/' + field, options)
  }

  static count (options) {
    return ApiService.get(namespace + '/count', options)
  }

  static countBySources (options) {
    return ApiService.get(namespace + '/count/sources', options)
  }
}

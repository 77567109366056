import Vue from 'vue'
import VeeValidateDa from 'vee-validate/dist/locale/da'
import VeeValidate, { Validator } from 'vee-validate'

Validator.localize('da', VeeValidateDa)

// Custom validation rules

import minDimensionsRule from '@/plugins/validation/rules/min_dimensions'
import maxDimensionsRule from '@/plugins/validation/rules/max_dimensions'
import minSizeRule from '@/plugins/validation/rules/min_size'

Validator.extend('min_dimensions', minDimensionsRule)
Validator.extend('max_dimensions', maxDimensionsRule)
Validator.extend('min_size', minSizeRule)

// Custom validation locales
import customValidationLocaleEn from '@/plugins/validation/locales/en.js'
import customValidationLocaleDa from '@/plugins/validation/locales/da.js'

Vue.use(VeeValidate, {
  locale: 'da',

  // custom bag names to avoid conflicts
  errorBagName: 'veeErrors',
  fieldsBagName: 'veeFields',

  // Custom validation localization, this merges custom messages and included messages from locale files

  dictionary: {
    en: { messages: customValidationLocaleEn.messages },
    da: { messages: customValidationLocaleDa.messages }
  }
})

import StorageService from '@/services/_app/storage/StorageService'
import moment from 'moment'
import ApiService from '@/services/_app/ApiService'

const OLD_TOKEN_KEY = "login-token"
const TOKEN_KEY = 'token'
const TOKEN_KEY_EXPIRATION = 'token-expiration'
const REFRESH_TOKEN_KEY = 'refresh-token'
const GHOST_TOKEN_KEY = 'ghost-identity'
const GHOST_REFRESH_TOKEN_KEY = 'ghost-refresh-token'
const GHOST_TOKEN_KEY_EXPIRATION = 'ghost-token-expiration'

class TokenService {
  static checkForOld() {
    if (!StorageService.get(TOKEN_KEY_EXPIRATION)) {
      TokenService.removeGhostToken()
      TokenService.removeToken()
    }
  }

  static checkToken(ghost = false) {
    const token = StorageService.get(ghost ? GHOST_TOKEN_KEY : TOKEN_KEY)
    const expiration = StorageService.get(ghost ? GHOST_TOKEN_KEY_EXPIRATION : TOKEN_KEY_EXPIRATION)

    if (!token || moment().diff(moment(expiration)) > 0) {
      if (ghost) {
        TokenService.removeGhostToken()
      } else {
        TokenService.removeToken()
      }

      return null
    }

    return token
  }

  static getToken() {
    return TokenService.checkToken()
  }

  static saveToken({ token, access_token, expires_in }) {
    const expiration = TokenService.getExpiration(expires_in)
    StorageService.set(TOKEN_KEY_EXPIRATION, expiration)
    StorageService.set(OLD_TOKEN_KEY, access_token)
    StorageService.set(TOKEN_KEY, access_token || token)
    ApiService.setHeader()
  }

  static removeToken() {
    StorageService.delete(TOKEN_KEY)
    StorageService.delete(REFRESH_TOKEN_KEY)
    StorageService.delete(TOKEN_KEY_EXPIRATION)
    StorageService.delete(OLD_TOKEN_KEY)

    TokenService.removeGhostToken()
    //StorageService.clear()
  }

  static getRefreshToken() {
    return TokenService.getGhostRefreshToken() || StorageService.get(REFRESH_TOKEN_KEY)
  }

  static getGhostToken() {
    const token = TokenService.checkToken(true)

    return token ? {
      token, expires_in: StorageService.get(GHOST_TOKEN_KEY_EXPIRATION)
    } : null
  }

  static saveGhostToken({ refresh_token }) {
    const employeeToken = StorageService.get(TOKEN_KEY)
    const employeeExpiration = StorageService.get(TOKEN_KEY_EXPIRATION)

    StorageService.set(GHOST_REFRESH_TOKEN_KEY, refresh_token)
    StorageService.set(GHOST_TOKEN_KEY, employeeToken)
    StorageService.set(GHOST_TOKEN_KEY_EXPIRATION, employeeExpiration)
  }

  static removeGhostToken() {
    StorageService.delete(GHOST_TOKEN_KEY)
    StorageService.delete(GHOST_REFRESH_TOKEN_KEY)
    StorageService.delete(GHOST_TOKEN_KEY_EXPIRATION)
  }

  static getGhostRefreshToken() {
    const token = StorageService.get(GHOST_REFRESH_TOKEN_KEY)

    if (!token) {
      TokenService.removeGhostToken()
    }

    return token
  }

  static getExpiration(expires_in = 3600) {
    return typeof expires_in === 'number' ? moment().add(expires_in, 'seconds').format() : expires_in
  }
}

export default TokenService

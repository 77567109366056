import TokenService from '@/services/_app/storage/TokenService'
import PasswordService from '@/services/_app/auth/PasswordService'
import SocialService from '@/services/_app/auth/SocialService'
import AuthService from '@/services/_app/auth/AuthService'
import StorageService from "@/services/_app/storage/StorageService";
import {STORAGE_CUSTOMER_KEY} from "@/app/users/customer.store";

const getDefaultState = () => {
  return {
    authenticating: false,
    authenticationErrors: false,
    accessToken: TokenService.getToken()
  }
}

const getters = {
  getStorageToken: () => TokenService.getToken(),
  getToken: state => state.accessToken,
  isLoggedIn: state => !!state.accessToken,
  isAuthenticating: state => state.authenticating,
  authenticationErrors: state => state.authenticationErrors
}

const mutations = {
  resetState (state) {
    Object.assign(state, getDefaultState())
  },
  setAuthenticating (state, value) {
    state.authenticating = value
  },
  loginRequest (state) {
    state.authenticating = true
    state.authenticationErrors = false
  },
  loginSuccess (state, accessToken) {
    state.accessToken = accessToken
  },
  loginError (state) {
    state.authenticating = false
    state.accessToken = null
  },
  errorsReset (state) {
    state.authenticationErrors = false
  },
  logoutSuccess (state) {
    state.authenticating = false
    state.accessToken = null
    state.authenticationErrors = false
  }
}

const actions = {
  resetLogin ({ commit }) {
    commit('setAuthenticating', false)
    commit('errorsReset')
  },

  /**
   * Get user type and load identity and customer, save both to state
   * @param commit
   * @param dispatch
   * @returns {Promise<boolean|*>}
   */
  async getIdentityType ({ commit, dispatch }) {
    // Fetch logged in user
    const identity = await dispatch('identity/loadIdentity', null, {
      root: true
    })

    commit('setAuthenticating', false)

    if (identity && identity.hasOwnProperty('discr')) {
      return identity.discr
    }

    return false
  },

  // LOGIN -------------------------------------------------------------------------
  /**
   * Reset authentication errors
   *
   * @param commit
   */
  resetErrors ({ commit }) {
    commit('errorsReset')
  },

  /**
   * Authenticate user
   *
   * @param commit
   * @param dispatch
   * @param payload
   * @returns {Promise<string|Object>}
   */
  async authenticate ({ commit, dispatch }, payload) {
    commit('loginRequest')

    try {
      const token = await AuthService.login(payload)
      commit('loginSuccess', token)
      const discr = await dispatch('getIdentityType')
      return AuthService.redirectTo(discr)
    } catch (e) {
      await AuthService.logout()
      commit('loginError')
      throw e
    }
  },

  /**
   * Sign out
   *
   * @param commit
   * @param dispatch
   * @returns {Promise<boolean>}
   */
  async logout ({ commit, dispatch }) {
    await AuthService.logout()

    commit('logoutSuccess')
    commit('setAuthenticating', false)

    await dispatch('resetState', null, { root: true })
    await commit('setFeatures', [], { root: true })
    return true
  },

  /**
   * Register customer
   *
   * @param context
   * @param payload
   * @returns {Promise<*>}
   */
  register (context, payload) {
    return AuthService.register(payload)
  },

  // SOCIAL LOGIN ------------------------------------------------------------------
  /**
   * Redirect to social login page
   *
   * @param context
   * @param provider
   * @param redirect
   * @returns {Promise<string>}
   */
  getProviderUrl (context, { provider, redirect }) {
    return SocialService.getProviderUrl(provider, redirect)
  },

  /**
   * Login through social provider
   *
   * @param commit
   * @param dispatch
   * @param provider
   * @param query
   * @returns {Promise<string|object>}
   */
  async authenticateSocialLogin ({ commit, dispatch }, { provider, query }) {
    commit('loginRequest')
    commit('setAuthenticating', true)

    try {
      const token = await SocialService.login(provider, query)
      commit('loginSuccess', token)
      commit('setAuthenticating', false)

      const discr = await dispatch('getIdentityType')

      return AuthService.redirectTo(discr)
    } catch (e) {
      await AuthService.logout()
      commit('logoutSuccess')
      commit('setAuthenticating', false)

      if (e.status == 200) {
        return { name: 'register', query: { email: e.response.email } }
      }

      throw { name: 'login', query: { error: 'oauth' } }
    }
  },

  // MAGIC LINK --------------------------------------------------------------------
  async magic ({commit, dispatch}, $route){
    commit('loginRequest')

    try {
	  if (!! $route.query.customer) {
		StorageService.set(STORAGE_CUSTOMER_KEY, $route.query.customer)
	  }

      const token = await AuthService.magicLink($route.query)

      commit('loginSuccess', token)
      const discr = await dispatch('getIdentityType')
      return AuthService.redirectTo(discr)
    } catch (e) {
      await AuthService.logout()
      commit('loginError')
      throw e
    }

  },

  // PASSWORD ----------------------------------------------------------------------
  /**
   * Send reset password email
   *
   * @param context
   * @param email
   * @returns {Promise<boolean>}
   */
  async resetPassword ({ commit }, email) {
	try {
		return await PasswordService.reset(email)
	} catch (exception) {
		return exception
	}
  },

  /**
   * Change password
   *
   * @param context
   * @param token
   * @param password
   * @returns {Promise<boolean>}
   */
  async changePassword ({ commit }, { token, password }) {
    commit('setAuthenticating', true)
    const response = await PasswordService.change(token, { password })
    commit('setAuthenticating', false)

    return response
  }
}

export default {
  namespaced: true,
  state: getDefaultState(),
  getters,
  mutations,
  actions
}

<template>
	<div class="card-tooltip">
		<template v-if="tooltip">
			<button v-if="!informationCurrentlyVisible" class="information__open" type="button" @click="toggleInformationVisibility">
				<i class="fa fa-info-circle"></i>
			</button>
			<transition name="fade">
				<div class="information" v-if="informationCurrentlyVisible" :style="organizationBorderColor">
					<div class="row">
						<div class="col-lg-12 information__headline-container">
							<span class="label-left" :style="organizationBackgroundColor">
								<i class="fa fa-info"></i>{{$t('tooltip.tooltip')}}
							</span>
						</div>

						<div class="information__content-container"
							 :class="tooltipSize"
							 :style="{ textAlign: blueprint.style.textAlign }">
							<div v-if="blueprint.image.imagePath" class="information__content-container__image" :style="{ 'background-image': 'url(' + blueprint.image.imagePath + ')', paddingTop: blueprint.image.aspectRatio }"></div>

							<h5 v-if="blueprint.headlineText">{{blueprint.headlineText}}</h5>
							<p v-html="blueprint.paragraphText" v-if="blueprint.paragraphText"></p>

							<ul v-if="blueprint.unorderedList">
								<li v-for="listItem in blueprint.unorderedList">{{listItem}}</li>
							</ul>

							<p v-html="blueprint.subParagraphText" v-if="blueprint.subParagraphText"></p>

							<router-link :to="{ name: blueprint.moreButton }" v-if="blueprint.moreButton">
								<button class="btn btn-xs btn-custom">
									{{$t('actions.readMore')}}
								</button>
							</router-link>
						</div>
					</div>

					<button class="information__close" type="button" @click="toggleInformationVisibility">
						<i class="fa fa-times-circle"></i>
					</button>
				</div>
			</transition>
		</template>
	</div>
</template>

<style lang="scss" scoped>
.information__open {
	position: absolute;
	top: 20px;
	right: 20px;
	cursor: pointer;
	background: none;
	border: none;
	&:focus {
		outline: none;
	}
}

.information__close {
	position: absolute;
	top: 20px;
	right: 5px;
	cursor: pointer;
	background: none;
	border: none;
	&:focus {
		outline: none;
	}
}

.fade-enter-active, .fade-leave-active {
	transition: all 0.2s linear;
}

.fade-enter, .fade-leave-to {
	transform: translateX(-5px);
	opacity: 0;
}

.label-left {
	padding: 6px 10px 5px 5px;
	color: #fff;
	font-size: 10px;
	background: #ffa534;
	border-radius: 3px;
	display: inline-block;
	font-weight: 500;
}

.label-right {
	padding: 5px 10px 4px 10px;
	color: #ffa534;
	font-size: 10px;
	border: 1px solid #ffa534;
	border-radius: 0 3px 3px 3px;
	display: inline-block;
	font-weight: 500;
}

ol, ul {
	padding-left: 30px;
	margin-bottom: 20px;
	li {
		font-size: 12px;
		line-height: 20px;
	}
}

.information {
	width: 100%;
	height: 100%;
	background: #f9f9f9;
	overflow: hidden;
	border-left: 5px solid #FFA534;
	position: absolute;
	left: 0;
	top: 0;
	
	overflow-y: scroll;

	padding: 30px 60px 30px 30px;
	border-radius: 3px;
	z-index: 999999999999;
	.information__headline-container {
		margin-bottom: 5px;
	}
	.information__content-container {
		p {
			font-size: 12px;
			max-width: 600px;
			margin-bottom: 20px;
			line-height: 18px;
		}
		.information__content-container__image {
			width: 100%;
			background-position: center center;
			background-repeat: no-repeat;
			background-size: cover;
			margin-bottom: 20px;
			border-radius: 3px;
		}
		.btn-custom {
			background: #4096ee;
			color: #fff;
			font-weight: 400;
			border: none;
		}
	}
}
</style>

<script>
	export default {
		props: {
			tooltip: {
				type: Object,
				required: true
			},
		},

		data() {
			return {
				informationCurrentlyVisible: false,
				
				blueprint: {
					headlineText: '',
					paragraphText: '',
					subParagraphText: '',
					columnSizes: Object,
					moreButton: '',
					unorderedList: [],
					image: {
						imagePath: '',
						aspectRatio: '25%'
					},
					style: {
						textAlign: 'left'
					}
				}
			}
		},

		computed: {
			tooltipSize() {
				const sizes = []
				const tooltip = this.tooltip
				const columnSizes = tooltip.columnSizes

				for(var key in columnSizes) {
					sizes.push('col-' + key + '-' + columnSizes[key])
				}

				return sizes.join(' ')
			},

            organizationBackgroundColor() {
			    return {backgroundColor:$org('colors.standard.tooltip.primary')}
            },

            organizationBorderColor() {
			    return {borderLeft:`5px solid ${$org('colors.standard.tooltip.primary')}`}
            }
		},

		mounted() {
			this.build()
		},

		methods: {
			toggleInformationVisibility() {
				this.informationCurrentlyVisible = !this.informationCurrentlyVisible
			},

			build() {
				const tooltip = JSON.parse(JSON.stringify(this.tooltip))
				const blueprint = JSON.parse(JSON.stringify(this.blueprint))

				Object.assign(blueprint, tooltip)

				this.blueprint = blueprint
			}
		}
	}
</script>
import ApiService from "@/services/_app/ApiService";
import StorageService from '@/services/_app/storage/StorageService'
import { API_URL } from '@/config/env'

const namespace = "users/clients"

export default class ClientService {
	// Specific to users
	static async get(options) {
		const user = await ApiService.get(`${namespace}/identity`, options);

		StorageService.set('authState', user.discr);

		return user;
	}
	static async getGhostUserIdentity(){
		const ghostToken = StorageService.get('ghost-identity');
		const headers = new Headers();

		headers.append('Authorization', `Bearer ${ghostToken}`);
		headers.append('Content-Type', 'application/json');

		const url = `${API_URL}/${namespace}/identity`;

		const request = new Request(url, {
			method: 'GET',
			headers: headers,
		});

		const identity = await fetch(request).then(response => response.json()).then(response => response ).catch(error => console.log(error) );

		return identity;
	}
	static remove(id) {
		return ApiService.delete(`${namespace}/${id}`);
	}
	static put(id, data) {
		return ApiService.put(`${namespace}/${id}`, data);
	}
	static password(password) {
		return ApiService.put(`${namespace}/password`, { password });
	}

	// Used by employee and client
	static find(id, options) {
		return ApiService.get(namespace + "/" + id, options);
	}

	static findAll(options) {
		return ApiService.get(namespace, options);
	}

	static remove(id) {
		return ApiService.remove(namespace + "/" + id);
	}

	static create(options) {
		return ApiService.post(namespace, options);
	}
}

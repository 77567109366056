<template>
    <div class="overview">
        <!-- Integration Check -->
        <check-for-missing-integrations :integrations="['Klaviyo']">
            <div slot="no-integrations"></div>

            <!-- Has integration -->
            <div class="container-fluid">
                <!-- Intro & Datepicker -->
                <div class="row">
					<div class="col-lg-12 col-sm-12">
						<tab-navigation>
							<ul>
								<li>
									<a @click="view = 'campaigns'"
									   :class="{ active: view === 'campaigns' }">
										{{$t('klaviyo.campaigns')}}
									</a>
								</li>

								<li>
									<a @click="view = 'flows'"
									   :class="{ active: view === 'flows' }">
										{{$t('klaviyo.flows')}}
									</a>
								</li>
							</ul>

							<div class="clearfix"></div>

							<hr/>

							<campaigns v-if="view === 'campaigns'" />
							<flows v-if="view === 'flows'" />
						</tab-navigation>
                    </div>
                </div>
            </div>
        </check-for-missing-integrations>
    </div>
</template>

<script>
    import Card from '@/app/shared/components/Card'
    import InfoBox from '@/app/layout/components/InfoBox'
    import CheckForMissingIntegrations from '@/app/integrations/components/CheckForMissingIntegrations'
    import InfiniteLoading from 'vue-infinite-loading'

	import Campaigns from '@/app/klaviyo/components/Campaigns'
	import Flows from "@/app/klaviyo/components/Flows";
	import TabNavigation from "@/app/layout/components/TabNavigation";

    export default {
		data: () => ({
			view: 'campaigns',
		}),

        components: {
			TabNavigation,
			Flows,
			Campaigns,
            Card,
            InfoBox,
            CheckForMissingIntegrations,
            InfiniteLoading
        }
    }
</script>

<template>
    <div>
        <div class="col-xxl-12 col-lg-6 col-md-6 col-sm-6 col-xs-6"
             style="padding: 20px;"
             v-for="column in Object.values(metaBoxes)"
             v-if="clonedCustomer">
            <div class="form-group meta ellipsis" v-for="data in column">
                <small class="text-muted meta-label">{{$t(data.translation)}}</small><br>
                <!-- if string -->
                <p class="ellipsis" v-if="data.metricType === 'string'">
                    {{clonedCustomer[data.metric] ? clonedCustomer[data.metric] : '-'}}
                </p>

                <!-- if object -->
                <p class="ellipsis" v-if="data.metricType === 'object'">
                    <template v-if="hasDataProviderConnection && data.conditional === 'crmConnection'">
                        <a class="link-external"
						   :href="crmLink"
						   target="_blank"
                           rel="noopener noreferrer"
						   v-text="$t('customers.meta.goToCRM')"
						/>
                    </template>

                    <template v-else>
                        {{clonedCustomer[data.conditional] ? clonedCustomer[data.conditional][data.metric] : '-'}}
                    </template>
                </p>

                <!-- if array -->
                <p class="ellipsis"
                   v-if="data.metricType === 'array'"
                   v-for="email in data.metric">
                    {{email}}
                </p>
            </div>
        </div>

		<div class="col-lg-12 mb-3">
			<div class="form-group meta">
				<small class="text-muted meta-label">Subscriptions:</small><br>

				<div style="margin-top: 2px;">
					<div v-if="formattedSubscriptions.active.length > 0"
						 class="subscriptions">
						{{ formattedSubscriptions.active.map(item => item.plan.title).join(', ') }}
					</div>

					<div v-else-if="formattedSubscriptions.inactive.length > 0"
						 class="subscriptions inactive">
						{{ formattedSubscriptions.inactive.map(item => item.plan.title).join(', ') }} (expired)
					</div>

					<template v-if="formattedSubscriptions.total === 0">-</template>
				</div>
			</div>
		</div>

		<div class="col-lg-12 mb-3">
			<button @click="addDemo"
					class="demo">
				<template v-if="demo === 0">Grant 48 hours of OP'N Plus trial access</template>
				<template v-else-if="demo === 1">
					<i class="fa fa-spinner fa-spin fa-fw" />
					Granting
				</template>
				<template v-else>
					<i class="fa fa-check fa-fw" />
					Granted
				</template>
			</button>
		</div>

        <ghost-identity-button :customer="customer" />

        <div class="col-lg-12">
            <hr>
        </div>

        <div class="col-lg-12">
            <small class="text-muted meta-label label-header">{{$t('customers.customerRelations')}}</small><br>

            <span class="label label-neutral"
                  v-for="relation in relatedEmployees"
                  v-tooltip="relationTooltip(relation)">
                    <i class="fa fa-star" v-if="relation.isClientManager" />{{relation.name}}
            </span>
        </div>

        <template v-if="warningVisible">
            <div class="col-lg-12">
                <hr>
            </div>

            <div class="col-lg-12">
                <small class="text-muted meta-label label-header">{{$t('customers.warnings.headline')}}</small><br>

                <span class="label label-danger"
                      v-if="!customer.active"
                      v-tooltip="labelTooltip('noLongerCustomer')">{{$t('customers.warnings.noLongerClient')}}
                </span>

                <span class="label label-warning"
                      v-if="!customer.supportConsent"
                      v-tooltip="labelTooltip('supportConsent')">{{$t('customers.warnings.noSupportConsent')}}
                </span>

                <span class="label label-warning"
                      v-if="customer.status === 'Under opsigelse'"
                      v-tooltip="labelTooltip('contractEnded')">{{$t('customers.warnings.contractTerminated')}}
                </span>

                <span class="label label-warning"
                      v-if="oneOrMoreMissingInquiryNotificationSubscriptions"
                      v-tooltip="labelTooltip('inquiryNotificationDisabledOnOneUser')">{{$t('customers.warnings.notificationOff')}}
                </span>
            </div>
        </template>


        <div class="col-lg-12">
            <hr>
        </div>

        <div class="col-xxl-12 xxl-col col-lg-6 col-md-6 col-sm-6 col-xs-6">
            <small class="text-muted meta-label">Aktive</small><br>
            <template v-for="service in activeServices">
                <span style="margin-top: 5px; display: inline-block;" class="ellipsis">
                    {{service}}
                </span><br>
            </template>
        </div>

        <div class="col-xxl-12 xxl-col col-lg-6 col-md-6 col-sm-6 col-xs-6">
            <small class="text-muted meta-label">Inaktive</small><br>
            <template v-for="service in inactiveIntegrations">
                <span style="margin-top: 5px; display: inline-block;" class="inactive-integration ellipsis">
                    {{service.name}}
                </span><br>
            </template>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    @import '~@/assets/scss/_vars.scss';
    @import '~@/assets/scss/_mixins.scss';

    .ellipsis {
        @include ellipsis;
    }

    .inactive-integration {
        -webkit-filter: grayscale(100%);
        filter: grayscale(100%);
        opacity: 0.7;
    }

    .btn-login {
        cursor: default;
        width: 100%;
        background: $focusSuccess;
        color: white;
        border: none;
        opacity: 1;
        transition: all 0.2s ease;
        &:hover {
            opacity: 0.85;
        }
    }

    .label-header {
        margin-bottom: 5px;
        display: inline-block;
    }

    .link-external {
        color: #000;
        &:hover {
            color: #888;
        }
    }

    .label {
        margin-right: 5px;
        margin-bottom: 5px;
        font-size: 12px;
        padding: 5px 10px;
        display: inline-block;
        &.label-danger {
            background: $primaryAlert;
            color: $tertiaryAlert;
            cursor: default;
        }

        &.label-warning {
            background: $primaryWarning;
            color: $tertiaryWarning;
            cursor: default;
        }

        &.label-neutral {
            background: #eaeaea;
            color: #888;
            cursor: default;
        }
    }

	button.demo {
		color: #fff;
		border: 0;
		box-shadow: none;
		outline: 0;
		display: block;
		width: 100%;
		border-radius: 3px;
		padding: 10px;
		font-size: 14px;
		font-weight: 500;
		letter-spacing: -.1px;
		background-color: #ee8fd1;
	}

	.subscriptions {
		&.inactive {
			color: #939393;
			text-decoration: line-through;
			text-decoration-thickness: .5px;
		}
	}
</style>

<script>
    import GhostIdentityButton from '@/app/users/components/GhostIdentityButton'
    const ServiceService = require('@/services/integrations/ServiceService')

    import { mapGetters} from 'vuex'

	const SubscriptionService = require("@/services/billing/SubscriptionService");

	import moment from 'moment'

    export default {
      props: {
            customer: {
                type: Object
            },

            subscriptions: {
                type: Array
            }
        },

        data() {
            return {
                services: [],
                clonedCustomer: null,
				demo: 0,
                metaBoxes: {
                    left: [
                        {
                            translation: 'customers.meta.organization',
                            conditional: 'organization',
                            metric: 'title',
                            metricType: 'object'
                        },
                        {
                            translation: 'customers.meta.industry',
                            conditional: 'industry',
                            metric: 'title',
                            metricType: 'object'
                        },
                        {
                            translation: 'customers.meta.email',
                            metric: [],
                            metricType: 'array'
                        },
                        {
                            translation: 'customers.meta.vat',
                            metric: 'vatNumber',
                            metricType: 'string'
                        }
                    ],

                    right: [
                        {
                            translation: 'customers.meta.contactPerson',
                            metric: 'contactPerson',
                            metricType: 'string'
                        },
                        {
                            translation: 'customers.meta.phoneNumber',
                            metric: 'phoneNumber',
                            metricType: 'string'
                        },
                        {
                            translation: 'customers.meta.source',
                            metric: 'source',
                            metricType: 'string'
                        },
                        {
                            translation: 'customers.meta.crm',
                            conditional: 'crmConnection',
                            metric: 'externalCrmId',
                            metricType: 'object'
                        }
                    ]
                }
            }
        },

        computed: {
            ...mapGetters('identity', {
                identity: 'getIdentity'
            }),

			crmLink() {
				const customer = this.customer || {}
				const country = this.customer.country || null
				const crmConnection = customer.crmConnection || null

				if (! crmConnection || ! country) {
					return null
				}

				const id = crmConnection.externalCrmId

				switch (country.code) {
					case 'DK':
					case 'SE':
					case 'DE':
						if (id.includes('landing-page-')) {
							return `https://se.generaxion.tech/admin/landing-pages/view/${id.replace('landing-page-', '')}`
						}

						return `https://${country.code.toLowerCase()}.generaxion.tech/admin/customers/${id}/show`

					case 'FI':
						return `https://hub.makdev.pro/customers/${id.split('-')[1]}`
				}
			},

            inactiveIntegrations() {
                const activeServices = this.activeServices

                const integrationWhitelist = [
                    'Formstack',
                    'Freespee',
                    'Google Business Profile',
                    'Google adwords',
                    'Google analytics',
                    'Google Search Console',
                    'Facebook ads',
                    'Facebook pages'
                ]

                return this.services.filter(service => {
                    return !activeServices.includes(service.name) && integrationWhitelist.includes(service.name)
                }).sort((a,b) => b.name > a.name ? -1 : 1)
            },

            hasDataProviderConnection() {
                return this.customer.crmConnection
            },

            activeServices() {
                if (!this.customer) {
                    return []
                }

                return [...new Set(this.customer.integrations.map(integration => integration.service.name))]
            },

            relatedEmployees() {
                if (!this.clonedCustomer) {
                    return []
                }

                const productResponsibles = this.clonedCustomer.productResponsibles.map(relation => {
                    return {
                        name: relation.name,
                        email: relation.email
                    }
                })

                if (this.clonedCustomer.clientManager) {
                    productResponsibles.unshift({
                        name: this.clonedCustomer.clientManager.name,
                        email: this.clonedCustomer.clientManager.email,
                        isClientManager: true
                    })
                }

                return productResponsibles
            },

            users() {
                return this.customer.users.filter(user => user.deleted === null)
            },

            warningVisible() {
                return !this.customer.active ||
                    !this.customer.supportConsent ||
                    this.customer.status === 'Under opsigelse' ||
                    this.oneOrMoreMissingInquiryNotificationSubscriptions
            },

            oneOrMoreMissingInquiryNotificationSubscriptions() {
                const customer = this.customer

                if (!customer) {
                    return true
                }

                const users = customer.users

                return users.filter(user => {
                    return user.notificationSubscriptions.filter(subscription => subscription.slug === 'form').length === 0
                }).length > 0
            },

			formattedSubscriptions() {
				const subscriptions = this.subscriptions
					.reduce(
						(carry, subscription) => {
							const is_active = ! subscription.endDate || moment(subscription.endDate.date).isAfter(moment())

							if (is_active || ! carry[subscription.plan.id]) {
								carry[subscription.plan.id] = {
									...subscription,
									is_active
								}
							}

							return carry
						},
						{}
					)

				const active = Object.values(subscriptions).filter(subscription => !! subscription.is_active)
				const inactive = Object.values(subscriptions).filter(subscription => ! subscription.is_active)

				return {
					active,
					inactive,
					total: active.length + inactive.length
				}
			}
        },

        mounted() {
            this.initialize()
        },

        methods: {

            initialize() {
                let clonedCustomer = JSON.parse(JSON.stringify(this.customer))

                const users = clonedCustomer.users.filter(user => user.deleted === null)

                this.$set(this.metaBoxes.left[2], 'metric', users.map(user => user.email))

                clonedCustomer.updated.since = moment(clonedCustomer.updated.date).fromNow()

                this.clonedCustomer = clonedCustomer

                this.load()
            },

            load() {
                ServiceService.findAll({}, ({ data }) => {
                    this.services = data.rows
                })
            },


            labelTooltip(metric) {
                const name = this.customer.name

                switch (metric) {
                    case 'noLongerCustomer':
                        return `${name} ${$t('customers.noLongerCustomer')}`
                    case 'supportConsent':
                        return `${name} ${$t('customers.noSupportConsent')}`
                    case 'contractEnded':
                        return `${name} ${$t('customers.contractEnded')}`
                    case 'inquiryNotificationDisabledOnOneUser':
                        return $t('customers.inquiryNotificationDisabledOnOneUser')
                }
            },

            relationTooltip(relation) {
                switch (relation.isClientManager) {
                    case true:
                        return `<strong>Client manager</strong><br>${relation.email}`
                    default:
                        return relation.email
                }
            },

			addDemo() {
				if (this.demo !== 0) {
					return
				}

				this.demo = 1

				const startDate = moment()

				const endDate = moment()
				endDate.add(2, 'days')

				SubscriptionService.manageCreate({
					customer_id: this.customer.id,

					plan_id: '12308dac-a9f3-43e6-8b07-9f058c427517',
					start_date: startDate.format('YYYY-MM-DD'),
					end_date: endDate.format('YYYY-MM-DD'),
				}, () => {
					this.demo = 2
				}, () => {
					this.demo = 0
				})
			}
        },

		components: { GhostIdentityButton }
    }
</script>

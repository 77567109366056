export default [
  {
    path: "website",
    name: "frontend.reporting.website",
    component: () => import(/* webpackChunkName: "Website-dev" */ './Website'),
    meta: {
      headline: () => $t("navigation.statistics.visitors"),
      description: () => $t("topBar.descriptions.reporting.visitors"),
      tracking: "Visitors - Page view - Main page",
      datepicker: true
    }
  }
];

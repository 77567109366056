<template>
    <div class="missing-integration-cta-message">
        <div class="row">
            <div class="col-sm-6 text-center hidden-xs">
                <img :src="image" class="img-responsive">
            </div>

            <div class="col-sm-6">
                <div class="spacer visible-lg"></div>

                <h4>{{headline}}</h4>

                <img :src="image" class="visible-xs img-responsive">

                <p class="description" v-html="description"></p>

                <slot></slot>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    .missing-integration-cta-message {
        padding: 0 30px;

        .spacer {
            height: 40px;
        }

        h4 {
            font-size: 26px;
            margin-bottom: 25px;
        }

        .description {
            color: #888;
            margin-bottom: 30px;
            font-size: 16px;
        }
    }
</style>

<script>
    export default {
      props: {
        image: {
          type: String,
          required: true,
        },
        headline: {
          type: String,
          required: true,
        },
        description: {
          type: String,
          required: true,
        }
      }

    }
</script>
export default {
    organizations() {
        return {
            name: 'SpotOn Marketing',
            altName: 'SpotOn',
            slug: 'spoton-marketing',
            phoneNumbers: {
                sales: '-',
                info: '-'
            },
            logos: {
                standard: '/images/spotonlive-logo-color.svg',
                inverted: '/images/spotonlive-logo-white.svg',
                pinkBackground: '/images/opn/Generaxion_OPN_logo_pink_transparent_background.svg',
                whiteLogoOnBlack: '/images/opn/Generaxion_OPN_Bomærke_Hvid_Pink.svg',
                wordmarkWhite: "/images/opn/Generaxion_OPN_Logo-17.svg",
                arrowRight: "/images/opn/arrow-right.svg",
                closeIconWhite: "/images/opn/close_icon_white.svg",
                pinkStone: '/images/opn/pink-stone.png',
                pinkStairway: '/images/opn/pink-stairway.png',
                alt: '/images/sl-alt-logo.svg',
                loader: {
                    top: '/images/loader/spoton-red.png',
                    right: '/images/loader/spoton-orange.png',
                    arc: '/images/loader/spoton-yellow.png'
                }
            },
            colors: {
                standard: {
                    primary: {
                        hex: '#f0ad54',
                        darkHex: '#ce9146'
                    },
                    secondary: {
                        hex: '#4096ee'
                    },
                    tertiary: {
                        hex: '#d5655a'
                    },
                    link: {
                        primary: {
                            hex: '#4096ee'
                        },
                        secondary: {
                            hex: '#4096ee'
                        }
                    },
                    button: {
                        outlined: '#4096ee',
                        filled: '#f0ad54'
                    },
                    label: {
                        filled: '#4096ee'
                    },
                    card: {
                        background: '#4096ee',
                        backgroundMock: '#2782cc'
                    },
                    tooltip: {
                        primary: '#f0ad54'
                    },
                    integrations: {
                        connect: '#4096ee'
                    },
                    statuses: {
                        positive: {
                            primary: '#dcefdc',
                            secondary: '#b6ccb6',
                            tertiary: '#8a998a',
                            focus: '#6baa6b'
                        },
                        neutral: {
                            primary: '#f7f6e2',
                            secondary: '#e0dfc9',
                            tertiary: '#99978a',
                            focus: '#dbc258'
                        },
                        negative: {
                            primary: '#f2dede',
                            secondary: '#ccb4b4',
                            tertiary: '#9b8989',
                            focus: '#9b5353'
                        },
                        focus: {
                            primary: '#FFA534',
                            secondary: '#b8b8cc',
                            tertiary: '#8c8d99',
                            focus: '#6c6ca8'
                        }
                    }
                },
                inquiries: {
                    calls: '#00438f',
                    emails: '#00c49f'
                },
                trafficSources: {
                    'facebook': '#00C69E',
                    'adwords': '#7cb5ec',
                    'direct': '#434348',
                    'organic': '#FE9D45',
                    'referral': '#8085e9',
                    'other': '#ff625d',
                    'total': '#d11e48',
                }
            }
        }
    }
}

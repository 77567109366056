const BaseService = require('@/services/BaseService')

const namespace = 'adversus'

const create = (options, cb, errorCb) => {
    BaseService.post(namespace, options, cb, errorCb)
}

const checkDomain = (options, cb, errorCb) => {
    BaseService.get(`${namespace}/domain`, options, cb, errorCb)
}

export default {
  create,
  checkDomain
}

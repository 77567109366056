const BaseService = require('@/services/BaseService')

const namespace = 'contacts/statuses'

const find = (id, options, cb, errorCb) => {
  BaseService.get(namespace + '/' + id, options, cb, errorCb)
}

const findAll = (options, cb, errorCb) => {
  BaseService.get(namespace, options, cb, errorCb)
}

const update = (contactId, statusId, cb, errorCb) => {
  BaseService.post('contacts/' + contactId + '/status', {
    status: statusId
  }, cb, errorCb)
}

export {
  find,
  findAll,
  update
}

<template>
  <div class="mcc-account-edit-pop-up">
    <div class="mcc-account-edit-pop-up__container">
      <template v-if="!isLoading">
        <template v-if="accountData">
          <h5>Editing MCC Account: <strong>{{ accountData.name }}</strong></h5>
          <div style="opacity:.6;">id: {{ accountData.id }}</div>
          <div style="opacity:.6;">provider: {{ accountData.provider }}</div>
        </template>
        <template v-else>
          <h5>Create MCC Account (System user)</h5>
        </template>
        <div class="mcc-account-edit-pop-up__fields">
          <label>
            <span class="mcc-account-edit-pop-up__input-label">Name</span>
            <input type="text" v-model="formNameField" class="mcc-account-edit-pop-up__input" />
          </label>
          <label>
            <span class="mcc-account-edit-pop-up__input-label" v-if="accountData">Overwrite access token</span>
            <span class="mcc-account-edit-pop-up__input-label" v-if="!accountData">Access token</span>
            <input type="text" v-model="formAccessTokenField" class="mcc-account-edit-pop-up__input"
              placeholder="Your new access token here" />
            <small>Ensure all correct permissions are present on access token</small>
          </label>
          <label v-if="accountData === null">
            <span class="mcc-account-edit-pop-up__input-label">Provider</span>
            <input type="text" v-model="formProviderField" class="mcc-account-edit-pop-up__input" />
          </label>
        </div>

      </template>
      <template v-else>
        <h5>Loading...</h5>
      </template>
      <div class="mcc-account-edit-pop-up__actions">
        <button class="btn btn-fill btn-primary" type="submit" @click="submit" v-if="!isLoading">
          Save
        </button>
        <button class="btn" @click="cancel">
          Cancel
        </button>
      </div>
      <div class="mcc-account-edit-pop-up__delete-zone" v-if="accountData && !isLoading">
        <h5>Delete this account</h5>
        <p>
          <strong>No regrets!</strong> This will also <span style="color: red;">delete all cached accounts</span> associated with this mcc account.
          <strong>Are you sure?</strong>
        </p>
        <input type="text" v-model="formDeleteField" class="mcc-account-edit-pop-up__input"
          placeholder="Enter 'delete this account'" />
        <button class="btn btn-danger" @click="deleteAccount" v-if="isDeleteFieldValid">
          Delete
        </button>
      </div>
    </div>

  </div>
</template>
<style lang="scss" scoped>
@import '~@/assets/scss/_vars.scss';
@import '~@/assets/scss/_mixins.scss';

.mcc-account-edit-pop-up {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;

  h5,
  p {
    margin: 0;
  }

  &__container {
    width: 600px;
    max-width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 30px;
    border-radius: 4px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    background: white;
  }

  &__fields {
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  &__actions {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
  }

  &___input-label {
    line-height: 1;
  }

  &__input {
    width: 100%;
    padding: 10px;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    outline: none;
  }

  &__delete-zone {
    margin-top: 25px;
    padding-top: 25px;
    border-top: 1px solid #e0e0e0;
    display: flex;
    gap: 10px;
    flex-direction: column;

    >button {
      margin: auto;
    }
  }
}
</style>
<script>
import OAuth2IsAccessService from '@/services/oauth2/OAuth2IsAccessService'
import OAuth2AccountService from '@/services/oauth2/OAuth2AccountService'


export default {
  name: 'MccAccountForm',
  props: {
    mccAccountId: {
      type: String | null,
      default: null
    },
    presetProvider: {
      type: String | null,
      default: null
    },
  },
  data() {
    return {
      isLoading: false,
      accountData: null,
      formNameField: '',
      formAccessTokenField: '',
      formProviderField: '',
      formDeleteField: '',
    }
  },
  computed: {
    isDeleteFieldValid() {
      return this.formDeleteField === 'delete this account'
    }
  },
  methods: {
    cancel() {
      this.$emit('close')
    },
    submit() {
      if (this.accountData === null) {
        this.submitCreateForm()
      } else {
        this.submitEditForm()
      }
    },
    submitCreateForm() {
      this.isLoading = true;
      OAuth2AccountService.create(
        {
          name: this.formNameField !== '' ? this.formNameField : undefined,
          accessToken: this.formAccessTokenField !== '' ? this.formAccessTokenField : undefined,
          provider: this.formProviderField !== '' ? this.formProviderField : this.presetProvider,
        },
        ({ data }) => {
          this.isLoading = false;
          this.$emit('close')
        },
        () => {
          this.isLoading = false
        }
      )
    },
    submitEditForm() {
      this.isLoading = true;
      OAuth2AccountService.update(
        this.mccAccountId,
        {
          name: this.formNameField !== '' ? this.formNameField : undefined,
          accessToken: this.formAccessTokenField !== '' ? this.formAccessTokenField : undefined,
        },
        ({ data }) => {
          this.isLoading = false;
          this.$emit('close')
        },
        () => {
          this.isLoading = false
        }
      )
    },
    deleteAccount() {
      this.isLoading = true;
      OAuth2AccountService.delete(
        this.mccAccountId,
        {},
        ({ data }) => {
          this.isLoading = false
          this.$emit('close')
        },
        () => {
          this.isLoading = false
        }
      )

    },
    load() {
      if (this.presetProvider !== null) {
        this.formProviderField = this.presetProvider
      }
      if (this.mccAccountId === null) {
        return
      }

      this.isLoading = true;
      OAuth2AccountService.find(this.mccAccountId, {}, ({ data }) => {
        this.accountData = data;
        this.formNameField = data.name;
        this.isLoading = false
      }, () => {
        this.isLoading = false
      })
    }
  },
  mounted() {
    this.load()
  },
};
</script>
import store from '@/store'

export default [
  {
    path: '/billing',
    name: 'billing',
    component: () => import(/* webpackChunkName: "Billing" */ './Billing'),
    meta: {
      guest: true,
      tracking: 'Billing - View'
    }
  }
]

const BaseService = require('@/services/BaseService')
const namespace = 'google-adwords'

const cacheParam = 'cache=1hour&id='



const campaigns = (from, to, cb, errorCb) => {
    BaseService.get(
        namespace + '/campaigns/' + from.format('YYYY-MM-DD') + '/' + '' + to.format('YYYY-MM-DD'), {}, cb, errorCb
    )
}

const groups = (from, to, campaignId, cb, errorCb) => {
    BaseService.get(namespace + '/campaigns/' + from.format('YYYY-MM-DD') + '/' + '' + to.format('YYYY-MM-DD') + '/' + campaignId + '/groups' , {}, cb, errorCb)
}

const ads = (from, to, groupId, cb, errorCb) => {
    BaseService.get(namespace + '/campaigns/' + from.format('YYYY-MM-DD') + '/' + '' + to.format('YYYY-MM-DD') + '/groups/' + groupId + '/ads' , {}, cb, errorCb)
}

const adInsights = (adId, options, cb, errorCb) => {
    BaseService.get(namespace + '/ad/' + adId, options, cb, errorCb)
}

const cpc = (from, to, cb, errorCb) => {
    BaseService.get(
        namespace + '/cpc/' + from.format('YYYY-MM-DD') + '/' + '' + to.format('YYYY-MM-DD'), {}, cb, errorCb
    )
}

const ctr = (from, to, cb, errorCb) => {
    BaseService.get(
        namespace + '/ctr/' + from.format('YYYY-MM-DD') + '/' + '' + to.format('YYYY-MM-DD'), {}, cb, errorCb
    )
}

const clicks = (from, to, cb, errorCb) => {
    BaseService.get(
        namespace + '/clicks/' + from.format('YYYY-MM-DD') + '/' + '' + to.format('YYYY-MM-DD'), {}, cb, errorCb
    )
}

const spend = (from, to, cb, errorCb) => {
    BaseService.get(
        namespace + '/spend/' + from.format('YYYY-MM-DD') + '/' + '' + to.format('YYYY-MM-DD'), {}, cb, errorCb
    )
}

const impressions = (from, to, cb, errorCb) => {
    BaseService.get(
        namespace + '/impressions/' + from.format('YYYY-MM-DD') + '/' + '' + to.format('YYYY-MM-DD'), {}, cb, errorCb
    )
}

const conversions = (from, to, cb, errorCb) => {
    BaseService.get(
        namespace + '/conversions/' + from.format('YYYY-MM-DD') + '/' + '' + to.format('YYYY-MM-DD'), {}, cb, errorCb
    )
}

const allConversions = (from, to, cb, errorCb) => {
    BaseService.get(
        namespace + '/all-conversions/' + from.format('YYYY-MM-DD') + '/' + '' + to.format('YYYY-MM-DD'), {}, cb, errorCb
    )
}

const accountPerformance = (from, to, options, cb, errorCb) => {
    BaseService.get(
        namespace + '/account-performance/' + from.format('YYYY-MM-DD') + '/' + '' + to.format('YYYY-MM-DD'), options, cb, errorCb
    )
}

const placements = (from, to, options, cb, errorCb) => {
    BaseService.get(
        namespace + '/placements/' + from.format('YYYY-MM-DD') + '/' + '' + to.format('YYYY-MM-DD'), options, cb, errorCb
    )
}

const report = (options, cb, errorCb) => {
    BaseService.get(
        namespace + '/report', options, cb, errorCb
    )
}

const accounts = (cb) => {
    BaseService.get(namespace + '/accounts', {}, cb)
}

const integrate = (options, cb, errorCb) => {
    BaseService.post(namespace + '/integrate', options, cb, errorCb)
}

export {
    campaigns,
    groups,
    ads,
    adInsights,
    cpc,
    ctr,
    clicks,
    spend,
    impressions,
    accountPerformance,
    report,
    conversions,
    allConversions,
    accounts,
    integrate,
    placements
}

import bugsnag from 'bugsnag-js'
import { BUGSNAG_KEY, NODE_ENV } from '@/config/env'

const developmentSettings = NODE_ENV === 'development' ? { logger: null } : {}

const bugsnagClient = bugsnag({
  apiKey: BUGSNAG_KEY,
  releaseStage: NODE_ENV,
  notifyReleaseStages: ['qa', 'production'],
  ...developmentSettings
})

export default bugsnagClient

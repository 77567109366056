import TokenService from '@/services/_app/storage/TokenService'
import GhostService from '@/services/_app/users/GhostService'
import UserService from '@/services/_app/users/UserService'

const getDefaultState = () => {
  return {
    token: TokenService.getGhostToken() || null,
    ghostUserIdentity: null,
	demo: false
  }
}

const getters = {
  getGhostIdentity: state => state.token,
  isGhostIdentity: state => !! state.token,
  isGhost: state => !! state.token,
  isDemo: state => !! state.demo,
  getGhostUserIdentity: state => state.ghostUserIdentity,
}

const mutations = {
  resetState (state) {
    Object.assign(state, getDefaultState());
  },
  setLanguage (state, language) {
    state.language = language;
  },
  setToken (state, token) {
    state.token = token;
  },
  setDemo (state, demo) {
    state.demo = demo;
  },
  setGhostUserIdentity(state, ghostUserIdentity){
    state.ghostUserIdentity = ghostUserIdentity;
  }
}

const actions = {
  /**
   * Login as ghost user
   *
   * @param dispatch
   * @param commit
   * @param state
   * @param payload
   * @returns {Promise<*>}
   */
  async logInAsGhost ({ dispatch, commit, rootGetters }, payload) {
    const token = await TokenService.getToken();
    commit('setToken', token);

    await GhostService.login(payload, this.$app.$route.path);

    const ghostUserIdentity = await UserService.getGhostUserIdentity();

    commit('setGhostUserIdentity', ghostUserIdentity);

    const identity = await dispatch('identity/loadIdentity', null, { root: true });

    return identity;
  },

  /**
   * Logout ghost user and reload employee
   *
   * @param dispatch
   * @param commit
   * @param rootGetters
   * @param state
   * @returns {Promise<{name: string, params: {id: *}}>}
   */
  async logoutAsGhost ({ dispatch, commit, rootGetters, state }) {
    const redirect = await GhostService.logout();

    commit('resetState');
    commit('setGhostUserIdentity', null);

    await dispatch('identity/loadIdentity', null, { root: true });

    return redirect;
  },

  async setGhostToken ({commit, dispatch}){
    commit('setToken', TokenService.getToken());
    return await dispatch('identity/loadIdentity', null, { root: true });
  }
}

export const DEMO_INTEGRATIONS = [
	'Seo',
	'Facebook pages',
	'Trustpilot review',
	'Google MyBusiness',
	'Freespee',
	'Formstack',
	'WordPress forms',
	'Google analytics',
	'Google Ads',
	'Google Business Profile',
]

export default {
  namespaced: true,
  state: getDefaultState(),
  getters,
  mutations,
  actions
}

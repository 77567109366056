import Klaviyo from '@/app/klaviyo/Klaviyo'

export default [
  {
    path: '/klaviyo',
    name: 'frontend.klaviyo',
    component: () => import(/* webpackChunkName: "Klaviyo" */ './Klaviyo'),
    meta: {
      headline: () => $t('navigation.statistics.klaviyo'),
      description: () => $t('topBar.descriptions.klaviyo'),
      tracking: 'Klaviyo - Page view'
    }
  },
]

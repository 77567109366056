<template>
    <div class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header" v-if="title">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>

                    <h4 class="modal-title" id="myModalLabel">{{title}}</h4>
                </div>

                <div class="modal-body">
                    <slot />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: String
    },

    methods: {
        show() {
			jQuery(this.$el).modal('show')
        },

        close() {
			jQuery(this.$el).modal('hide')
        }
    }
}
</script>

export default {
  backend: [
    {
      path: '/backend/profile',
      name: 'backend.profile',
      component: () => import(/* webpackChunkName: "Users" */ './pages/EmployeeProfile')
    },
    {
      path: '/backend/users',
      name: 'backend.users',
      component: () => import(/* webpackChunkName: "Users" */ './Users')
    },
    {
      path: '/backend/users/:id',
      name: 'backend.users.view',
      component: () => import(/* webpackChunkName: "Users" */ './pages/UserView')
    }
  ],
  frontend: [
    {
      path: '/settings/profile',
      name: 'frontend.settings.profile',
      component: () => import(/* webpackChunkName: "Users" */ './pages/CustomerProfile'),
      meta: {
        headline: () => $t('topBar.myAccount'),
        description: () => $t('topBar.descriptions.profile'),
        tracking: 'Profile - Page view - Layout'
      }
    },
    {
      path: '/settings/profile/:page',
      name: 'frontend.settings.profile.page',
      component: () => import(/* webpackChunkName: "Users" */ './pages/CustomerProfile'),
      meta: {
        headline: () => $t('topBar.myAccount'),
        description: () => $t('topBar.descriptions.profile'),
        tracking: 'Profile - Page view - Tab'
      }
    }
  ]
}

<template>
    <div>
        <template>
            <template v-if="!hasLoaded">
                <loader class="loader"></loader>
            </template>

            <template v-else>
                <div class="row element">
                    <div class="col-lg-12 box" v-for="(number, index) in numbers">
                        <card>
                            <div class="pull-left info">
                                <span class="icon pe-7s-phone pull-left"></span>

                                <div class="pull-left">
                                    <span class="text-muted">{{$t('backend.callTracking.phoneNumber')}}</span><br />
                                    {{number.phonenr}}
                                </div>

                                <div class="clearfix"></div>
                            </div>

                            <div class="pull-right action" @click="remove(number.number_id)">
                                <span class="icon pe-7s-trash"></span>
                            </div>
                        </card>
                    </div>

                    <div class="col-md-12 box add-new" @click="create()">
                        <card>
                            <div class="pull-left info">
                                <span class="icon pe-7s-plus pull-left"></span>

                                <div class="pull-left">
                                    <span class="description">{{$t('backend.callTracking.addNewPhoneNumber')}}</span>
                                </div>

                                <div class="clearfix"></div>
                            </div>
                        </card>
                    </div>
                </div>
            </template>
        </template>

        <modal ref="number-create" title="Tilføj et nyt nummer">
            <number-create @created="created" :customerId="customerId"></number-create>
        </modal>
    </div>
</template>

<style lang="scss" scoped>
    .loader {
        line-height: 127px;
    }

    code.block {
        display: block;
        padding: 15px;
    }

    .element {
        .create-user-btn {
            margin-bottom: 15px;
        }

        .info {
            margin-bottom: 10px;

            .icon {
                font-size: 32pt;
                margin-right: 10px;
            }
        }

        .box {
            > .card {
                overflow: hidden;
            }
        }

        .action {
            .icon {
                cursor: pointer;
                color: #747474;
                font-size: 20pt;
                margin: 8px 0;
            }
        }

        a {
            color: #000;
        }

        .add-new {
            cursor: pointer;

            &:hover {
                > div {
                    background-color: #f7f7f7;
                }
            }

            .description {
                display: inline-block;
                margin-top: 10px;
                font-weight: 500;
            }
        }
    }
</style>

<script>
    import Loader from '@/app/shared/components/Loader'
    import Card from '@/app/shared/components/Card'
    import Modal from '@/app/layout/components/Modal'
    import NumberCreate from '@/app/integrations/components/calltracking/NumberCreate'

    const FreespeeService = require('@/services/calltracking/FreespeeService')

    export default {
        props: {
            customer: {
                type: Object,
                required: false,
                default: null
            },

            customerId: {
                type: String,
                required: true
            }
        },

        data() {
            return {
                hasLoaded: false,
                hasFailed: false,
                numbers: []
            }
        },

        mounted() {
            this.load()
        },

        methods: {
            load() {
                this.hasLoaded = false
                this.hasFailed = false
                this.numbers = []

                FreespeeService.numbers(this.customerId, (response) => {
                    const data = response.data

                    this.numbers = data.numbers

                    this.hasLoaded = true
                }, () => {
                    this.hasLoaded = true
                    this.hasFailed = true
                })
            },

            remove(numberId) {
                this.$swal({
                    text: 'Er du sikker?',
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Ja',
                    cancelButtonText: 'Nej'
                }).then(() => {
                    FreespeeService.removeNumber(this.customerId, numberId, (response) => {
                        this.load();
                    })
                })
            },

            create() {
                if (!this.$refs['number-create'].$el) {
                    setTimeout(this.create, 200);
                }

                jQuery(this.$refs['number-create'].$el).modal('show');
            },

            created() {
                if (!this.$refs['number-create'].$el) {
                    setTimeout(this.created, 200);
                }

                jQuery(this.$refs['number-create'].$el).modal('hide');

                this.load();
            },
        },

        components: {
            Loader,
            Card,
            Modal,
            NumberCreate
        }
    }
</script>

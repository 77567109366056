const BaseService = require('@/services/BaseService')

const namespace = 'keywords'

const findAll = (keywordId, options, cb, errorCb) => {
  BaseService.get(namespace + '/' + keywordId + '/rankings', options, cb, errorCb)
}

const findAllGroupedWeekly = (keywordId, options, cb, errorCb) => {
  BaseService.get(namespace + '/' + keywordId + '/rankings-grouped-weekly', options, cb, errorCb)
}

const findAllByArray = (options, cb, errorCb) => {
  BaseService.get(namespace + '/rankings-array', options, cb, errorCb)
}

export {
  findAll,
  findAllByArray,
  findAllGroupedWeekly
}

<template>
    <div class="code-window">
        <button class="btn btn-copy-to-clipboard pull-right" @click="copy" :style="organizationBackgroundColor">
            <transition name="slide-fade">
                <span class="fa fa-check fa-fw checkmark" v-show="success" />
            </transition>
            {{$t('backend.callTracking.copyScript')}}
        </button>
        <textarea class="code-input" style="opacity: 0;">{{code}}</textarea>
    </div>
</template>

<style lang="scss" scoped>
    .btn-copy-to-clipboard {
        cursor: default;
        color: #fff;
        border: none;
        opacity: 1;
        transition: all 0.2s ease;
        &:hover {
            color: #fff;
            opacity: 0.85;
        }
    }

    .checkmark {
        color: #fff;
    }
</style>

<script>
export default {
    props: {
        code: {
            type: String,
            required: false,
            default: ''
        },

        language: {
            type: String,
            required: false
        }
    },

    data() {
        return {
            success: false
        }
    },

    computed: {
        organizationBackgroundColor() {
            return {backgroundColor:$org('colors.standard.primary.hex')}
        }
    },

    methods: {
        format() {
            var MicroCode = (function(){
                return {
                    init: function(inputSel, outputSel) {
                        this.focusInput(inputSel)
                        this.listenForInput(inputSel)
                        this.renderOutput(outputSel, jQuery(inputSel)[0].value)
                        this.listenerForScroll(inputSel, outputSel)
                    },

                    listenForInput: function(inputSel) {
                        var self = this;

                        jQuery(inputSel).on('input keydown', function(key){
                            var input = this,
                                selStartPos = input.selectionStart,
                                inputVal = input.value

                            if (key.keyCode === 9){
                                input.value = inputVal.substring(0, selStartPos) + "    " + inputVal.substring(selStartPos, input.value.length);
                                input.selectionStart = selStartPos + 4
                                input.selectionEnd = selStartPos + 4
                                key.preventDefault()
                            }

                            self.renderOutput('.code-output', this.value)
                        });
                    },

                    listenerForScroll: function(inputSel, outputSel) {
                        jQuery(inputSel).on('scroll', function() {
                            jQuery(outputSel)[0].scrollTop = this.scrollTop
                        });
                    },

                    renderOutput: function(outputSel, value) {
                        jQuery('code', outputSel)
                            .html(value.replace(/&/g, "&amp;").replace(/</g, "&lt;")
                                    .replace(/>/g, "&gt;") + "\n")
                    },

                    focusInput: function(inputSel){
                        var input = jQuery(inputSel)

                        input.focus()

                        input[0].selectionStart = input[0].value.length
                        input[0].selectionEnd = input[0].value.length
                    }
                }
            })();

            MicroCode.init(
                this.$el.querySelector('.code-input'),
                this.$el.querySelector('.code-output')
            )
        },

        copy() {
            const input = this.$el.querySelector('.code-input')

            input.select()

            try {
                let successful = document.execCommand('copy')

                if (window.getSelection) {
                    window.getSelection().removeAllRanges()
                } else if (document.selection) {
                    document.selection.empty()
                }

                // Success
                this.success = true

                setTimeout(() => {
                    this.success = false
                }, 1500)
            } catch (err) {
            }
        }
    }
}
</script>
export default [
  {
    path: '/backend/faq',
    component: () => import(/* webpackChunkName: "FAQ" */ '@/app/layout/Backend'),
    children: [
      {
        path: '/backend/faq',
        name: 'backend.faq',
        component: () => import(/* webpackChunkName: "FAQ" */ './faq'),
        meta: {
          requiresEmployeeIdentity: true
        }
      }
    ]
  },
  {
    path: '/frontend/faq',
    component: () => import(/* webpackChunkName: "FAQ" */ '@/app/layout/Frontend'),
    children: [
      {
        path: '/frontend/faq',
        name: 'frontend.faq',
        component: () => import(/* webpackChunkName: "FAQ" */ './faq'),
        meta: {
          headline: () => $t('navigation.faq.title'),
          description: () => $t('navigation.faq.description'),
          tracking: 'Frequently Asked Questions',
          requiresClientIdentity: true
        }
      }
    ]
  }
]

<template>
    <div class="tabbar">
        <card>
            <slot></slot>
        </card>
    </div>
</template>

<style lang="scss">
    $breakpoint-l: 1400px;
    $break-point-xl: 1600px;
    .tabbar {
        .card {
            > .content >  div {
                > ul {
                    clear: both;
                    list-style: none;
                    padding: 0;
                    text-transform: uppercase;
                    margin: -30px;

                    li {
                        float: left;

                        &:last-child {
                            margin-right: 0;
                        }

                        a {
                            cursor: pointer;
                            display: block;
                            color: #6c6c6c;
                            font-size: 9pt;
                            font-weight: 500;
                            padding: 15px 20px 13px 20px;

                            &.active,
                            &.router-link-active {
                                border-bottom: 2px solid #4998ff;
                            }
                        }
                    }

                    @media screen and (max-width: $break-point-xl){
                        display:grid;
                        grid-template-columns: 1fr 1fr 1fr;
                        padding-bottom:30px;
                        li{
                            margin:auto;
                            &:last-child {
                                margin-right: auto;
                            }
                        }
                    }

                    @media screen and (max-width: $breakpoint-l) {
                        display:flex;
                        flex-direction:column;
                        padding-bottom:30px;
                        align-items: center;
                        li{
                            margin:auto;
                            &:last-child {
                                margin-right: auto;
                            }
                        }
                    }
                }

                > hr {
                    clear: both;
                    margin: 20px -30px;
                    margin-top: 0;
                }
            }
        }
    }
</style>

<script>
export default {
}
</script>

const getTranslatedErrorMessage = (errorCode, errorValue = null) => {
	switch (errorCode) {
		case 'NOT_ADS_USER':
			return $t('errorCodes.wrongCookieUser');
		case 'CUSTOMER_NOT_ENABLED':
			return $t('errorCodes.customerNotEnabled');
		case 'ANALYTICS_INSUFFICIENT_PERMISSIONS':
			return $t('errorCodes.analyticsInsufficientPermissions');
		case 'ANALYTICS_NO_TRACKING':
			return $t('errorCodes.analyticsNoTracking');
		case 'BUSINESS_LOCATION_SUSPENDED':
			return $t('errorCodes.businessLocationSuspended');
		case 'BUSINESS_LOCATION_DISABLED':
			return $t('errorCodes.businessLocationDisabled');
		default:
			return $t('errorCodes.unsuccessfulConnectionTo', errorValue);
	}
}

export {
	getTranslatedErrorMessage
}

<template>
    <div class="integration list-group-item" v-if="!destroyed">
        <div class="row">
            <div class="col-lg-10 col-md-10 col-sm-10 col-xs-10 ellipsis">
                <p>{{integration.connectedAccount}}</p>&nbsp;
                <p class="text-muted" style="overflow-wrap: break-word;">ID: {{integration.data}}</p>
                <p class="text-muted txt-sm" v-if="hasInstagram">instagram tilkoblet</p>
            </div>
        </div>

        <loader :inline="true" v-if="removing"/>
        <fa class="remove" icon="trash-alt" @click="remove" v-else/>
    </div>
</template>

<style lang="scss" scoped>

    .txt-sm {
        font-size: 12px;
        color: #0b967b;
        margin-bottom: 0;
    }
</style>

<script>
  const IntegrationService = require('@/services/integrations/IntegrationService')

  export default {
    props: {
      integration: Object,
      hasInstagram: Boolean
    },

    data () {
      return {
        destroyed: false,
        removing: false
      }
    },

    methods: {
      remove () {
        this.removing = true

        IntegrationService.remove('integrations', this.integration.id, () => {
          this.destroyed = true
          this.removing = false
        })
      }
    }
  }
</script>

<template>
	<div class="warning" v-if="show" :class="type">
		<slot></slot>
		<i class="fa fa-ban" @click="remove"></i>
	</div>
</template>

<style lang="scss" scoped>
@import '~@/assets/scss/_vars.scss';
@import '~@/assets/scss/_custom.scss';

	.warning {
		padding: 10px 20px;
		margin-bottom: 15px;
		border-radius: 5px;
		position: relative;
		opacity: 1;

		p {
			margin: 0;
		}

		&.success {
			background: $primarySuccess;
			border: 1px solid $secondarySuccess;
			color: $tertiarySuccess;
		}

		&.warning {
			background: $primaryWarning;
			border: 1px solid $secondaryWarning;
			color: $tertiaryWarning;
		}

		&.error {
			background: $primaryAlert;
			border: 1px solid $secondaryAlert;
			color: $tertiaryAlert;
		}

		i {
			position: absolute;
			top: 10px;
			right: 10px;
			cursor: pointer;
		}
	}

</style>

<script>
	export default {
		props: {
			type: {
				type: String,
				required: true
			}
		},

		data() {
			return {
				show: true
			}
		},

		methods: {
			remove() {
				this.show = false
			}
		}
	}
</script>

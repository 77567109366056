<template>
    <div class="inquiry-call" v-if="inquiry && inquiry.answered && !isGhostIdentity">
        <template v-if="item && item.recordingId">
            <loader v-if="!hasLoaded || !hasLoadedAudio"></loader>

            <template v-if="hasLoaded">
                <div class="audio-container" v-if="hasLoadedAudio">
                    <div class="audio-player aplayer" v-show="audio"></div>

                    <div class="text-center" v-if="!audio">
                        <div class="no-audio">
                            {{$t('inquiries.audio.notFound')}}
                        </div>
                    </div>
                </div>
            </template>
        </template>
    </div>
</template>

<style lang="scss">
    .inquiry-call {
        .audio-container {
            overflow: hidden;
            width: 100%;
            margin-bottom: 10px;
            border-radius: 2px;

            audio {
                width: 100%;
                margin-bottom: 10px;
            }

            .audio-player {
                background-color: #fff;
                box-shadow: none;
                border: 1px solid #e2e2e2;
            }

            .no-audio {
                border: 1px solid #ccc;
                background-color: #fff;
                padding: 10px;
            }
        }
    }
</style>

<script>
    import Loader from '@/app/shared/components/Loader.vue'

    let APlayer = require('aplayer')

    import {mapGetters} from 'vuex'

    const CallService = require('@/services/inquiries/CallService')
    import MixpanelService from '@/services/mixpanel/MixpanelService'

    export default {
        props: {
          inquiry: Object
        },

        data() {
            return {
                hasLoaded: false,
                hasLoadedAudio: false,
                item: null,
                audio: null,
                ap: null
            }
        },

        computed: {
          ...mapGetters('ghost', ['isGhostIdentity']),

            contact() {
                if (!this.inquiry.contact || !this.inquiry.contact.name) {
                    return $t('inquiries.unknownContact')
                }

                return this.inquiry.contact.name
            },

            number() {
                if (!this.inquiry.anum) {
                    return $t('inquiries.unknownCaller')
                }

                return this.inquiry.anum
            }
        },

        watch: {
            inquiry() {
                this.load()
            }
        },

        mounted() {
            this.load()
        },

        destroyed() {
            if (!this.ap) {
                return;
            }

            this.ap.pause()
        },

        methods: {
            load() {
                this.hasLoaded = false
                this.hasLoadedAudio = false

                CallService.find(this.inquiry.id, {}, (response) => {
                    this.item = response.data

                    this.hasLoaded = true

                    if (this.item.recordingId && !this.isGhostIdentity) {
                        this.loadAudio()
                    }
                }, response => {
                    this.hasLoaded = true
                })
            },

            loadAudio() {
                CallService.audio(this.inquiry.id, {}, response => {
                    this.audio = 'data:audio/ogg;base64,' + response.data.audio
                    this.hasLoadedAudio = true

                    setTimeout(() => {
                        if (!this.$el.querySelector('.audio-player')) {
                            return
                        }

                        this.ap = new APlayer({
                            element: this.$el.querySelector('.audio-player'),
                            mode: 'order',
                            music: {
                                title: this.contact,
                                author: this.number,
                                url: this.audio,
                                pic: '/images/audio_player.jpg',
                                lrc: '[00:00.00]lrc here\n[00:01.00]aplayer'
                            }
                        });

                        this.ap.on('play', () => {
                            MixpanelService.track('Inquiries - Recording - Play')
                        })
                    }, 250);

                    this.hasLoaded = true
                }, (error) => {
                    this.hasLoaded = true
                    this.hasLoadedAudio = true
                });
            }
        },
        components: {
            Loader
        }
    }
</script>

import ApiService from '@/services/_app/ApiService'

const namespace = 'leadenhancer'

export default class LeadEnhancerService {
    static async search({ query, countries = false }) {
        const queryCountries = countries ? `?countries=${countries}` : ''

        return ApiService.get(`${namespace}/search-orgs-by-name/${query}${queryCountries}`)
    }

    static async sendMail(leads = '') {
      return ApiService.post(`${namespace}/mail-le-ids?ids=${leads}`)
    }
}

<template>
    <div class="stats">
        <div class="container-fluid">
            <div class="row">
                <div class="col-xs-4">
                    <div class="box calls">
                        <div class="wrapper" :style="organizationColorCalls">
                            <span class="icon pe-7s-phone"></span>&nbsp;

                            <span class="text" v-if="hasLoaded">
                                <span class="num">{{calls}}</span>
                                <small class="text-muted">{{$tc('inquiries.calls', calls)}}</small>
                            </span>

                            <div v-else class="loader-wrapper">
                                <loader :inline="true"></loader>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-xs-4">
                    <div class="box mails">
                        <div class="wrapper" :style="organizationColorEmails">
                            <span class="icon pe-7s-mail-open-file"></span>&nbsp;

                            <span class="text" v-if="hasLoaded">
                                <span class="num">{{mails}}</span>
                                <small class="text-muted">{{$tc('inquiries.emails', mails)}}</small>
                            </span>

                            <div v-else class="loader-wrapper">
                                <loader :inline="true"></loader>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-xs-4">
                    <div class="box total">
                        <div class="wrapper">
                            <span class="icon pe-7s-comment"></span> &nbsp;

                            <span class="text" v-if="hasLoaded">
                                <span class="num">{{total}}</span>
                                <small class="text-muted">{{$t('inquiries.total')}}</small>
                            </span>

                            <div v-else class="loader-wrapper">
                                <loader :inline="true"></loader>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  const InquiryService = require('@/services/inquiries/InquiryService')
  const FormService = require('@/services/inquiries/FormService')

  import moment from 'moment'
  import datepickerMixin from '@/app/datepicker/datepicker.mixin'

  export default {
    mixins: [datepickerMixin],
    data() {
      return {
        total: 0,
        mails: 0,
        hasLoaded: false,
        seriesLoaded: 0
      }
    },

    watch: {
      seriesLoaded() {
        if (this.seriesLoaded != 2) {
          return;
        }

        this.hasLoaded = true

        this.$emit('hasLoaded')
      }
    },
    mounted() {
      this.load()
    },

    computed: {
	  ...mapGetters('customer', {
		  customer: 'getCustomer'
	  }),

      calls() {
        return this.total - this.mails
      },

      organizationColorEmails() {
        return {color:$org('colors.inquiries.emails')}
      },

      organizationColorCalls() {
        return {color:$org('colors.inquiries.calls')}
      }
    },
    methods: {
      load() {
        this.seriesLoaded = 0
        this.hasLoaded = false

        let to = moment(this.to)
        to.add(1, 'day')

        const data = {
          limit: 1,
          filter_groups: [
            {
              filters: [
                {
                  key: 'date',
                  value: this.from.format('YYYY-MM-DD'),
                  operator: 'gt',
                },
                {
                  key: 'date',
                  value: to.format('YYYY-MM-DD'),
                  operator: 'lte',
                },
                {
                  key: 'customer',
                  value: this.customer.id,
                  operator: 'eq',
                }
              ]
            }
          ],
          sort: [{
            key: 'date',
            direction: 'DESC'
          }]
        };

        InquiryService.findAll(data, (response) => {
          const body = response.data

          this.total = body.total
          this.seriesLoaded++
        });

        FormService.findAll(data, (response) => {
          const body = response.data

          this.mails = body.total
          this.seriesLoaded++
        });
      }
    }
  }
</script>

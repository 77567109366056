<template>
	<tr>
		<td>
			{{campaign.name}}<br />

			<small class="text-muted">
				{{$t('klaviyo.created')}} {{campaign.created | moment('from', 'now')}}
			</small>
		</td>

		<td>
			{{campaign.num_recipients}}<br />
			<small class="text-muted">
				{{campaign.metrics.email_bounces}} {{$tc('klaviyo.bouncesPlural', campaign.metrics.email_bounces)}}
			</small>
		</td>

		<td>
			{{campaign.metrics.email_opens}}&nbsp;

			<span class="text-muted" v-if="campaign.num_recipients && campaign.metrics.email_opens">
				({{campaign.metrics.email_opens / campaign.num_recipients * 100 | fixedNumber(0)}}%)
			</span>
		</td>

		<td>
			{{ campaign.metrics.email_clicks + campaign.metrics.sms_clicks }}
		</td>

		<td>
			{{ amount(campaign.metrics.revenue) }}
		</td>

		<td>
			<a :href="url" target="_blank" rel="nofollow noreferrer" class="btn btn-sm btn-default btn-fill mt-2 mt-lg-0 me-2">
				{{$t('klaviyo.openInMailChimp')}}
			</a>
		</td>
	</tr>
</template>

<style lang="scss" scoped>
tr {
	td {
		vertical-align: top;
	}
}

.btn-link {
	color: #000;
	border: 0;
}
</style>

<script>
export default {
	props: {
		campaign: Object
	},

	data: () => ({
		hasLoaded: false,
		metrics: null
	}),

	computed: {
		url() {
			return `https://www.klaviyo.com/campaign/${this.campaign.id}/web-view`
		}
	},

	methods: {
		amount(amount) {
			const locale = this.$i18n.locale.replace('_', '-')
			return Number(amount).toLocaleString(locale)
		}
	}
}
</script>

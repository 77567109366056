const BaseService = require('@/services/BaseService')

const integrations = 'integrations'

const find = (namespace, id, options, cb, errorCb) => {
  BaseService.get(`${namespace.length === 0 ? integrations : namespace}/${id}`, options, cb, errorCb)
}

const findAll = (namespace, options, cb, errorCb) => {
  BaseService.get(namespace.length === 0 ? integrations : namespace, options, cb, errorCb)
}

const remove = (namespace, id, cb, errorCb) => {
  return BaseService.remove(`${namespace.length === 0 ? integrations : namespace}/${id}`, {}, cb, errorCb)
}

const create = (options, cb, errorCb) => {
  BaseService.post(integrations, options, cb, errorCb)
}

export {
  find,
  findAll,
  remove,
  create
}

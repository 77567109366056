import ApiService from "@/services/_app/ApiService";

const namespace = "traffic-sources";

export default class TrafficSourcesService {
    static find(id, options) {
        return ApiService.get(`${namespace}/${id}`, options);
    }
    static findAll(options) {
        return ApiService.get(namespace, options)
    }
}

<template>
    <span v-if="clientCategory" class="client-category" :class="clientCategoryClass" v-text="clientCategory"></span>
</template>

<style lang="scss">
.client-category {
    color: #fff;
    font-weight: 600;
    font-size: 9pt;
    background-color: #878787;
    padding: 4px 7px;
    border-radius: 20px;

    &.category-a {
        background-color: #00af8e;
    }

    &.category-b {
        background-color: #fac725;
    }

    &.category-c {
        background-color: #9c61ff;
    }

}
</style>

<script>
export default {
    props: {
        clientCategory: String,
    },

    computed: {
        clientCategoryClass() {
            switch (this.clientCategory) {
                case 'A+':
                case 'A':
                default:
                    return 'category-a'
                case 'B':
                    return 'category-b'
                case 'C':
                    return 'category-c'
            }
        }
    }
}
</script>

<template>
    <div>
        <div class="row">
            <div class="col-lg-8 col-md-12">
			  <create-backend-keywords v-if="keywords"
									   :keywords="keywords"
									   :customerId="customerId"
			  />

              <div class="row" v-else>
                <div class="col-lg-12 text-center" style="height: 100px; position: relative;">
                  <logo-loader />
                </div>
              </div>
            </div>
        </div>

        <hr>

        <div class="row">
            <div class="col-lg-12">
                <keywords :customerId="customerId"/>
            </div>
        </div>
    </div>
</template>

<script>
    import CreateKeywordsForm from '@/app/seo/components/CreateKeywordsForm'
    import Keywords from '@/app/seo/components/Keywords'
    import LogoLoader from '@/app/shared/components/LogoLoader'
	import CreateBackendKeywords from "@/app/customers/components/CreateBackendKeywords";

    const KeywordService = require('@/services/seo/KeywordService')

    export default {
        data() {
            return {
                keywords: null,
                page: 'keywords',
            }
        },
        computed: {
            customerId() {
                return this.$route.params.id
            },
        },

        mounted() {
            this.load()

            eventHub.$on('keyword.created', this.load)
            eventHub.$on('keyword.removed', this.load)
        },

        destroyed() {
            eventHub.$off('keyword.created', this.load)
            eventHub.$off('keyword.removed', this.load)
        },

        methods: {
            load() {
                KeywordService.findAll({
                    includes: ['searchLocation'],
                    filter_groups: [
                        {
                            filters: [
                                {
                                    key: 'customer',
                                    value: this.customerId,
                                    operator: 'eq'
                                }
                            ]
                        }
                    ]
                }, response => {
                    const { rows } = response.data

                    this.keywords = rows
                })
            },
        },

        components: {
			CreateBackendKeywords,
            CreateKeywordsForm,
            Keywords,
            LogoLoader
        }
    }
</script>

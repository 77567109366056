import Vue from 'vue'
import CardBox from '@/app/shared/components/CardBox'
import Card from '@/app/shared/components/Card'
import Loader from '@/app/shared/components/Loader'
import LogoLoader from '@/app/shared/components/LogoLoader'

Vue.component('card-box', CardBox)
Vue.component('card', Card)
Vue.component('loader', Loader)
Vue.component('logo-loader', LogoLoader)

import Multiselect from 'vue-multiselect'
Vue.component('multiselect', Multiselect)

<template>
    <div class="inquiry-form">
        <loader v-if="!hasLoaded"></loader>

        <template v-if="hasLoaded">
            <template v-for="(value, index) in data">
                <strong>{{value.key}}</strong><br />
                <div v-html="value.value"></div><br />
            </template>
        </template>
    </div>
</template>

<style lang="scss">
</style>

<script>
    import Loader from '@/app/shared/components/Loader'

    const FormService = require('@/services/inquiries/FormService')

    export default {
        props: {
            inquiry: Object
        },

        watch: {
            inquiry() {
                this.load();
            }
        },

        data() {
            return {
                hasLoaded: false,
                item: null,
                data: null
            }
        },

        mounted() {
            this.load();
        },

        methods: {
            load() {
                this.hasLoaded = false;
                this.item = null;
                this.data = null;

                FormService.find(this.inquiry.id, {}, (response) => {
                    this.item = response.data;

                    this.data = JSON.parse(this.item.data);

                    this.hasLoaded = true
                }, () => {
                    this.hasLoaded = true
                });
            }
        },

        components: {
            Loader
        }
    }
</script>

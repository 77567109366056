const BaseService = require('@/services/BaseService')

const namespace = 'access-api'

const findAllMcc = (options, cb, errorCb) => {
    BaseService.get(namespace + '/find-all-mcc', options, cb, errorCb)
}

export default {
    findAllMcc
}

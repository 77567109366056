const BaseService = require('@/services/BaseService')

const namespace = 'facebook-ads/cached-account'

const find = (id, options, cb, errorCb) => {
    BaseService.get(namespace + '/' + id, options, cb, errorCb)
}

const findAll = (options, cb, errorCb) => {
    BaseService.get(namespace, options, cb, errorCb)
}

const integrate = (options, cb, errorCb) => {
    BaseService.post(namespace + '/integrate', options, cb, errorCb)
}

export default {
  find,
  findAll,
  integrate
}

<template>
    <div class="keyword-list-item row table-body" @click="toggleItemExpand" :class="{ 'has-been-expanded': expanded && currentRank }">
        <div class="col-lg-6 col-md-5 col-sm-12 col-xs-12 table-body__column">
            <template v-if="keyword.tags && keyword.tags.includes('usertag')">
                <span v-if="keyword.tags && keyword.tags.includes('usertag')" class="label label-info" :style="organizationColor">{{$t('seo.userCreatedKeywordLabel')}}</span>
            </template>

            <span class="column-text">{{keyword.keyword}}</span><br>

            <template v-if="hasFetchedPosition">
                <small v-if="currentRank.url">
                    <a class="text-muted" :href="currentRank.url" target="_blank" rel="noopener noreferrer">
                        {{currentRank.url}}
                    </a>
                </small>
            </template>
        </div>

        <div class="col-lg-2 col-md-1 col-sm-2 col-xs-4 table-body__column table-body__column--space text-center no-ellipsis">
            <template v-if="hasFetchedPosition">
                <small class="sm-header">{{$t('seo.tableHeader.currentRanking')}}</small>
                <span class="column-text" v-if="currentRank.rank < 101" v-text="currentRank.rank"/>

                <template v-else>
                    <small class="column-text hidden-md hidden-sm hidden-xs">{{$t('seo.notInTop')}}</small>

                    <i class="fa fa-warning g-has-title hidden-lg" :title="$t('seo.notInTop')"/>
                </template>
            </template>

            <span class="fa fa-circle-o-notch fa-spin fa-fw" v-else/>
        </div>

        <div class="col-lg-1 col-md-2 col-sm-2 col-xs-4 text-center table-body__column table-body__column--space">
            <template v-if="hasFetchedPosition && currentRank.rank < 101">
                <small class="sm-header">{{$t('seo.tableHeader.progress')}}</small>
                <span class="column-text" v-if="change === 0">-</span>
                <span class="column-text" v-else :class="[change > 0 ? 'success' : 'warning' ]">
                    <i v-if="absoluteChange" :class="[ change > 0 ? 'fa fa-caret-up up' : 'fa fa-caret-down down' ]"/>
                    {{absoluteChange}}
                </span>
            </template>
        </div>

        <div class="col-lg-1 col-md-2 col-sm-3 col-xs-6 text-center table-body__column table-body__column--space">
            <span class="flag-icon" :class="flag"></span>
            <small v-tooltip="keyword.locationName" v-if="keyword.locationName !== null">{{keyword.locationName}}</small>
            <small v-tooltip="searchLocation.name" v-else-if="searchLocation">{{searchLocation.name}}</small>
        </div>

        <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6 text-center table-body__column table-body__column--space">
            <a :href="currentRank.searchUrl" :title="$t('seo.keywordList.visitGoogle')" class="google-url" target="_blank" rel="noopener noreferrer" v-if="currentRank && currentRank.searchUrl">
                <i class="fa fa-google fa-fw"></i>
            </a>
        </div>

        <div class="col-lg-1 col-md-1 col-sm-2 col-xs-6 text-center table-body__column table-body__column--space">
            <button type="button" class="starred" @click.stop.prevent="onToggleStar" :class="{ active: !! keyword.starred }">
				<i v-if="!! keyword.starred" class="fas fa-star fa-fw" />
				<i v-else class="far fa-star fa-fw" />
			</button>
        </div>

        <template v-if="expanded">
            <div class="col-lg-12">
                <div class="clearfix"></div>
                <div class="row" :class="{ 'expanded-view': currentRank }">
                    <keyword-chart :keyword="keyword" :employeeAccess="true" :currentRank="currentRank"/>
                </div>
            </div>
        </template>
    </div>
</template>

<style lang="scss" scoped>
    @import '~@/assets/scss/_vars.scss';
    @import '~@/assets/scss/_mixins.scss';
    @import '~@/assets/scss/_custom.scss';

	.starred {
		color: #f8aa1c;

		background-color: transparent;
		box-shadow: none;
		border: 0;
		padding: 0;

		font-size: 18px;
	}

    .has-been-expanded {
        border: 1px solid #f0f0f0;
        min-height: 214px;
        padding: 30px 20px 20px 20px !important;
    }

    .expanded-view {
        padding-top: 30px;
    }

    .google-url {
        color: #000;

        &:hover {
            color: #555555;
        }

        i {
            font-size: 14px;
        }
    }

    a:focus {
        color: #9a9a9a;
    }

    .table-body {
        padding: 10px 20px;
        overflow: hidden;
        position: relative;
        cursor: pointer;

        &:nth-child(even) {
            background: #fcfcfc;
        }

        .table-body__column {
            min-height: 42px;
            padding-right: 20px;

            &:not(.no-ellipsis) {
                @include ellipsis;
            }

            a {
                &:hover {
                    color: #7A7A7A;
                }
            }

            &.table-body__column--space {
                padding-top: 10px;
            }
        }

        .label {
            margin-right: 5px;
        }

        .success {
            color: $focusSuccess;
        }

        .warning {
            color: $focusAlert;
        }
    }

    .sm-header {
        font-size: 10px;
        display: none;
    }

    .fa.fa-warning {
        color: #a3a2a3;
    }

    .label.label-default {
        cursor: pointer;

        &:hover {
            background: $focusAlert;
        }
    }

    @media screen and (max-width: 991px) {
        .sm-header {
            display: block;
        }

        .table-body {
            .table-body__column {
                min-height: 63px;
                padding: 5px;
                white-space: normal;
            }
        }
    }
</style>

<script>
  import KeywordChart from '@/app/seo/components/KeywordChart'

  const RankingService = require('@/services/seo/RankingService')
  const LocationService = require('@/services/seo/LocationService')

  import { mapGetters } from 'vuex'

  export default {
    props: {
      keyword: {
        type: Object,
        required: true
      },

      employeeAccess: {
        type: Boolean,
        required: true
      }
    },

    watch: {
      hasFetchedPosition () {
        this.$emit('keyword-rank-change', {
          keyword: this.keyword,
          rankChange: this.change
        })
      }
    },

    data () {
      return {
        expanded: false,
        initialRank: null,
        currentRank: null,
        searchLocation: null,
      }
    },

    computed: {
      ...mapGetters('identity', {
        identity: 'getIdentity'
      }),
      ...mapGetters('customer', {
        customer: 'getCustomer'
      }),

      organizationColor () {
        return { background: $org('colors.standard.label.filled') }
      },

      flag () {
        const identity = this.identity
        const keyword = this.keyword
        const searchLocation = this.searchLocation
        const customer = this.customer

        let country = null

        if (customer) {
          country = customer.country
        }

        if (keyword.country) {
          country = keyword.country
        }

        if (searchLocation && searchLocation.country) {
          country = searchLocation.country
        }

        return country ? 'flag-icon-' + country.code.toLowerCase() : 'no-country'
      },

      hasFetchedPosition () {
        return this.initialRank && this.currentRank
      },

      change () {
        if (!this.hasFetchedPosition) {
          return null
        }

        return parseInt(this.initialRank.rank - this.currentRank.rank)
      },

      absoluteChange () {
        return this.change ? Math.abs(this.change) : null
      }
    },

    mounted () {
      this.load()
      this.loadSearchLocation()
    },

    methods: {
      load () {
        this.loadInitialRank()
        this.loadCurrentRank()
      },

      loadSearchLocation () {
        const keyword = this.keyword
        const searchLocation = keyword.searchLocation

        if (!searchLocation) {
          return
        }

        LocationService.find(searchLocation.id, { includes: ['country'] }, response => {
          const data = response.data

          this.searchLocation = data
        })
      },

      loadInitialRank () {
        const keyword = this.keyword

        RankingService.findAll(keyword.id, {
          limit: 1,
          sort: [{
            key: 'date',
            direction: 'ASC'
          }]
        }, (response) => {
          const data = response.data
          const rows = data.rows

          this.initialRank = rows.length === 0 ? { rank: 101 } : rows[0]
        })
      },

      loadCurrentRank () {
        const keyword = this.keyword

        RankingService.findAll(keyword.id, {
          limit: 1,

          sort: [{
            key: 'date',
            direction: 'DESC'
          }]
        }, (response) => {
          const data = response.data
          const rows = data.rows

          this.currentRank = rows.length === 0 ? { rank: 101 } : rows[0]

          this.$emit('current-rank', {
            keyword: this.keyword,
            rank: this.currentRank.rank
          })
        })
      },

      toggleItemExpand () {
        return this.expanded = !this.expanded
      },

	  onToggleStar() {
		  this.$emit('toggleStar')
	  }
    },

    components: {
      KeywordChart
    }
  }
</script>

<template>
    <div class="info-box text-center">
        <span class="icon" :class="icon"></span>

        <span v-if="number !== null || hasLoaded">{{number | numberFormat}} {{affix}}</span>
        <span class="mock" v-else>ab</span>

        <span class="unit">{{unit}}</span>
        <small class="check-for-empty text-muted">
            <template v-if="to && from">
                {{from.format('Do MMMM YYYY')}} - {{to.format('Do MMMM YYYY')}}
            </template>
            <template v-if="explainer">
                {{explainer}}
            </template>
        </small>
    </div>
</template>

<style lang="scss" scoped>
    @import '~@/assets/scss/_vars.scss';

    .info-box {
        .center-item {
            flex: none;
        }

        span {
            display: block;
            margin: 10px;
            font-size: 20pt;

            &:first-child {
                margin-top: 0;
            }

            &:last-child {
                margin-bottom: 0;
            }

            &.unit {
                color: #4b4b4b;
                font-size: 12pt;
                margin-bottom: 0;
            }

            &.icon {
                font-size: 25pt;
            }

            .loader {
                font-size: 20pt;
            }
        }

        .check-for-empty {
            &:empty {
                display: block;
                height: 14px;
                background: none;
            }
        }
    }
</style>

<script>
    export default {
        props: {
            icon: String,

            number: {
                type: [String, Number],
                default: null
            },

            from: {
                type: Object,
                required: false
            },
            to: {
                type: Object,
                required: false
            },

            unit: {
                type: String,
                required: false
            },

            affix: {
                type: String,
                required: false
            },

            hasLoaded: {
                type: Boolean,
                default: false
            },

            explainer: {
                type: String,
                default: null
            }
        }
    }
</script>

import ApiService from '@/services/_app/ApiService'

const namespace = 'customers'

export default class CustomerService {
    static find (id, options) {
        return ApiService.get(namespace + '/' + id, options)
    }

    static findAll (options) {
        return ApiService.get(namespace, options)
    }

    static put (id, data) {
        return ApiService.put(namespace + '/' + id, data)
    }

    static requestDelete (id) {
        return ApiService.get(namespace + '/request-delete/' + id)
    }

    static checkDomain (data) {
        return ApiService.post(namespace + '/check-domain-redirects/', data)
    }

    static integrations (id) {
        return ApiService.get(namespace + '/customer-integrations/' + id)
    }
}

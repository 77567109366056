<template>
  <section class="auth">
    <div class="container-fluid">
      <div class="row">
        <main class="col-sm-6 col-xs-12 sign-in">
          <div class="center">
            <div>
              <div class="box-container">
                <div class="logo">
                  <img src="@/assets/images/Generaxion_OPN_Logo_Sort.svg" />
                </div>

                <card v-if="isAuthenticating || isLoggedIn">
                  <loader />
                </card>

                <router-view v-else />
              </div>
            </div>
          </div>
        </main>

        <aside class="col-sm-6 hidden-xs info-box">
          <auth-animation class="auth-animation" />
          <img class="pink-stairway" src="@/assets/images/pink-stairway-kopi.png" />
          <img class="symbol-background" src="@/assets/images/Generaxion_OPN_Bogmærk_Hvid_pink_gradient.svg" />
        </aside>
      </div>
    </div>
  </section>
</template>

<script>
import AuthAnimation from "@/app/auth/components/AuthAnimation";
import { mapGetters, mapActions } from "vuex";
import MixpanelService from "@/services/mixpanel/MixpanelService";

export default {
  name: "Auth",
  components: { AuthAnimation },
  created() {
    //if (this.$route.query.code) {
    //  const redirectTo = await this.magic(this.$route);
    //  MixpanelService.track("Auth - Login", { type: "magic" });
    //  this.$router.push(redirectTo);
    //  this.$nextTick();
    //} else if (this.isLoggedIn && this.hasIdentity) {
    //  this.redirectIfLoggedIn();
    //}

    this.checkForMagicOrRedirection();
  },
  computed: {
    ...mapGetters("auth", {
      isAuthenticating: "isAuthenticating",
      isLoggedIn: "isLoggedIn"
    }),
    ...mapGetters("identity", {
      hasIdentity: "hasIdentity",
      isClient: "isClient",
    }),
    ...mapGetters("customer", {
      customer: "getCustomer"
    })
  },
  watch: {
    $route() {
      this.checkForMagicOrRedirection();
    }
  },
  methods: {
    ...mapActions("auth", {
      magic: "magic"
    }),

    checkForMagicOrRedirection() {
      if (this.$route.query.code) this.loginIfMagicPresent();
      else if (this.isLoggedIn && this.hasIdentity) this.redirectIfLoggedIn();
    },

    async loginIfMagicPresent() {
      const redirectTo = await this.magic(this.$route);
      MixpanelService.track("Auth - Login", { type: "magic" });
      this.$router.push(redirectTo);
      this.$nextTick();
    },

    redirectIfLoggedIn() {
      if (this.customer && !this.customer.onboarded) {
        this.$router.push({
          name: "onboarding.step-1"
        });

        return;
      }

      this.$router.push({
        name: this.isClient ? "frontend.overview" : "backend.customers"
      });
    }
  }
};
</script>

<template>
    <div class="content">
        <img src="@/assets/images/Generaxion_OPN_Logo_Wordmark_white.svg" width="300" alt="">
        <h1 class="headline" v-html="$t('intro.headline')"></h1>
        <p>{{$t('intro.description')}}</p>

        <div class="animation-box">
            <img class="logo-animation animation-google-mybusiness" src="@/assets/images/onboarding/google-business.svg" width="50" height="50">
            <img class="logo-animation animation-google-analytics" src="@/assets/images/onboarding/google-analytics.svg" width="50" height="50">
            <img class="logo-animation animation-google-adwords" src="@/assets/images/onboarding/google-adwords.svg" width="50" height="50">
            <img class="logo-animation animation-google-plus" src="@/assets/images/onboarding/google-plus.svg" width="50" height="50">
            <img class="logo-animation animation-google-search-console" src="@/assets/images/onboarding/google-search-console.svg" width="50" height="50">
            <img class="logo-animation animation-facebook-ads" src="@/assets/images/onboarding/facebook-ads.svg" width="50" height="50">
            <img class="logo-animation animation-facebook" src="@/assets/images/onboarding/facebook.svg" width="50" height="50">
            <img class="logo-animation animation-facebook-pages" src="@/assets/images/onboarding/facebook-pages.svg" width="50" height="50">
            <img class="logo-animation animation-google" src="@/assets/images/onboarding/trustpilot.png" width="50" height="50">
            <img class="logo-animation animation-mailchimp" src="@/assets/images/mailchimp.svg" width="50" height="50">
        </div>
    </div>
</template>

<script>
  import { TimelineMax, Sine } from 'gsap'

  export default {
    name: 'AuthAnimation',
    mounted() {
      const animationTime = 4
      let animationImages = this.$el.querySelectorAll('.logo-animation')

      const inAnimation = {
        'opacity': '1',
        'left': '50%',
        'transform': 'translateX(-50%) scale(1)',
        ease: Sine.easeInOut
      }

      const outAnimation = {
        'opacity': '0',
        'left': '100%',
        'transform': 'translateX(-50%) scale(0.7)',
        ease: Sine.easeInOut
      }

      let timeline = new TimelineMax({ repeat: -1 })
      for (let i = 0; i < animationImages.length; i++) {
        timeline.to(animationImages[i], animationTime / 2, inAnimation, `-=${animationTime / 2}`)
        timeline.to(animationImages[i], animationTime / 2, outAnimation)
      }
    }
  }
</script>

<style scoped>

</style>

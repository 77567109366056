<template>
    <div>
        <template v-if="hasLoaded">
            <mobile-table v-if="total">
                <div class="col-custom-row">
                    <div v-if="!isFreemium" class="col-custom-6-12" style="padding: 0 5px;">
                        <div class="label-container">
                            <div class="row">
                                <div class="col-lg-8">
                                    <span class="label label-info" :style="organizationColor">{{$t('seo.userCreatedKeywordLabel')}}</span>
                                    <small class="text-muted" v-html="$t('seo.userCreatedKeywordLabelDescription')"></small>
                                    <div class="clearfix"></div>
                                </div>
                                <div class="col-lg-4 text-center">
                                    <small class="text-muted" style="float: none; padding: 8px;">
                                        <strong>
                                            {{$t('seo.toggleUserAddedKeywords')}}
                                        </strong>
                                    </small><br>
                                    <switches color="default" v-model="isHidden" style="display: inline-block;" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-custom-6-12" style="padding: 0 5px;" v-if="!customerId">
                        <div class="label-container">
                            <p>
                                <strong>{{$t('seo.nextUpdate')}}</strong> {{nextUpdate | moment("from", "now")}}
                            </p>
                            <small class="text-muted">
                                <strong>{{$t('seo.latestUpdate')}}</strong> {{lastUpdated | moment("from", "now")}}
                            </small>
                        </div>
                    </div>
                </div>

                <div class="row table-header" id="scroll">
                    <div class="col-lg-6 col-md-5 hidden-sm hidden-xs table-body__column">
                        <small class="text-muted">{{$t('seo.tableHeader.keywords')}}</small>
                    </div>

                    <div class="col-lg-2 col-md-1 hidden-sm hidden-xs table-body__column col-clickable" @click="selectSortMode('current-rank')">
                        <small class="text-muted header-text">{{$t('seo.tableHeader.currentRanking')}}</small>
                    </div>

                    <div class="col-lg-1 col-md-2 hidden-sm hidden-xs table-body__column col-clickable" @click="selectSortMode('rank-change')">
                        <small class="text-muted header-text">{{$t('seo.tableHeader.progress')}}</small>
                    </div>

                    <div class="col-lg-1 col-md-2 hidden-sm hidden-xs table-body__column">
                        <small class="text-muted header-text">{{$t('seo.tableHeader.location')}}</small>
                    </div>

                    <div class="col-lg-1 col-md-1 hidden-sm hidden-xs table-body__column">
                        <small class="text-muted header-text">{{$t('seo.tableHeader.googleUrl')}}</small>
                    </div>

                    <div class="col-lg-1 col-md-1 hidden-sm hidden-xs table-body__column">
                        <small class="text-muted header-text">Starred</small>
                    </div>
                </div>

                <keyword-list-item :key="keyword.id"
                                   :keyword="keyword"
                                   v-for="keyword in sortedKeywords"
                                   v-if="hasLoaded"
                                   v-on:current-rank="setCurrentRankForKeyword"
                                   v-on:keyword-rank-change="setRankChangeForKeyword"
								   @toggleStar="onToggleStar(keyword.id)"
                                   :employeeAccess="!! customerId"
				/>

            </mobile-table>

            <div class="text-center" v-else>
                <span class="text-muted">{{$t('seo.noKeywordsSelected')}}</span>
            </div>
        </template>

        <div class="row" v-else>
            <div class="col-lg-12 text-center" style="height: 120px; position: relative;">
                <logo-loader />
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    @import '~@/assets/scss/_vars.scss';
    @import '~@/assets/scss/_custom.scss';

    .table-header {
        padding: 10px 20px;
        .table-body__column {
            border-bottom: 1px solid #f0f0f0;
            padding-bottom: 20px;
            min-height: 81px;
        }

        div:not(:last-child) {
            border-right: 1px solid #f0f0f0;
        }
    }

    .col-clickable {
        cursor: pointer;
    }

    .label-container {
        width: 100%;
        padding: 30px;
        margin: 10px 0;
        background: #f9f9f9;
        border-radius: 3px;
        min-height: 97px;

        p {
            margin: 0;
        }

        span {
            float: left;
            display: inline-block;
            margin-right: 20px;
            margin-bottom: 10px;
        }

        small {
            float: left;
            display: inline-block;
        }
    }

    @media screen and (max-width: 1400px) {
        small.header-text {
            position: absolute;
            top: 10px;
            left: 0;
            display: block;
            transform-origin: 40% 60%;
            transform: rotate(70deg);
            padding: 0;
            margin: 0;
            max-width: 80px;
        }

        .table-header {
            .table-body__column {
                padding: 0;
                margin: 0;
                border-bottom: none;
            }
            div:not(:last-child) {
                border-right: none;
            }
        }
    }
</style>

<script>
    import KeywordListItem from '@/app/seo/components/KeywordListItem'
    import MobileTable from '@/app/layout/components/MobileTable'
    import LogoLoader from '@/app/shared/components/LogoLoader'
    import Switches from 'vue-switches'

    const KeywordService = require('@/services/seo/KeywordService')

    import organization from '@/mixins/customer/organization'
	import {mapGetters} from "vuex";

    const moment = require('moment')

    export default {
        mixins: [organization],

        props: {
            customerId: {
                type: String,
                required: false
            }
        },

        data() {
            return {
                hasLoaded: false,
                planningToReload: false,
                keywords: {},
                keywordStash: {},
                isHidden: false,
                metric: 'current-rank',
                ascending: true
            }
        },

        computed: {
			...mapGetters('customer', {
				customer: 'getCustomer'
			}),

            lastUpdated() {
                let start = moment()

                if (start.format('H') < 3) {
                    start.subtract(1, 'day')
                }

                return this.isFreemium ? start.startOf('week').hours(3) : start.startOf('day').hours(3)
            },

            organizationColor() {
                return {background:$org('colors.standard.label.filled')}
            },

            nextUpdate() {
                let start = moment()

                if (start.format('H') > 3) {
                    start.add(1, 'day')
                }

                const monday = 1

                if (start.format('H') < 3 && start.isoWeekday() === monday) {
                    start.subtract(1, 'day')
                }

                return this.isFreemium ? start.startOf('week').add(7, 'days').hours(3) : start.hours(3)
            },

            total() {
                return Object.values(this.keywords).length
            },

            sortedKeywords() {
                let keywords = Object.values(this.keywords)

                if (this.isHidden) {
                    keywords = keywords.filter(keyword => !keyword.tags || !keyword.tags.includes('usertag'))
                }

                let sortableKeywords = keywords.filter(keyword => keyword.currentRank && keyword.currentRank < 101)
                let nonSortableKeywords = keywords.filter(keyword => !keyword.currentRank || keyword.currentRank >= 101)

                sortableKeywords = sortableKeywords.sort((a, b) => {
                    const rankA = a.currentRank ? a.currentRank : 101
                    const rankB = b.currentRank ? b.currentRank : 101

                    switch (this.metric) {
                        case 'rank-change':
                            const rankChangeA = a.rankChange !== undefined ? a.rankChange : 0
                            const rankChangeB = b.rankChange !== undefined ? b.rankChange : 0

                            if (rankChangeA > rankChangeB) {
                                return this.ascending ? -1 : 1
                            }

                            return this.ascending ? 1 : -1

                        default:
                            if (rankA > rankB) {
                                return this.ascending ? 1 : -1
                            }

                            if (rankA < rankB) {
                                return this.ascending ? -1 : 1
                            }

                            if (a.rankChange > b.rankChange) {
                                return this.ascending ? -1 : 1
                            }

                            return this.ascending ? 1 : -1
                    }
                })

                sortableKeywords.push(...nonSortableKeywords)

                return sortableKeywords
            }
        },

        mounted() {
            this.load()

            eventHub.$on('keyword.created', this.afterKeywordCreated)
            eventHub.$on('keyword.removed', this.afterKeywordRemoved)
        },

        destroyed() {
            eventHub.$off('keyword.created', this.afterKeywordCreated)
            eventHub.$off('keyword.removed', this.afterKeywordRemoved)
        },

        methods: {
            loadWithDelay() {
                const size = 25
                const delay = 2.5 * 1000
                const stash = this.keywordStash

                for(let i = 0; i < Math.ceil(Object.keys(stash).length / size); i++) {
                    (function ($this, chunk, delay) {
                        setTimeout(() => {
                            chunk.forEach(id => {
                                $this.$set($this.keywords, id, $this.keywordStash[id])
                            })
                        }, delay)
                    })(this, Object.keys(stash).splice(size * i, size), delay * i)
                }
            },

            load() {
                this.keywords = {}
                this.hasLoaded = false

                let options = {
                    includes: ['searchEngine', 'country', 'searchLocation'],
                    sort: [{
                        key: 'keyword',
                        direction: 'ASC'
                    }]
                }

                if (this.customerId) {
                    Object.assign(options, {
                        filter_groups: [
                            {
                                filters: [
                                    {
                                        key: 'customer',
                                        value: this.customerId,
                                        operator: 'eq'
                                    }
                                ]
                            }
                        ]
                    })
                } else if (this.customer) {
					Object.assign(options, {
						filter_groups: [
							{
								filters: [
									{
										key: 'customer',
										value: this.customer.id,
										operator: 'eq'
									}
								]
							}
						]
					})
				}

                this.keywordStash = {}

                KeywordService.findAll(options, response => {
                    const data = response.data
                    const rows = data.rows

                    rows.forEach(keyword => {
                        this.$set(this.keywordStash, keyword.id, keyword)
                    })

                    this.hasLoaded = true

                    this.loadWithDelay()

                    this.$emit('keywordsMounted')
                    this.$emit('loaded')
                })
            },

            afterKeywordCreated(keyword) {
                this.$set(this.keywords, keyword.id, keyword)
            },

            afterKeywordRemoved(keyword) {
                this.$delete(this.keywords, keyword.id)
            },

            setCurrentRankForKeyword(payload) {
                const keyword = this.keywords[payload.keyword.id]
                const keywordWithCurrentRank = Object.assign({}, keyword, {currentRank: payload.rank})

                this.$set(this.keywords, keyword.id, keywordWithCurrentRank)
                this.$emit('current-rankings-changed', this.keywords)
            },

            setRankChangeForKeyword(payload) {
                const keyword = this.keywords[payload.keyword.id]
                const keywordWithRankChange = Object.assign({}, keyword, {rankChange: payload.rankChange})

                this.$set(this.keywords, keyword.id, keywordWithRankChange)
                this.$emit('rank-changes-updated', this.keywords)
            },

            selectSortMode(metric) {
                this.ascending = !this.ascending

                if (!this.metric === metric) {
                    this.ascending = true
                }

                this.metric = metric
            },

			onToggleStar(id) {
				const copy = JSON.parse(JSON.stringify(this.keywords))
				const starred = ! copy[id].starred

				copy[id].starred = starred

				this.keywords = copy

				KeywordService.update(id, {
					starred
				}, () => {}, () => {})
			}
        },

        components: {
            MobileTable,
            KeywordListItem,
            LogoLoader,
            Switches
        }
    }
</script>

export default [

  {
    path: '/settings/integrations',
    name: 'frontend.settings.integrations',
    component: () => import(/* webpackChunkName: "Integrations" */ './Integrations'),
    meta: {
      headline: () => $t('topBar.headlines.integrations'),
      description: () => $t('topBar.descriptions.integrations'),
      tracking: 'Settings - Page view - Integrations'
    }
  },
  {
    path: '/settings/integrations/calltracking/benefits',
    name: 'frontend.settings.integrations.calltracking.salesPage',
    component: () => import(/* webpackChunkName: "Integrations" */ './views/calltracking/SalesPage'),
    meta: {
      headline: () => $t('topBar.headlines.getCalltracking'),
      description: () => $t('topBar.descriptions.getCalltracking'),
      tracking: 'Settings - Call tracking - Page view - Sales page'
    }
  },
  {
    path: '/settings/integrations/calltracking/setup',
    name: 'frontend.settings.integrations.calltracking.setup',
    component: () => import(/* webpackChunkName: "Integrations" */ './views/calltracking/StepsLayout'),
    children: [
      {
        path: 'choose-configuration',
        name: 'frontend.settings.integrations.calltracking.setup.chooseConfiguration',
        component: () => import(/* webpackChunkName: "Integrations" */ './views/calltracking/ChooseConfiguration'),
        meta: {
          headline: () => $t('topBar.headlines.settings.setup.calltracking'),
          description: () => $t('topBar.descriptions.settings.setup.calltracking'),
          tracking: 'Settings - Call tracking - Page view - Choose configuration'
        }
      },
      {
        path: 'copy-html',
        name: 'frontend.settings.integrations.calltracking.setup.copyHtml',
        component: () => import(/* webpackChunkName: "Integrations" */ './views/calltracking/CopyHtml'),
        meta: {
          headline: () => $t('topBar.headlines.settings.setup.calltracking'),
          description: () => $t('topBar.descriptions.settings.setup.calltracking'),
          tracking: 'Settings - Call tracking - Page view - Copy html'
        }
      },
      {
        path: 'wordpress-install-plugin',
        name: 'frontend.settings.integrations.calltracking.setup.wordpressInstallPlugin',
        component: () => import(/* webpackChunkName: "Integrations" */ './views/calltracking/WordpressInstallPlugin'),
        meta: {
          headline: () => $t('topBar.headlines.settings.setup.calltracking'),
          description: () => $t('topBar.descriptions.settings.setup.calltracking'),
          tracking: 'Settings - Call tracking - Page view - WordPress install plugin'
        }
      },
      {
        path: 'wordpress-api-key',
        name: 'frontend.settings.integrations.calltracking.setup.wordpressApiKey',
        component: () => import(/* webpackChunkName: "Integrations" */ './views/calltracking/WordpressApiKey'),
        meta: {
          headline: () => $t('topBar.headlines.settings.setup.calltracking'),
          description: () => $t('topBar.descriptions.settings.setup.calltracking'),
          tracking: 'Settings - Call tracking - Page view - WordPress API key'
        }
      },
      {
        path: 'add-phone-numbers',
        name: 'frontend.settings.integrations.calltracking.setup.addPhoneNumbers',
        component: () => import(/* webpackChunkName: "Integrations" */ './views/calltracking/AddPhoneNumbers'),
        meta: {
          headline: () => $t('topBar.headlines.settings.setup.calltracking'),
          description: () => $t('topBar.descriptions.settings.setup.calltracking'),
          tracking: 'Settings - Call tracking - Page view - Add phone numbers'
        }
      },
      {
        path: 'verify',
        name: 'frontend.settings.integrations.calltracking.setup.verify',
        component: () => import(/* webpackChunkName: "Integrations" */ './views/calltracking/Verify'),
        meta: {
          headline: () => $t('topBar.headlines.settings.setup.calltracking'),
          description: () => $t('topBar.descriptions.settings.setup.calltracking'),
          tracking: 'Settings - Call tracking - Page view - Verify'
        }
      },
      {
        path: 'settings',
        name: 'frontend.settings.integrations.calltracking.setup.settings',
        component: () => import(/* webpackChunkName: "Integrations" */ './views/calltracking/Settings'),
        meta: {
          headline: () => $t('topBar.headlines.settings.setup.calltracking'),
          description: () => $t('topBar.descriptions.settings.setup.calltracking'),
          tracking: 'Settings - Call tracking - Page view - Settings'
        }
      }
    ]
  },
  {
    path: '/settings/integrations/formtracking/setup',
    name: 'frontend.settings.integrations.formtracking.setup',
    component: () => import(/* webpackChunkName: "Integrations" */ './views/formtracking/StepsLayout'),
    children: [
      {
        path: 'choose-configuration',
        name: 'frontend.settings.integrations.formtracking.setup.chooseConfiguration',
        component: () => import(/* webpackChunkName: "Integrations" */ './views/formtracking/ChooseConfiguration'),
        meta: {
          headline: () => $t('topBar.headlines.settings.setup.emailtracking'),
          description: () => $t('topBar.descriptions.settings.setup.emailtracking'),
          tracking: 'Settings - Form tracking - Page view - Choose configuration'
        }
      },
      {
        path: 'wordpress-install-plugin',
        name: 'frontend.settings.integrations.formtracking.setup.wordpressInstallPlugin',
        component: () => import(/* webpackChunkName: "Integrations" */ './views/formtracking/WordpressInstallPlugin'),
        meta: {
          headline: () => $t('topBar.headlines.settings.setup.emailtracking'),
          description: () => $t('topBar.descriptions.settings.setup.emailtracking'),
          tracking: 'Settings - Form tracking - Page view - WordPress install plugin'
        }
      },
      {
        path: 'wordpress-api-key',
        name: 'frontend.settings.integrations.formtracking.setup.wordpressApiKey',
        component: () => import(/* webpackChunkName: "Integrations" */ './views/formtracking/WordpressApiKey'),
        meta: {
          headline: () => $t('topBar.headlines.settings.setup.emailtracking'),
          description: () => $t('topBar.descriptions.settings.setup.emailtracking'),
          tracking: 'Settings - Form tracking - Page view - WordPress API key'
        }
      },
      {
        path: 'verify',
        name: 'frontend.settings.integrations.formtracking.setup.verify',
        component: () => import(/* webpackChunkName: "Integrations" */ './views/formtracking/Verify'),
        meta: {
          headline: () => $t('topBar.headlines.settings.setup.emailtracking'),
          description: () => $t('topBar.descriptions.settings.setup.emailtracking'),
          tracking: 'Settings - Form tracking - Page view - Verify'
        }
      }
    ]
  },
  {
    path: '/settings/integrations/dinero/setup',
    name: 'frontend.settings.integrations.dinero.setup',
    component: () => import(/* webpackChunkName: "Integrations" */ './views/dinero/StepsLayout'),
    children: [
      {
        path: 'start-page',
        name: 'frontend.settings.integrations.dinero.setup.startPage',
        component: () => import(/* webpackChunkName: "Integrations" */ './views/dinero/StartPage'),
        meta: {
          headline: () => $t('topBar.headlines.settings.setup.dinero'),
          description: () => $t('topBar.descriptions.settings.setup.dinero'),
          tracking: 'Settings - Dinero - Page view - Start'
        }
      },
      {
        path: 'input-api-key',
        name: 'frontend.settings.integrations.dinero.setup.inputApiKey',
        component: () => import(/* webpackChunkName: "Integrations" */ './views/dinero/GetApiKey'),
        meta: {
          headline: () => $t('topBar.headlines.settings.setup.dinero'),
          description: () => $t('topBar.descriptions.settings.setup.dinero'),
          tracking: 'Settings - Dinero - Page view - Get API key'
        }
      },
      {
        path: 'verify',
        name: 'frontend.settings.integrations.dinero.setup.verify',
        component: () => import(/* webpackChunkName: "Integrations" */ './views/dinero/InputApiKey'),
        meta: {
          headline: () => $t('topBar.headlines.settings.setup.dinero'),
          description: () => $t('topBar.descriptions.settings.setup.dinero'),
          tracking: 'Settings - Dinero - Page view - Verify'
        }
      },
      {
        path: 'success',
        name: 'frontend.settings.integrations.dinero.setup.success',
        component: () => import(/* webpackChunkName: "Integrations" */ './views/dinero/Success'),
        meta: {
          headline: () => $t('topBar.headlines.settings.setup.dinero'),
          description: () => $t('topBar.descriptions.settings.setup.dinero'),
          tracking: 'Settings - Dinero - Page view - Success'
        }
      }
    ]
  },
  {
    path: '/settings/integrations/matomo/setup',
    name: 'frontend.settings.integrations.matomo.setup',
    component: () => import(/* webpackChunkName: "Integrations" */ './views/matomo/Matomo')
  },
  {
    path: '/settings/integrations/trustpilot/setup',
    name: 'frontend.settings.integrations.trustpilot.setup',
    component: () => import(/* webpackChunkName: "Integrations" */ './views/trustpilot/Settings'),
    meta: {
      headline: () => $t('topBar.headlines.settings.setup.trustpilot'),
      description: () => $t('topBar.descriptions.settings.setup.trustpilot'),
      tracking: 'Settings - Trustpilot - Page view - Setup'
    }
  },
  {
    path: '/settings/integrations/klaviyo/setup',
    name: 'frontend.settings.integrations.klaviyo.setup',
    component: () => import(/* webpackChunkName: "Integrations" */ './views/klaviyo/Klaviyo'),
    meta: {
      headline: () => $t('topBar.headlines.settings.setup.klaviyo'),
      description: () => $t('topBar.descriptions.settings.setup.klaviyo'),
      tracking: 'Settings - Klaviyo - Page view - Setup'
    }
  },
]

<template>
	<div class="integration-page">
		<slot name="no-integrations" v-if="! hasHealthyIntegration(integrations) && ! demoMode" />
		<slot v-else />
	</div>
</template>

<script>
import hasIntegration from '@/mixins/integrations/hasIntegration'
import {mapGetters} from 'vuex'

import {DEMO_INTEGRATIONS} from '@/app/users/ghost.store'

export default {
	mixins: [hasIntegration],

	props: {
		integrations: Array
	},

	computed: {
		...mapGetters({
			isDemo: 'ghost/isDemo'
		}),

		demoMode() {
			return this.isDemo && this.integrations.some(item => DEMO_INTEGRATIONS.includes(item))
		}
	}
}
</script>

<template>
    <div class="integrations col-custom-row">
        <template v-if="!services.length || !this.customer">
            <div class="col-custom-12">
                <logo-loader :height="60"/>
            </div>
        </template>

        <div class="col-custom-6-12" v-for="(service, index) in sortedServices" :key="index" v-else>
            <service :service="service"
                     :customer="customer"
                     :integrations-list="integrations"
                     v-if="!['dataStudio'].includes(service.name)"
            />

            <template v-else-if="customer">
                <data-studio-service v-if="service.name === 'dataStudio'"
									 :customer="customer"
									 :service="service"
				/>
            </template>
        </div>

		<div v-if="!! customer"
			 class="col-custom-6-12">
			<card>
				<h1>{{ $t('customers.competitors.title') }}</h1>

				<div class="d-flex align-items-center justify-content-between mb-3">
					<div>
						{{ $t('customers.competitors.showCompetitors') }}
					</div>

					<div>
						<switch-toggle :is-enabled="showCompetitors"
									   @toggle="toggleShowCompetitors"
						/>
					</div>
				</div>

				<div class="d-flex align-items-center justify-content-between">
					<div>
						{{ $t('customers.competitors.competitorData') }}
					</div>

					<div>
						<switch-toggle :is-enabled="competitorData"
									   @toggle="toggleCompetitorData"
						/>
					</div>
				</div>
			</card>
        </div>
    </div>
</template>

<style lang="scss">
    @import '~@/assets/scss/mixins';
    .integrations {
        h1 {
            font-size: 14pt;
            font-weight: 400;
            margin-top: 0;
        }

        &.col-custom-row .col-custom-6-12 hr.divider {
            margin: 20px 0;
        }

        a {
            i,
            p {
                float: left;
                color: #000;
            }

            i {
                font-size: 18px;
                margin-top: 1px;
                margin-right: 10px;
            }
        }

        input {
            padding: 7px 10px 10px 10px;
            border-radius: 3px;
            border: 1px solid #eaeaea;
            display: inline-block;
            width: 100%;
            transition: all 0.8s ease;

            &.initial-value {
                background: #f9f9f9;
            }
        }

        button {
            margin-top: 10px;
            margin-right: 5px;
        }
    }

    @media screen and (min-width: 1200px) and (max-width: 1530px) {
        .col-custom-6-12 {
            width: 100% !important;
            display: block;
            flex: none;
        }
    }

    .is-backend .integration {
        padding: 20px;
        margin-bottom: 20px;
        position: relative;

        .remove {
            position: absolute;
            top: 20px;
            right: 20px;
            cursor: pointer;
            font-size: 14pt;
            margin: 0 0 -20px 0;
            opacity: .6;
            &:hover {
                opacity: .8;
            }
        }
    }
</style>

<script>
  import Service from '@/app/customers/components/integrations/IntegrationService'
  import DataStudioService from '@/app/customers/components/integrations/DataStudioService'

  const ServiceService = require('@/services/integrations/ServiceService')
  import CustomerService from '@/services/_app/customers/CustomerService'

  import SwitchToggle from '@/app/shared/components/SwitchToggle'

  export default {
    data () {
      return {
        services: [],
        customer: null,
        dataStudioInput: '',
        hasSaved: false,
        integrations: {},
        sortOrder: [
          'Facebook ads',
          'Facebook pages',
          'Google Ads',
          'Google analytics',
          'Google Business Profile',
          'Google Search Console',
        ],

		showCompetitors: false,
		competitorData: false,
      }
    },

    computed: {
      sortedServices () {
        let sorted = this.services
          .sort((a, b) => b.name - a.name)
          .sort((a, b) => (this.sortOrder.includes(a.name) ? -1 : 1))

        sorted.splice(this.sortOrder.length, 0, { name: 'dataStudio' })

        return sorted
      }
    },

    mounted () {
      this.load()

      eventHub.$on('integration.created', this.load)
    },

    destroyed () {
      eventHub.$off('integration.created', this.load)
    },

    methods: {
      async load () {
        const id = this.$route.params.id

        const serviceOptions = {
          sort: [
            {
              key: 'name',
              direction: 'ASC'
            }
          ]
        }

        ServiceService.findAll(serviceOptions, ({ data }) => {
          this.services = data.rows
        })

        this.integrations = await CustomerService.integrations(id)

        this.customer = await CustomerService.find(id, { includes: ['integrations', 'integrations.service'] })

	    this.showCompetitors = this.customer.showCompetitors
	    this.competitorData = this.customer.competitorData
	  },

	  async toggleShowCompetitors() {
		  const id = this.$route.params.id

		  await CustomerService.put(id, {
			  showCompetitors: ! this.showCompetitors
		  })

		  await this.load()
	  },

	  async toggleCompetitorData() {
		  const id = this.$route.params.id

		  await CustomerService.put(id, {
			  competitorData: ! this.competitorData
		  })

		  await this.load()
	  }
    },

    components: {
      DataStudioService,
      Service,
	  SwitchToggle
    }
  }
</script>

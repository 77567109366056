export default [
  {
    path: "/meta/ads",
    name: "frontend.reporting.meta.ads",
    component: () => import(/* webpackChunkName: "Meta" */ './pages/MetaAds'),
    meta: {
      headline: () => $t("navigation.statistics.facebook"),
      description: () => $t("topBar.descriptions.reporting.facebook"),
      tracking: "Meta - Page view - Ads",
      datepicker: true
    }
  },
  {
    path: "/meta/facebook/page",
    name: "frontend.reporting.meta.facebook.page",
    component: () => import(/* webpackChunkName: "Meta" */ './pages/FacebookPage'),
    meta: {
      headline: () => $t("navigation.statistics.facebook"),
      description: () => $t("topBar.descriptions.reporting.facebook"),
      tracking: "Meta - Page view - Facebook - Page",
      datepicker: true
    }
  },
  {
    path: "/meta/instagram/page",
    name: "frontend.reporting.meta.instagram.page",
    component: () => import(/* webpackChunkName: "Meta" */ './pages/InstagramPage'),
    meta: {
      headline: () => $t("navigation.statistics.facebook"),
      description: () => $t("topBar.descriptions.reporting.facebook"),
      tracking: "Meta - Page view - Instagram - Page",
      datepicker: true
    }
  }
];

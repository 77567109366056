<template>
  <div class="connect-mcc-account-btn">
    <button class="btn btn-primary btn-xs" @click="redirectOffSite">
      <slot></slot>
    </button>
  </div>
</template>
<script>
import OAuth2IsAccessService from '@/services/oauth2/OAuth2IsAccessService'
const OAuth2Service = require('@/services/oauth2/OAuth2Service')

export default {
  name: 'ConnectMccAccountBtn',
  props: {
    serviceNamespace: {
      type: String,
      default: ''
    },
    service: {
      type: String,
      default: ''
    },
    isAccess: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      isLoading: false
    }
  },
  computed: {
    getOAuthService() {
      if (!this.isAccess) {
        return OAuth2Service
      }
      return new OAuth2IsAccessService(this.serviceNamespace)
    },
    code() {
      const currentRoute = this.$router.currentRoute
      const query = currentRoute.query

      return query.code
    },

    state() {
      const currentRoute = this.$router.currentRoute
      const query = currentRoute.query

      return query.state
    },

    redirectUrl() {
      return window.location.origin + this.$router.currentRoute.path
    },
  },
  methods: {
    redirectOffSite() {
      this.isLoading = true
      this.getOAuthService.url(
        this.service,
        this.redirectUrl, (response) => {
          const data = response.data

          window.location.href = data.url
        },
        () => {
          this.isLoading = false
        })
    },
    handleCallbackData() {
      //if state and service are different return (only for old api)
      if ((this.state !== this.service) && !this.isAccess) {
        return
      }

      //if isAccess api and state is not from access-google-ppc return
      if (this.isAccess && (this.state && !this.state.includes(this.serviceNamespace))) {
        return
      }

      //if either code isConnected or service is set return
      if (!this.code || !this.service) {
        return
      }
      if (!this.isAccess) {
        this.getOAuthService.callback(
          this.service,
          this.redirectUrl,
          this.code,
          this.state,
          async (response) => {
            this.$emit('added-new-account', response.data);
          },
          (error) => console.log(error),
          true
        )

        return;
      }

      this.getOAuthService.mccCallback(
        this.redirectUrl,
        this.code,
        this.state,
        async (response) => {
          this.$emit('added-new-account', response.data);
        },
        (error) => console.log(error),
        true
      )

    }
  },
  mounted() {
    this.handleCallbackData()
  },
};
</script>
//======> SHARED ROUTES
import authRoutes from './auth/auth.routes'
import sharedRoutes from './shared/shared.routes'
import errorRoutes from './errors/errors.routes'
import faqRoutes from './faq/faq.routes'

//======> FRONTEND ROUTES
import billingRoutes from './billing/billing.routes'
import contactsRoutes from './contacts/contacts.routes'
import coPilotRoutes from './copilot/copilot.routes'
import dashboardRoutes from './dashboard/dashboard.routes'
import googleAdsRoutes from './google-ads/google.routes'
import linkedinRoutes from './linkedin/linkedin.routes'
import inquiriesRoutes from './inquiries/inquiries.routes'
import mailchimpRoutes from './newsletters/newsletters.routes'
import klaviyoRoutes from './klaviyo/klaviyo.routes'
import onboardingRoutes from './onboarding/onboarding.routes'
import reviewsRoutes from './reviews/reviews.routes'
import servicesRoutes from './services/services.routes'
import integrationsRoutes from './integrations/integrations.routes'
import competitorsRoutes from './competitors/competitors.routes'
import conversionsRoutes from './conversions/conversions.routes'
import economyRoutes from './economy/economy.routes'
import linkedInRoutes from './linkedin/linkedin.routes'
import metaRoutes from './meta/meta.routes'
import seoRoutes from './seo/seo.routes'
import visitorRoutes from './website/website.routes'
import websiteRoutes from './website-dev/website.routes'
import wordPressRoutes from './wordpress/wordpress.routes'
import unsubscribeRoutes from '@/app/unsubscribe/unsubscribe.routes'

//======> BACKEND ROUTES
import subscriptionsRoutes from './subscriptions/subscriptions.routes'
import customersRoutes from './customers/customers.routes'
import usersRoutes from './users/users.routes'
import cachedAccountOverviewRoutes from './cached-account-overview/cached-account-overview.routes'
import integrationCheckRoutes from './integration-check/integration-check.routes'
import performanceReportsRoutes from './performance-reports/performance-reports.routes'
import streamRoutes from './stream/stream.routes'
import systemRoutes from './system/system.routes'
import leadenhancerRoutes from './leadenhancer/lead-enhancer.routes'
import leadsRoutes from './leads/leads.routes'
import serpGridRoutes from './serp-grid/serp-grid.routes'
import chatBotRoutes from "@/app/chat-bot/chat-bot.routes"

const Reporting = {
  template: '<router-view/>'
}

export default [
  {
    path: '/crm-redirect/:crmId',
    name: 'crm-redirect',
    component: () => import(/* webpackChunkName: "CrmRedirect" */ './layout/CrmRedirect.vue'),
    props: true
  },
  ...authRoutes,
  ...unsubscribeRoutes,
  ...onboardingRoutes,
  {
    path: '/',
    redirect: '/overview',
    name: 'frontend',
    component: () => import(/* webpackChunkName: "Frontend" */ './layout/Frontend.vue'),
    meta: {
      requiresClientIdentity: true
    },
    children: [
      ...usersRoutes.frontend,
	  ...billingRoutes,
      ...contactsRoutes,
      ...dashboardRoutes,
      ...googleAdsRoutes,
      ...linkedinRoutes,
      ...inquiriesRoutes,
      ...mailchimpRoutes,
	  ...klaviyoRoutes,
      ...reviewsRoutes,
      ...servicesRoutes,
      ...integrationsRoutes,
      ...metaRoutes,
      ...leadenhancerRoutes.frontend,
	  ...wordPressRoutes,
      {
        path: '/reporting',
        name: 'frontend.reporting',
        component: Reporting,
        children: [
          ...competitorsRoutes,
          ...conversionsRoutes,
          ...economyRoutes,
          ...linkedInRoutes,
          ...seoRoutes,
          ...websiteRoutes,
          ...visitorRoutes
        ]
      }
    ]
  },
  {
    path: '/backend',
    name: 'backend',
    component: () => import(/* webpackChunkName: "Backend" */ './layout/Backend.vue'),
    redirect: { name: 'backend.customers' },
    meta: {
      requiresEmployeeIdentity: true
    },
    children: [
	  ...serpGridRoutes,
	  ...leadsRoutes,
	  ...subscriptionsRoutes,
      ...customersRoutes.backend,
      ...usersRoutes.backend,
      ...cachedAccountOverviewRoutes,
      ...integrationCheckRoutes,
      ...performanceReportsRoutes,
      ...streamRoutes,
      ...systemRoutes,
      ...leadenhancerRoutes.backend
    ]
  },
  ...chatBotRoutes,
  ...coPilotRoutes,
  ...faqRoutes,
  ...sharedRoutes,
  ...errorRoutes,
]

export default [
	{
		path: '/chat-bot',
		component: () => import('@/app/layout/Frontend'),
		meta: {
			requiresClientIdentity: true
		},
		children: [
			{
				path: '/',
				name: 'chatbot',
				component: () => import('./pages/ChatBot'),
				meta: {
					headline: () => $t('chatBot.topBar.headline'),
					description: () => $t('chatBot.topBar.description'),
					tracking: 'ChatBot - Page view - View',
				}
			}
		]
	}
]

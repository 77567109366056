<template>
    <div class="filter-group">
        <!--<span class="filter-title title-hidden">{{filterGroup.title}}: </span>-->

        <button class="btn btn-xs btn-metric"
                :class="{ 'active': metric.selected && !metric.color }"
                :style="getColor(metric)"
                v-for="metric in filterGroup.metrics"
                :key="metric.slug"
                @click="toggleMetric(metric.slug)">

            {{metric.label}}
        </button>
    </div>
</template>

<script>
  export default {
    props: {
      filterGroup: Object
    },

    computed: {
      isMultiSelect () {
        return this.filterGroup.multiSelect
      }
    },

    methods: {
      toggleMetric (metricSlug) {
        const metricArray = []
        const multiSelect = this.isMultiSelect

        this.filterGroup.metrics.forEach(existingMetric => {
          const metric = JSON.parse(JSON.stringify(existingMetric))

          if (metric.slug === metricSlug) {
            metric.selected = multiSelect ? !metric.selected : true
            metricArray.push(metric)
            return
          }

          metric.selected = multiSelect ? metric.selected : false

          metricArray.push(metric)
        })

        eventHub.$emit('topbar.filterGroups.update', {
          slug: this.filterGroup.slug,
          metrics: metricArray
        })
      },

      getColor (metric) {        
        if (!metric.selected) {
          return
        }

        return this.isMultiSelect ?
          { color: '#fff', background: metric.color } :
          { color: '#fff', background: $org('colors.standard.secondary.hex') }
      }
    }
  }
</script>

<style lang="scss" scoped>
    @import "~@/assets/scss/variables/all";

</style>

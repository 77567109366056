const BaseService = require('@/services/BaseService')

const namespace = 'inquiries'

const find = (id, options, cb, errorCb) => {
  BaseService.get(namespace + '/' + id, options, cb, errorCb)
}

const findAll = (options, cb, errorCb) => {
  BaseService.get(namespace, options, cb, errorCb)
}

const averageByIndustry = (from, to, cb, errorCb) => {
    const options = {from, to}

    BaseService.get(namespace + '/average-by-industry', options, cb, errorCb)
}

export {
  find,
  findAll,
  averageByIndustry
}

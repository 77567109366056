export default [
  {
    path: '/backend/performance-reports',
    name: 'backend.performancereports',
    component: () => import(/* webpackChunkName: "PerformanceReports" */ './pages/Reports'),
    children: [
      {
        name: 'backend.performancereports.list',
        path: 'list',
        component: () => import(/* webpackChunkName: "PerformanceReports" */ './pages/List')
      },
      {
        name: 'backend.performancereports.view-report',
        path: 'view/:id',
        component: () => import(/* webpackChunkName: "PerformanceReports" */ './pages/View')
      }
    ]
  },
]

<template>
    <div class="google-adwords">
        <template v-if="step == 1">
            <div class="form-group">
                <label>{{$t('integrations.backend.account')}}</label>

                <loader v-if="!hasLoaded" size="small"></loader>

                <multiselect v-if="hasLoaded"
                             v-model="account"
                             :options="accounts"
                             track-by="name"
                             label="name"
                             :close-on-select="true"
                             :show-labels="false"
                             :placeholder="$t('integrations.backend.selectAccount')"
                             :selectLabel="$t('multiSelect.selectLabel')">
                    <p slot="noResult">{{ $t('multiSelect.noResultsFound') }}</p>
                </multiselect>
            </div>

            <button type="button" class="btn btn-success btn-block" @click="setAccount">
                <span v-if="updatingAccount" class="fa fa-spin fa-spinner"></span>
                {{$t('integrations.backend.next')}}
            </button>
        </template>

        <template v-if="step == 2">
            <div class="form-group">
                <label>{{$t('integrations.backend.id')}}</label>
                <input type="text" class="form-control" v-model="id">
            </div>

            <button type="button" class="btn btn-success btn-block" @click="create">
                <span v-if="creatingIntegration" class="fa fa-spin fa-spinner"></span>
                {{$t('integrations.backend.add')}}
            </button>
        </template>
    </div>
</template>

<style lang="scss">
    .google-adwords {
    }
</style>

<script>
    import Loader from '@/app/shared/components/Loader'

    const IntegrationService = require('@/services/integrations/IntegrationService')

    const CustomerService = require('@/services/customers/CustomerService')
    const GoogleAdWordsService = require('@/services/google-adwords/GoogleAdWordsService')

    export default {
        props: {
            service: Object,
            customer: Object
        },
        data() {
            return {
                hasLoaded: false,
                account: null,
                step: 1,
                accounts: [],
                updatingAccount: false,
                creatingIntegration: false,
                id: null
            }
        },
        mounted() {
            this.load();
        },
        methods: {
            setAccount() {
                const data = {
                    adwordsAccount: this.account.id
                };

                this.updatingAccount = true;

                CustomerService.put(this.customer.id, data, (response) => {
                    this.updatingAccount = false;

                    this.step = 2;
                });
            },

            create() {
                if (!this.id) {
                    return;
                }

                this.creatingIntegration = true;

                const data = {
                    service: this.service.id,
                    customer: this.customer.id,
                    data: this.id,
                    connectedAccount: this.id
                };

                IntegrationService.create(data, (response) => {
                    this.creatingIntegration = false;

                    eventHub.$emit('integration.created', response.data);
                });
            },

            load() {
                GoogleAdWordsService.accounts((response) => {
                    var body = response.data;

                    this.accounts = body.rows;
                    this.hasLoaded = true;
                }, (response) => {
                    this.hasLoaded = true;
                });
            }
        },
        components: {
            Loader
        }
    }
</script>

export default [
  {
    path: '/overview',
    name: 'frontend.overview',
    component: () => import(/* webpackChunkName: "Dashboard" */ './Dashboard'),
    meta: {
      headline: () => $t('navigation.overview'),
      description: () => $t('topBar.descriptions.overview'),
      tracking: 'Overview - Page view - Main page'
    }
  }
]

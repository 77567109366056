const BaseService = require('@/services/BaseService')

const namespace = 'billing/subscriptions'

const find = (id, body, cb, errorCb) => {
	BaseService.get(`${namespace}/${id}`, body, cb, errorCb)
}
const findAll = (body, cb, errorCb) => {
	BaseService.get(namespace, body, cb, errorCb)
}

const resume = (id, body, cb, errorCb) => {
	BaseService.post(`${namespace}/${id}/resume`, body, cb, errorCb)
}

const cancel = (id, body, cb, errorCb) => {
	BaseService.remove(`${namespace}/${id}`, body, cb, errorCb)
}

const manageCreate = (body, cb, errorCb) => {
	BaseService.post(`${namespace}/manage`, body, cb, errorCb)
}

const manageUpdate = (id, body, cb, errorCb) => {
	BaseService.put(`${namespace}/manage/${id}`, body, cb, errorCb)
}

const manageDelete = (id, body, cb, errorCb) => {
	BaseService.delete(`${namespace}/manage/${id}`, body, cb, errorCb)
}

export {
	find,
	findAll,
	resume,
	cancel,
	manageCreate,
	manageUpdate,
	manageDelete
}

<template>
    <div class="keyword-chart row">
        <div class="col-lg-4" style="padding-left: 30px;">
            <template v-if="currentRank">
                <div class="row">
                    <div class="col-lg-4 col-md-4 col-sm-6 col-xs-6">
                        <p>{{$t('seo.keywordList.bestResult')}}</p>
                    </div>

                    <div class="col-lg-8 col-md-4 col-sm-6 col-xs-6">
                        <strong v-if="min < 101">{{min}}</strong>

                        <strong v-else>{{$t('seo.notInTop')}}</strong>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-4 col-md-4 col-sm-6 col-xs-6">
                        <p>{{$t('seo.keywordList.worstResult')}}</p>
                    </div>

                    <div class="col-lg-8 col-md-4 col-sm-6 col-xs-6">
                        <strong v-if="max < 101">{{max}}</strong>

                        <strong v-else>{{$t('seo.notInTop')}}</strong>
                    </div>
                </div>
            </template>

            <template v-if="(keyword.tags && keyword.tags.includes('usertag')) || employeeAccess">
                <hr>

                <div class="row">
                    <div class="col-lg-4 col-md-4 col-sm-6 col-xs-6">
                        <small v-if="(keyword.tags && keyword.tags.includes('usertag')) || employeeAccess" class="unfollow" @click="removeUserKeyword">
                            {{$t('seo.keywordList.delete')}}
                        </small>
                        <br>
                        <small v-if="keyword.tags.includes('serp') && employeeAccess">
                            {{$t('seo.keywordList.source')}}: {{keyword.tags.join(',')}}
                        </small>
                    </div>
                </div>
            </template>
        </div>

        <div class="col-lg-8 chart-container" v-show="currentRank">
            <div class="chart"></div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    @import '~@/assets/scss/_custom.scss';
    @import '~@/assets/scss/_vars.scss';

    .positive {
        color: $focusSuccess;
    }

    .negative {
        color: $focusAlert;
    }

    .neutral {
        color: $focusWarning;
    }

    .chart-container {
        height: 150px;
    }

    .unfollow {
        color: $focusAlert;
        &:hover {
            color: $tertiaryAlert;
        }
    }

    hr {
        max-width: 200px;
        margin: 10px 0;
    }

    @media screen and (max-width: 991px) {
        hr {
            margin: 5px 0;
        }
    }
</style>

<script>
    const HighCharts = require('highcharts')
    const moment = require('moment')

    const RankingService = require('@/services/seo/RankingService')
    const KeywordService = require('@/services/seo/KeywordService')

    export default {
        props: {
            keyword: {
                type: Object,
                required: true
            },
            currentRank: {
                type: Object,
                required: false
            },
            employeeAccess: {
                type: Boolean,
                required: true
            }
        },

        data() {
            return {
                service: null,
                min: null,
                max: null,
                tempArr: [],
                series: []
            }
        },

        watch: {
            currentRank() {
                this.load()
            }
        },

        mounted() {
            this.load()
        },

        methods: {
            load() {
                if (!this.currentRank) {
                    return
                }

                RankingService.findAll(this.keyword.id, {
                    limit: 1,

                    sort: [{
                        key: 'date',
                        direction: 'DESC'
                    }]
                }, (response) => {
                    const data = response.data
                    const rows = data.rows

                    if (rows.length === 0) {
                        return
                    }

                    const latestRank = rows[0]

                    RankingService.findAll(this.keyword.id, {
                        limit: 1,

                        sort: [{
                            key: 'date',
                            direction: 'ASC'
                        }]
                    }, response => {
                        const data = response.data
                        const rows = data.rows

                        if (rows.length === 0) {
                            return
                        }

                        const initialRank = rows[0]

                        RankingService.findAllGroupedWeekly(this.keyword.id, {}, (response) => {
                            const data = response.data
                            const rows = data.rows

                            if (rows.length === 0) {
                                return
                            }

                            if (rows[0].id !== initialRank.id) {
                                rows.unshift(initialRank)
                            }

                            if (rows[rows.length - 1].id !== latestRank.id) {
                                rows.push(latestRank)
                            }

                            const ranks = rows.map(item => item.rank)

                            this.min = Math.min(...ranks)
                            this.max = Math.max(...ranks)

                            const series = [{
                                name: $t('seo.keywordList.graph.historicalDevelopment'),
                                color: '#b4b4b4',
                                data: rows.map(rank => {
                                    return {
                                        y: rank.rank,
                                        name: this.formatDate(rank.date.date)
                                    }
                                })
                            }]

                            this.series = series
                            this.render(series)
                        })
                    })
                })
            },

            formatDate(unformattedDate) {
                return moment(unformattedDate).format('Do MMMM YYYY')
            },

            render(series) {
                const el = this.$el.querySelector('.chart')

                this.chart = HighCharts.chart(el, {
                    chart: {
                        type: 'spline',
                        height: 150,
                        margin: 0,
                        zoomType: 'x',
                        style: {
                            fontFamily: '"Roboto","Helvetica Neue",Arial,sans-serif'
                        },
                        events: {
                            click: function(e) {
                                e.stopPropagation()
                            }
                        },
                        backgroundColor: 'transparent',
                    },
                    title: {
                        text: ''
                    },
                    plotOptions: {
                        series: {
                            marker: {
                                enabled: false
                            }
                        }
                    },
                    xAxis: {
                        minTickInterval: 1
                    },
                    yAxis: {
                        reversed: true,
                        title: {
                            text: false
                        },
                        gridLineColor: 'transparent',
                        minTickInterval: 1
                    },
                    credits: {
                        enabled: false
                    },
                    legend: {
                        enabled: true
                    },
                    series: series
                });
            },

            removeUserKeyword(event) {
                event.stopPropagation()

                let keyword = this.keyword

                this.$swal({
                    text: $t('seo.alertTypeMessages.deleteAlert.actionDescription'),
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonText: $t('seo.alertTypeMessages.deleteAlert.actionButton'),
                    cancelButtonText: $t('seo.alertTypeMessages.deleteAlert.cancelButton')
                }).then(() => {
                    KeywordService.remove(keyword.id, response => {
                        eventHub.$emit('keyword.removed', this.keyword)
                    }, error => {
                        this.$swal({
                            html: $t('seo.alertTypeMessages.couldNotDeleteError.errorDescription'),
                            type: 'error'
                        })
                    })
                }).catch(() => {})
            }
        }
    }
</script>

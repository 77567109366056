/**
 * The custom USGSOverlay object contains the USGS image,
 * the bounds of the image, and a reference to the map.
 */
export default class USGSOverlay extends google.maps.OverlayView {
	bounds;
	ranking;

	constructor(bounds, ranking) {
		super()

		this.bounds = bounds
		this.ranking = ranking
	}

	onAdd() {
		this.div = document.createElement('div')
		this.div.style.position = 'absolute'
		this.div.innerText = this.ranking
		this.div.className = ['result-marker', this.class()].join(' ')

		const panes = this.getPanes()

		panes.overlayLayer.appendChild(this.div)
	}

	class() {
		if (this.ranking === '-') {
			return 'no-rank'
		}

		if (this.ranking <= 3) {
			return 'good'
		}

		if (this.ranking <= 7) {
			return 'medium'
		}

		return 'bad'
	}

	draw() {
		const overlayProjection = this.getProjection()

		const sw = overlayProjection.fromLatLngToDivPixel(this.bounds)
		const ne = overlayProjection.fromLatLngToDivPixel(this.bounds)

		if (this.div) {
			this.div.style.left = sw.x + "px";
			this.div.style.top = ne.y + "px";
		}
	}

	onRemove() {
		if (this.div) {
			this.div.parentNode.removeChild(this.div)
			delete this.div;
		}
	}
}

<template>
    <div>
        <template v-if="!loadingIntegration">
            <div class="alert alert-danger" v-if="errorMessage">
                Der skete en fejl: {{errorMessage}}
            </div>
            <card v-if="integration" minHeight="68px">
                <button class="btn btn-danger btn-on-mobile" @click="disconnect">
                    <template v-if="!isRemoving">{{$t('backend.OAuth2.remove')}}</template>
                    <loader v-else/>
                </button>

                <template v-if="customer">
                    <span v-text="integration.connectedAccount" />
                    <br />

                    <small class="text-muted" v-if="account">
                        {{account.name}}
                    </small>

                    <span v-if="unhealthy" class="unhealthy">
                        <i class="fa fa-warning" /><br>
                        {{$t('backend.OAuth2.removedDueToHealth')}}
                    </span>
                </template>

                <loader :inline="true" v-else/>
            </card>

            <template v-else>
                <div class="form-group">
                    <label>{{$t('backend.OAuth2.searchByAccountName')}}</label>
                    <input v-model="query" class="form-control">
                </div>

                <template v-if="!loading">
                    <hr v-if="results.length" />

                    <card v-for="(result, i) in filteredResults" minHeight="68px" :key="i">
                        <button class="btn btn-primary pull-right" @click="connect(result.google_ads_id)">
                            {{$t('backend.OAuth2.connect')}}
                        </button>

                        <span v-text="result.descriptive_name" style="word-break: break-all;" /><br />

                        <small class="text-muted" v-if="account && result.account">
                            {{result.account.name}}
                        </small>
                    </card>

                    <template v-if="total">
                        <pagination :total="total"
                                    :limit="limit"
                                    :page="page"
                                    @paginate="paginate"
                                    :showFirstAndLast="false"
                                    size="small"
                                    :keyboardControl="true" />
                        <small class="text-muted current-pages">{{currentlyShowing}}</small>
                    </template>
                </template>

                <loader v-else/>
            
            </template>
        </template>

        <loader v-else />
    </div>
</template>

<style lang="scss" scoped>
    @import '~@/assets/scss/_vars.scss';

    .btn-on-mobile {
        float: right;
    }

    @media screen and (max-width: 992px) {
        .btn-on-mobile {
            display: block;
            margin-bottom: 10px;
            float: none;
        }
    }

    .unhealthy {
        text-align: center;
        margin: 10px 0;
        padding: 5px 40px;
        display: block;
        background: $focusAlert;
        border-radius: 3px;
        color: #ffffff;
    }

    .dark {
        padding: 0;
        color: #000;
        text-shadow: none;
        .card {
            padding: 0;
            margin-bottom: 15px;
        }
        .form-group label {
            color: #fff;
            text-transform: none;
            font-size: 14px;
            font-weight: 600;
            text-shadow: 1px 1px 2px #000;
        }
        hr {
            border-top: #000;
            display: none;
        }
    }
</style>

<script>
import Card from '@/app/shared/components/Card'
import Pagination from '@/app/layout/components/paginator/Pagination'
import Loader from '@/app/shared/components/Loader'

import AccessGoogleAdsService from '@/services/google-ads/AccessGoogleAdsService'
import * as CustomerService from '@/services/customers/CustomerService'

import { mapGetters } from 'vuex'

export default {
    props: {
        customerId: {
            type: String,
            required: true
        }
    },

    data() {
        return {
            errorMessage: '',
            integration: null,
            loading: false,
            loadingIntegration: true,
            isRemoving: false,
            query: null,
            results: [],
            customer: null,
            limit: 4,
            page: 1
        }
    },

    computed: {
        ...mapGetters("identity", {
            identity: "getIdentity"
        }),

        ...mapGetters('ghost', {
            ghostUserIdentity: 'getGhostUserIdentity',
        }),

        total() {
            return this.results.length
        },

        currentlyShowing() {
            return $t('pagination.showing', { current: this.page, total: Math.ceil(this.total/4) })
        },

        filteredResults() {
            if (!this.results.length) {
                return []
            }

            return this.results.slice((this.page - 1) * this.limit, this.limit * this.page)
        },

        unhealthy() {
            const integration = this.integration
            return integration && !this.integration.healthy
        },

        account () {
            const customer = this.customer
            const oauth2Accounts = customer.oauth2Accounts

            const account = oauth2Accounts.find(account => {
                return account.provider === 'googleAdWords'
            })

            return account === undefined ? null : account
        },
        isSuperAdmin() {
             return this.identity.role === 'employee-super-admin' || this.ghostUserIdentity.role === 'employee-super-admin'
        }
    },

    watch: {
        query() {
            this.search(this.query)
        },
        integration() {
            this.errorMessage = '';
        }
    },

    mounted() {
        this.loadCustomer()
    },

    methods: {
        loadIntegration() {
            const customer = this.customer
            const integrations = customer.integrations

            const integration = integrations.find(integration => {
                if (integration.service.name === 'Google Ads') {
                    return integration
                }
            })

            if (!integration) {
                this.loadingIntegration = false
                this.search(customer.domain)
                return
            }

            // Set integration
            this.integration = integration
            this.loadingIntegration = false
        },

        search(query) {
            this.page = 1
            this.results = []

            if (!query || query.length < 2) {
                return
            }

            this.loading = true

            const options = {
                query: query
            }

            AccessGoogleAdsService.findAll(options, result => {
                this.results = result.data
                this.loading = false
            })
        },

        connect(accountId) {
            this.loading = true

            AccessGoogleAdsService.integrate({
                customerId: this.customer.id,
                accountId: accountId
            }, response => {
                this.query = null
                this.results = []

                this.loadCustomer()

                this.$emit('integrationStateChange', this.customerId)

                this.loading = false
            }, error => {
                this.loading = false;
                this.errorMessage = error.message;
            })
        },

        loadCustomer() {
            const options = {
                includes: [
                    'integrations',
                    'integrations.service',
                    'oauth2Accounts'
                ]
            }

            CustomerService.find(this.customerId, options, response => {
                this.customer = response.data

                this.loadIntegration()
            })
        },

        disconnect() {
            this.isRemoving = true

            AccessGoogleAdsService.remove(this.integration.id, () => {
                this.integration = null

                this.loadCustomer()

                this.$emit('integrationStateChange', this.customerId)

                this.isRemoving = false
            })
        },

        paginate(page) {
            this.page = page
        },
    },

    components: {
        Card,
        Loader,
        Pagination
    }
}
</script>

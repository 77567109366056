<template>
    <div class="loader-wrapper" :style="loaderHeight">
        
        <div class="loader">
            <div class="spinner red" :style="organizationLoaderTop"></div>
            <div class="loader-text" v-if="text">{{stage}}</div>
        </div>
        
    </div>
</template>

<style lang="scss" scoped>
    .loader {
        width: 40px;
        height: 40px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -70%);
    }

    .loader-text {
        width: 130px;
        text-align: center;
        position: absolute;
        top: 60px;
        left: 50%;
        transform: translateX(-50%);
        font-family: 'Roboto', sans-serif;
        font-size: 8px;
        font-weight: bold;
        color: #909090;
        text-transform: uppercase;
        letter-spacing: 1px;
    }

    .spinner {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
    }

    .red {
        background-image: url('/images/loader/red.png');
        background-size: 100% 100%;
        background-position: 0 0;
        animation: red 3.5s ease-out infinite;
    }

    @keyframes red {
        45% {
            transform: scale(1);
        }
        50% {
            transform: scale(1.2);
        }
        55% {
            transform: scale(1);
        }
        70% {
            transform: scale(1);
        }
        75% {
            transform: scale(1.4);
        }
        80% {
            transform: scale(0.8);
        }
        85% {
            transform: scale(1);
        }

    }

    .orange {
        background-image: url('/images/loader/orange.png');
        background-size: 100% 100%;
        background-position: 0 0;
        animation: orange 3.5s ease-out infinite;
    }

    @keyframes orange {
        50% {
            transform: scale(1);
        }
        55% {
            transform: scale(1.2);
        }
        60% {
            transform: scale(1);
        }
        70% {
            transform: scale(1);
        }
        75% {
            transform: scale(1.4);
        }
        80% {
            transform: scale(0.8);
        }
        85% {
            transform: scale(1);
        }

    }

    .yellow {
        background-image: url('/images/loader/yellow.png');
        background-size: 100% 100%;
        background-position: 0 0;
        animation: yellow 3.5s ease-out infinite;
    }

    @keyframes yellow {
        55% {
            transform: scale(1);
        }
        60% {
            transform: scale(1.2);
        }
        65% {
            transform: scale(1);
        }
        70% {
            transform: scale(1);
        }
        75% {
            transform: scale(1.4);
        }
        80% {
            transform: scale(0.8);
        }
        85% {
            transform: scale(1);
        }
    }
</style>

<script>
    export default {
        props: {
            loadStage: {
                type: Number
            },
            text: {
                type: Boolean,
                default: true
            },
            height: {
                type: Number,
                required: false
            }
        },

        computed: {
            stage() {
                if (!this.loadStage) {
                    return $t('loader.pleaseWait')
                }

                switch (this.loadStage) {
                    case 5:
                        return $t('loader.searchingPermissions')
                    case 10:
                        return $t('loader.loading')
                }
            },

            loaderHeight() {
                return this.height ?
                    {height: `${this.height}px`} :
                    null
            },

            organizationLoaderTop() {
                return {backgroundImage:`url(${$org('logos.loader.opn')})`}
            },

            organizationLoaderRight() {
                return {backgroundImage:`url(${$org('logos.loader.right')})`}
            },

            organizationLoaderArc() {
                return {backgroundImage:`url(${$org('logos.loader.arc')})`}
            }
        }
    }
</script>

import moment from 'moment'
import AnalyticsService from '@/services/_app/google-analytics/AnalyticsService'
import CallService from '@/services/_app/inquiries/CallService'
import FormService from '@/services/_app/inquiries/FormService'
import BenchmarkService from '@/services/_app/benchmarks/BenchmarkService'
import {getTranslatedErrorMessage} from "@/services/response/ResponseService";

const defaultState = () => {
	return {
		isLoading: false,
		international: null,
		to: null,
		from: null,
		range: null,
		total: null,
		benchmark: {
			loaded: [],
			sessions: 0,
			calls: 0,
			forms: 0,
			conversionRate: {
				industry: 0
			},
			averageSessionDuration: {
				customer: 0,
				industry: 0
			},
			visitsFromDevice: {
				customer: 0,
				industry: 0
			}
		},
		sources: null,
		sessions: null,
		userTypes: null,
		userAge: null,
		userGender: null,
		averageDuration: null,
		averagePagesVisited: null,
		platforms: null,
		mostVisitedPages: null,
		referrals: null,
		cities: null,
		integrationId: null
	}
}

const getters = {
	getSources: state => state.sources,
	getAverageDuration: state => state.averageDuration,
	getAveragePagesVisited: state => state.averagePagesVisited,
	getPlatforms: state => state.platforms,
	getReferrals: state => state.referrals,
	getMostVisitedPages: state => state.mostVisitedPages,
	getCities: state => state.cities,
	getUserGender: state => state.userGender,
	getUserType: state => state.userTypes,
	getUserAge: state => state.userAge,
	getBenchmark: state => state.benchmark,
	getTotalSessions: state => state.total,
	getSessions: state => state.sessions,
	getIntegrationId: state => state.integrationId
}

const mutations = {
	resetState(state) {
		Object.assign(state, defaultState())
	},

	unsetAnalytics: (state, property) => state[property] = null,
	initialAnalyticsLoad: (state, includeInternationalTraffic) => {
		state.initialLoad = true
		state.international = includeInternationalTraffic
	},
	setAnalyticsDates(state, {to, from, range}) {
		state.range = range
		state.to = to
		state.from = from
	},
	averageDuration: (state, data) => {
		state.averageDuration = data.averageDurationSeconds
	},
	averagePagesVisited: (state, data) => {
		state.averagePagesVisited = data.averageVisitedPages
	},
	sources: (state, data) => {
		state.sources = data.rows.length > 0 ? data : null
	},
	sessions: (state, data) => {
		state.sessions = data.rows.length > 0 ? data : null
	},
	userTypes: (state, data) => {
		state.userTypes = data.rows.length > 0 ? data : null
	},
	userGender: (state, data) => {
		state.userGender = data.rows.length > 0 ? data : null
	},
	userAge: (state, data) => {
		state.userAge = data.rows.length > 0 ? data : null
	},
	platforms: (state, percentage) => {
		state.platforms = percentage * 100
	},
	mostVisitedPages: (state, data) => {
		state.mostVisitedPages = data.rows.length > 0 ? data : null
	},
	referrals: (state, data) => {
		state.referrals = data.rows.length > 0 ? data : null
	},
	cities: (state, data) => {
		state.cities = data.rows.length > 0 ? data : null
	},
	total: (state, data) => {
		state.total = data.total
	},
	setIntegrationId(state, integrationId) {
		state.integrationId = integrationId
	},

	/*
	 * Benchmarks
	 */
	benchmarkReset(state) {
		state.benchmark = {
			loaded: [],
			sessions: 0,
			calls: 0,
			forms: 0,
			conversionRate: {
				industry: 0
			},
			averageSessionDuration: {
				customer: 0,
				industry: 0
			},
			visitsFromDevice: {
				customer: 0,
				industry: 0
			}
		}
	},
	benchmarkTotalSessions: (state, data) => {
		state.benchmark.sessions = data.total
	},
	benchmarkTotalCalls: (state, data) => {
		state.benchmark.calls = data.total
	},
	benchmarkTotalForms: (state, data) => {
		state.benchmark.forms = data.total
	},
	benchmarkVisitsFromDevice: (state, percentage) => {
		state.benchmark.visitsFromDevice.customer = percentage
	},
	benchmarkAverageSessionDuration: (state, data) => {
		state.benchmark.averageSessionDuration.customer = data.averageDurationSeconds
	},
	benchmarkIndustryAveragesSessionDuration: (state, data) => {
		if (data.rows.length === 0) return

		const row = data.rows[0]

		if (JSON.parse(row.metaData.customersCount <= 1)) return

		state.benchmark.averageSessionDuration.industry = row.value
	},
	benchmarkIndustryVisitsFromDevice: (state, data) => {
		if (data.rows.length === 0) return

		const row = data.rows[0]

		if (JSON.parse(row.metaData.customersCount <= 1)) return

		state.benchmark.visitsFromDevice.industry = row.value
	},
	benchmarkIndustryCR: (state, data) => {
		if (data.rows.length === 0) return

		const row = data.rows[0]

		if (JSON.parse(row.metaData.customersCount <= 1)) return

		state.benchmark.conversionRate.industry = row.value
	}
}

const actions = {

	fetchAnalytics({dispatch, commit, rootGetters, state}) {

		const includeInternationalTraffic = rootGetters['customer/includeInternationalTraffic']

		const from = rootGetters['datepicker/getFrom']
		const to = rootGetters['datepicker/getTo']
		const range = rootGetters['datepicker/getRange']

		const integrationId = rootGetters['website/getIntegrationId']

		const payload = {
			range,
			from,
			to,
			cache: '1hour',
			id: integrationId
		}

		const fetch = !state.initialLoad || state.range !== range || state.international !== includeInternationalTraffic

		if (fetch) {
			commit('initialAnalyticsLoad', includeInternationalTraffic)
			commit('setAnalyticsDates', payload)
		}

		if (fetch || !state.total) {
			dispatch('totalSessions', payload)
		}
		if (fetch || !state.sources) {
			dispatch('sessionsWithSources', payload)
		}
		if (fetch || !state.platforms) {
			dispatch('platforms', payload)
		}
		if (fetch || !state.averageDuration) {
			dispatch('averageDuration', payload)
		}
		if (fetch || !state.averagePagesVisited) {
			dispatch('averagePagesVisited', payload)
		}
		if (fetch || !state.referrals) {
			dispatch('referrals', payload)
		}
		if (fetch || !state.mostVisitedPages) {
			dispatch('mostVisitedPages', payload)
		}
		if (fetch || !state.cities) {
			dispatch('cities', payload)
		}
		if (fetch || !state.userGender) {
			dispatch('userGender', payload)
		}
		if (fetch || !state.userAge) {
			dispatch('userAge', payload)
		}
		if (fetch || !state.userTypes) {
			dispatch('userTypes', payload)
		}
	},

	platformsPercentage: ({commit}, data) => new Promise(resolve => {
		const {rows, total} = data

		if (!total || total === 0) {
			resolve(total)
			return
		}

		const mobileOrTablet = rows
			.filter(row => row['deviceCategory'] === 'mobile' || row['deviceCategory'] === 'tablet')
			.map(row => row.sessions)
			.reduce((max, cur) => max + cur)

		const platforms = mobileOrTablet / total

		resolve(platforms)
	}),

	async totalSessions({dispatch, commit}, {from, to, cache, id}) {
		commit('unsetAnalytics', 'total')

		let response = await AnalyticsService.sessions(from, to, cache, id);
		response = handleResponse.handleError(response);

		commit('total', response)
		return response
	},

	async sessionsWithSources({dispatch, commit}, {from, to, sort = null, cache, id}) {
		commit('unsetAnalytics', 'sources')

		let response = await AnalyticsService.sessionsWithSources(from, to, sort, cache, id);
		response = handleResponse.handleError(response);

		commit('sources', response)
		return response
	},

	async platforms({dispatch, commit}, {from, to, commitTo = 'platforms', cache, id}) {
		if (commitTo === 'platforms') {
			commit('unsetAnalytics', 'platforms')
		}

		let response = await AnalyticsService.sessionsWithPlatforms(from, to, cache, id);
		response = handleResponse.handleError(response);

		const percentage = await dispatch('platformsPercentage', response)
		commit(commitTo, percentage)
		return percentage
	},

	async averageDuration({dispatch, commit}, {from, to, commitTo = 'averageDuration'}) {
		if (commitTo === 'averageDuration') {
			commit('unsetAnalytics', 'averageDuration')
		}

		const response = await AnalyticsService.averageDuration(from, to)
		commit(commitTo, response)
		return response
	},

	async averagePagesVisited({dispatch, commit}, {from, to}) {
		commit('unsetAnalytics', 'averagePagesVisited')

		const response = await AnalyticsService.averagePagesVisited(from, to)
		commit('averagePagesVisited', response)
		return response
	},

	async referrals({dispatch, commit}, {from, to}) {
		commit('unsetAnalytics', 'referrals')

		const response = await AnalyticsService.referrals(from, to)
		commit('referrals', response)
		return response
	},

	async mostVisitedPages({dispatch, commit}, {from, to}) {
		commit('unsetAnalytics', 'mostVisitedPages')

		const response = await AnalyticsService.pages(from, to)
		commit('mostVisitedPages', response)
		return response
	},

	async cities({dispatch, commit}, {from, to}) {
		commit('unsetAnalytics', 'cities')

		const response = await AnalyticsService.cities(from, to)
		commit('cities', response)
		return response
	},

	async userGender({dispatch, commit}, {from, to}) {
		commit('unsetAnalytics', 'userGender')

		let response = await AnalyticsService.sessionsUserGender(from, to);
		response = handleResponse.handleError(response);

		commit('userGender', response)
		return response
	},

	async userAge({dispatch, commit}, {from, to}) {
		commit('unsetAnalytics', 'userAge')

		let response = await AnalyticsService.sessionsUserAgeBracket(from, to);
		response = handleResponse.handleError(response);

		commit('userAge', response)
		return response
	},

	async userTypes({dispatch, commit}, {from, to}) {
		commit('unsetAnalytics', 'userTypes')

		let response = await AnalyticsService.sessionsUserType(from, to);
		response = handleResponse.handleError(response);

		commit('userTypes', response)
		return response
	},

	async benchmarkTotalCalls({commit}, params) {
		const calls = await CallService.findAll(params)
		commit('benchmarkTotalCalls', calls)
	},

	async benchmarkTotalForms({commit}, params) {
		const forms = await FormService.findAll(params)
		commit('benchmarkTotalForms', forms)
	},

	async benchmarkIndustry({commit}, {params, commitTo}) {
		const benchmarks = await BenchmarkService.findAll(params)
		commit(commitTo, benchmarks)
	},

	benchmarkConversionRates({dispatch, rootState}, {to, from, industryId, integrationType}) {
		const industryParams = {
			limit: 1,
			filter_groups: [
				{
					filters: [
						{
							value: integrationType,
							key: 'type',
							operator: 'eq'
						},
						{
							value: industryId,
							key: 'industry',
							operator: 'eq'
						}
					]
				}
			],
			sort: [
				{
					key: 'date',
					direction: 'DESC'
				}
			]
		}

		const customerParams = {
			limit: 1,
			filter_groups: [
				{
					filters: [
						{
							key: 'date',
							value: from.format('YYYY-MM-DD'),
							operator: 'gt'
						},
						{
							key: 'date',
							value: to.format('YYYY-MM-DD'),
							operator: 'lte'
						},
						{
							key: 'customer',
							value: rootState.customer.customer.id,
							operator: 'eq'
						}
					]
				}
			]
		}

		dispatch('benchmarkTotalCalls', customerParams)
		dispatch('benchmarkTotalForms', customerParams)
		dispatch('benchmarkIndustry', {params: industryParams, commitTo: 'benchmarkIndustryCR'})
	},

	benchmarkAverageSessionDuration({dispatch, commit}, payload) {
		dispatch('averageDuration', {commitTo: 'benchmarkAverageSessionDuration', ...payload})

		const params = {
			limit: 1,
			filter_groups: [
				{
					filters: [
						{
							value: 'average-session-duration',
							key: 'type',
							operator: 'eq'
						},
						{
							value: payload.industryId,
							key: 'industry',
							operator: 'eq'
						}
					]
				}
			],
			sort: [
				{
					key: 'date',
					direction: 'DESC'
				}
			]
		}

		dispatch('benchmarkIndustry', {params, commitTo: 'benchmarkIndustryAveragesSessionDuration'})
	},

	benchmarkVisitsFromDevice({dispatch, commit}, payload) {
		dispatch('platforms', {commitTo: 'benchmarkVisitsFromDevice', ...payload})

		const params = {
			limit: 1,
			filter_groups: [
				{
					filters: [
						{
							value: 'visits-from-device',
							key: 'type',
							operator: 'eq'
						},
						{
							value: payload.industryId,
							key: 'industry',
							operator: 'eq'
						}
					]
				}
			],
			sort: [
				{
					key: 'date',
					direction: 'DESC'
				}
			]
		}
		dispatch('benchmarkIndustry', {params, commitTo: 'benchmarkIndustryVisitsFromDevice'})
	},


	async benchmarks({dispatch, commit, rootState}, payload) {
		commit('benchmarkReset')

		const from = moment().startOf('month').subtract(3, 'months')
		const to = moment().startOf('month')

		payload.from = from
		payload.to = to

		let response = await AnalyticsService.sessions(from, to);
		response = handleResponse.handleError(response);

		commit('benchmarkTotalSessions', response)

		dispatch('benchmarkAverageSessionDuration', payload)
		dispatch('benchmarkVisitsFromDevice', payload)

		if (payload.showConversionRate) {
			dispatch('benchmarkConversionRates', payload)
		}
	}
}

const handleResponse = {
	handleError(response) {
		if (response?.success === false) {
			return response?.errorMsg;
		}
		return response;
	}
}

export default {
	namespaced: true,
	state: defaultState(),
	getters,
	mutations,
	actions
}

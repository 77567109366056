import ApiService from '@/services/_app/ApiService'

const namespace = 'analytics/cached-account'

export default class CachedAnalyticsAccountService {
  static find (id, options) {
    return ApiService.get(namespace + '/' + id, options)
  }

  static findAll (options) {
    return ApiService.get(namespace, options)
  }

  static integrate (options) {
    return ApiService.post(namespace + '/integrate', options)
  }
}

import {mapGetters} from 'vuex'

export default {
    computed: {
        ...mapGetters('identity', {
            identity: 'getIdentity'
        }),

        ...mapGetters('customer', {
            customer: 'getCustomer'
        }),

        /**
         * Check if the customer is Saas
         *
         * @returns {boolean}
         */
        isFreemium() {
            if (!this.identity) {
                 return false
            }

            const customer = this.customer

            if (! customer) {
                return false
            }

            if (!customer.organization) {
                return true
            }

            const organization = customer.organization

            return organization.freemium
        }
    },
}

<template>
    <div class="economic">
        <div class="form-group">
            <label>{{$t('integrations.backend.id')}}</label>

            <progress-bar v-if="!hasLoaded" :estimated="estimatedTime" :seconds="seconds"></progress-bar>

            <multiselect v-if="hasLoaded"
                         v-model="selected"
                         :options="accounts"
                         track-by="title"
                         label="title"
                         :close-on-select="true"
                         :show-labels="false"
                         :placeholder="$t('integrations.backend.selectAccount')"
                         :selectLabel="$t('multiSelect.selectLabel')">
                <p slot="noResult">{{ $t('multiSelect.noResultsFound') }}</p>
            </multiselect>
        </div>

        <button type="button" class="btn btn-success btn-block" @click="create">
            <span v-if="creatingIntegration" class="fa fa-spin fa-spinner"></span>
            {{$t('integrations.backend.add')}}
        </button>
    </div>
</template>

<style lang="scss">
    .economic {
    }
</style>

<script>
    import Loader from '@/app/shared/components/Loader'
    import ProgressBar from './ProgressBar.vue'

    const IntegrationService = require('@/services/integrations/IntegrationService')

    const EconomyService = require('@/services/economy/EconomyService')

    export default {
        props: {
            service: Object,
            customer: Object
        },
        data() {
            return {
                hasLoaded: false,
                creatingIntegration: false,
                accounts: [],
                selected: null,
                estimatedTime: 1,
                seconds: 0
            }
        },
        mounted() {
            this.load();
        },
        methods: {
            load() {
                this.seconds = 0;

                EconomyService.accounts({}, (response) => {
                    var body = response.data;

                    for (var key in body) {
                        if (!body.hasOwnProperty(key)) {
                            continue;
                        }

                        this.accounts.push({
                            id: key,
                            title: body[key] + ' (ID: ' + key + ')'
                        });
                    }

                    this.hasLoaded = true;
                }, (response) => {

                    this.hasLoaded = true;
                });
            },

            create() {
                if (!this.selected) {
                    return;
                }

                this.creatingIntegration = true;

                var data = {
                    service: this.service.id,
                    customer: this.customer.id,
                    data: this.selected.id,
                    connectedAccount: this.selected.title
                };

                IntegrationService.create(data, (response) => {
                    this.creatingIntegration = false;

                    eventHub.$emit('integration.created', response.data);
                });
            }
        },
        components: {
            Loader,
            ProgressBar
        }
    }
</script>

const BaseService = require('@/services/BaseService')

const namespace = 'keywords'

const find = (id, options, cb, errorCb) => {
  BaseService.get(`${namespace}/${id}`, options, cb, errorCb)
}

const findAll = (options, cb, errorCb) => {
  BaseService.get(namespace, options, cb, errorCb)
}

const create = (options, cb, errorCb) => {
  BaseService.post(namespace, options, cb, errorCb);
}

const update = (id, options, cb, errorCb) => {
  BaseService.put(`${namespace}/${id}`, options, cb, errorCb);
}

const remove = (id, cb, errorCb) => {
  BaseService.remove(`${namespace}/${id}`, {}, cb, errorCb)
}

const findLatest = (options, cb, errorCb) => {
  BaseService.get(`${namespace}/rankings-array`, options, cb, errorCb)
}

export {
  find,
  findAll,
  create,
  update,
  remove,
  findLatest
}

import Vue from 'vue'

import store from '@/store'

const onFail = (error, errorCb) => {
  if (!errorCb && error.response) {
    eventHub.$emit('ajax-error', error.response)
  }

  if (errorCb) {
    errorCb(error)
  }
}

const onSuccess = (response, cb) => {
  if (cb) {
    cb(response)
  }

  return Promise.resolve(response)
}

const resolveData = data => {
  const customer = store.getters['customer/getCustomer']
  const isDemo = store.getters['ghost/isDemo']

  if (customer) {
	data = {
	  customerId: customer.id,
	  ...data
	}
  }

  if (isDemo) {
	  data = {
		  demo: 1,
		  ...data
	  }
  }

  return data
}

const get = (path, data, cb, errorCb, config = {}) => {
	const axiosConfig = JSON.parse(JSON.stringify(config))
	axiosConfig.params = resolveData(data)

  return Vue.axios.get(path, axiosConfig)
    .then(response => onSuccess(response, cb))
    .catch(error => onFail(error, errorCb))
}

const remove = (path, data, cb, errorCb, config = {}) => {
	const axiosConfig = JSON.parse(JSON.stringify(config))
	axiosConfig.params = resolveData(data)

  return Vue.axios.delete(path, axiosConfig)
    .then(response => onSuccess(response, cb))
    .catch(error => onFail(error, errorCb))
}

const post = (path, data, cb, errorCb) => {
  return Vue.axios.post(path, resolveData(data))
    .then(response => onSuccess(response, cb))
    .catch(error => onFail(error, errorCb))
}

const put = (path, data, cb, errorCb) => {
  return Vue.axios.put(path, resolveData(data))
    .then(response => onSuccess(response, cb))
    .catch(error => onFail(error, errorCb))
}

const patch = (path, data, cb, errorCb) => {
  return Vue.axios.patch(path, resolveData(data))
    .then(response => onSuccess(response, cb))
    .catch(error => onFail(error, errorCb))
}

const postRaw = (resource, options) => {
  return Vue.axios.post(resource, null, options)
}

export {
  get,
  remove,
  post,
  put,
  patch,
  postRaw
}

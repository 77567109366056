import Vue from "vue";
import Router from "vue-router";
import StorageService from "@/services/_app/storage/StorageService";
import TokenService from "@/services/_app/storage/TokenService";
import routes from "@/app/app.routes";
import store from "@/store";

Vue.use(Router);

const router = new Router({ mode: "history", routes });

// Security
router.beforeEach(async (to, from, next) => {
  eventHub.$emit("router.beforeRouteChange");

  const isLoggedIn = TokenService.getToken() || false;
  const authState = StorageService.get("authState");

  const isRequiredAsClient = to.matched.some(
    record => record.meta.requiresClientIdentity
  );
  const isRequiredAsEmployee = to.matched.some(
    record => record.meta.requiresEmployeeIdentity
  );
  const guest =
    to.matched.some(record => record.meta.guest) &&
    !isRequiredAsClient &&
    !isRequiredAsEmployee;
  const query = !isLoggedIn ? { redirect: to.fullPath } : {};

  if(to.name === 'opn-redirect'){
    next()
    return;
  }

  if (to.name === "logout") {
    next();
    return;
  }

  // Has magic link
  if (!isLoggedIn && !["login", "oauth"].includes(to.name) && to.query.code) {
    next({ name: "login", query: to.query });
    return;
  } else if (!isLoggedIn && !guest) {
    next({ name: "login", query });
    return;
  }

  if (isLoggedIn) {
    if (!store.getters["identity/hasIdentity"])
      await store.dispatch("identity/loadIdentity");

    const customer = store.getters["customer/getCustomer"];
    if (!to.name.includes("onboarding") && customer && !customer.onboarded) {
      next({ name: "onboarding.step-1" });

      return;
    }

    if ( (isRequiredAsEmployee && authState === "client") || (isRequiredAsClient && authState !== "client") ) {
      next({ name: authState === "client" ? "frontend" : "backend" });
      return;
    }
  }

  next();
});

export default router;

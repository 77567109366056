import ApiService from "@/services/_app/ApiService";

const namespace = "password";

export default class PasswordService {
    /**
     * Send a reset password email
     *
     * @param email
     * @returns {Promise<boolean>}
     */
    static async reset(email) {
        return await ApiService.post(`${namespace}/reset/${email}`);
    }

    /**
     * Change password
     *
     * @param token
     * @param data
     * @returns {Promise<boolean>}
     */
    static async change(token, data) {
        await ApiService.post(`${namespace}/change/${token}`, data);
        return true;
    }
}

<template>
    <div class="box">
        <router-link :to="{name: 'backend.customers.view.overview', params: {id: customer.id}}">
            <card>
                <div class="top text-center">
                    <h1>{{customer.name}}</h1>
                    <span class="domain">{{customer.domain}}</span>

                    <client-category :clientCategory="customer.clientCategory"></client-category>
                </div>

                <hr />

                <div class="box-footer">
                    <span class="pull-left">
                        <span class="fa fa-refresh"></span>&nbsp;
                        {{customer.updated.date | moment("from", "now") | capitalize}}
                    </span>

                    <div class="pull-right">
                        <div v-if="customer.clientManager" class="manager">
                            <img :src="clientManagerImage">
                        </div>
                    </div>

                    <div class="clearfix"></div>
                </div>
            </card>
        </router-link>
    </div>
</template>

<style lang="scss" scoped>
    .box {
        a {
            color: initial;

            &:hover {
                color: initial;

                .card {
                    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3), 0 0 0 1px rgba(63, 63, 68, 0.3);
                }
            }
        }

        h1 {
            overflow: hidden;
            display: inline-block;
            text-overflow: ellipsis;
            max-width: 90%;
            font-size: 16pt;
            white-space: nowrap;
            font-weight: 500;
            margin: 25px auto;
        }

        .domain {
            display: block;
            color: #828282;
            margin-bottom: 40px;
        }

        .top {
            height: 200px;
        }

        .client-category {
            zoom: 2;
        }

        .box-footer {
            color: #696969;
            font-size: 9pt;
            margin: -5px -15px -15px -15px;
        }

        .manager {
            position: relative;
            overflow: hidden;
            display: inline-block;
            width: 36px;
            height: 36px;
            border-radius: 50%;
            margin: -15px 0 -15px 5px;
            text-align: center;
            border: 1px solid #dcdcdc;

            img {
                display: inline-block;
                max-height: 180%;
                margin-top: -18%;
                margin-left: -17%;
            }
        }
    }
</style>

<script>
import Card from '@/app/shared/components/Card'
import ClientCategory from '@/app/customers/components/ClientCategory'

export default {
    props: {
        customer: Object
    },

    computed: {
        clientManagerImage() {
            return '/images/employees/' + this.customer.clientManager.photo
        }
    },

    components: {
        ClientCategory,
        Card
    }
}
</script>

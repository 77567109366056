<template>
    <div class="inquiries">
        <check-for-missing-integrations :integrations="['Freespee', 'Formstack', 'WordPress forms']">
            <landing-page slot="no-integrations" image="/images/integrations/cta-messages/mail-and-calls.png" :headline="$t('inquiries.onboarding.headline')" :description="$t('inquiries.onboarding.description')">
                <hr>

                <call-and-form></call-and-form>
            </landing-page>

            <div class="container-fluid">
                <div class="row">
                    <div class="col-sm-12 col-xs-12 hidden-lg hidden-md">
                        <datepicker/>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-12">
                        <card class="tour-step-1">
                            <inquiry-stats @hasLoaded="startTourWhenReady()"></inquiry-stats>
                        </card>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-12">
                        <card class="tour-step-3" icon="pe-7s-graph3" :headline="$t('inquiries.list.headline')" :description="$t('inquiries.list.description')">
                            <inquiries />
                        </card>
                    </div>
                </div>
            </div>
        </check-for-missing-integrations>
    </div>
</template>

<script>
  import Inquiries from '@/app/inquiries/components/Inquiries'
  import InquiryStats from '@/app/inquiries/components/Stats'
  import Banner from '@/app/onboarding/components/Banner'
  import CheckForMissingIntegrations from '@/app/integrations/components/CheckForMissingIntegrations'
  import LandingPage from '@/app/onboarding/components/LandingPage'
  import CallAndForm from '@/app/onboarding/components/integrations/CallAndForm'
  import Datepicker from '@/app/datepicker/components/Datepicker'

  import tourMixin from '@/mixins/tours/tourMixin'

  export default {
    mixins: [tourMixin],
    methods: {
      startTourWhenReady () {
        let options = {
          steps: [
            {
              element: '.tour-step-1',
              intro: '<h5>Se statistik for henvendelser</h5><p>Her vises det samlede antal henvendelser, du har modtaget. Tallet vises kun, hvis du oprettet ‘Opkaldstracking’ og ‘Emailtracking’</p>',
              position: 'top',
            },
            {
              element: '.tour-step-2',
              intro: '<h5>Vælg en periode</h5><p>Brug denne funktion til at se antal henvendelser for en bestemt periode. Sammenlign eksempelvis dine resultater med forrige måneds tal.</p>',
              position: 'top',
            },
            {
              element: '.tour-step-3',
              intro: '<h5>Se seneste henvendelser</h5><p>Med denne funktion kan du finde information om dine seneste henvendelser. Se kontaktoplysningerne, genlæs emailen eller lyt til jeres samtale</p>',
              position: 'top',
            },
          ]
        }

        if (this.isOnboarding) {
          options.steps.push({
            element: '.tour-step-integration',
            intro: '<h5>Fortsæt det gode arbejde</h5><p>Du har nu opsat din første integration i OP\'N. Klik på integrationssiden for at opsætte den næste.</p>',
          })
        }

        this.startTour('inquiries', options)
      }
    },

    components: {
      Inquiries,
      InquiryStats,
      Datepicker,
      Banner,
      CheckForMissingIntegrations,
      LandingPage,
      CallAndForm,
    }
  }
</script>

<template>
    <div class="create-user">
        <div class="alert alert-danger" v-if="error">
            <strong>OBS:</strong> Noget gik desværre galt. Dobbelttjek nummeret
            eller prøv igen senere
        </div>

        <br />

        <div class="form-group text-left" :class="{ 'has-error': error }">
            <label>Telefonnr.</label>
            <input
                type="text"
                v-model="number"
                ref="phoneNumber"
                class="form-control"
            />
        </div>

        <br />

        <hr />

        <button class="btn btn-primary btn-fill pull-right" @click="create">
            <template v-if="hasLoaded">Tilføj nummeret</template>
            <loader v-else></loader>
        </button>

        <div class="clearfix"></div>
    </div>
</template>

<script>
const FreespeeService = require("@/services/calltracking/FreespeeService");

export default {
    props: {
        customerId: [String, Number]
    },

    data() {
        return {
            number: null,
            error: false,
            hasLoaded: true
        };
    },

    watch: {
        email() {
            this.error = false;
        }
    },

    methods: {
        create() {
            if (!this.hasLoaded) {
                return;
            }

            if (!this.number) {
                this.error = true;
                this.hasLoaded = true;

                return;
            }

            this.error = false;
            this.hasLoaded = false;

            const data = {
                number: this.number
            };

            FreespeeService.createNumber(
                this.customerId,
                data,
                response => {
                    this.number = null;
                    this.error = false;
                    this.hasLoaded = true;

                    this.$emit("created");
                },
                response => {
                    this.error = true;
                    this.hasLoaded = true;
                }
            );
        }
    }
};
</script>

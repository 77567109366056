<template>
    <div class="col-lg-12 actions">
        <button
            class="btn btn-login"
            v-if="
                customer.supportConsent ||
                  (customer.organization && !customer.organization.freemium)
            "
            @click="signIn"
        >
            {{ $t("enterAs") }} <strong>{{ customer.domain }}</strong>
        </button>

        <template v-else>
            <span class="attention" v-if="!customer.onboarded">{{
                $t("customers.didNotOnboard")
            }}</span>
            <span
                class="attention"
                v-if="
                    !customer.supportConsent &&
                        customer.organization &&
                        !customer.organization.freemium
                "
            >
                {{ $t("customers.noSupportConsent") }}
            </span>
            <span class="attention" v-if="!users.length">{{
                $t("customers.noUser")
            }}</span>
        </template>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
    name: "GhostIdentityButton",
    props: {
        customer: {
            type: Object
        }
    },
    computed: {
        ...mapGetters("identity", {
            identity: "getIdentity"
        }),

        users() {
            return this.customer.users.filter(user => user.deleted === null);
        }
    },
    methods: {
        ...mapActions("ghost", ["logInAsGhost"]),

        async signIn() {
            try {
                const payload = {};

                if (!this.users || !this.users.length) payload.customerId = this.customer.id;
                else payload.email = this.users[0].email;

                await this.logInAsGhost(payload);

                this.$router.push({ name: "frontend.overview" });
            } catch (e) {
                throw e;
            }
        }
    }
};
</script>

<style scoped lang="scss">
@import "~@/assets/scss/vars";
@import "~@/assets/scss/variables/all";

.btn-login {
    width: 100%;
    background: $digital-blue;
    color: $white;
    border: none;
    opacity: 1;
    transition: all 0.2s ease;
    cursor: pointer;
    border-radius:2px;
    padding:10px;
    &:hover {
        opacity: 0.85;
    }
}
</style>

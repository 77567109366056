const BaseService = require('@/services/BaseService')

const namespace = 'reports'

const findAll = (options, cb, errorCb) => {
  BaseService.get(namespace, options, cb, errorCb)
}
// TODO is this still active?
const preview = (id, val, cb, errorCb) => {
  BaseService.get(namespace + '/' + id + '/preview/' + val, {}, cb, errorCb)
}

const sendReport = (id, args, cb, errorCb) => {
  BaseService.post(namespace + '/send-report/' + id, args, cb, errorCb)
}

export {
  findAll,
  preview,
  sendReport
}

import Inquiries from '@/app/inquiries/Inquiries'

export default [
  {
    path: '/inquiries',
    name: 'frontend.inquiries',
    component: Inquiries,
    meta: {
      headline: () => $t('navigation.latestInquiries'),
      description: () => $t('topBar.descriptions.inquiries'),
      tracking: 'Inquiries - Page view - Main page',
      datepicker: true
    }
  },
]

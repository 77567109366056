<template>
    <div class="text-center noselect" :class="sizeClass">
        <ul v-if="this.total > 0">
            <li :class="{disabled: page === first}">
                <a @click="previous" class="previous" :class="{disabled: page === first}" aria-label="Previous">
                    <span aria-hidden="true">
                        <span class="fa fa-caret-left"></span>
                    </span>
                </a>
            </li>

            <!-- Quick button to first -->
            <template v-if="firstQuickButton">
                <li class="hidden-xs">
                    <a @click="goTo(1)">
                        <span aria-hidden="true">1</span>
                    </a>
                </li>

                <li class="placeholder hidden-xs" v-if="page - ticksLeft - 1 !== first">
                    ...
                </li>
            </template>

            <!-- Ticks -->
            <template v-if="size === 'large'">
                <li v-for="n in ticksLeft" class="hidden-xs" :key="n">
                    <a @click="goTo(page - ticksLeft + n - 1)">
                        <span aria-hidden="true">{{ page - ticksLeft + n - 1}}</span>
                    </a>
                </li>

                <li class="active">
                    <a>
                        <span aria-hidden="true">{{ page }}</span>
                    </a>
                </li>

                <li v-for="(n, index) in ticksRight" :key="index" class="hidden-xs">
                    <a @click="goTo((page + n))">
                        <span aria-hidden="true">{{ page + n }}</span>
                    </a>
                </li>
            </template>

            <!-- Quick button to last -->
            <template v-if="lastQuickButton">
                <li class="placeholder hidden-xs" v-if="page + ticksRight + 1 !== last">
                    ...
                </li>

                <li class="hidden-xs">
                    <a @click="goTo(last)">
                        <span aria-hidden="true">{{last}}</span>
                    </a>
                </li>
            </template>

            <li :class="{disabled: page === last}">
                <a @click="next"
                   class="next"
                   :class="{disabled: page === last}"
                   aria-label="Next">
                    <span aria-hidden="true">
                        <span class="fa fa-caret-right"></span>
                    </span>
                </a>
            </li>
        </ul>
    </div>
</template>

<style lang="scss" scoped>
    .pagination--large {
        margin-top: 15px;
        li {
            float: left;
            list-style: none;

            a {
                padding: 9px 12px;
                margin-left: 10px;
                border: 1px solid #c9c9c9;
                border-radius: 4px;
                font-weight: bold;
                background-color: #fff;

                &:hover {
                    border-color: #a7a7a7;
                }

                &.disabled {
                    color: #ccc;
                    border-color: #eee;
                    cursor: auto;
                }
            }

            &.active a {
                background-color: #f5f5f5;
                 color: #333;
                &:hover {
                     border: 1px solid #c9c9c9;
                     cursor: auto;
                 }
            }

            &.placeholder {
                padding-left: 10px;
                padding-right: 0;
            }
        }
    }

    .pagination--small {
        float: right;
        ul {
            margin: 10px 0 0;
            padding: 0;
            li {
                float: left;
                list-style: none;
                &:last-child {
                    margin-left: 5px;
                }

                a {
                    display: block;
                    padding: 6px 8px;
                    border-radius: 4px;
                    color: #000;
                    font-weight: 300;
                    background-color: #eee;
                    &.disabled {
                        color: #ccc;
                        cursor: auto;
                    }

                  &:not(.disabled):hover {
                    background-color: #dedede;
                  }
                }

                &.active a {
                    background-color: #f5f5f5;
                     color: #333;
                }
            }
        }
    }
</style>

<script>

export default {
    props: {
        total: Number,
        limit: Number,
        page: Number,
        size: {
            type: String,
            default: 'large'
        },
        ticks: {
            type: Number,
            default: 1
        },
        showFirstAndLast: {
            type: Boolean,
            default: true
        },
        keyboardControl: {
            type: Boolean,
            required: false,
        }
    },

    computed: {
        first() {
            return 1
        },

        last() {
            return Math.ceil(this.total / this.limit)
        },

        ticksLeft() {
            let results = this.ticks

            if (this.page === this.last) {
                results = results * 2
            }

            if ((this.page - results) < this.first) {
                results = this.page - 1
            }

            return results
        },

        sizeClass() {
            return 'pagination--' + this.size
        },

        ticksRight() {
            let results = this.ticks

            if (this.page === this.first) {
                results = results * 2
            }

            if ((this.page + results) > this.last) {
                results = 0
            }

            return results
        },

        firstTickIsVisible() {
            return ((this.page - this.ticksLeft) <= this.first)
        },

        lastTickIsVisible() {
            return ((this.page + this.ticksRight) >= this.last)
        },

        firstQuickButton() {
            if (!this.showFirstAndLast || this.firstTickIsVisible) {
                return false
            }

            return true
        },

        lastQuickButton() {
            if (!this.showFirstAndLast || this.lastTickIsVisible) {
                return false
            }

            return true
        }
    },

    mounted() {
        if (this.keyboardControl) {
            window.addEventListener('keyup', this.keyboardController)
        }
    },

    destroyed() {
        if (this.keyboardControl) {
            window.removeEventListener('keyup', this.keyboardController)
        }
    },

    methods: {
        keyboardController(event) {
            if (event.key === "ArrowRight") {
                this.next()
            }

            if (event.key === "ArrowLeft") {
                this.previous()
            }
        },

        next() {
            if (this.page === this.last) {
                return
            }

            this.goTo(this.page + 1)
        },

        previous() {
            if (this.page === this.first) {
                return
            }

            this.goTo(this.page - 1)
        },

        goTo(page) {
            this.$emit('paginate', page)
        }
    }
}
</script>

<template>
    <article class="card" :class="[$org('slug'), {'is-centered': centered, 'has-org-bg': organization}]">
        <slot />
    </article>
</template>

<script>
  export default {
    name: 'CardBox',
    props: {
      centered: {
        type: Boolean,
        required: false,
        default: false
      },
      organization: {
        type: Boolean,
        required: false,
        default: false
      }
    }
  }
</script>

<style scoped lang="scss">
    @import "~@/assets/scss/variables/_colors.scss";

    .is-centered {
        width: 100%;
        justify-content: center;
        align-items: center;
        display: flex;
        flex-direction: column;
    }

    .has-org-bg {
        &.sgme {
            background: $sgme-primary;
        }

        &.spoton-marketing {
            background: $sol-primary;
        }
    }
</style>

// TODO: Replace old with new
import ApiService from '@/services/_app/ApiService'

const namespace = 'inquiries'

export default class InquiryService {
  static find (id, options) {
    return ApiService.get(namespace + '/' + id, options)
  }

  static findAll (options) {
    return ApiService.get(namespace, options)
  }

  static averageByIndustry (from, to) {
    return ApiService.get(namespace + '/average-by-industry', {from, to})
  }
}

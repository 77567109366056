<template>
    <div>
        <template v-if="!hasLoaded">
            <loader class="loader"></loader>
        </template>

        <template v-else>
            <code-box :code="javascript" language="HTML" v-if="!hasFailed"></code-box>

            <div class="text-center" v-else>
                <div class="well">{{$t('backend.callTracking.noHTML')}}</div>
            </div>
        </template>
    </div>
</template>

<style lang="scss" scoped>
    .loader {
        line-height: 337px;
    }

    code.block {
        display: block;
        padding: 16px;
    }
</style>

<script>
    import Loader from '@/app/shared/components/Loader'
    import CodeBox from '@/app/layout/components/CodeBox'

    const FreespeeService = require('@/services/calltracking/FreespeeService')

    export default {
        props: {
            customer: {
                type: Object,
                required: false,
                default: null
            }
        },

        data() {
            return {
                hasLoaded: false,
                hasFailed: false,
                javascript: null,
                customerId: null
            }
        },

        mounted() {
            if (this.$route.params.id) {
                this.customerId = this.$route.params.id
            }

            if (this.customer !== null) {
                this.customerId = this.customer.id
            }

            this.load()
        },

        methods: {
            load() {
                this.javascript = null
                this.hasLoaded = false

                FreespeeService.javascript(this.customerId, (response) => {
                    const data = response.data

                    this.javascript = data.javascript
                    this.hasLoaded = true
                }, (response) => {
                    this.hasLoaded = true
                    this.hasFailed = true
                })
            },
        },

        components: {
            Loader,
            CodeBox
        }
    }
</script>

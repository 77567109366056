import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { I18N_FALLBACK_LOCALE, I18N_LOCALE, I18N_LOCALE_CODE } from '@/config/env'

Vue.use(VueI18n)

function loadLocaleMessages() {
  const locales = require.context('@/', true, /locales\/[A-Za-z0-9-_,\s]+\.json$/i)

  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]

      if (!messages[locale]) {
        messages[locale] = {}
      }

      messages[locale] = { ...messages[locale], ...locales(key) }
    }
  })
  return messages
}

const domains = {
    'app.opnx.dk': {
        short: 'da',
        code: 'da_DK',
    },
    'app.opnx.se': {
        short: 'sv',
        code: 'sv_SW'
    },
    'app.opnx.fi': {
        short: 'fi',
        code: 'fi_FI'
    },
    'de.opnx.dk': {
        short: 'de',
        code: 'de_DE'
    },
    'localhost': {
        short: 'da',
        code: 'da_DK'
    }
}

export const resolveLanguage = () => {
    const fallback = {short: I18N_LOCALE, code: I18N_LOCALE_CODE} || {short: 'en', code: 'en_US'}
    
    const domain = window && window.location
        ? window.location.hostname
        : null
    
    return domain && domains[domain]
        ? domains[domain]
        : fallback
}

const i18n = new VueI18n({
  locale: resolveLanguage().code,
  fallbackLocale: I18N_FALLBACK_LOCALE || 'en',
  messages: loadLocaleMessages()
})

global.$t = (key, ...values) => i18n.t(key, values)
global.$tc = (key, choice, ...values) => i18n.tc(key, choice, values)

export default i18n

<template>
    <div class="status">
        <div class="dropdown">
            <div class="labels">
                <span class="prefix">{{$t('contacts.table.status')}}:&nbsp;</span>

                        <span class="value" v-if="value">
                            {{label}}
                        </span>

                        <span class="empty" v-else>
                            -
                        </span>

                <span class="caret"></span>
            </div>

            <select class="form-control pull-left" v-model="value">
                <option :value="null" :selected="value == null">
                    -
                </option>

                <option v-for="(option, optionIndex) in options" :value="option.value">
                    {{option.label}}
                </option>
            </select>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    @import '~@/assets/scss/_vars.scss';

    .status {
        @media(max-width: $screen-xs-max) {
            .dropdown {
                display: block;
                width: 100%;
                border-radius: 0;
                border-bottom: 0;
                padding: 12px 14px;
                font-size: 11pt;

                &:first-of-type {
                    border-top-left-radius: 5px;
                    border-top-right-radius: 5px;
                }

                &:last-of-type {
                    border-bottom: 1px solid #ccc;
                    border-bottom-left-radius: 5px;
                    border-bottom-right-radius: 5px;
                }
            }
        }

        .dropdown {
            display: inline-block;

            cursor: pointer;
            position: relative;
            overflow: hidden;
            border: 1px solid #ccc;
            padding: 7px 14px;
            font-size: 10pt;
            border-radius: 20px;
            margin-right: 10px;

            -webkit-touch-callout: none;
            -webkit-user-select: none;
            -khtml-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;

            .prefix,
            .empty {
                color: #696969;
            }

            .prefix {
                font-size: 9pt;
                font-weight: 500;
                margin-right: 2px;
            }

            .caret {
                color: #696969;
                margin-left: 2px;
            }

            .labels {
                position: relative;
                width: 100%;
                white-space: nowrap;
                z-index: 1;
            }

            select {
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                opacity: 0.01;
                z-index: 2;
            }

            .value {
                color: $primary;
                font-weight: 500;
            }
        }
    }
</style>

<script>
import {mapGetters} from 'vuex'

const StatusService = require('@/services/contacts/StatusService')

import MixpanelService from '@/services/mixpanel/MixpanelService'

export default {
    props: {
        contact: Object
    },

    data() {
        return {
            value: null
        }
    },

    watch: {
        value() {
            if (this.value == this.contactValue) {
                return;
            }

            if (!this.contact) {
                return
            }

            StatusService.update(this.contact.id, this.value)

            MixpanelService.track('Inquiries - Status - Change', {'value': this.label})
        }
    },

    mounted() {
        this.value = this.contactValue;
    },

    computed: {
        ...mapGetters('contacts', {
            statuses: 'getStatuses',
        }),

        contactValue() {
            if (!this.contact) {
                return null
            }

            if (!this.contact.status) {
                return null;
            }

            return this.contact.status.id;
        },

        label() {
            if (!this.value) {
                return
            }

            const option = this.options.filter((option) => {
                return (option.value == this.value)
            })

            if (!option.length) {
                return;
            }

            return option[0].label
        },

        options() {
            return this.statuses.map((status) => {
                return {
                    label: status.title,
                    value: status.id
                }
            })
        }
    }
}
</script>

<template>
    <div>
        <freespee v-if="service.name == 'Freespee'" :service="service" :customer="customer"></freespee>

        <formstack v-else-if="service.name == 'Formstack'" :service="service" :customer="customer"></formstack>

        <google-analytics v-else-if="service.name == 'Google analytics'" :service="service" :customer="customer"></google-analytics>

        <google-adwords v-else-if="service.name == 'Google adwords'" :service="service" :customer="customer"></google-adwords>

        <economic v-else-if="service.name == 'E-conomic'" :service="service" :customer="customer"></economic>

        <id v-else :service="service" :customer="customer"></id>
    </div>
</template>

<script>
    import Id from '@/app/integrations/components/integrations/ID'
    import Freespee from '@/app/integrations/components/integrations/Freespee'
    import Formstack from '@/app/integrations/components/integrations/Formstack'
    import GoogleAnalytics from '@/app/integrations/components/integrations/GoogleAnalytics'
    import GoogleAdwords from '@/app/integrations/components/integrations/GoogleAdWords'
    import Economic from '@/app/integrations/components/integrations/Economic'

    export default {
        props: {
            service: Object,
            customer: Object
        },
        components: {
            Id,
            Freespee,
            Formstack,
            GoogleAnalytics,
            GoogleAdwords,
            Economic
        }
    }
</script>

import ApiService from "@/services/_app/ApiService";

const BaseService = require('@/services/BaseService')

const namespace = 'users/clients'

const find = (id, options, cb) => {
  BaseService.get(`${namespace}/${id}`, options, cb);
}

const findAll = (options, cb) => {
  BaseService.get(namespace, options, cb);
}

const remove = (id, cb) => {
  BaseService.remove(`${namespace}/${id}`, {}, cb);
}

const create = (options, cb, errorCb) => {
  BaseService.post(namespace, options, cb, errorCb);
}

const put = (id, data) => {
   return ApiService.put(`${namespace}/${id}`, data)
}

const reset = (id, cb, errorCb) => {
  BaseService.post(`${namespace}/${id}/reset`, {}, cb, errorCb);
}

export {
  find,
  findAll,
  remove,
  create,
  put,
  reset
}

const BaseService = require('@/services/BaseService')

const namespace = 'onboarding/tours'

const find = (id, options, cb, errorCb) => {
    BaseService.get(namespace + id, options, cb, errorCb)
}

const findAll = (options, cb, errorCb) => {
    BaseService.get(namespace, options, cb, errorCb)
}

const create = (body, cb, errorCb) => {
    BaseService.post(namespace, body, cb, errorCb)
}

export {
    find,
    findAll,
    create
}

'use strict'

import Vue from 'vue'
import axios from 'axios'
import store from '@/store'
import { API_URL } from '@/config/env'
import ApiService from '@/services/_app/ApiService'
import TokenService from '@/services/_app/storage/TokenService'
import router from '@/router'
import Qs from 'qs'

//axios.defaults.baseURL = API_URL
axios.defaults.headers.post['Content-Type'] = 'application/json'

let config = {
  baseURL: API_URL,
  timeout: 60 * 1000,
  paramsSerializer: params => {
    return Qs.stringify(params)
  }
}

const _axios = axios.create(config)

const excludedPaths = [
  'tokens',
  'tokens/*',
  'customers',
  'customers/*',
  'users',
  'users/*',
  'auth/*',
  'reviews/integration',
  'reports/*',
  'onboarding/*',
  'economy/*',
  'integrations/*',
  'billing/*'
]

const CancelToken = axios.CancelToken
let source = CancelToken.source()

Vue.mixin({
  beforeRouteEnter(to, from, next) {
    source.cancel()
    source = CancelToken.source()
    next()
  }
})

const loading = (isLoading) => {
  store.dispatch('setLoading', isLoading)
}

_axios.interceptors.request.use(
  (config) => {
    if (config.cancelToken) {
      return config
    }

    ApiService.setHeader()

    loading(true)

    // Do something with response data
    const excludeLength = excludedPaths.length

    const path = config.url.replace(config.baseURL, '')

    for (let i = 0; i < excludeLength; i++) {
      const regexTest = excludedPaths[i].replace(/\//g, '\\/')
      const patternMatch = new RegExp(regexTest).test(path)

      if (patternMatch) {
        return config
      }
    }

    config.cancelToken = source.token

    return config
  }, (error) => {
    loading(false)
    return Promise.reject(error)
  })

// Add a response interceptor
_axios.interceptors.response.use((response) => {
  loading(false)
  return response
}, async (error) => {
  loading(false)
  const originalRequest = error.config
  if (error.response && [401, 403].includes(error.response.status)) {
    originalRequest.cancelToken = source.token
    const refreshToken = TokenService.getRefreshToken()

    if (refreshToken && !originalRequest._retry) {
      originalRequest._retry = true
      const response = await _axios.post('/login/refresh', { refreshToken })

      if ([200, 201].includes(response.status)) {
        TokenService.saveToken(response.data)
        return _axios(originalRequest)
      }

    } else if (originalRequest._retry && router.currentRoute.path === '/login/refresh' || !refreshToken) {
      await store.dispatch('auth/logout')
      const query = router.currentRoute.name !== 'login' ? { redirect: router.currentRoute.path } : {}

      router.push({ name: 'login', query })
    }
  }

  return Promise.reject(error)
})

Plugin.install = function (Vue, options) {
  Vue.axios = _axios
  window.axios = _axios
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return _axios
      }
    },
    $axios: {
      get() {
        return _axios
      }
    },
    $http: {
      get() {
        return _axios
      }
    }
  })
}

Vue.use(Plugin)

export default Plugin

export default {
	general: {
		icon: '😊',
	},
	consumers: {
		icon: '🛒',
	},
	students: {
		icon: '🧑‍🎓',
	},
	professionals: {
		icon: '🏢️',
	},
	business_owners: {
		icon: '📈',
	},
	job_seekers: {
		icon: '💼',
	},
	investors: {
		icon: '💰',
	},
	entrepreneurs: {
		icon: '👨‍💼',
	}
}

import Vue from 'vue'
import router from '@/router'

import bugsnagClient from '@/services/bugsnag/BugsnagService'
import { DEBUG_MODE } from '@/config/env'

Vue.config.errorHandler = (error) => {
  if (DEBUG_MODE) {
    console.error(error)
  }

  bugsnagClient.notify(error)

  console.error(error)
}

router.beforeEach((to, from, next) => {
  bugsnagClient.refresh()
  next()
})

export default [
  {
    path: '/terms-and-conditions',
    name: 'termsAndConditions',
    component: () => import(/* webpackChunkName: "TermsAndConditions" */ './pages/TermsAndConditions'),
    meta: {
      guest: true,
      tracking: 'Terms and conditions - Page view - Main page',
      allowAccessBeforeOnboarding: true
    }
  }
]

const BaseService = require('@/services/BaseService')

const namespace = 'organizations'

const find = (id, options, cb, errorCb) => {
    BaseService.get(namespace + '/' + id, options, cb, errorCb)
}

const findAll = (options, cb, errorCb) => {
    BaseService.get(namespace, options, cb, errorCb)
}

export {
    find,
    findAll,
}

<template>
    <div class="search">
        <div class="search-bar">
            <input type="text" class="search-bar-input" v-model="query" :placeholder="$t('filters.search')">
            <i class="pe-7s-search search-bar-icon text-muted"></i>
        </div>

        <div class="search-filters" v-if="filters.length">
            <template v-for="(filter, filterIndex) in filters">
                <div class="dropdown pull-left">
                    <div class="labels">
                        <span class="prefix" v-if="filter.prefix">{{filter.prefix}}:&nbsp;</span>

                        <span class="value" v-if="filter.value != null">
                            {{selectedLabel(filter.key)}}
                        </span>

                        <span class="empty" v-else>
                            {{filter.empty}}
                        </span>

                        <span class="caret"></span>
                    </div>

                    <select class="form-control pull-left" v-model="filters[filterIndex].value">
                        <option :value="null" :selected="filter.value == null">
                            {{filter.empty}}
                        </option>

                        <option v-for="(option, optionIndex) in filter.options" :value="option.value">
                            {{option.label}}
                        </option>
                    </select>
                </div>
            </template>
        </div>

        <div class="clearfix"></div>
    </div>
</template>

<style lang="scss" scoped>
    @import '~@/assets/scss/_vars.scss';

    .search {
        position: relative;

        .search-bar {
            background-color: white;
            margin-bottom: 15px;
            border: 1px solid #eee;

            .search-bar-icon {
                font-size: 28px;
                position: absolute;
                top: 10px;
                left: 15px;
            }

            .search-bar-input {
                margin-left: 60px;
                border: none;
                background-color: none;
                height: 45px;
                width: 69%;
            }
        }

        @media(max-width: $screen-xs-max) {
            .search-filters {
                .dropdown {
                    display: block;
                    width: 100%;
                    border-radius: 0;
                    border-bottom: 0;
                    padding: 12px 14px;
                    font-size: 11pt;
                }
            }
        }

        .search-filters {
            margin-top: 15px;

            > .form-control {
                height: 36px;
                font-size: 12px;
                color: #888;
                margin-right: 15px;
                width: 130px;
            }

            .title {
                margin-top: 5px;
                margin-right: 15px;
            }

            .category-select {
                width: 150px;
            }
        }

        .dropdown {
            cursor: pointer;
            position: relative;
            overflow: hidden;
            padding: 7px 14px;
            font-size: 10pt;

            -webkit-touch-callout: none;
            -webkit-user-select: none;
            -khtml-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;

            .prefix,
            .empty {
                color: #696969;
            }

            .prefix {
                font-size: 9pt;
                font-weight: 500;
                margin-right: 2px;
            }

            .caret {
                color: #696969;
                margin-left: 2px;
            }

            .labels {
                position: relative;
                width: 100%;
                background-color: #fff;
                white-space: nowrap;
                z-index: 1;
            }

            select {
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                opacity: 0.01;
                z-index: 2;
            }

            .value {
                color: $primary;
                font-weight: 500;
            }
        }
    }

    @media screen and (max-width: 767px) {
        .search {
            .dropdown {
                &:not(:last-child) {
                    border-right: none;
                }
            }
        }
    }
</style>

<script>
export default {
    props: {
        options: Object
    },

    data() {
        return {
            query: null,
            filters: [],
            debounce: 300,
            sortedClientsClicked: false,
        }
    },

    mounted() {
      if (!this.options) {
        return
      }

      this.query = this.options.query
      this.filters = this.options.filters
    },

    watch: {
        query() {
            (query => {
                setTimeout(() => {
                    if (query !== this.query) {
                        return
                    }

                    this.filtersChanged()
                }, this.debounce)
            })(this.query)
        },

        filters: {
            handler: function (val, oldVal) {
                this.filtersChanged()
            },
            deep: true
        }
    },

    methods: {
        filtersChanged() {
            this.$emit('filters-updated', {
                query: this.query,
                filters: this.filters
            })
        },

        sortChosenClientsOnly() {
            this.sortedClientsClicked = !this.sortedClientsClicked
        },

        selectedLabel(filterKey) {
            const filters = this.filters.filter((filter) => {
                return (filter.key === filterKey)
            })

            if (!filters.length) {
                return null
            }

            const filter = filters[0]
            const value = filter.value

            const options = filter.options.filter((option) => {
                return option.value === value
            })

            if (!options.length) {
                return null
            }

            const option = options[0]

            return option.label
        }
    }
}
</script>

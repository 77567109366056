<template>
    <div class="element">

        <div class="row">
            <div class="col-md-12">
                <span style="display: inline-block; float: right;">
                    <button class="btn btn-primary btn-fill"
                            :style="{backgroundColor:$org('colors.standard.primary.hex')}"
                            type="button"
                            @click="create">
                        <span class="fa fa-circle-o-notch fa-spin fa-fw" v-if="creating" />
                        {{$t('tokens.add')}}
                    </button>
                </span>

                <input type="text"
                        v-model="name"
                        class="form-control"
                        :placeholder="$t('tokens.placeholder')"
                        style="margin-right: 10px; float: right; width: 200px;">
            </div>
        </div>

        <br />
        <br />

        <loader v-if="!hasLoaded" />

        <template v-if="hasLoaded">
            <div class="text-center" v-if="!tokens.length">
                {{$t('tokens.noAPIKeys')}}
            </div>

            <div class="row">
                <div class="col-xs-12" v-for="(token, index) in tokens">
                    <card icon="pe-7s-lock" :headline="token.name" :description="token.created.date | moment('calendar')">
                        <div slot="header">
                            <div class="pull-right action hidden-xs" style="margin-left: 10px;" @click="remove(index)">
                                <span class="icon pe-7s-trash"></span>
                            </div>
                        </div>

                        <p style="overflow-wrap: break-word;" :id="`token-${index}`">{{token.token}}</p>
                        <a href="#"
                           @click.prevent="copyToClipboard(index)"
                           :style="{color:$org('colors.standard.primary.hex')}"
                        >{{$t('tokens.copyToClipboard')}}</a>
                    </card>
                </div>
            </div>
        </template>
    </div>
</template>

<style lang="scss" scoped>
    .element {
        .action {
            .icon {
                cursor: pointer;
                color: #747474;
                font-size: 20pt;
                margin: 8px 0;
            }
        }
    }

    .form-control {
        height: 36px;
        padding: 4px 12px;
    }

    .btn {
        cursor: default;
        color: #fff;
        border: none;
        opacity: 1;
        transition: all 0.2s ease;
        &:hover {
            color: #fff;
            opacity: 0.85;
        }
    }
</style>

<script>
import Loader from '@/app/shared/components/Loader'
import Card from '@/app/shared/components/Card'

const TokenService = require('@/services/customers/TokenService')

import {mapGetters, mapActions} from 'vuex'

export default {
    data() {
        return {
            hasLoaded: false,
            tokens: [],
            customerId: null,
            notificationTypes: [],
            sendingMailTo: null,
            name: null,
            creating: false
        }
    },

    mounted() {
        this.customerId = this.$route.params.id

        this.load()
    },

    methods: {
        copyToClipboard(index) {
            let copyToken = document.getElementById(`token-${index}`)
            let range = document.createRange()

            range.selectNode(copyToken)
            window.getSelection().addRange(range)

            if (!document.execCommand('copy')) {
                return
            }

            window.getSelection().removeAllRanges()
        },

        load() {
            this.hasLoaded = false
            this.tokens = []

            TokenService.findAll(this.customerId, {}, response => {
                const data = response.data

                this.tokens = data
                this.hasLoaded = true
            });
        },

        create() {
            this.creating = true

            TokenService.create(this.customerId, this.name, response => {
                this.creating = false
                this.name = null
                this.load()
            }, errorResponse => {
                this.$swal({
                    text: 'Noget gik galt - kontakt udvikling',
                    type: 'error',
                    confirmButtonText: 'Okay'
                })
            })
        },

        remove(index) {
            var token = this.tokens[index]
            var deletedTokenId = token.id

            this.$swal({
                text: 'Vil du slette API nøglen?',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Ja',
                cancelButtonText: 'Nej'
            }).then(() => {
                this.$swal({
                    text: 'Sådan',
                    type: 'success',
                    timer: 1500
                })

                TokenService.remove(this.customerId, deletedTokenId, () => {
                    this.load()
                })
            })
        }
    },
    components: {
        Loader,
        Card
    }
}
</script>

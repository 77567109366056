<template>
	<div class="integration" :class="[{styled: styled}, styling, {unhealthy: unhealthy}]" :id="elementId">
		<div :class="{'border': border}">
            <span v-if="styling === 'landscape'">
                <span class="logos" v-if="logos.length > 0">
                    <img v-for="(_logo, index) in logos" :src="_logo" :key="index">
                </span>
                <img :src="logo" v-else>
            </span>

			<div class="text-container">
				<h5>
                    <span v-if="styling === 'portrait'">
                        <span class="logos" v-if="logos.length > 0">
                          <img v-for="(_logo, index) in logos" :src="_logo" :key="index">
                        </span>
                        <img :src="logo" v-else>
                    </span>

					{{ headline }}
				</h5>

				<span class="text-muted" v-if="description" v-text="description"></span>

				<div class="clearfix"></div>

				<div v-if="noAccountsFound" class="alert alert-danger mb-0 m-5"
					 v-html="$t('onboarding.noAccountsFound')"></div>

				<div class="controls">
					<template v-if="service.length !== 0">
						<div class="connect" v-if="!isConnected && !isDisabled">
							<div v-if="!accounts.length">
								<template v-if="!isLoading">
									<div>
										<button v-if="!isGhostIdentity" class="btn btn-primary btn-md btn-fill"
												:style="organizationBackground"
												:class="{'btn-lg': (styling == 'portrait')}" @click="connect">
											{{ $t('onboarding.connect') }}
										</button>

										<strong v-else
												class="text-danger">{{ $t('onboarding.isGhostIdentity') }}</strong>
									</div>
								</template>

								<template v-else>
									<div class="loader"></div>

									<template v-if="isSlow">
										<br/>

										<small>
											<span class="fa fa-circle-o-notch fa-spin fa-fw"></span>
											{{ $t('onboarding.stalling') }}
										</small>
									</template>

								</template>
							</div>

							<div v-else>
								<multiselect
									class="choose-account"
									:placeholder="$t('multiSelect.selectYourAccount')"
									v-model="account"
									:options="formattedAccounts"
									track-by="label"
									label="label"
									:close-on-select="true"
									:show-labels="false"
									:selectLabel="$t('multiSelect.selectLabel')"
									:custom-label="customLabel">

									<template slot="option" slot-scope="props">
										<div class="option-wrap" v-tooltip="$t('backend.OAuth2.merchantLabel')">
											<span class="option-label" v-text="props.option.label"/>
											<label v-if="props.option.status" class="merchant-label">
												<fa icon="thumbs-up" class="voice-of-merchant-icon"/>
												<span v-text="'Lokation i god stand'"/>
											</label>
										</div>
									</template>

									<p slot="noResult">{{ $t('multiSelect.noResultsFound') }}</p>
								</multiselect>
							</div>
						</div>

						<template v-else>
							<div v-if="!unhealthy" class="connected text-muted"
								 :class="{'connected-lg': (styling == 'portrait')}">
								<span class="fa fa-check fa-fw"/>
								{{ $t('onboarding.connected') }}
							</div>

							<template v-else>
								<div :class="{ 'levelHeight': isIntegrationsPage }">
									<button class="btn btn-danger unhealthy btn-md btn-fill"
											:class="{'btn-lg': (styling == 'portrait')}" @click="reconnect">
										<span class="fa fa-warning fa-fw"/>&nbsp;
										{{ $t('onboarding.connectionLost') }}
									</button>
								</div>
							</template>

							<div class="remove-container">
								<a @click="remove()" class="text-muted" v-if="!isRemoving">{{
										$t('onboarding.remove',
											{source: headline})
									}}</a>
								<i class="fa fa-circle-o-notch fa-spin" v-else></i>
							</div>

							<template
								v-if="isIntegrationsPage && integrationConnectedAccount && (!integration || !integration.healthy)">
								<small class="text-muted">{{ $t('onboarding.connectedTo') }} </small>

								<span class="g-dynamic-value"
									  style="font-size: 10px;">{{ integrationConnectedAccount }}</span>
							</template>
						</template>
					</template>
					<slot/>

				</div>

			</div>

			<div class="clearfix"></div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
@import '~@/assets/scss/_custom.scss';
@import '~@/assets/scss/_vars.scss';

.option-wrap {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.unhealthy {
	white-space: normal;
}

.levelHeight {
	margin-bottom: 20px;
}

.integration {
	position: relative;
	color: initial;

	&.styled {
		> div {
			border-radius: 4px;
			background-color: #fff;
			padding: 28px 30px;

			&.border {
				border: 1px solid #dedede;
			}
		}
	}

	.loader {
		overflow: hidden;
		position: relative;
		width: 100%;
		height: 4px;
		background-color: #ececec;
		border-radius: 8px;
		margin-top: 10px;

		&:after {
			content: " ";
			position: absolute;
			width: 98%;
			left: 0;
			top: 0;
			height: 100%;
			background-color: #ffca54;
			animation-name: loader-integration;
			animation-duration: 10s;
		}
	}

	.logos {
		width: 30px;
	}

	img {
		width: 30px;
	}

	h5 {
		font-size: 18px;
		padding: 0;
		margin: 0;
		line-height: 1.2;
	}

	.controls {
		.connected {
			padding: 8px 16px;
			border-radius: 4px;
			border: 2px solid #eaeaea;
			color: #d5d5d5;
		}

		.unhealthy {
			cursor: pointer;
			color: #fff;
			font-weight: 500;
			background: #e45d54;
			border: 0;
			margin-bottom: 0 !important;

			&:hover {
				color: #fff !important;
				border: 0 !important;
			}
		}
	}

	&.landscape {

		.logos {
			margin-right: 15px;
			margin-top: 3px;
			float: left;

			img {
				margin-bottom: 15px;
				float: none;
				margin-top: 0;
			}
		}

		img {
			margin-right: 15px;
			margin-top: 3px;
			float: left;
		}

		h5 {
			margin-bottom: 10px;
		}

		.text-container {
			width: calc(100% - 50px);
			float: left;
		}

		.connected {
			margin-top: 15px;
			float: left;
		}

		.remove-container {
			float: left;
			margin-top: 22px;
			margin-left: 15px;
		}

		.btn {
			display: block;
			float: left;
		}

		.help-link {
			display: block;
			float: left;
			margin: 22px 0 0 15px;
		}

		.choose-account {
			margin-top: 15px;
		}
	}

	&.portrait {
		text-align: center;
		position: relative;
		height: 100%;

		> div {
			padding: 20px 15px 135px;
		}

		img {
			margin-top: -10px;
			margin-right: 5px;
		}

		h5 {
			margin-top: 15px;
			margin-bottom: 20px;
			font-weight: bold;
		}

		.controls {
			position: absolute;
			margin-top: 15px;
			width: calc(100% - 30px);
			bottom: 15px;

			.btn {
				margin-left: auto;
				margin-right: auto;
				margin-bottom: 22px;
			}

			.connect {
				min-height: 85px;
			}

			.connected {
				display: inline-block;
				margin-top: 15px;

				&.connected-lg {
					font-size: 15px;
					padding: 0 18px;
					line-height: 43px;
				}
			}
		}

		.remove-container {
			margin-top: 18px;
		}
	}

	.btn {
		margin-top: 15px;
	}

	@keyframes loader-integration {
		0% {
			width: 0%;
		}
		10% {
			width: 70%;
		}
		40% {
			width: 80%;
		}
		50% {
			width: 90%;
		}
		100% {
			width: 98%;
		}
	}
}
</style>

<script>
const OAuth2Service = require('@/services/oauth2/OAuth2Service')
const IntegrationService = require('@/services/integrations/IntegrationService')
import MixpanelService from '@/services/mixpanel/MixpanelService'
import Badge from '@/app/layout/components/Badge'
import {getTranslatedErrorMessage} from "@/services/response/ResponseService";
import OAuth2IsAccessService from '@/services/oauth2/OAuth2IsAccessService'

import {mapActions, mapGetters} from 'vuex'
import hasIntegration from '@/mixins/integrations/hasIntegration'

var VueScrollTo = require('vue-scrollto')

export default {
	mixins: [hasIntegration],

	data() {
		return {
			isLoading: false,
			isSlow: false,
			isConnected: false,
			isRemoving: false,
			accounts: [],
			account: null,
			highlight: false,
			timeout: null,
			namespace: null,
			oAuth2Service: null,
			noAccountsFound: false
		}
	},

	props: {
		serviceNamespace: {
			type: String
		},
		isAccess: {
			type: Boolean,
			default: false
		},
		logo: {
			type: String
		},

		logos: {
			type: Array,
			default: () => []
		},

		headline: {
			type: String
		},

		description: {
			type: String,
			required: false
		},

		service: {
			type: String,
			default: ''
		},

		name: {
			type: String
		},

		styled: {
			type: Boolean,
			default: true
		},

		isDisabled: {
			type: Boolean,
			default: false
		},

		styling: {
			type: String,
			default: 'landscape'
		},

		border: {
			type: Boolean,
			default: false
		},

		help: {
			type: String,
			default: 'page-change'
		},

		longHelpText: {
			type: Boolean,
			default: true
		},
	},

	watch: {
		account() {
			this.selectAccount()
		},

		isLoading() {
			if (this.timeout) {
				clearTimeout(this.timeout)
			}

			if (!this.isLoading) {
				return
			}

			this.isSlow = false

			this.timeout = setTimeout(() => {
				this.isSlow = true
			}, 8500)
		},
	},

	mounted() {
		this.validateCode()
		this.isConnected = this.hasIntegration(this.name)
	},

	computed: {
		...mapGetters('identity', {
			identity: 'getIdentity'
		}),
		...mapGetters('customer', {
			customer: 'getCustomer'
		}),
		...mapGetters('ghost', ['isGhostIdentity']),

		organizationBackground() {
			return {background: $org('colors.standard.integrations.connect')}
		},

		organizationColor() {
			return {color: $org('colors.standard.integrations.connect')}
		},

		integrations() {
			if (!this.customer) {
				return []
			}

			return this.customer.integrations
		},

		fullPath() {
			return window.location.origin + this.$router.currentRoute.path
		},

		code() {
			const currentRoute = this.$router.currentRoute
			const query = currentRoute.query

			return query.code
		},

		state() {
			const currentRoute = this.$router.currentRoute
			const query = currentRoute.query

			return query.state
		},

		formattedAccounts() {
			if (! this.isAccess) {
				return this.accounts.map(function (account) {
					return {
						label: account.name,
						value: account.id
					}
				})
			}
			if (this.serviceNamespace === 'google-business-profile') {
				return this.accounts.map(function (account) {
					return {
						label: account.name,
						status: account.metadata_json.hasVoiceOfMerchant,
						value: account.title
					}
				})
			} else {
				return this.accounts.map(function (account) {
					return {
						label: account.descriptive_name,
						status: account.status,
						value: account.google_ads_id
					}
				})
			}
		},

		elementId() {
			return 'id-' + this.service
		},

		integration() {
			return this.getIntegration(this.name)
		},

		integrationConnectedAccount() {
			const integration = this.integration

			if (!integration || !integration.connectedAccount) {
				return null
			}

			return integration.connectedAccount
		},

		isIntegrationsPage() {
			return this.$route.name === 'frontend.settings.integrations'
		},

		unhealthy() {
			const integration = this.integration

			return integration && !integration.healthy
		},
		getOAuthService() {
			if (!this.isAccess) {
				return OAuth2Service
			}
			return new OAuth2IsAccessService(this.serviceNamespace)
		}
	},

	methods: {
		...mapActions('identity', {
			reloadIdentity: 'loadIdentity'
		}),

		customLabel({label, status}) {
			return `${label} - ${status}`
		},

		scrollToElement() {
			VueScrollTo.scrollTo(this.$el, 400, {offset: -60})
		},

		handleError(errorCode, errorValue) {
			this.isLoading = false
			this.$swal({
				type: 'error',
				text: getTranslatedErrorMessage(errorCode, errorValue)
			})
		},

		validateCode() {

			//if state and service are different return (only for old api)
			if (this.state !== this.service && !this.isAccess) {
				return
			}

			//if isAccess api and state is not from access-google-ppc return
			if (this.isAccess && (this.state && !this.state.includes(this.serviceNamespace))) {
				return
			}

			//if either code isConnected or service is set return
			if (!this.code || this.isConnected || !this.service) {
				return
			}

			this.isLoading = true

			this.scrollToElement()

			//Make callback with data
			this.getOAuthService.callback(this.service, this.fullPath, this.code, this.state, async (response) => {
				const data = response.data

				if (data?.success === false) {
					this.handleError(data.errorCode, null);
				} else if (this.serviceNamespace !== 'google-business-profile') {
					this.loadAccounts(data)
				} else {
					this.loadLocations()
				}

			}, (error) => {
				this.handleError(null, {source: this.headline + error});
			})
		},

		loadLocations() {
			const options = {endUrl: 'locations'}
			this.getOAuthService.accounts(this.service, options, (response) => {
				this.noAccountsFound = false
				this.isLoading = false
				this.accounts = response.data

				if (response.data?.success === false) {
					this.handleError(response.data.errorCode, null);
				} else if (!this.accounts.length) {
					this.noAccountsFound = true
				}

			}, () => {
				this.handleError(null, {source: this.headline});
			})
		},

		loadAccounts(data) {
			let options = {endUrl: 'accounts'}

			if (data.id) {
				options = {
					id: data.id,
				}
			}

			this.getOAuthService.accounts(this.service, options, (response) => {
				this.noAccountsFound = false
				this.isLoading = false
				this.accounts = response.data

				if (response.data?.success === false) {
					this.handleError(response.data.errorCode, null);
				}
				if (!this.accounts.length) {
					this.noAccountsFound = true
				}

			}, (error) => {
				this.handleError(null, {source: this.headline});
			})
		},

		connect() {
			this.isLoading = true

			this.getOAuthService.url(this.service, this.fullPath, (response) => {
				const data = response.data

				window.location.href = data.url
			}, () => {
				this.isLoading = false
			})

		},

		selectAccount() {
			this.isLoading = true

			if (!this.account) {
				return
			}

			this.getOAuthService.integrate(this.service, this.account.label, this.account.value, async () => {
				await this.reloadIdentity()
				this.$highlighter.removeElement(this.$el)

				this.isConnected = true
				this.isLoading = false

				this.$emit('integrationConnected')

				MixpanelService.track('Integration - Create', {'Integration type': this.name})
				this.additionalInformation();
			}, () => {
				this.handleError(null, {source: this.headline});
			})
		},

		additionalInformation() {
			let text = '';
			let integrationName = '';

			switch (this.name) {
				case 'Google analytics':
					text = $t('onboarding.additionalInformation.googleAnalytics');
					integrationName = 'Google Search Console';
					break;
				default:
					return;
			}

			if (this.hasIntegration(integrationName)) {
				return;
			}

			this.$swal({
				text: text,
				type: 'info',
			})
		},

		removeAccessIntegration(integrationId) {
			this.getOAuthService.remove(integrationId, async () => {
				this.accounts = []
				this.account = null

				await this.reloadIdentity()

				this.isConnected = this.hasIntegration(this.name)
				this.isRemoving = false

				this.isLoading = false
			}, () => {
				this.isRemoving = false
			})
		},

		remove() {
			this.$swal({
				title: $t('onboarding.deleteAlert.areYourSure'),
				text: $t('onboarding.deleteAlert.description', {source: this.headline}),
				type: 'warning',
				showCancelButton: true,
				confirmButtonText: $t('onboarding.deleteAlert.confirmButton'),
				cancelButtonText: $t('onboarding.deleteAlert.cancelButton')
			}).then(() => {
				this.isRemoving = true

				const integrations = this.integrations.filter((integration) => {
					const service = integration.service

					return (service && service.name === this.name)
				})

				if (!integrations.length) {
					return
				}

				const integration = integrations[0]

				//new api
				if (this.isAccess) {
					this.removeAccessIntegration(integration.id)
					return
				}

				//old api
				IntegrationService.remove('integrations', integration.id, async () => {
					this.accounts = []
					this.account = null

					await this.reloadIdentity()
					this.isConnected = this.hasIntegration(this.name)
					this.isRemoving = false

					this.isLoading = false
				}, () => {
					this.isRemoving = false
				})
			})
		},

		reconnect() {
			this.isRemoving = true

			const integrations = this.integrations.filter((integration) => {
				const service = integration.service

				return (service && service.name === this.name)
			})

			if (!integrations.length) {
				return
			}

			const integration = integrations[0]

			const namespace = this.isAccess ? `access-google-ppc/integrate` : 'integrations'

			IntegrationService.remove(namespace, integration.id, async () => {
				await this.reloadIdentity()
				this.isConnected = this.hasIntegration(this.name)
				this.isRemoving = false

				this.isLoading = false

				this.connect()
			})
		}
	},
	components: {
		Badge
	}
}
</script>

const BaseService = require('@/services/BaseService')
const namespace = 'oauth2/accounts'

export default {
    find(id, options, cb, errorCb) {
        BaseService.get(`${namespace}/${id}`, options, cb, errorCb)
    },

    findAll(options, cb, errorCb) {
        BaseService.get(namespace, options, cb, errorCb)
    },

    delete(id, options, cb, errorCb) {
        BaseService.remove(`${namespace}/${id}`, options, cb, errorCb)
    },

    update(id, options, cb, errorCb) {
        BaseService.patch(`${namespace}/${id}`, options, cb, errorCb)
    },

    create(data, cb, errorCb) {
        BaseService.post(namespace, data, cb, errorCb)
    },
}

<template>
	<section
		class="datepicker-parent">
		<section class="datepicker-col">
			<article
				:class="{ 'is-active': toggleFilter }"
				class="filter-group-container"
			>
				<dynamic-topbar-filter
					v-for="(filterGroup, i) in filterGroups"
					:filterGroup="filterGroup"
					:key="i"
				/>
			</article>
			<article class="filter-group-container-link">
				<a
					:class="{ 'is-active': toggleFilter }"
					@click.prevent="toggleFilter = !toggleFilter"
				>
					<fa icon="filter" />
				</a>
			</article>
			<article class="select-international-container">
				<switch-international />
			</article>
			<article class="datepicker-container">
				<div class="select">
					<!--<span class="datepicker-explainer title-hide">{{$t('datepicker.pickPeriod')}}:</span>-->
					<fa icon="calendar-alt" />
					<strong>{{ start.format("D MMM YYYY") }}</strong>
					{{ $t("datepicker.andTo") }}
					<strong>{{ end.format("D MMM YYYY") }}</strong>
					<span class="caret" />
				</div>
			</article>
		</section>
	</section>
</template>

<style lang="scss">
@import "~@/assets/scss/variables/all";

.toggle-comparison-container {
	border-top: 1px solid rgb(0, 0, 0, 0.1);
	padding: 10px 12px;

	display: none;
	align-items: center;
	.left-wrap {
		flex: 2;
		p {
			font-size: 13px;
			color: $black;
			margin: 0;
		}
	}
	.right-wrap {
		flex: 1;
		padding: 2px;
		.outer-rectangle {
			background: $gray-500;
			width: 35px;
			height: 100%;
			border-radius: 15px;
			padding: 2px;
			cursor: pointer;

			&:hover {
				background: darken($gray-500, 8%);
			}
			.inner-rectangle {
				position: relative;
				background: $white;
				width: 15px;
				height: 15px;
				border-radius: 100%;
				box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
				rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;

				transition: 200ms ease-in-out;

				&::before {
					content: "";
					position: absolute;
					top: 0;
					left: 0;
					background: $gray-500;
					width: 4px;
					height: 4px;
					border-radius: 50%;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
					rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
				}
			}

			.is-toggled {
				margin-left: 15px;
				&::before {
					background: $digital-blue;
				}
				&:hover {
					background: $white;
				}
			}
		}
		.is-toggled {
			background: $digital-blue;

			&:hover {
				background: darken($digital-blue, 8%);
			}
		}
	}
}

.datepicker-parent {
	position: sticky;
	top: 0;
	z-index: 999;

	.datepicker-col {
		.datepicker-container {
			.select {
				user-select: none;
				svg {
					margin-right: 5px;
				}
			}
		}
	}
}
</style>

<script>
import "bootstrap-daterangepicker";
import { mapActions, mapGetters } from "vuex";
import DynamicTopbarFilter from "@/app/shared/global/filters/DynamicTopbarFilter";
import SwitchInternational from "@/app/layout/components/SwitchInternational";
import moment from "moment";
import hasIntegration from "@/mixins/integrations/hasIntegration";

export default {
	mixins: [hasIntegration],
	data() {
		return {
			toggleFilter: false,
			filterGroups: [],
			filterBlueprint: {
				title: "",
				slug: "",
				color: "",
				metrics: [],
				multiSelect: false,
				loadingDateChanges: false,
				interactionEnabled: true
			}
		};
	},

	watch: {
		getShowComparisonToggleButton: function(isVisible) {
			const $el = document.querySelector(".toggle-comparison-container")

			if (! $el) {
				return
			}

			$el.style.display = isVisible ? 'flex' : 'none'
		}
	},

	computed: {
		...mapGetters("datepicker", {
			start: "getFrom",
			end: "getTo",
			getShowComparisonToggleButton: "getShowComparisonToggleButton",
			getShowComparison: "getShowComparison"
		})
	},

	mounted() {
		this.initializeDatepicker();
		if (this.hasHealthyIntegration("Facebook ads")) {
			this.createToggleButton();
		}

		eventHub.$on("topbar.filterGroups.push", this.pushFilterGroup);
		eventHub.$on("topbar.filterGroups.update", this.updateFilterGroup);
		eventHub.$on("topbar.filterGroups.remove", this.removeFilterGroup);
		eventHub.$on("router.beforeRouteChange", this.clearFilters);
	},

	destroyed() {
		eventHub.$off("topbar.filterGroups.push", this.pushFilterGroup);
		eventHub.$off("topbar.filterGroups.update", this.updateFilterGroup);
		eventHub.$off("topbar.filterGroups.remove", this.removeFilterGroup);
		eventHub.$off("router.beforeRouteChange", this.clearFilters);
	},

	beforeDestroy() {
		const dateRangePicker = jQuery(this.$el).data("daterangepicker");

		if (dateRangePicker) {
			dateRangePicker.remove();
		}
	},

	methods: {
		...mapActions("datepicker", {
			datepickerSetDates: "setDates"
		}),

		pushFilterGroup(filterGroup) {
			this.clearFilters();

			const matchFound =
				this.filterGroups.filter(
					existingFilterGroup => existingFilterGroup.slug === filterGroup.slug
				).length > 0;

			matchFound
				? this.updateFilterGroup(filterGroup)
				: this.filterGroups.push(
					this.mergeFilterGroup(this.filterBlueprint, filterGroup)
				);
		},

		updateFilterGroup(filterGroup) {
			let index = null;

			this.filterGroups.forEach((existingFilterGroup, i) => {
				if (existingFilterGroup.slug === filterGroup.slug) {
					index = i;
				}
			});

			if (index === null) {
				return;
			}

			const mergedFilterGroup = this.mergeFilterGroup(
				this.filterGroups[index],
				filterGroup
			);

			this.$set(this.filterGroups, index, mergedFilterGroup);

			const metricValues = {};

			mergedFilterGroup.metrics.forEach(metric => {
				metricValues[metric.slug] = metric.selected;
			});

			eventHub.$emit(
				"topbar.filterGroups." + filterGroup.slug + ".metricsChanged",
				{
					metrics: metricValues
				}
			);
		},

		removeFilterGroup(filterGroup) {
			let index = null;

			this.filterGroups.forEach((existingFilterGroup, i) => {
				if (existingFilterGroup.slug === filterGroup.slug) {
					index = i;
				}
			});

			if (index === null) {
				return;
			}

			this.$delete(this.filterGroups, index);
		},

		mergeFilterGroup(blueprint, filterGroup) {
			const filterGroupToAdd = JSON.parse(JSON.stringify(blueprint));

			for (let key in filterGroup) {
				filterGroupToAdd[key] = filterGroup[key];
			}

			return filterGroupToAdd;
		},

		clearFilters() {
			this.filterGroups = [];
		},

		createToggleButton() {
			if (document.querySelector(".toggle-comparison-container")) {
				return;
			}

			const ranges = document.querySelector(".ranges");
			const container = document.createElement("div");
			const leftWrap = document.createElement("div");
			const rightWrap = document.createElement("div");
			const leftText = document.createElement("p");
			const outerRect = document.createElement("div");
			const innerRect = document.createElement("div");

			container.className = "toggle-comparison-container";
			leftWrap.className = "left-wrap";
			rightWrap.className = "right-wrap";
			outerRect.className = "outer-rectangle";
			innerRect.className = "inner-rectangle";
			leftText.innerText = $t("datepicker.show_comparison");

			outerRect.addEventListener("click", event => {
				if (this.getShowComparison) {
					outerRect.classList.remove("is-toggled");
					innerRect.classList.remove("is-toggled");
					this.$store.commit("datepicker/setShowComparison", false);
				} else {
					outerRect.classList.add("is-toggled");
					innerRect.classList.add("is-toggled");
					this.$store.commit("datepicker/setShowComparison", true);
				}
			});

			leftWrap.appendChild(leftText);
			outerRect.appendChild(innerRect);
			rightWrap.appendChild(outerRect);
			container.appendChild(leftWrap);
			container.appendChild(rightWrap);
			ranges.appendChild(container);
		},

		initializeDatepicker() {
			let ranges = {};

			//Defining currentdate by using moment(); - is equal to "Date();"
			const today = moment();

			ranges[$t("datepicker.today")] = [moment(), moment()];

			ranges[$t("datepicker.yesterday")] = [
				moment().subtract(1, 'days'),
				moment().subtract(1, 'days')
			];

			//Latest week - using variable to get todays date
			//The second argument (today), is the "from" date in the datepicker element

			ranges[$t("datepicker.last7Days")] = [
				moment().subtract(7, 'days'),
				moment().subtract(1, 'days')
			];

			ranges[$t("datepicker.last14Days")] = [
				moment().subtract(14, 'days'),
				moment().subtract(1, 'days')
			];

			ranges[$t("datepicker.last30Days")] = [
				moment().subtract(30, 'days'),
				moment().subtract(1, 'days')
			];

			ranges[$t("datepicker.lastMonth")] = [
				moment().subtract(1, 'month').startOf('month'),
				moment().subtract(1, 'month').endOf('month')
			];

			ranges[$t("datepicker.last6Months")] = [
				moment().subtract(6, 'month').startOf('month'),
				moment().subtract(1, 'month').endOf('month')
			];

			ranges[$t("datepicker.last12Months")] = [
				moment().subtract(12, 'month').startOf('month'),
				moment().subtract(1, 'month').endOf('month')
			];

			const weekDays = moment()._locale._weekdays.map(function(day) {
				return day.substr(0, 3);
			});

			const options = {
				ranges: ranges,
				locale: {
					applyLabel: $t("datepicker.pickDate"),
					cancelLabel: $t("datepicker.cancel"),
					fromLabel: $t("datepicker.from"),
					toLabel: $t("datepicker.to"),
					customRangeLabel: $t("datepicker.pickPeriod"),
					daysOfWeek: weekDays,
					monthNames: moment()._locale._months.slice(),
					firstDay: moment.localeData().firstDayOfWeek(),
					format: $t("i18n.dateFormat")
				},
				alwaysShowCalendars: true,
				autoApply: true,
				opens: "left",
				startDate: this.start,
				endDate: this.end,
				linkedCalendars: false,
				weekStart: 3,
				showCustomRangeLabel: false,
				maxDate: moment().format($t("i18n.dateFormat")),
				parentEl: jQuery(this.$el)
			};

			jQuery(this.$el.querySelector(".select")).daterangepicker(
				options,
				(from, to) => {
					this.datepickerSetDates({
						from: from,
						to: to
					});
				}
			);
		}
	},

	components: {
		DynamicTopbarFilter,
		SwitchInternational
	}
};
</script>

export default {
	da_DK: {
		icon: '🇩🇰',
		title: 'Dansk'
	},
	en_US: {
		icon: '🇺🇸',
		title: 'English (US)'
	},
	sv_SE: {
		icon: '🇸🇪',
		title: 'Svenska'
	},
	fi_FI: {
		icon: '🇫🇮',
		title: 'Suomi'
	},
	es_ES: {
		icon: '🇪🇸',
		title: 'Español'
	},
	de_DE: {
		icon: '🇩🇪',
		title: 'Deutsch'
	}
}

<template>
    <div class="label" :class="labelClass">
        <div class="icon-wrapper" v-if="icon">
            <span class="icon" :class="icon"></span>
        </div>
        {{text}}
    </div>
</template>

<style lang="scss" scoped>
    .label {
        font-size: 10pt;
        padding: 7px 10px;

        .icon-wrapper {
            display: inline-block;

            .icon {
                float: left;
                font-size: 150%;
                line-height: 10px;

                &.fa {
                    font-size: 140%;
                    line-height: 8px;
                }
            }
        }
    }
</style>

<script>
export default {
    props: ['icon', 'text', 'type'],
    computed: {
        labelClass() {
            return 'label-' + this.type;
        }
    }
}
</script>
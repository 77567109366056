import auth from './auth/auth.store'
import users from './users/users.store'
import datepicker from './datepicker/datepicker.store'
import trafficSources from './traffic-sources/traffic-sources.store'
import shared from './shared/shared.store'

import facebookAds from './meta/ads.store'
import instagram from './meta/instagram.store'
import contacts from './contacts/contacts.store'
import website from './website/website.store'
import leadenhancer from './leadenhancer/lead-enhancer.store'

export default {
  ...shared,
  modules: {
    auth,
    ...users,
    datepicker,
    trafficSources,
    contacts,
    facebookAds,
    instagram,
    website,
    leadenhancer
  },
}

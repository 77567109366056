<template>
    <card>
        <h1>{{ $t('navigation.statistics.dataStudio') }}</h1>

        <input type="text"
               :placeholder="$t('customers.dataStudio.inputPlaceholder')"
               v-model="dataStudioInput"
               :class="{'initial-value': customer.dataStudioLink === dataStudioInput}"
        />

        <button class="btn btn-primary btn-sm"
                @click="putCustomerDataStudioLink()"
        >
            <i v-show="hasSaved" class="fa fa-check"></i>
            {{ $t('customers.dataStudio.save') }}
        </button>

        <button class="btn btn-danger btn-sm"
                @click="deleteCustomerDataStudioLink()"
        >
            {{ $t('customers.dataStudio.delete') }}
        </button>
    </card>
</template>

<script>
  import CustomerService from '@/services/_app/customers/CustomerService'

  export default {
    name: 'DataStudioService',
    props: {
      service: {
        required: true,
        type: Object
      },
      customer: {
        required: true,
        type: Object
      }
    },
    data: () => ({
      hasSaved: false,
      dataStudioInput: ''
    }),

    mounted () {
      this.load()
    },

    methods: {
      async load () {
        this.dataStudioInput = this.customer.dataStudioLink ? this.customer.dataStudioLink : ''
      },

      async putCustomerDataStudioLink () {
        await CustomerService.put(this.customer.id, { dataStudioLink: this.dataStudioInput })
        this.hasSaved = true
        setTimeout(() => {this.hasSaved = false}, 3000)
      },

      async deleteCustomerDataStudioLink () {
        await CustomerService.put(this.customer.id, { dataStudioLink: '' })
        this.dataStudioInput = ''
      }
    }
  }
</script>

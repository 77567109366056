export default [
  {
    path: "/linkedin/ads",
    name: "frontend.reporting.linkedin.ads",
    component: () => import(/* webpackChunkName: "LinkedIn" */ './pages/LinkedInAds'),
    meta: {
      headline: () => $t("navigation.statistics.linkedInAds"),
      description: () => $t("topBar.descriptions.reporting.linkedInAds"),
      tracking: "LinkedIn - Ads - Page view - Main page",
      datepicker: true
    }
  },
  {
    path: "/linkedin/social",
    name: "frontend.reporting.linkedin.social",
    component: () => import(/* webpackChunkName: "LinkedIn" */ './pages/LinkedInSocial'),
    meta: {
      headline: () => $t("navigation.statistics.linkedInSocial"),
      description: () =>
        $t("topBar.descriptions.reporting.linkedInSocial"),
      tracking: "LinkedIn - Social - Page view - Main page",
      datepicker: true
    }
  }
];

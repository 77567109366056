
export default [
  {
    path: "competitors",
    name: "frontend.reporting.competitors",
    component: () => import(/* webpackChunkName: "Competitors" */ './Competitors'),
    meta: {
      headline: () => $t("navigation.statistics.competitors"),
      description: () => $t("topBar.descriptions.reporting.competitors"),
      tracking: "Competitors - Page view - Main page"
    }
  }
];

const BaseService = require('@/services/BaseService')

const namespace = 'inquiries/calls'

const find = (id, options, cb, errorCb) => {
  BaseService.get(namespace + '/' + id, options, cb, errorCb)
}

const findAll = (options, cb, errorCb) => {
  BaseService.get(namespace, options, cb, errorCb)
}

const audio = (id, options, cb, errorCb) => {
  BaseService.get(namespace + '/' + id + '/audio', options, cb, errorCb)
}

const accounts = (cb) => {
  BaseService.get(namespace + '/accounts', {}, cb)
}

const accountCustomers = (accountId, options, cb, errorCb) => {
    BaseService.get(namespace + '/accounts/' + accountId + '/customers', options, cb, errorCb)
}

const sum = (field, options, cb, errorCb) => {
    BaseService.get(namespace + '/sum/' + field, options, cb, errorCb)
}

const count = (options, cb, errorCb) => {
    BaseService.get(namespace + '/count', options, cb, errorCb)
}

const countBySources = (options, cb, errorCb) => {
    BaseService.get(namespace + '/count/sources', options, cb, errorCb)
}

export {
  find,
  findAll,
  audio,
  accounts,
  accountCustomers,
  sum,
  count,
  countBySources,
}

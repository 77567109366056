export default [
  {
    path: '/contacts',
    name: 'frontend.contacts',
    component: () => import(/* webpackChunkName: "Contacts" */ './Contacts'),
    meta: {
      headline: () => $t('navigation.contacts'),
      description: () => $t('topBar.descriptions.contacts'),
      tracking: 'Contacts - Page view - Main page'
    }
  },
  {
    path: '/contacts/view/:id',
    name: 'frontend.contacts.view',
    component: () => import(/* webpackChunkName: "Contacts" */ './pages/View'),
    meta: {
      headline: () => $t('navigation.contacts'),
      description: () => $t('topBar.descriptions.contacts'),
      tracking: 'Contacts - Page view - Profile'
    }
  },
]

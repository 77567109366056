const BaseService = require('@/services/BaseService')

const namespace = 'klaviyo'

const campaigns = (options, cb, errorCb) => {
    BaseService.get(namespace + '/campaigns', options, cb, errorCb)
}

const flows = (options, cb, errorCb) => {
    BaseService.get(namespace + '/flows', options, cb, errorCb)
}

const connect = (options, cb, errorCb) => {
    BaseService.post(`${namespace}/connect`, options, cb, errorCb)
}

export default {
    campaigns,
	flows,
	connect
}

const BaseService = require('@/services/BaseService')

const namespace = 'customers'

const find = (id, options, cb, errorCb) => {
    BaseService.get(namespace + '/' + id, options, cb, errorCb)
}

const findAll = (options, cb, errorCb) => {
    BaseService.get(namespace, options, cb, errorCb)
}

const put = (id, data, cb, errorCb) => {
	BaseService.put(namespace + '/' + id, data, cb, errorCb)
}

const requestDelete = (id, cb, errorCb) => {
    BaseService.get(namespace + '/request-delete/' + id, {}, cb, errorCb)
}

const deleteCustomer = (id, cb, errorCb) => {
    BaseService.get(namespace + '/delete/' + id, {}, cb, errorCb)
}

const checkDomain = (data, cb, errorCb) => {
    BaseService.post(namespace + '/check-domain-redirects/', data, cb, errorCb)
}

const integrations = (id, cb, errorCb) => {
    return BaseService.get(namespace + '/customer-integrations/' + id, {}, cb, errorCb)
}

export {
    find,
    findAll,
    put,
    requestDelete,
    deleteCustomer,
    checkDomain,
    integrations
}

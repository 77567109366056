<template>
    <div class="callAndForm">
        <integration logo="/images/onboarding/calls.png" :border="true"
                     :headline="$t('onboarding.callAndForm.calls.headline')"
                     :description="$t('onboarding.callAndForm.calls.description')">
            <ribbon text="Business" top="-5px" right="-5px" />

            <template v-if="!hasHealthyIntegration('Freespee')">
                <router-link :to="{name: 'frontend.settings.integrations.calltracking.setup.chooseConfiguration'}"
                             class="btn btn-fill btn-primary" :style="organizationBackgroundColor">{{ $t('integrations.setUp') }}</router-link>
            </template>
        </integration>

        <br>

        <integration logo="/images/onboarding/forms.png" :border="true"
                     :headline="$t('onboarding.callAndForm.forms.headline')"
                     :description="$t('onboarding.callAndForm.forms.description')">
            <template v-if="!(hasHealthyIntegration('Formstack') || hasHealthyIntegration('WordPress forms'))">
                <router-link :to="{name: 'frontend.settings.integrations.formtracking.setup.chooseConfiguration'}"
                             class="btn btn-fill btn-primary" :style="organizationBackgroundColor">{{ $t('integrations.setUp') }}</router-link>
            </template>
        </integration>
    </div>
</template>

<style lang="scss" scoped>
    .callAndForm {
        .btn {
            margin-top: 15px;
            display: block;
            float: left;
        }

        .help-link {
            display: block;
            float: left;
            margin: 23px 0 0 15px;
        }

    }
</style>

<script>
    import Integration from '@/app/onboarding/components/integrations/Integration'
    import hasIntegration from '@/mixins/integrations/hasIntegration'
    import Ribbon from '@/app/layout/components/Ribbon'

    export default {
        mixins: [hasIntegration],

        computed: {
            organizationColor() {
                return {color:$org('colors.standard.primary.hex')}
            },

            organizationBackgroundColor() {
                return {backgroundColor:$org('colors.standard.primary.hex')}
            }
        },

        components: {
            Integration,
            Ribbon,
        }
    }
</script>

<template>
	<div class="subscriptions">
		<card>
			<h1>Manage subscriptions and features</h1>
			<div class="description">Manage the different subscriptions and features</div>

			<div class="subscription-items">
				<div v-for="plan in plans" class="subscription">
					<div class="title">
						{{ plan.title }}
						<span v-if="!! plan.is_default"
							  class="badge">
							Default
						</span>
					</div>

					<button type="button"
							@click="onManage(plan)">
						<i class="fa fa-cog fa-fw" />
						<span>Manage</span>
					</button>
				</div>

				<div v-if="plans === null"
					 class="loader">
					<i class="fa fa-spinner fa-spin fa-fw" />
				</div>
			</div>
		</card>

		<form v-if="!! manage"
			  @submit.prevent="onUpdate">
			<modal ref="updateModal"
				   :title="manage.title">
				<div class="modal-body">
					<div class="form-group">
						<label for="title">Title</label>

						<input type="text"
							   v-model="manage.title"
							   class="form-control"
						/>
					</div>

					<div class="features">
						<div class="actions">
							<button type="button"
									@click="onAddFeature">
								<i class="fa fa-plus fa-fw" />
								<span>Add feature</span>
							</button>
						</div>

						<div v-for="(feature, index) in manage.features"
							 :key="`feature-${index}`"
							 class="feature">
							<div class="title">
								<select class="form-control"
										:value="feature.slug"
										@input="$event => onChangeFeature(index, $event.target.value)">
									<option v-for="mapping in Object.values(mappings)"
											:key="`mapping-${mapping.slug}`"
											:value="mapping.slug">{{ mapping.title }}</option>
								</select>
							</div>

							<div class="value">
								<template v-if="!! mappings[feature.slug]">
									<input v-if="mappings[feature.slug].type === 'string'"
										   type="text"
										   v-model="feature.value"
										   class="form-control"
									/>

									<input v-if="mappings[feature.slug].type === 'integer'"
										   type="tel"
										   v-model="feature.value"
										   class="form-control"
									/>
								</template>
							</div>

							<div class="remove">
								<button type="button"
										@click="onRemoveFeature(index)">
									<i class="fa fa-remove fa-fw" />
								</button>
							</div>
						</div>
					</div>
				</div>

				<div class="modal-footer">
					<button :disabled="updating"
							type="submit"
							class="btn btn-primary">
						<i v-if="updating"
						   class="fa fa-spinner fa-spin mr-2"
						/>
						Save changes
					</button>
				</div>
			</modal>
		</form>
	</div>
</template>

<script>
import Modal from '@/app/layout/components/Modal'
import * as PlanService from '@/services/billing/PlanService'

import SwitchToggle from '@/app/shared/components/SwitchToggle'

const mappings = {
	'ai.googleAds': {
		title: 'AI Google Ads',
		slug: 'ai.googleAds',
		type: 'boolean'
	},

	'ai.content': {
		title: 'AI Content',
		slug: 'ai.content',
		type: 'boolean'
	},

	'ai.content.extended': {
		title: 'AI Content extended',
		slug: 'ai.content.extended',
		type: 'boolean'
	},

	'ai.creatives': {
		title: 'AI Creatives Access',
		slug: 'ai.creatives',
		type: 'boolean'
	},

	'ai.creatives.items': {
		title: 'AI Creatives Count',
		slug: 'ai.creatives.items',
		type: 'integer',
		default: 15
	},

	'ai.content.items': {
		title: 'AI Content Count',
		slug: 'ai.content.items',
		type: 'integer',
		default: 15
	},

	'ai.content.article': {
		title: 'AI Content Articles',
		slug: 'ai.content.article',
		type: 'boolean'
	},

	'ai.content.product': {
		title: 'AI Content Product Page',
		slug: 'ai.content.product',
		type: 'boolean'
	},

	'ai.content.blog': {
		title: 'AI Content Blog',
		slug: 'ai.content.blog',
		type: 'boolean'
	},

	'ai.content.i18n.da': {
		title: 'AI Language DK',
		slug: 'ai.content.i18n.da',
		type: 'boolean'
	},

	'ai.content.i18n.en': {
		title: 'AI Language EN',
		slug: 'ai.content.i18n.en',
		type: 'boolean'
	},

	'ai.content.i18n.fi': {
		title: 'AI Language FI',
		slug: 'ai.content.i18n.fi',
		type: 'boolean'
	},

	'ai.content.i18n.sv': {
		title: 'AI Language SV',
		slug: 'ai.content.i18n.sv',
		type: 'boolean'
	},

	'ai.content.i18n.es': {
		title: 'AI Language ES',
		slug: 'ai.content.i18n.es',
		type: 'boolean'
	},

	'ai.content.i18n.de': {
		title: 'AI Language DE',
		slug: 'ai.content.i18n.de',
		type: 'boolean'
	},

	'ai.chatbot': {
		title: 'Chat Bot',
		slug: 'ai.chatbot',
		type: 'boolean'
	},

	'seo.keywords': {
		title: 'SEO Keywords',
		slug: 'seo.keywords',
		type: 'integer',
		default: 15
	},

	'competitors.items': {
		title: 'Competitors',
		slug: 'competitors.items',
		type: 'integer',
		default: 3
	},

	'reputation.trustpilot': {
		title: 'Reputation Trustpilot',
		slug: 'reputation.trustpilot',
		type: 'boolean'
	},

	'reputation.facebook': {
		title: 'Reputation Facebook',
		slug: 'reputation.facebook',
		type: 'boolean'
	},

	'reputation.google': {
		title: 'Reputation Google',
		slug: 'reputation.google',
		type: 'boolean'
	},
}

export default {
	data: () => ({
		plans: null,
		manage: null,
		updating: false,
		mappings
	}),

	mounted() {
		this.load()
	},

	methods: {
		load() {
			PlanService.findAll(
				{
					includes: ['features']
				},
				({ data }) => {
					this.plans = data.rows
				},
				() => {}
			)
		},

		onManage(plan) {
			this.manage = plan

			this.$nextTick(() => {
				jQuery(this.$refs.updateModal.$el).modal('show')
			})
		},

		organizationColor (toggled) {
			return toggled ? {
				backgroundColor: $org('colors.standard.button.outlined')
			} : null
		},

		onUpdate() {
			if (this.updating) {
				return
			}

			this.updating = true

			PlanService.update(
				this.manage.id,
				{
					...this.manage
				},
				() => {
					this.updating = false

					jQuery(this.$refs.updateModal.$el).modal('hide')

					setTimeout(() => {
						this.manage = null
					}, 500)

					this.load()
				},
				() => {
					this.updating = false
				}
			)
		},

		onAddFeature() {
			const copy = JSON.parse(JSON.stringify(this.manage))

			copy.features.push({
				slug: null,
				value: null
			})

			this.manage = copy
		},

		onRemoveFeature(index) {
			const copy = JSON.parse(JSON.stringify(this.manage))
			copy.features.splice(index, 1)

			this.manage = copy
		},

		onChangeFeature(index, slug) {
			if (! mappings[slug]) {
				return
			}

			const item = JSON.parse(JSON.stringify(mappings[slug]))
			const copy = JSON.parse(JSON.stringify(this.manage))

			copy.features[index] = {
				slug: slug,
				value: item.default
			}

			if (item.type === 'boolean') {
				copy.features[index].value = 1
			}

			this.manage = copy
		}
	},

	components: {
		Modal,
		SwitchToggle
	}
}
</script>

<style lang="scss" scoped>
.subscriptions {
	border: 1px solid #eee;
	width: 800px;
	max-width: calc(100vw - 50px);
	margin: 50px auto;

	h1 {
		color: #3d4852;
		font-size: 24px;
		font-weight: 800;
		margin: 0 0 4px 0;
		padding: 0;
	}

	div.description {
		color: #626c73;
		font-weight: 500;
		font-size: 14px;
	}

	div.subscription-items {
		margin: 0 -30px;
		margin-top: 20px;

		div.subscription {
			padding: 20px 30px;
			border-top: 1px solid #eee;

			display: flex;
			justify-content: space-between;
			align-items: center;

			div.title {
				display: flex;
				align-items: center;
				gap: 8px;

				color: #000;
				font-size: 18px;
				font-weight: 500;
			}

			button {
				display: flex;
				align-items: center;
				gap: 8px;

				color: #fff !important;
				font-weight: 500;
				letter-spacing: -.1px;
				border: 0 !important;
				border-radius: 6px;
				padding: 10px 20px;
				background-color: #000 !important;
			}
		}

		div.loader {
			padding: 20px 30px;
			border-top: 1px solid #eee;

			display: flex;
			justify-content: center;

			color: #000;
			font-size: 17px;
		}
	}

	.features {
		display: flex;
		flex-direction: column;

		> div.actions {
			display: flex;
			justify-content: flex-end;
			border-top: 2px solid #eee;
			border-bottom: 1px solid #eee;
			padding: 10px 0;

			button {
				display: flex;
				align-items: center;
				gap: 8px;

				color: #fff !important;
				font-weight: 500;
				letter-spacing: -.1px;
				border: 0 !important;
				border-radius: 6px;
				padding: 10px 20px;
				background-color: #000 !important;
			}
		}

		> div.feature {
			display: flex;
			align-items: center;
			padding: 20px 0;
			gap: 15px;
			border-top: 1px solid #eee;

			.title {
				margin-right: auto;
			}

			.remove {
				button {
					display: flex;
					align-items: center;
					gap: 8px;

					min-width: 30px;
					min-height: 30px;

					color: black !important;
					font-weight: 500;
					letter-spacing: -.1px;
					border: 0 !important;
					border-radius: 6px;
					background-color: #eee !important;
				}
			}
		}
	}
}
</style>

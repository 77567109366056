const BaseService = require('@/services/BaseService')

const namespace = 'economy'

const accounts = (options, cb, errorCb) => {
  BaseService.get(namespace + '/accounts', options, cb, errorCb)
}

export {
  accounts
}

export default [
  {
    path: "economy",
    name: "frontend.reporting.economy",
    component: () => import(/* webpackChunkName: "Economy" */ './Economy'),
    meta: {
      headline: () => $t("navigation.statistics.economy"),
      description: () => $t("topBar.descriptions.reporting.economy"),
      tracking: "Economy - Page view - Main page"
    }
  },
  {
    path: "economy/integration",
    name: "frontend.reporting.economy.integration",
    component: () => import(/* webpackChunkName: "Economy" */ './views/ActiveEconomyIntegration'),
    meta: {
      headline: () => $t("navigation.statistics.economy"),
      description: () => $t("topBar.descriptions.reporting.economy"),
      tracking: "Economy - Page view - Integration",
      datepicker: true
    }
  },
  {
    path: "economy/onboarding",
    name: "frontend.reporting.economy.onboarding",
    component: () => import(/* webpackChunkName: "Economy" */ './views/onboarding/Onboarding'),
    children: [
      {
        path: "matches",
        name: "frontend.reporting.economy.onboarding.matches",
        component: () => import(/* webpackChunkName: "Economy" */ './views/onboarding/Matches'),
        meta: {
          headline: () => $t("navigation.statistics.economy"),
          description: () =>
            $t("topBar.descriptions.reporting.economy"),
          tracking: "Economy - Page view - Matches"
        }
      },
      {
        path: "not-enough-data",
        name: "frontend.reporting.economy.onboarding.not-enough-data",
        component: () => import(/* webpackChunkName: "Economy" */ './views/onboarding/NotEnoughData'),
        meta: {
          headline: () => $t("navigation.statistics.economy"),
          description: () =>
            $t("topBar.descriptions.reporting.economy"),
          tracking: "Economy - Page view - Not enough data"
        }
      }
    ]
  },
  {
    path: "economy/callback/:service",
    name: "frontend.economy.callback",
    component: () => import(/* webpackChunkName: "Economy" */ './views/Callback'),
    meta: {
      headline: () => $t("navigation.statistics.economy"),
      description: () => $t("topBar.descriptions.reporting.economy"),
      tracking: "Economy - Page view - Callback"
    }
  }
];

export default [
  {
	path: '/co-pilot',
	component: () => import(/* webpackChunkName: "Frontend" */ '../layout/Frontend.vue'),
	meta: {
	  requiresClientIdentity: true
	},
	children: [
	  {
	    path: '/',
		name: 'copilot',
		component: () => import(/* webpackChunkName: "CoPilot" */ './pages/List'),
		meta: {
		  tracking: 'CoPilot - Page view - List',
	    }
	  },
	]
  },
  {
    path: '/co-pilot/:id',
    name: 'copilot.view',
    component: () => import(/* webpackChunkName: "CoPilot" */ './pages/CoPilot'),
    meta: {
      guest: true,
      tracking: 'CoPilot - Page view - View',
    }
  }
]

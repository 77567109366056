import StorageService from '@/services/_app/storage/StorageService'
import { DEV_MODE } from '@/config/env'

import { resolveLanguage } from "../../plugins/i18n";

const getDefaultState = () => {
  return {
    isReview: false,
    language: null,
    loading: false,
    isDevMode: DEV_MODE,
    refCount: 0
  }
}

const getters = {
  isMenuOpen: state => state.isMenuOpen,
  isReview: state => state.isReview,
  isDevMode: state => state.isDevMode,
  isLoading: state => state.loading,
  getLanguage: state => {
    if (state.language) {
      return state.language
    }

    return resolveLanguage()
  }
}

const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  setIsReview(state, value) {
    state.isReview = value
  },
  setLanguage(state, language) {
    state.language = language
  },
  setLoading(state, isLoading) {
    if (isLoading) {
      state.refCount++
      state.loading = true
    } else if (state.refCount > 0) {
      state.refCount--
      state.loading = (state.refCount > 0)
    }
  }
}

const actions = {
  checkIfReview({ commit }, route) {
    let isReview = !!route.query.hasOwnProperty('review')
    if (isReview && !StorageService.get('review')) {
      StorageService.set('review', 'true')
    } else if (StorageService.get('review')) {
      isReview = true
    }

    commit('setIsReview', isReview)
  },
  clearReview({ commit }) {
    StorageService.delete('review')
    commit('setIsReview', false)
  },
  setLanguage({ commit }, language) {
    commit('setLanguage', language)
  },
  setLoading({ commit }, isLoading) {
    commit('setLoading', isLoading)
  },
  resetState({ commit }) {
    commit('resetState')
    commit('auth/resetState')
    commit('identity/resetState')
    commit('customer/resetState')
    commit('employee/resetState')
    commit('ghost/resetState')
    commit('datepicker/resetState')
    commit('trafficSources/resetState')
    commit('facebookAds/resetState')
    commit('instagram/resetState')
    commit('website/resetState')
    commit('contacts/resetState')
  }
}

export default {
  state: getDefaultState(),
  getters,
  mutations,
  actions
}

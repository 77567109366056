const getDefaultState = () => {
  return {}
}

const getters = {}

const mutations = {
  resetState (state) {
    Object.assign(state, getDefaultState())
  }
}

const actions = {}

export default {
  namespaced: true,
  state: getDefaultState(),
  getters,
  mutations,
  actions
}

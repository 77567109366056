<template>
  <div id="app" class="wrapper" v-if="bootstrapped" :class="[$org('slug')]">
    <div id="review-button" v-if="isReview" @click="clearReview">
      <span> <fa icon="times" /> </span>Luk review
    </div>

    <template v-if="showMaintenance">
      <maintenance-page/>
    </template>

    <template v-else>
      <auth v-if="isAuthenticating" />
      <router-view v-else />
    </template>

    <notifications group="notify" position="bottom right" />
  </div>
</template>

<style scoped lang="scss">
#review-button {
  transition: background ease-in-out 400ms;
  display: flex;
  font-size: 12px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: fixed;
  z-index: 9999999999999;
  padding: 10px 10px 15px;
  bottom: 0;
  right: 25px;
  background: #c47878;
  cursor: pointer;
  box-shadow: 0 -5px 15px rgba(0, 0, 0, 0.15);
  border-radius: 3px 3px 0 0;
  color: white;

  span {
    font-size: 22px;
    margin-bottom: 5px;
  }

  &:hover {
    background: darken(#c47878, 10%);

    span {
      transition: all ease-in-out 400ms;
      transform: rotate(180deg);
      top: -1px;
      position: relative;
    }
  }
}
</style>

<script>
import organization from "@/mixins/customer/organization";
import Auth from "@/app/auth/Auth";
import { mapGetters } from "vuex";
import "@/assets/scss/app.scss";
import StorageService from "@/services/_app/storage/StorageService";
import TokenService from "@/services/_app/storage/TokenService";
import UserService from '@/services/_app/users/UserService'
import MaintenancePage from '@/app/errors/MaintenancePage'

export default {
  components: { Auth, MaintenancePage },
  mixins: [organization],

  name: "app",
  data() {
    return {
      bootstrapped: false,
      layout: false,
      underMaintenance: false, //TODO: this should be removed and set in .env
    };
  },

  computed: {
    ...mapGetters("auth", ["isLoggedIn", "isAuthenticating"]),
    ...mapGetters("identity", {
      isLoadingIdentity: "isLoading",
      hasIdentity: "hasIdentity",
      identity: "getIdentity",
      customer: "getCustomer"
    }),
    ...mapGetters("ghost", ["isGhost"]),
    ...mapGetters(["isReview"]),

    showMaintenance() {
      if(!this.underMaintenance) return false

      const params = new Proxy(new URLSearchParams(window.location.search), { get: (searchParams, prop) => searchParams.get(prop), })
      const bypass = params.bypass
      
      if(!bypass){
        return true
      }
     
      return false
    }
  },

  watch: {
    hasIdentity() {
      if (this.isLoggedIn && this.hasIdentity) {
        this.bootstrapped = true;
      }
    },
    $route() {
      if (this.$route.matched.some(record => record.meta.guest)) {
        this.bootstrapped = true;
      }
    }
  },

  async created() {
    await this.$store.dispatch("checkIfReview", this.$route);

    try {
      await this.$store
        .dispatch("identity/loadIdentity")
        .then(response => {
          return response;
        })
        .catch(error => {
          switch (error.response.status) {
            case 400:
              break;
            case 401:
              console.log("401 error")

              if (
                StorageService.get("token") !==
                StorageService.get("login-token")
              ) {
                TokenService.removeToken();
                window.location.reload();
              }
              break;
            default:
              break;
          }
        });
    } catch (error) {
      return Promise.reject(error);
    }

    //check if ghost-idenity token is set in LocalStorage.
    //If so, then make request and save ghostUserIdentity to Vuex store.
    const ghostIdentity = StorageService.get("ghost-identity");
    if(ghostIdentity){
      const ghostUserIdentity = await UserService.getGhostUserIdentity();
      this.$store.commit("ghost/setGhostUserIdentity", ghostUserIdentity);
    }
  
    // if (this.isLoggedIn && !this.hasIdentity) {

    //   await this.$store.dispatch("identity/loadIdentity");
    //   console.log("this.hasIdentity second one", this.hasIdentity);
    // }

    if (this.isLoggedIn && this.$route.name === "login") {
      const authState = StorageService.get("authState");
      if (authState) {
        this.$router.push({
          name: authState === "client" ? "frontend" : "backend"
        });
      }
    }

    this.bootstrapped = true;
  },

  methods: {
    clearReview() {
      this.$store.dispatch("clearReview");
      this.$router.push({ name: this.$route.name });
    },
    listenForTokenChange() {
      window.addEventListener(
        "storage",
        event => {
          if (event.key === "authState") {
            setTimeout(() => {
              const token = this.$store.getters["auth/getStorageToken"];

              console.log(token);
              // If no change exit
              if (this.token === token) {
                return;
              }

              // If logged out or went into ghost mode, reload page to update all data
              location.reload();
            }, 1500);
          }
        },
        false
      );
    }
  }
};
</script>

export default [
  {
    path: '/onboarding',
    name: 'onboarding',
    component: () => import(/* webpackChunkName: "Onboarding" */ './Onboarding'),
    meta: {
      layout: false,
      allowAccessBeforeOnboarding: true,
      tracking: 'Onboarding - Page view - Layout'
    },
    children: [
      {
        path: 'welcome',
        name: 'onboarding.welcome',
        component: () => import(/* webpackChunkName: "Onboarding" */ './views/Welcome'),
        meta: {
          layout: false,
          allowAccessBeforeOnboarding: true,
          tracking: 'Onboarding - Page view - Welcome'
        }
      },
      {
        path: 'step-1',
        name: 'onboarding.step-1',
        component: () => import(/* webpackChunkName: "Onboarding" */ './views/Step1'),
        meta: {
          layout: false,
          allowAccessBeforeOnboarding: true,
          tracking: 'Onboarding - Page view - Step 1'
        }
      },
      {
        path: 'step-2',
        name: 'onboarding.step-2',
        component: () => import(/* webpackChunkName: "Onboarding" */ './views/Step2'),
        meta: {
          layout: false,
          allowAccessBeforeOnboarding: true,
          tracking: 'Onboarding - Page view - Step 2'
        }
      },
      {
        path: 'step-3',
        name: 'onboarding.step-3',
        component: () => import(/* webpackChunkName: "Onboarding" */ './views/Step3'),
        meta: {
          layout: false,
          allowAccessBeforeOnboarding: true,
          tracking: 'Onboarding - Page view - Step 3'
        }
      }
    ]
  }
]

export default [
  {
    path: 'seo',
    name: 'frontend.reporting.seo',
    component: () => import(/* webpackChunkName: "Seo" */ './Seo'),
    meta: {
      headline: () => $t('navigation.statistics.seo'),
      description: () => $t('topBar.descriptions.reporting.seo'),
      tracking: 'SEO - Page view - Main page',
      datepicker: true
    }
  },
]

<template>
    <div class="row">
        <div class="col-lg-12">
            <card>
                <div class="row">
                    <div class="col-lg-12">
                        <table style="width: 100%;">
                            <thead>
                                <tr>
                                    <td class="thead-title">
                                        <h6>{{ $t('providerOverview.accounts') }}</h6>
                                    </td>
                                    <td class="thead-integrations hide-mobile">
                                        <table width="100%">
                                            <tr>
                                                <td class="thead-product" v-for="value in header" width="25%">
                                                    <small>{{ value.title }}</small>
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                            </thead>

                            <tbody>
                                <template v-for="(account, key) in sortedAccounts">
                                    <tr class="provider-group">
                                        <td class="company">
                                            <small class="company__name ellipsis">
                                                {{ $t(`providerOverview.${key}`) }}
                                            </small>
                                            <div style="display:flex; gap:5px; padding-top: 4px;"
                                                v-if="account[0] && account[0].enabled">
                                                <connect-mcc-account-btn :is-access="account[0].is_access_provider"
                                                    :service="account[0].service_name"
                                                    :service-namespace="account[0].service_namespace"
                                                    @added-new-account="onAddedNewMccAccount">
                                                    Connect/Reconnect MCC
                                                </connect-mcc-account-btn>
                                                <div class="" v-if="account[0].manualCreate">
                                                    <button class="btn btn-primary btn-xs"
                                                        @click="toggleCreateNewMccForm(account[0].service_namespace)">
                                                        Add manually
                                                    </button>
                                                </div>
                                            </div>
                                        </td>
                                        <td class="company-columns hide-mobile" width="80%">
                                            <table width="100%">
                                                <tr>
                                                    <td class="integration" v-for="value in header"
                                                        style="text-align: center;" width="25%">

                                                        <small v-if="value.slug === 'amountOfAccounts'">
                                                            {{ providerAccounts(key) }}
                                                        </small>
                                                    </td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>

                                    <tr v-for="data in account">
                                        <td class="company">
                                            <small>
                                                {{ data.name }}
                                            </small>

                                            <!-- MOBILE -->
                                            <div class="show-mobile">
                                                <p v-for="value in header">
                                                    <small v-if="value.slug === 'nextUpdate'">
                                                        <strong>{{ $t('providerOverview.nextUpdate') }}: </strong>
                                                        (todo)
                                                    </small>

                                                    <small v-if="value.slug === 'latestUpdate'">
                                                        <strong>{{ $t('providerOverview.latestUpdate') }}: </strong>
                                                        {{ data.updated_at }}
                                                    </small>

                                                    <template v-if="value.slug === 'healthy'">
                                                        <small><strong>{{ $t('providerOverview.healthy') }}:
                                                            </strong></small>
                                                        <span class="mcc-warning" v-if="!data.healthy"
                                                            v-tooltip="$t('providerOverview.healthyTooltip')">
                                                            <i class="fa fa-warning" />
                                                        </span>
                                                    </template>

                                                    <hr>
                                                </p>
                                            </div>
                                        </td>
                                        <td class="company-columns hide-mobile" width="80%">
                                            <table width="100%">
                                                <tr>
                                                    <td class="integration" v-for="value in header"
                                                        style="text-align: center;" width="25%">

                                                        <small v-if="value.slug === 'nextUpdate'">
                                                            (todo)
                                                        </small>

                                                        <small v-if="value.slug === 'latestUpdate'">
                                                            {{ data.updated_at }}
                                                        </small>

                                                        <template v-if="value.slug === 'healthy'">
                                                            <span class="mcc-warning" v-if="!data.healthy"
                                                                v-tooltip="$t('providerOverview.healthyTooltip')">
                                                                <i class="fa fa-warning" />
                                                            </span>
                                                        </template>

                                                        <button @click="setCurrentlyEditingMccAccountId(data)"
                                                            v-tooltip="'Edit MCC account directly'" class="mcc-edit-btn"
                                                            type="button" v-if="value.slug === 'healthy' && !data.is_access_provider">
                                                            <i class="fa fa-pencil" />
                                                        </button>
                                                        <i class="fa fa-info" v-tooltip="JSON.stringify(data.raw_json_data)" v-if="value.slug === 'healthy' && data.is_access_provider""/>
                                                    </td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                </template>
                            </tbody>
                        </table>
                    </div>
                </div>
            </card>
        </div>
        <mcc-account-form v-if="currentlyEditingMccAccountId" :mcc-account-id="currentlyEditingMccAccountId"
            @close="closeForm" />
        <mcc-account-form v-if="displayMccAccountCreateForm" @close="closeForm"
            :preset-provider="mccCreateFormPresetProvider" />
    </div>
</template>

<style lang="scss" scoped>
@import '~@/assets/scss/_vars.scss';
@import '~@/assets/scss/_mixins.scss';

.provider-group {
    >td {
        border-top: 2px solid black;
        background: #eaeaea;
    }
}

.ellipsis {
    @include ellipsis;
}

.mcc-warning {
    padding: 5px 5px 7px 5px;

    i {
        font-size: 16px;
        color: $focusAlert;
    }
}

.mcc-edit-btn {
    background: none;
    padding: 0;
    border: none;
    transition: all 0.2s ease-in-out;
    font-size: 16px;

    &:hover {
        cursor: pointer;
        transform: scale(1.2);
    }
}

.legend-container {
    padding: 15px;

    small {
        margin-right: 15px;

        img {
            width: 15px;
            opacity: 0.4;
            margin-right: 5px;
        }
    }

    .legend {
        display: inline-block;
        width: 10px;
        height: 10px;
        border-radius: 50%;
    }

    .legend-color--positive {
        background: rgba(91, 160, 204, 0.2);
    }

    .legend-color--negative {
        background: rgba(206, 77, 77, 0.6);
    }

    .legend-color--neutral {
        background: #ffffff;
        border: 1px solid #d4d4d4;
    }
}

.label {
    font-size: 8px;
    display: inline-block;

    &.label--no-user {
        background: #2e6da4;
        color: #ffffff;
        padding: 3px 5px;
        border-radius: 2px;
        margin-left: 5px;
    }
}

.filter-container {
    margin-bottom: 20px;

    .filter-button {
        padding: 10px 20px;
        display: inline-block;
        border-radius: 4px;
        margin-right: 10px;
    }
}

.integration-logo {
    width: 20px;
}

.keywords-total {
    color: rgba(0, 0, 0, 0.5);
    font-size: 12px !important;
    font-weight: 700;
    display: inline-block;
    position: absolute;
    bottom: 5px;
    left: 10px;
}

* {
    border-collapse: collapse !important;
}

table {
    thead {
        background: #f9f9f9;

        tr {
            height: 0;

            td {
                position: sticky;
                top: -1px;
                z-index: 999999;
                background: #f9f9f9;
                padding: 10px 0;
                border-bottom: 1px solid rgba(0, 0, 0, 0.1);

                table tr td {
                    border: none !important;
                }

                &.thead-title {
                    width: 30%;
                    padding-left: 20px;
                }

                &.thead-integrations {
                    width: 70%;
                }

                &.thead-product {
                    padding-left: 10px;
                    text-align: center;
                }
            }
        }
    }

    tbody {
        tr {
            td {
                padding: 10px 0;

                &.company {
                    padding-left: 20px;
                    width: 20%;

                    .company__name {
                        font-weight: 500;
                    }

                    .company__domain {
                        font-size: 10px;
                        color: #888;
                        margin: 0;
                    }
                }

                &.integration {
                    position: relative;

                    img {
                        display: inline-block;
                        width: 20px;
                        opacity: 0.5;
                    }

                    .integration-status {
                        text-align: left;
                        padding: 30px;
                        position: absolute;
                        background: #282828;
                        color: #fff;
                        width: 400px;
                        border-radius: 4px;
                        opacity: 0.95;
                        z-index: 99999;
                        box-shadow: 1px 3px 10px #555;
                        text-shadow: 1px 1px 2px #000;

                        a {
                            color: #ffa534;
                        }

                        &.hover-left {
                            left: 0 !important;
                        }

                        &.hover-right {
                            right: 0 !important;
                        }

                        &.hover-top {
                            top: 50px !important;
                        }

                        &.hover-bottom {
                            bottom: 50px !important;
                        }
                    }
                }

                a {
                    color: #888888;

                    &:hover {
                        color: #333333;
                    }
                }

                table {
                    tr {
                        td {
                            height: 40px;
                        }
                    }
                }
            }
        }
    }
}

.show-mobile {
    display: none;
}

@media screen and (max-width: 992px) {
    .legend-container {
        small {
            display: block;
            margin-bottom: 5px;
        }
    }
}

@media only screen and (max-width: 768px) {
    .hide-mobile {
        display: none;
    }

    .show-mobile {
        display: block;

        .mcc-warning {
            background: none;

            i {
                color: $focusAlert;
            }
        }
    }

    table {
        thead {
            tr {
                td {
                    &.thead-product {
                        padding: 0;
                        text-align: left;
                    }

                    &.thead-integrations {
                        width: 100%;

                        table tr td {
                            position: relative;

                            img {
                                position: absolute;
                                top: 10px;
                                left: 50%;
                                transform: translateX(-50%);
                            }

                            small {
                                display: inline-block;
                                transform: rotate(90deg);
                                text-align: left;
                                width: 100px;
                            }
                        }
                    }
                }
            }
        }

        tbody {
            tr {
                td {
                    &.company {
                        small {
                            @include ellipsis;
                        }
                    }
                }
            }
        }
    }
}

$opacity: 0.4;

.active {
    text-align: center;
    background: rgba(91, 160, 204, 0.2);
    outline: 1px solid #ffffff;

    i {
        color: #000000;
        opacity: $opacity;
        font-size: 16px;
    }
}

.inactive {
    text-align: center;
    background: rgba(206, 77, 77, 0.6);
    outline: 1px solid #ffffff;

    i {
        color: #000000;
        opacity: $opacity;
        font-size: 16px;
    }
}

.null {
    text-align: center;

    i {
        color: #b5b5b5;
        font-size: 16px;
    }
}
</style>

<script>
import Card from '@/app/shared/components/Card'
import ConnectMccAccountBtn from '@/app/cached-account-overview/components/ConnectMccAccountBtn'
import MccAccountForm from '@/app/cached-account-overview/components/MccAccountForm'
import OAuth2AccountService from '@/services/oauth2/OAuth2AccountService'
import AccessApiService from '@/services/access-api/AccessApiService'

const moment = require('moment')

export default {
    data() {
        return {
            data: null,
            currentlyEditingMccAccountId: null,
            displayMccAccountCreateForm: false,
            mccCreateFormPresetProvider: null,
            header: [
                {
                    title: $t('providerOverview.amountOfAccounts'),
                    slug: 'amountOfAccounts'
                },
                {
                    title: $t('providerOverview.latestUpdate'),
                    slug: 'latestUpdate'
                },
                {
                    title: $t('providerOverview.nextUpdate'),
                    slug: 'nextUpdate'
                },
                {
                    title: $t('providerOverview.healthy'),
                    slug: 'healthy'
                }
            ]
        }
    },

    computed: {
        sortedAccounts() {
            if (!this.data) {
                return {}
            }

            const clonedData = JSON.parse(JSON.stringify(this.data))
            const collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' })
            Object.keys(clonedData).map(key => {
                clonedData[key] = clonedData[key].sort((a, b) => collator.compare(a.name, b.name))
            })

            return clonedData
        },
        providerToServiceMap() {
            return {
                accessGoogleBusinessProfile: {
                    service: 'google-business-profile',
                    serviceNamespace: 'google-business-profile',
                    enabled: true,
                    manualCreate: false
                },
                accessGoogleAds: {
                    service: 'access-google-ads',
                    serviceNamespace: 'access-google-ppc',
                    enabled: true,
                    manualCreate: false
                },
                googleMyBusiness: {
                    service: 'google-business-profile',
                    serviceNamespace: 'google-business-profile',
                    enabled: false,
                    manualCreate: false
                },
                googleSearchConsole: {
                    service: 'google-search-console',
                    serviceNamespace: 'google-search-console',
                    enabled: true,
                    manualCreate: false
                },
                googleAnalytics: {
                    service: 'analytics',
                    serviceNamespace: 'analytics',
                    enabled: true,
                    manualCreate: false
                },
                facebookAds: {
                    service: 'facebook-ads',
                    serviceNamespace: 'facebook-ads',
                    enabled: true,
                    manualCreate: true
                },
                facebookPages: {
                    service: 'facebook-pages',
                    serviceNamespace: 'facebook-pages',
                    enabled: true,
                    manualCreate: true
                },
            }
        }
    },

    mounted() {
        this.load()
    },

    methods: {
        closeForm() {
            this.currentlyEditingMccAccountId = null
            this.displayMccAccountCreateForm = false
            this.mccCreateFormPresetProvider = null
            this.data = null
            this.load()
        },
        setCurrentlyEditingMccAccountId(accountData) {
            this.currentlyEditingMccAccountId = accountData.id
        },
        toggleCreateNewMccForm(serviceNamespace) {
            this.displayMccAccountCreateForm = true;
            // change serviceNamespace from kebab-case to camelCase
            this.mccCreateFormPresetProvider = serviceNamespace.replace(/-([a-z])/g, g => g[1].toUpperCase());
        },
        onAddedNewMccAccount() {
            this.data = null;
            this.load()
        },
        isAccess(provider) {
            return (provider === 'accessGoogleAds') || (provider === 'accessGoogleBusinessProfile')
        },
        getServiceNameFromProvider(provider) {
            return this.providerToServiceMap[provider] ? this.providerToServiceMap[provider].service : null
        },
        getServiceNameSpaceFromProvider(provider) {
            return this.providerToServiceMap[provider] ? this.providerToServiceMap[provider].serviceNamespace : null
        },
        getProviderIsEnabled(provider) {
            return this.providerToServiceMap[provider] ? this.providerToServiceMap[provider].enabled === true : false
        },
        getCanAddManually(provider) {
            return this.providerToServiceMap[provider] ? this.providerToServiceMap[provider].manualCreate === true : false
        },
        load() {
            const options = {
                filter_groups: [
                    {
                        filters: [
                            {
                                key: 'owner',
                                operator: 'eq',
                                value: 'NULL'
                            }
                        ]
                    }
                ]
            }
            const addExtraFieldsToAccountObj = (account) => {
                account.is_access_provider = this.isAccess(account.provider)
                account.service_name = this.getServiceNameFromProvider(account.provider)
                account.service_namespace = this.getServiceNameSpaceFromProvider(account.provider)
                account.enabled = this.getProviderIsEnabled(account.provider)
                account.manualCreate = this.getCanAddManually(account.provider)
                if (account.updated_at) {
                    account.updated_at = moment(account.updated_at).format('HH:mm DD-MM-YYYY')
                }
                if (account.updated) {
                    account.updated_at = moment(account.updated.date).format('HH:mm DD-MM-YYYY')
                }
            }
            OAuth2AccountService.findAll(options, ({ data }) => {
                this.data = data.rows.reduce((acc, account) => {
                    if (!acc[account.provider]) {
                        acc[account.provider] = []
                    }
                    addExtraFieldsToAccountObj(account)
                    acc[account.provider].push(account)
                    return acc
                }, { ...this.data })
            })
            // load access mcc accounts
            AccessApiService.findAllMcc(
                {},
                ({ data }) => {
                    this.data = data.reduce((acc, account) => {
                        if (!acc[account.provider]) {
                            acc[account.provider] = []
                        }
                        addExtraFieldsToAccountObj(account)
                        acc[account.provider].push(account)
                        return acc
                    }, { ...this.data })
                }
            )

        },

        providerAccounts(key) {
            return this.data[key].length
        },
    },

    components: {
        Card,
        ConnectMccAccountBtn,
        MccAccountForm
    }
}
</script>

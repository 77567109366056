<template>
	<div>
		<table class="table table-striped">
			<thead>
				<tr>
					<th class="col-lg-4">{{$t('klaviyo.flow')}}</th>
					<th class="col-lg-2">{{$t('klaviyo.opens')}}</th>
					<th class="col-lg-2">{{$t('klaviyo.clicks')}}</th>
					<th class="col-lg-2">{{$t('klaviyo.revenue')}}</th>
				</tr>
			</thead>

			<tbody>
				<flow-item :flow="flow"
						   v-for="(flow, i) in sortedFlows"
						   :key="i"
				/>
			</tbody>
		</table>

		<div class="text-center" v-if="! hasLoaded">
			<div>
				<span class="fa fa-circle-o-notch fa-spin fa-2x"></span>
			</div>
		</div>

		<infinite-loading @infinite="load" v-if="hasLoaded && !reachedEnd" :distance="250" />
	</div>
</template>

<script>
import KlaviyoService from '@/services/klaviyo/KlaviyoService'
import NewsletterItem from '@/app/klaviyo/components/NewsletterItem'
import InfiniteLoading from 'vue-infinite-loading'

const moment = require('moment')

import hasIntegration from '@/mixins/integrations/hasIntegration'
import FlowItem from "@/app/klaviyo/components/FlowItem";

export default {
	mixins: [hasIntegration],

	data: () => ({
		flows: [],
		metrics: {},
		hasLoaded: true,
		data: {},
		perPage: 2,
		page: 1,
		reachedEnd: false
	}),

	computed: {
		sortedFlows() {
			return this.flows.sort((a, b) => {
				return moment(b.created).unix() - moment(a.created).unix()
			})
		}
	},
	methods: {
		load() {
			if (!this.hasLoaded || this.reachedEnd) {
				return
			}

			if (! this.hasHealthyIntegration('Klaviyo')) {
				return
			}

			this.hasLoaded = false

			KlaviyoService.flows({
				page: this.page,
				limit: this.perPage,
			}, (response) => {
				const data = response.data
				const items = data.data

				this.page++

				this.flows = [
					...this.flows,
					...items
				]

				if (items.length === 0) {
					this.reachedEnd = true
					this.hasLoaded = true
					return
				}

				this.hasLoaded = true
			})
		}
	},

	components: {
		FlowItem,
		NewsletterItem,
		InfiniteLoading
	}
}
</script>

import Vue from 'vue'
import VueOrganizations from 'vue-organizations'

Vue.use(VueOrganizations)

import sgme from './soegemedier'
import spotonMarketing from './spoton'
import freemium from './freemium'
import opn from './opn'

Vue.organization('sgme', sgme.organizations())
Vue.organization('spoton-marketing', spotonMarketing.organizations())
Vue.organization('freemium', freemium.organizations())
Vue.organization('opn', opn.organizations())

Vue.config.org = 'freemium'

global.$org = Vue.org


<template>
  <div class="element">
    <div class="row" v-if="!hasLoaded" style="min-height: 355px">
      <div class="col-lg-12">
        <logo-loader :height="355" />
      </div>
    </div>

    <div class="row" v-else>
      <div class="col-lg-12">
        <h4>{{ $t("customers.meta.organization") }}</h4>

        <multiselect
          v-if="organizations.length && customer"
          v-model="selectedOrganization"
          :options="organizationFilter"
          track-by="label"
          label="label"
          :id="selectedOrganization.value"
          @select="updateCustomerOrganization"
          :close-on-select="true"
          :allowEmpty="false"
          :show-labels="false"
          :placeholder="'Pick organization'"
          :selectLabel="$t('multiSelect.selectLabel')"
        >
          <p slot="noResult">{{ $t("multiSelect.noResultsFound") }}</p>
        </multiselect>
      </div>
      <div v-if="source !== null"
		   class="col-lg-12">
        <h4>Source</h4>

		<multiselect
		  v-if="organizations.length && customer"
		  v-model="source"
		  :options="sourceOptions"
		  track-by="label"
		  label="label"
		  @select="updateCustomerSource"
		  :close-on-select="true"
		  :allowEmpty="false"
		  :show-labels="false"
		  :placeholder="'Pick source'"
	      :selectLabel="$t('multiSelect.selectLabel')">
			  <p slot="noResult">{{ $t("multiSelect.noResultsFound") }}</p>
		  </multiselect>
      </div>
      <div v-if="displayDeleteCustomerButton" class="col-lg-12">
        <h4 style="color: red">Danger zone</h4>
        <p>Deleting a customer like this might take a few minutes.</p>
        <button @click="deleteCustomerAction" class="btn btn-fill btn-danger">
          Delete customer (Are you sure?)
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import LogoLoader from "@/app/shared/components/LogoLoader";

const OrganizationService = require("@/services/customers/OrganizationService");
const CustomerService = require("@/services/customers/CustomerService");
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      hasLoaded: false,
      organizations: [],
      customer: {},
	  source: null,
    };
  },

  computed: {
    ...mapGetters("identity", {
      identity: "getIdentity"
    }),
    customerId() {
      return this.$route.params.id;
    },
    displayDeleteCustomerButton() {
        return this.identity.role === 'employee-super-admin'
    },
    organizationFilter() {
      if (!this.organizations) {
        return [];
      }

      return this.organizations.map((organization) => {
        return {
          label: organization.title,
          value: organization.id,
        };
      });
    },
	  sourceOptions() {
		return ['opn', 'crm', 'makhub', 'facebook.seo', 'website.spotonlive', 'sgme', 'website.spotonmarketing', 'adversus', 'facebook.traffic', 'facebook.reviews', 'newsletter'].reduce(
			(carry, item) => ([
				...carry,
				{ label: item }
			]),
			[]
		)
	  }
  },

  watch: {
	customer() {
	  const source = (this.customer || {}).source || null

	  if (! source) {
	    return
	  }

	  this.source = { label: source }
	}
  },

  mounted() {
    this.loadOrganizations();
    this.loadCustomer();
  },

  methods: {
    loadOrganizations() {
      OrganizationService.findAll({}, ({ data }) => {
        this.organizations = data.rows;
      });
    },

    loadCustomer() {
      this.hasLoaded = false;
      this.customer = null;

      const data = {
        includes: ["organization"],
      };

      CustomerService.find(
        this.customerId,
        data,
        ({ data }) => {
          this.customer = data;

          this.selectedOrganization = {
            label: data.organization.title,
            value: data.organization.id,
          };

          this.hasLoaded = true;
        },
        () => {
          this.hasLoaded = true;
        }
      );
    },

    updateCustomerOrganization(payload) {
      this.selectedOrganization = payload;

      const data = {
        organization: this.selectedOrganization.value,
      };

      CustomerService.put(this.customerId, data, (response) => {
        eventHub.$emit("backend.customer.updated");
      });
    },

    updateCustomerSource(payload) {
      const data = {
        source: payload.label,
      };

      CustomerService.put(this.customerId, data, (response) => {
        eventHub.$emit("backend.customer.updated");
      });
    },

    deleteCustomerAction() {
      if (
        !confirm(
          "Are you sure you want to permanently delete " +
            this.customer.name +
            "? CANNOT BE UNDONE"
        )
      ) {
        return;
      }

      CustomerService.deleteCustomer(
        this.customerId,
        (response) => {
          eventHub.$emit('backend.customer.deleted')
        },
        (error) => {
          console.error(error);
        }
      );
    },
  },

  components: {
    LogoLoader,
  },
};
</script>

<template>
    <div class="ribbon" :style="{'top': top, 'right': right}">
        <span>{{text}}</span>
    </div>
</template>

<style lang="scss" scoped>
    @import '~@/assets/scss/_vars.scss';

    .ribbon {
        position: absolute;
        right: -20px;
        top: -20px;
        z-index: 1;
        overflow: hidden;
        width: 75px; height: 75px;
        text-align: right;

        span {
            font-size: 10px;
            font-weight: bold;
            color: #FFF;
            text-transform: uppercase;
            text-align: center;
            line-height: 20px;
            transform: rotate(45deg);
            -webkit-transform: rotate(45deg);
            width: 100px;
            display: block;
            background: #79A70A;
            background: linear-gradient(#F79E05 0%, #F79E05 100%);
            box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
            position: absolute;
            top: 19px; right: -21px;

            &:before {
                content: "";
                position: absolute; left: 0px; top: 100%;
                z-index: -1;
                border-left: 3px solid #F79E05;
                border-right: 3px solid transparent;
                border-bottom: 3px solid transparent;
                border-top: 3px solid #F79E05;
            }

            &:after {
                content: "";
                position: absolute; right: 0px; top: 100%;
                z-index: -1;
                border-left: 3px solid transparent;
                border-right: 3px solid #F79E05;
                border-bottom: 3px solid transparent;
                border-top: 3px solid #F79E05;
            }
        }
    }
</style>

<script>
export default {
    props: {
      text: {
        type: String,
        required: true,
      },

      top: {
        type: String,
      },

      right: {
        type: String,
      }
    }
}
</script>

// TODO: Refactor this file
// 1: Extract to class
// 2: Get rid of overly complicated code

import FacebookService from '@/services/_app/meta/FacebookService'
import InstagramService from '@/services/_app/meta/InstagramService'

const FILTER_NORMAL = 'normal'
const FILTER_WEBSHOP = 'webshop'
const FILTER_EVENTS = 'events'

const STATUS_ACTIVE = 'ACTIVE'
const STATUS_PAUSED = 'PAUSED'

const FORMATS = {
	conversions: 'integer',
	website_purchase_roas: 'percentage',
	calculated_purchase_roas: 'percentage',
	add_to_cart: 'money',
	view_content: 'money',
	purchase: 'money',
	cost_per_action_type: 'money',

	outbound_clicks: 'integer',
	outbound_clicks_ctr: 'percentage',
	cost_per_inline_link_click: 'money',
	calculated_cost_per_outbound_click: 'money',
	reach: 'integer',
	impressions: 'integer',
	spend: 'money',
	account_currency: 'string',

	action_landing_page_view: 'integer',
	action_omni_view_content: 'integer',
	action_lead: 'integer',
	action_omni_complete_registration: 'integer',
	action_contact_total: 'integer',
}

const getDefaultState = () => {
	return {
		[FILTER_EVENTS]: {
			fields: [
				'website_purchase_roas',
				'action_values',
				'actions',
				'spend',
				'account_currency'
			],
			metrics: [
				{
					metric: 'action_landing_page_view',
					icon: 'mouse-pointer'
				},
				{
					metric: 'action_omni_view_content',
					icon: 'eye'
				},
				{
					metric: 'action_lead',
					icon: 'filter'
				},
				{
					metric: 'action_omni_complete_registration',
					icon: 'user-plus'
				},
				{
					metric: 'action_contact_total',
					icon: 'envelope'
				},
				{
					metric: 'spend',
					icon: 'credit-card'
				}
			],
			overviewMetrics: [
				{
					metric: 'action_landing_page_view',
					icon: 'mouse-pointer'
				},
				{
					metric: 'action_omni_view_content',
					icon: 'eye'
				},
				{
					metric: 'action_lead',
					icon: 'filter'
				},
				{
					metric: 'action_omni_complete_registration',
					icon: 'user-plus'
				},
				{
					metric: 'action_contact_total',
					icon: 'envelope'
				},
				{
					metric: 'spend',
					icon: 'credit-card'
				}
			]
		},
		[FILTER_WEBSHOP]: {
			fields: [
				'website_purchase_roas',
				'action_values',
				'actions',
				'spend',
				'account_currency'
			],
			metrics: [
				{
					metric: 'view_content',
					icon: 'eye'
				},
				{
					metric: 'add_to_cart',
					icon: 'shopping-cart'
				},
				{
					metric: 'purchase_value',
					icon: 'receipt'
				},
				{
					metric: 'purchase',
					icon: 'users'
				},
				{
					metric: 'website_purchase_roas',
					icon: 'handshake'
				},
				{
					metric: 'spend',
					icon: 'credit-card'
				}
			],
			overviewMetrics: [
				{
					metric: 'view_content',
					icon: 'eye'
				},
				{
					metric: 'add_to_cart',
					icon: 'shopping-cart'
				},
				{
					metric: 'purchase_value',
					icon: 'receipt'
				},
				{
					metric: 'purchase',
					icon: 'users'
				},
				{
					metric: 'calculated_purchase_roas',
					icon: 'handshake'
				},
				{
					metric: 'spend',
					icon: 'credit-card'
				}
			]
		},
		[FILTER_NORMAL]: {
			fields: [
				'action_values',
				'outbound_clicks',
				'outbound_clicks_ctr',
				'cost_per_inline_link_click',
				'clicks',
				'reach',
				'impressions',
				'spend',
				'account_currency'
			],
			metrics: [
				{
					metric: 'impressions',
					icon: 'eye'
				},
				{
					metric: 'reach',
					icon: 'users'
				},
				{
					metric: 'clicks',
					icon: 'exchange-alt'
				},
				{
					metric: 'outbound_clicks',
					icon: 'mouse-pointer'
				},
				{
					metric: 'cost_per_inline_link_click',
					icon: 'money-bill'
				},
				{
					metric: 'spend',
					icon: 'credit-card'
				}
			],
			overviewMetrics: [
				{
					metric: 'impressions',
					icon: 'eye'
				},
				{
					metric: 'reach',
					icon: 'users'
				},
				{
					metric: 'clicks',
					icon: 'exchange-alt'
				},
				{
					metric: 'inline_link_clicks',
					icon: 'mouse-pointer'
				},
				{
					metric: 'cost_per_inline_link_click',
					icon: 'money-bill'
				},
				{
					metric: 'spend',
					icon: 'credit-card'
				}
			]
		},
		selected: {
			[STATUS_ACTIVE]: {
				campaign: null,
				adSet: null,
				ad: null,
				preview: null
			},
			[STATUS_PAUSED]: {
				campaign: null,
				adSet: null,
				ad: null,
				preview: null
			}
		},
		limit: {
			[STATUS_ACTIVE]: 25,
			[STATUS_PAUSED]: 10
		},
		filter: FILTER_NORMAL,
		accountCurrency: null,
		webshopData: false,
		hasLoadedComparisonData: false,
		allCampaignComparisonData: null,
		period: 'period',
		comparisonPeriodDates: null,
		spend: 0,
		facebookPerformance: 0,
		integrationId: null,
		service: null,
		visibleMetrics: [],
		unformattedInsights: {}
	}
}

const getters = {
	getMetrics: state => state[state.filter].metrics,
	getOverviewMetrics: state => state[state.filter].overviewMetrics,
	getFields: state => state[state.filter].fields,
	getAccountCurrency: state => state.accountCurrency,
	getSelected(state) {
		return status => state.selected[status]
	},
	getLimit(state) {
		return status => state.limit[status]
	},
	getStatuses: state => {
		return {
			active: STATUS_ACTIVE,
			paused: STATUS_PAUSED
		}
	},
	getSpend(state){
		return state.spend
	},
	getAllCampaignComparisonData(state){
		return state.allCampaignComparisonData
	},
	getDataElements(state){
		const elements = []
		if(state.allCampaignComparisonData){
			if(state.filter === FILTER_NORMAL){
				elements.push(state.allCampaignComparisonData.impressions)
				elements.push(state.allCampaignComparisonData.reach)
				elements.push(state.allCampaignComparisonData.clicks)
				elements.push(state.allCampaignComparisonData['outbound_clicks:outbound_click'])
				elements.push(state.allCampaignComparisonData['cost_per_inline_link_click'])
				elements.push(state.allCampaignComparisonData.spend)
			}
			if(state.filter === FILTER_WEBSHOP){
				elements.push(state.allCampaignComparisonData['action_values:omni_view_content'])
				elements.push(state.allCampaignComparisonData['action_values:omni_add_to_cart'])
				elements.push(state.allCampaignComparisonData['action_values:omni_purchase'])
				elements.push(state.allCampaignComparisonData['actions:omni_purchase'])
				elements.push(state.allCampaignComparisonData['calculated_purchase_roas'])
				elements.push(state.allCampaignComparisonData.spend)
			}
			if(state.filter === FILTER_EVENTS){
				elements.push(state.allCampaignComparisonData['action_values:omni_purchase'])
				elements.push(state.allCampaignComparisonData['action_values:omni_purchase'])
				elements.push(state.allCampaignComparisonData['action_values:omni_purchase'])
				elements.push(state.allCampaignComparisonData['action_values:omni_purchase'])
				elements.push(state.allCampaignComparisonData['action_values:omni_purchase'])
				elements.push(state.allCampaignComparisonData['action_values:omni_purchase'])
			}
		}
		return elements
	},
	getFilters: state => {
		return {
			current: state.filter,
			filters: [
				{
					name: 'normal',
					icon: 'globe',
					option: FILTER_NORMAL
				},
				{
					name: 'webshop',
					icon: 'store',
					option: FILTER_WEBSHOP
				},
				{
					name: 'events',
					icon: 'tags',
					option: FILTER_EVENTS
				}
			]
		}
	},
	hasWebshopData: state => state.webshopData,
	getHasLoadedComparisonData: state => state.hasLoadedComparisonData,
	getPeriod: state => state.period,
	getComparisonPeriodDates: state => state.comparisonPeriodDates,
	getFacebookPerformance: state => state.facebookPerformance,
	getIntegrationId: state => state.integrationId,
	getService: state => state.service,
	getUnformattedInsights: state => state.unformattedInsights
}

const mutations = {
	resetState(state) {
		Object.assign(state, getDefaultState())
	},
	setFilter(state, option) {
		state.filter = option
	},
	setSelected(state, { type, status, object }) {
		state.selected[status][type] = object
	},
	setAccountCurrency(state, value) {
		state.accountCurrency = value
	},
	setWebshopData(state, value) {
		state.webshopData = value
	},
	setAllCampaignComparisonData(state, data){
		state.allCampaignComparisonData = data
	},
	setHasLoadedComparisonData(state, hasLoadedComparisonData){
		state.hasLoadedComparisonData = hasLoadedComparisonData
	},
	setPeriod(state, period){
		state.period = period
	},
	setComparisonPeriodDates(state, dates){
		state.comparisonPeriodDates = dates
	},
	setSpend(state, spend){
		state.spend = spend
	},
	setFacebookPerformance(state, facebookPerformance){
		state.facebookPerformance = facebookPerformance
	},
	setIntegrationId(state, integrationId){
		state.integrationId = integrationId
	},
	setService(state, service){
		state.service = service === 'instagram' ? InstagramService : FacebookService
	},
	setUnformattedInsights(state, data){
		state.unformattedInsights = data
	}
}

const BaseService = require("@/services/BaseService");

const actions = {

	loadPeriod({commit}, period){
		commit("setPeriod", period)
	},

	//GET ALL COMPARISON DATA
	async getAllCampaignComparisonData({commit, dispatch, rootGetters}, customerId){
		commit('setHasLoadedComparisonData', false)

		const period = rootGetters['facebookAds/getPeriod']
		const from = rootGetters['datepicker/getFormatted']('from')
		const to =  rootGetters['datepicker/getFormatted']('to')
		const unformattedInsights = rootGetters['facebookAds/getUnformattedInsights']

		let formattedCampaignsResponse;

		if (
			typeof unformattedInsights[customerId] === 'undefined' ||
			unformattedInsights[customerId].from !== from ||
			unformattedInsights[customerId].to !== to )
		{

			const campaignsUrl = `compare/facebookads/${customerId}/campaigns-insights/${from}/${to}/${period}`;
			const campaignsResponse = await BaseService.get(campaignsUrl);
			const accountUrl = `compare/facebookads/${customerId}/account-insights/${from}/${to}/${period}`;
			const accountResponse = await BaseService.get(accountUrl);

			Object.values(campaignsResponse.data).map((period, index) => {
				Object.values(period).map((campaign) => {
					const keys = Object.keys(accountResponse.data)
					const accountDataInsight = Object.values(accountResponse.data[keys[index]]).find(item =>
						item.publisher_platform === campaign.publisher_platform
					);

					if (accountDataInsight && typeof accountDataInsight === 'object') {
						campaign.reach = accountDataInsight.reach;
						campaign.cost_per_inline_link_click = accountDataInsight.cost_per_inline_link_click
					}
				})
			})


			formattedCampaignsResponse = await dispatch('formatCampaignsInsights', { data: campaignsResponse.data} )

			unformattedInsights[customerId] = {
				data: formattedCampaignsResponse,
				from: from,
				to: to
			};

			commit('setUnformattedInsights', unformattedInsights);
		}

		commit('setAllCampaignComparisonData', unformattedInsights[customerId].data)
		const datesObj = {
			current_period_from: unformattedInsights[customerId].data.currentPeriod_from,
			current_period_to: unformattedInsights[customerId].data.currentPeriod_to,
			last_period_from: unformattedInsights[customerId].data.lastPeriod_from,
			last_period_to: unformattedInsights[customerId].data.lastPeriod_to
		}

		commit('setComparisonPeriodDates', datesObj)
		commit('setSpend', unformattedInsights[customerId].data.spend)
		dispatch('getFacebookPerformance', customerId)
		commit('setHasLoadedComparisonData', true)
	},

	//GET FACEBOOK PERFORMANCE
	getFacebookPerformance({ commit, rootGetters }, customerId){
		const period = rootGetters['facebookAds/getPeriod']
		const from = rootGetters['datepicker/getFormatted']('from')
		const to =  rootGetters['datepicker/getFormatted']('to')

		const url = `compare/googleanalytics/${customerId}/session-sources/${from}/${to}/${period}`;

		BaseService.get(
			url,
			{},
			(response) => {
				if (response.data) {
					const data = ((response.data || {}).data || {}).facebook || {}

					commit('setFacebookPerformance', data)
					commit('setHasLoadedComparisonData', true)
				}
			},
			(error) => {
				console.log("error: ", error);
			}
		);
	},

	//=========> CAMPAIGNS
	/**
	 * Fetch Campaigns
	 *
	 * @param commit
	 * @param dispatch
	 * @param state
	 * @param rootGetters
	 * @param payload
	 * @returns {Promise<{next, total: *, previous: null, campaigns}>}
	 */
	async loadCampaigns({ commit, dispatch, state, rootGetters }, payload) {
		const service = rootGetters['facebookAds/getService']

		const status = payload.status || STATUS_ACTIVE

		const options = {
			parameters: {
				limit: payload.limit ?? state.limit[status],
				effective_status: [status]
			}
		}

		if (payload.token) {
			options.parameters.pagination = payload.token
		}

		const data = await service.campaigns(options)

		const { previous, next } = data.pagination
		const total = data.total
		const campaigns = data.campaigns

		return {
			previous, next, total, campaigns
		}
	},

	/**
	 * Load Campaign insights
	 * @param dispatch
	 * @param state
	 * @param rootGetters
	 * @param commit
	 * @param campaign
	 * @param fields
	 * @param from
	 * @param to
	 * @returns {Promise<{insights: {}}>}
	 */

	async loadCampaignInsights({ dispatch, state, rootGetters, commit }, { campaign, fields, from, to }) {

		if (!fields) fields = state[FILTER_NORMAL].fields

		const unformattedInsights = rootGetters['facebookAds/getUnformattedInsights']

		if (typeof unformattedInsights[campaign.id] === 'undefined' ||
			unformattedInsights[campaign.id].from !== from ||
			unformattedInsights[campaign.id].to !== to
		) {
			const service = rootGetters['facebookAds/getService']
			const responseData = await service.campaignInsights(campaign.id, {from, to, fields})

			unformattedInsights[campaign.id] = {
				data: responseData,
				fields: fields,
				from: from,
				to: to
			};

			commit('setUnformattedInsights', unformattedInsights)
		}

		const filteredInsight = {
			'clicks': 0,
			'reach': 0,
			'impressions': 0,
			'spend': 0,
			'cost_per_inline_link_click': 0,
			'action_values': [],
			'outbound_clicks': [],
			'outbound_clicks_ctr': [],
		};

		const insights = await dispatch('formatInsights', { unformattedInsight: unformattedInsights[campaign.id], filteredInsight: filteredInsight } )

		return {
			...campaign,
			...unformattedInsights[campaign.id].data.campaign,
			insights
		};
	},

	//=========> AD SETS
	/**
	 * Fetch ad sets to selected campaign
	 * @param rootFetters
	 * @param state
	 * @param status
	 * @returns {Promise<Array|*[]|T[]>}
	 */
	async loadAdSets({ rootGetters, state }, status) {
		const service = rootGetters['facebookAds/getService']

		const selectedId = state.selected[status].campaign.id

		const data = await service.adSets(selectedId)

		return data.adSets.map(adSet => {
			adSet.campaignId = selectedId
			return adSet
		})
	},

	/**
	 * Load AdSet Insights
	 *
	 * @param rootGetters
	 * @param dispatch
	 * @param commit
	 * @param adSet
	 * @param fields
	 * @param from
	 * @param to
	 * @returns {Promise<{insights: *}>}
	 */
	async loadAdSetInsights({ rootGetters, dispatch, commit }, { adSet, fields, from, to }) {
		const unformattedInsights = rootGetters['facebookAds/getUnformattedInsights']

		if (
			typeof unformattedInsights[adSet.id] === 'undefined' ||
			unformattedInsights[adSet.id].from !== from ||
			unformattedInsights[adSet.id].to !== to )
		{
			const service = rootGetters['facebookAds/getService']
			const responseData = await service.adSetInsights(adSet.campaignId, adSet.id, { from, to, fields })
			unformattedInsights[adSet.id] = {
				data: responseData,
				fields: fields,
				from: from,
				to: to
			};

			commit('setUnformattedInsights', unformattedInsights);
		}

		const filteredInsight = {
			'clicks': 0,
			'reach': 0,
			'impressions': 0,
			'spend': 0,
			'cost_per_inline_link_click': 0,
			'action_values': [],
			'outbound_clicks': [],
			'outbound_clicks_ctr': [],
		};

		const insights = await dispatch('formatInsights', { unformattedInsight: unformattedInsights[adSet.id], filteredInsight: filteredInsight } )
		return { ...adSet, insights }
	},

	//=========> ADS
	async loadAds({ rootGetters, state }, status) {
		const service = rootGetters['facebookAds/getService']

		const selectedCampaignId = state.selected[status].campaign.id
		const selectedAdSetId = state.selected[status].adSet.id

		const data = await service.ads(selectedAdSetId, selectedCampaignId, { fields: ['name', 'status'] })

		return data.ads.map(ad => {
			ad.campaignId = selectedCampaignId
			ad.adSetId = selectedAdSetId
			return ad
		})
	},

	async loadAdInsights({rootGetters, dispatch, commit }, { ad, fields, from, to }) {
		const unformattedInsights = rootGetters['facebookAds/getUnformattedInsights']

		if (
			typeof unformattedInsights[ad.id] === 'undefined' ||
			unformattedInsights[ad.id].from !== from ||
			unformattedInsights[ad.id].to !== to )
		{
			const service = rootGetters['facebookAds/getService']
			const data = await service.adInsights(ad.id, { from, to, fields })
			unformattedInsights[ad.id] = {
				data: data,
				fields: fields,
				from: from,
				to: to
			};

			commit('setUnformattedInsights', unformattedInsights);
		}

		const filteredInsight = {
			'clicks': 0,
			'reach': 0,
			'impressions': 0,
			'spend': 0,
			'cost_per_inline_link_click': 0,
			'action_values': [],
			'outbound_clicks': [],
			'outbound_clicks_ctr': [],
		};

		const insights = await dispatch('formatInsights', { unformattedInsight: unformattedInsights[ad.id], filteredInsight: filteredInsight } )
		return { ...ad, insights }
	},

	/**
	 * Fetch preview
	 *
	 * @param rootGetters
	 * @param commit
	 * @param state
	 * @param status
	 * @returns {Promise<void>}
	 */
	async setAdPreview({ rootGetters, commit, state }, status) {
		const service = rootGetters['facebookAds/getService']

		const selectedAdId = state.selected[status].ad.id

		const data = await service.previews(selectedAdId)

		if (data.previews.length > 0) {
			commit('setSelected', {
				type: 'preview',
				status,
				object: data.previews[0].body
			})
		}
	},

	//=========> ACCOUNT
	async accountInsights({ rootGetters, dispatch, state, commit }) {
		const options = {
			fields: state[state.filter].fields,
			from: rootGetters['datepicker/getFormatted']('from'),
			to: rootGetters['datepicker/getFormatted']('to')
		}

		const service = rootGetters['facebookAds/getService']
		const data = await service.accountInsights(options)

		if (!state.webshopData) {
			if (!Array.isArray(data.insights) && data.insights.action_values) {
				commit('setWebshopData', true)
			}
		}

		return await dispatch('iterateData', { insights: data.insights, fields: options.fields })
	},

	//=========> ACCOUNT
	async allCampaignsTotals({ rootGetters, dispatch, state, commit }) {
		const integrationId = rootGetters['facebookAds/getIntegrationId']
		const unformattedInsights = rootGetters['facebookAds/getUnformattedInsights']

		const options = {
			fields: state[state.filter].fields,
			from: rootGetters['datepicker/getFormatted']('from'),
			to: rootGetters['datepicker/getFormatted']('to'),
			cache: '1hour',
			id: integrationId
		}

		let campaignsTotalData, accountData;

		if (
			typeof unformattedInsights[options.id] === 'undefined' ||
			unformattedInsights[options.id].from !== options.from ||
			unformattedInsights[options.id].to !== options.to )
		{
			const service = rootGetters['facebookAds/getService']

			campaignsTotalData = await service.campaignsTotals(options)
			accountData = await service.accountInsights(options)

			campaignsTotalData.insights.map((insight) => {
				const accountDataInsight = Object.values(accountData.insights).find(item =>
					item.publisher_platform === insight.publisher_platform
				);

				if (accountDataInsight) {
					insight.reach = parseInt(accountDataInsight.reach)
					insight.cost_per_inline_link_click = accountDataInsight.cost_per_inline_link_click
				}
			});

			unformattedInsights[options.id] = {
				data: campaignsTotalData,
				fields: options.fields,
				from: options.from,
				to: options.to
			};

			commit('setUnformattedInsights', unformattedInsights);
		}

		const insights = await dispatch('formatInsights', { unformattedInsight: unformattedInsights[options.id], filteredInsight: {} });

		if (!state.webshopData) {
			const hasShopData = !Array.isArray(unformattedInsights[options.id].data.insights) && unformattedInsights[options.id].data.insights.action_values.length > 0;
			commit('setWebshopData', hasShopData);
		}

		return insights
	},

	//=========> MISC
	/**
	 * Reset selection, either one specific or all, if none are set
	 *
	 * @param commit
	 * @param selected
	 * @param status
	 */
	resetSelected({ commit }, { selected, status }) {

		let reset = [STATUS_ACTIVE, STATUS_PAUSED]
		let resetSelected = ['campaign', 'adSet', 'ad']

		commit('setWebshopData', false)

		if (status) {
			reset = [status]
		}

		if (selected) {
			resetSelected = !Array.isArray(selected) ? [selected] : selected
		}

		if (selected === 'ad') {
			resetSelected.push('adSet')
		}

		reset.forEach((_status) => {
			resetSelected.forEach(type => {
				commit('setSelected', { type, status: _status, object: null })
			})
		})
	},

	/**
	 * Set selected service
	 *
	 * @param commit
	 * @param payload
	 */
	setService({ commit }, payload) {
		commit(`setService`, payload)
	},

	checkForWebShopData() {

	},

	/**
	 * Set selected campaign, ad set or ad
	 *
	 * @param commit
	 * @param payload
	 */
	select({ commit }, payload) {
		commit(`setSelected`, payload)
	},

	/**
	 * Iterate through gathered insights
	 *
	 * @param dispatch
	 * @param insights
	 * @param fields
	 * @returns {Promise<{}>}
	 */
	async iterateData({ dispatch, state }, { insights, fields }) {
		const object = {}

		if (!state.accountCurrency && insights.account_currency) {
			await dispatch('formatData', { value: insights.account_currency, key: 'account_currency' })
		}

		for (const key of Object.keys(insights)) {
			if (! insights[key] ||
				['account_currency', 'date_stop', 'date_start'].includes(key)
			) {
				continue
			}

			let value = insights[key]

			switch (key) {
				case 'actions':
					value.forEach(action => {
						object[`action_${action.action_type}`] = action.value
					})

					const purchase = value.find(v => v.action_type.includes('purchase'))

					value = purchase ? purchase.value : '-'
					object.purchase = await dispatch('formatData', { value, key })
					break

				case 'action_values':
					const pixel = [...value].filter(v => v.action_type.includes('pixel')).map(item => {
						item.key = item.action_type
							.replace('offsite_conversion.fb_pixel_', '')
							.replace('purchase', 'purchase_value')

						delete item.action_type
						return item
					})

					for (const item of pixel) {
						object[item.key] = await dispatch('formatData', item)
					}
					break

				case 'cost_per_inline_link_click':
					const calc = insights.clicks > 0
						? parseFloat(insights.spend || 0) /  parseFloat(insights.clicks || 0)
						: 0

					object[key] = await dispatch('formatData', { value: calc, key })
					break

				default:
					if (value[0] instanceof Object) {
						value = value[0].value
					}

					object[key] = await dispatch('formatData', { value, key })
					break
			}
		}

		return object
	},

	/**
	 * Format to human readable data
	 *
	 * @param commit
	 * @param state
	 * @param value
	 * @param key
	 * @returns {string|*}
	 */
	formatData({ commit, state }, { value, key }) {
		if (key === 'account_currency' && !state.accountCurrency) {

			commit('setAccountCurrency', value.toString())

			return value.toString()

		} else {

			const filters = this.$app.$options.filters
			const format = FORMATS[key]

			if(key === 'purchase_value'){
				return filters.numberFormat(parseFloat(value).toFixed(2)) + `<span style="font-size:12px;" class="accountCurrency">${state.accountCurrency}</span>`
			}

			if(key === 'outbound_clicks'){
				return value.toLocaleString()
			}

			if(key === 'reach'){
				return value.toLocaleString()
			}

			if(key === 'impressions'){
				return value.toLocaleString()
			}

			switch (format) {
				case 'money':
					return filters.numberFormat(parseFloat(value).toFixed(2)) + `<span style="font-size:12px;" class="accountCurrency">${state.accountCurrency}</span>`

				case 'integer':
					return filters.numberFormat(parseFloat(value).toFixed(2))

				case 'float':
					return filters.numberFormat(parseFloat(value).toFixed(1))

				case 'percentage':
					return filters.numberFormat(parseFloat(value).toFixed(2)) + '%'

				default:
					return value.toLocaleString()
			}
		}
	},

	/**
	 * Router for insights loading when selecting
	 *
	 * @param dispatch
	 * @param state
	 * @param rootGetters
	 * @param campaign
	 * @param ad
	 * @param adSet
	 * @param status
	 * @returns {*}
	 */ async loadInsights({dispatch, state, rootGetters}, {campaign, ad, adSet, status}) {
		const payload = {
			fields: state[state.filter].fields,
			from: rootGetters['datepicker/getFormatted']('from'),
			to: rootGetters['datepicker/getFormatted']('to')
		}

		if (campaign) {
			return await dispatch('loadCampaignInsights', {campaign, ...payload})
		}
		if (adSet) {
			return dispatch('loadAdSetInsights', {adSet, ...payload})
		}
		if (ad) {
			return dispatch('loadAdInsights', {ad, ...payload})
		}
	},

	changeFilter({ dispatch, commit }, filter) {
		commit('setFilter', filter)
	},

	initializeTabs({ dispatch, rootGetters, commit }, filter) {
		const sources = {
			audience_network: {
				label: 'Audience network',
				slug: 'audience_network',
				color: $org('colors.trafficSources.adwords'),
				selected: false
			},
			facebook: {
				label: 'Facebook',
				slug: 'facebook',
				color: $org('colors.trafficSources.facebook'),
				selected: true
			},
			messenger: {
				label: 'Messenger',
				slug: 'messenger',
				color: $org('colors.trafficSources.organic'),
				selected: false
			},
			unknown: {
				label: 'Unknown',
				slug: 'unknown',
				color: $org('colors.trafficSources.direct'),
				selected: false
			}
		}

		this.visibleMetrics = Object.values(sources).filter(source => source.selected).map(source => source.slug)

		eventHub.$emit('topbar.filterGroups.push', {
			title: $t('conversions.sources'),
			slug: 'sources',
			multiSelect: true,
			metrics: Object.values(sources).map(network => {
				return {
					label: network.label,
					slug: network.slug,
					color: network.color,
					selected: network.selected ? 1 : 0
				}
			})
		})

		eventHub.$on('topbar.filterGroups.sources.metricsChanged', payload => {
			this.visibleMetrics = Object.keys(payload.metrics).filter(metricKey => payload.metrics[metricKey]);
		})
	},

	async formatInsights({ dispatch, rootGetters, commit }, { unformattedInsight, filteredInsight }) {

		const usePredefinedKeys = Object.keys(filteredInsight).length === 0;
		const data = unformattedInsight.data;
		const fields = unformattedInsight.fields;

		Object.values(data.insights).forEach((insight) => {
			if (!this.visibleMetrics.includes(insight['publisher_platform'])) {
				return;
			}

			const keys = Object.keys(insight);

			for (let i = 0; i < keys.length; i++) {
				const key = keys[i];
				const value = insight[key];

				if (key === 'account_currency') {
					filteredInsight[key] = value;
					continue;
				}

				if (!filteredInsight.hasOwnProperty(key)) {
					if (usePredefinedKeys) {
						filteredInsight[key] = value;
					}
					continue;
				}

				if (Array.isArray(value)) {
					value.forEach((action) => {
						filteredInsight[key].push(action);
					});
					continue;
				}

				filteredInsight[key] = parseFloat(filteredInsight[key] || 0) + parseFloat(value || 0);
			}
		});

		return await dispatch('iterateData', {insights: filteredInsight, fields})
	},

	async formatCampaignsInsights({ dispatch, rootGetters, commit }, { data }) {

		const currentPeriod = data.currentPeriod;
		const lastPeriod = data.lastPeriod;

		const result = {
			'total': [],
			'data': [],
			'currentPeriod_from': currentPeriod.currentPeriodFromDate,
			'currentPeriod_to': currentPeriod.currentPeriodToDate,
			'lastPeriod_from': lastPeriod.lastPeriodFromDate,
			'lastPeriod_to':  lastPeriod.lastPeriodToDate,
		};

		delete currentPeriod.currentPeriodFromDate;
		delete currentPeriod.currentPeriodToDate;
		delete lastPeriod.lastPeriodFromDate;
		delete lastPeriod.lastPeriodToDate;

		let formattedCurrentPeriod, formattedLastPeriod;

		const getFormattedVersion = (period) => {
			const formattedPeriod = [];

			for (const visibleMetric of this.visibleMetrics) {
				const element = Object.values(period).find(item => item.publisher_platform === visibleMetric);
				delete element.publisher_platform;

				Object.keys(element).map((key) => {
					if (key === 'account_currency') {
						formattedPeriod[key] = element[key];
					} else {
						const item = formattedPeriod[key] ?? 0;
						formattedPeriod[key] =  parseFloat(item || 0) + parseFloat(element[key] || 0);
					}
				})
			}

			return formattedPeriod;
		}

		formattedCurrentPeriod = getFormattedVersion(currentPeriod);
		formattedLastPeriod = getFormattedVersion(lastPeriod);

		const commonKeys = Object.keys(formattedCurrentPeriod).filter(key => formattedLastPeriod.hasOwnProperty(key));

		for (const key of commonKeys) {

			const diff = ((formattedCurrentPeriod[key] - formattedLastPeriod[key]) / formattedLastPeriod[key] * 100);
			const diffVal = formattedLastPeriod[key] ? diff : null;

			result['data'][key] = {
				'name': key,
				'currentPeriod': formattedCurrentPeriod[key],
				'lastPeriod': formattedLastPeriod[key],
				'change': diffVal,
				'formatted': diffVal ? (diffVal.toFixed(1) + '%') : null
			};
		}

		return result;
	}
}

export default {
	namespaced: true,
	state: getDefaultState(),
	getters,
	mutations,
	actions
}

const BaseService = require('@/services/BaseService')

const createAccount = (id, cb, errorCb) => {
  BaseService.post(`customers/${id}/freespee/accounts`, {}, cb, errorCb)
}

const javascript = (id, cb, errorCb) => {
  BaseService.get(`customers/${id}/freespee/accounts/javascript`, {}, cb, errorCb)
}

const hasFreespeeScript = (id, cb, errorCb) => {
  BaseService.get(`customers/${id}/freespee/hasFreespeeScript`, {}, cb, errorCb)
}

const campaigns = (id, cb, errorCb) => {
  BaseService.get(`customers/${id}/freespee/campaigns`, {}, cb, errorCb)
}

const numbers = (id, cb, errorCb) => {
  BaseService.get(`customers/${id}/freespee/numbers`, {}, cb, errorCb)
}

const createNumber = (id, options, cb, errorCb) => {
  BaseService.post(`customers/${id}/freespee/numbers`, options, cb, errorCb)
}

const removeNumber = (id, numberId, cb, errorCb) => {
  BaseService.remove(`customers/${id}/frespee/numbers/${numberId}`, {}, cb, errorCb)
}

export {
  createAccount,
  javascript,
  campaigns,
  numbers,
  createNumber,
  removeNumber,
  hasFreespeeScript,
}

export default [
  {
    path: '/services',
    name: 'frontend.services',
    component: () => import(/* webpackChunkName: "Services" */ './Services'),
    meta: {
      headline: () => $t('navigation.services'),
      description: () => $t('topBar.descriptions.services'),
      tracking: 'Services - Page view - Main page'
    }
  },
]

export const NODE_ENV = process.env.NODE_ENV;
export const DEBUG_MODE = !!process.env.VUE_APP_DEBUG_MODE;
export const DEV_TOOLS = !!process.env.VUE_APP_DEV_TOOLS;
export const MIXPANEL = process.env.VUE_APP_MIXPANEL;
export const DEV_MODE = process.env.VUE_APP_DEV_MODE || "local";
export const I18N_FALLBACK_LOCALE = process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en';
export const I18N_LOCALE = process.env.VUE_APP_I18N_LOCALE || 'da';
export const I18N_LOCALE_CODE = process.env.VUE_APP_I18N_LOCALE || 'da_DK';

// Required config vars
export const GOOGLE_MAPS_API = process.env.VUE_APP_GOOGLE_MAPS_API;
export const API_URL = process.env.VUE_APP_API_URL;
export const BUGSNAG_KEY = process.env.VUE_APP_BUGSNAG_KEY;

// TOKENS
export const LINKEDIN_ACCESS_TOKEN = process.env.VUE_APP_LINKEDIN_ACCESS_TOKEN

// Check if required config variables are present
if (!API_URL) {
  throw "Config error: Api URL not present in environment files";
}
if (!BUGSNAG_KEY) {
  throw "Config error: Bugsnag KEY not present in environment files";
}
if (!GOOGLE_MAPS_API) {
  throw "Config error: Google Maps Api Key not present in environment files";
}

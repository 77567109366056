import moment from 'moment'

const getDefaultState = () => {
  return {
    from: moment().subtract(30, 'day'),
    to: moment(),
    interval: 'day',
    showComparisonToggleButton: false,
    showComparison: false,
    toggleButtonDisabled: true
  }
}

const getters = {
  getFrom: state => state.from,
  getTo: state => state.to,
  getFormatted (state) {
    return (date, format = 'YYYY-MM-DD') => state[date].format(format)
  },
  getInterval: state => state.interval,
  getRange: state => `${state.to.format('YYYY-MM-DD')} - ${state.from.format('YYYY-MM-DD')}`,
  getShowComparisonToggleButton: state => state.showComparisonToggleButton,
  getShowComparison: state => state.showComparison,
}

const mutations = {
  resetState (state) {
    Object.assign(state, getDefaultState())
  },

  setDates (state, { from, to }) {
    state.from = from
    state.to = to
  },

  setInterval (state, interval) {
    state.interval = interval
  },
  setShowComparisonToggleButton(state, showComparisonToggleButton){
    state.showComparisonToggleButton = showComparisonToggleButton
  },
  setShowComparison(state, showComparison){
    state.showComparison = showComparison
  },
}

const actions = {
  setDates ({ commit }, { from, to }) {
    commit('setDates', { from, to })

    // Set range
    const days = to.diff(from, 'days')

    let interval = 'day'

    if (days <= 56) {
      interval = 'day'
    } else if (days <= 210) {
      interval = 'week'
    } else if (days <= 2555) {
      interval = 'month'
    } else {
      interval = 'year'
    }

    commit('setInterval', interval)
  },

  setInterval ({ commit }, payload) {
    commit('setInterval', payload.interval)
  },

  reset ({ dispatch }) {
    dispatch('setDates', {
      from: moment().subtract(30, 'day'),
      to: moment()
    })
  }
}

export default {
  namespaced: true,
  state: getDefaultState(),
  getters,
  mutations,
  actions
}

import { mapGetters } from 'vuex'

export default {
	computed: {
		...mapGetters('identity', {
			billingFeatures: 'getFeatures'
		})
	},

	methods: {
		featuresHas(slug) {
			return this.billingFeatures.filter(feature => feature.slug === slug).length > 0
		},

		featuresMax(slug) {
			return this.billingFeatures.reduce(
				(carry, feature) => {
					if (feature.slug !== slug) {
						return carry
					}

					return Number(feature.value) > carry
						? Number(feature.value)
						: carry
				},
				0
			)
		}
	}
}

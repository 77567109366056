import store from '@/store'

export default [
  {
    path: '/oauth/:provider',
    name: 'oauth',
    component: () => import(/* webpackChunkName: "OAuth" */ './pages/OAuth'),
    meta: {
      guest: true,
      tracking: 'Auth - Page view - Oauth',
      allowAccessBeforeOnboarding: true
    }
  },
  {
    path: '/logout',
    name: 'logout',
    async beforeEnter(to, from, next) {
      await store.dispatch('auth/logout')
      next({ name: 'login', query: { ...to.query } })
    }
  },
  {
    path: '/ghost-logout',
    name: 'ghost-logout',
    async beforeEnter(to, from, next) {
      const route = await store.dispatch('ghost/logoutAsGhost')
      next(route)
    }
  },
  {
    path: '/auth',
    name: 'auth',
    component: () => import(/* webpackChunkName: "Auth" */ './Auth'),
    meta: {
      guest: true
    },
    children: [
      {
        path: '/login',
        name: 'login',
        component: () => import(/* webpackChunkName: "Auth" */ './pages/Login')
      },
      {
        path: '/forgot-password',
        name: 'forgot-password',
        component: () => import(/* webpackChunkName: "Auth" */ './pages/ForgotPassword'),
        meta: {
          tracking: 'Auth - Page view - Forgot password',
          allowAccessBeforeOnboarding: true
        }
      },
      {
        path: '/password/reset/:token',
        name: 'reset-password',
        component: () => import(/* webpackChunkName: "Auth" */ './pages/ResetPassword'),
        meta: {
          tracking: 'Auth - Page view - Reset password',
          allowAccessBeforeOnboarding: true
        }
      },
      {
        path: '/sign-up',
        name: 'register',
        component: () => import(/* webpackChunkName: "Auth" */ './pages/Register'),
        meta: {
          tracking: 'Auth - Page view - Sign up',
          allowAccessBeforeOnboarding: true
        }
      }
    ]
  }
]

<template>
    <span class="tag label" :class="labelClass">
        <span class="icon-wrapper" v-if="icon">
            <span class="icon" :class="icon" />
        </span>

          <span>{{text}}</span>

          <a @click="remove"><i class="remove fa fa-fw fa-remove" /></a>
    </span>
</template>

<style lang="scss" scoped>
  .tag {
      display: inline-block;
      float: left;
      font-size: 14px;
      padding: 7px 10px;
      margin: 0 5px 5px 0;

      &.label-info {
          background: #f9f9f9;
          color: #333333;
          i {
              color: #333333;
          }
      }

      .icon-wrapper {
            display: inline-block;

            .icon {
                float: left;
                font-size: 150%;
                line-height: 10px;

                &.fa {
                    font-size: 140%;
                    line-height: 8px;
                }
            }
      }

      .remove {
          top: 0;
      }

      a {
          color: #ffffff;
          cursor: pointer;
          opacity: 0.8;
          margin: 0 0 0 .3em;

          &:hover {
              opacity: 1.0
          }
      }
  }
</style>

<script>
export default {
    props: {
        icon: {
            type: String,
            required: false
        },

        text: {
            type: String,
            required: true
        },

        type: {
            type: String,
            required: true
        }
    },

    computed: {
        labelClass() {
            return `label-${this.type}`
        }
    },

    methods: {
        remove() {
            this.$emit('remove')
        }
    }
}
</script>

<template>
  <div class="element">
    <div class="row" v-if="!hasLoaded" style="min-height: 355px;">
      <div class="col-lg-12">
        <logo-loader :height="355"/>
      </div>
    </div>

    <template v-if="hasLoaded">
      <div class="row">
        <div class="col-lg-12">
          <button class="create-user-btn btn pull-right"
                  :style="{backgroundColor:$org('colors.standard.primary.hex')}"
                  v-if="users.length"
                  @click="create">
            {{$t('users.backend.createUser')}}
          </button>
        </div>

        <div class="col-lg-12 error-container" v-if="error">
          <transition name="fade">
            <warning type="error">
              <p>{{error | capitalize}}</p>
            </warning>
          </transition>
        </div>

        <div class="col-md-12 text-center" v-if="!users.length">
          {{$t('users.backend.noUser')}}<br/>
          <br/>

          <button class="btn btn-success btn-round" @click="create">
            {{$t('users.backend.createUser')}}
          </button>
        </div>

        <div class="col-xxl-12 col-lg-6 col-md-12 col-sm-12 col-xs-12 users" v-for="(user, index) in users" :key="index">
          <card>
            <div class="pull-left info">
              <span class="icon pe-7s-user pull-left"></span>

              <!-- STANDARD - User Email -->
              <div class="pull-left" v-if="!userBeingEdited[user.id].isEditing">
                <span class="text-muted">{{$t('users.backend.email')}}</span><br/>
                <span>{{user.email}}</span>

                <a @click.prevent="signIn(user.email)">{{$t('users.backend.login')}}</a>
                <a @click.prevent="userBeingEdited[user.id].isEditing = true">{{$t('users.backend.edit')}}</a>
                <a v-if="user.magicLink" v-clipboard="user.magicLink"
                   @success="onCopy">Magic link</a>
              </div>

              <!-- BEING EDITED - User Email -->
              <div class="pull-left" v-else>
                <span class="text-muted">{{$t('users.backend.email')}}</span><br/>

                <input class="email-editable" v-model="mailChangeInput" :placeholder="$t('users.backend.changeEmail')"/>

                <a @click.prevent="updateUserEmail(user)">{{$t('users.backend.accept')}}</a>
                <a @click.prevent="userBeingEdited[user.id].isEditing = false">{{$t('users.backend.reset')}}</a>
              </div>

              <div class="clearfix"></div>
            </div>

            <div class="pull-right action hidden-xs" @click="remove(index)">
              <span class="icon pe-7s-trash"></span>
            </div>

            <div class="clearfix"></div>

            <hr/>

            <div class="row">
              <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">

                <div class="content">

                  <span class="headline text-muted">{{$t('users.backend.password')}}</span>

                  <div class="password">
                    <span class="preview">********</span>

                    <a class="reset" @click="reset(user)">{{$t('users.backend.resetPassword')}}</a>

                    <span class="pe-7s-mail mail-sent" v-if="isSendingEmailTo(user)"></span>
                  </div>
                </div>

              </div>
              <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                <div class="content">
                  <span class="headline text-muted">{{$t('users.backend.language')}}</span>

                  <multiselect v-if="languages.length"
                               v-model="userBeingEdited[user.id].language"
                               :options="languages"
                               track-by="label"
                               label="label"
                               :id="user.id"
                               @select="updateUserLanguage"
                               :close-on-select="true"
                               :allowEmpty="false"
                               :show-labels="false"
                               :placeholder="$t('profile.language.choose')"
                               :selectLabel="$t('multiSelect.selectLabel')">
                    <p slot="noResult">{{ $t('multiSelect.noResultsFound') }}</p>
                  </multiselect>
                </div>
              </div>
            </div>

            <hr style="margin: 20px -30px;"/>

            <a @click.prevent="showUserNotificationSettings(index)">
              <template v-if="visibleUserNotifications.includes(index)">
                {{$t('users.backend.notifications.hide')}}
              </template>
              <template v-else>{{$t('users.backend.notifications.show')}}</template>
            </a>

            <notification-center-backend :user="user" v-if="visibleUserNotifications.includes(index)"/>
          </card>
        </div>
      </div>
    </template>

    <modal ref="create-user" title="Opret en ny bruger">
      <user-create @created="created" :customerId="customerId"></user-create>
    </modal>
  </div>
</template>

<style lang="scss" scoped>
  @import '~@/assets/scss/_vars.scss';

  @media screen and (min-width: 1200px) and (max-width: 1530px) {
    .col-xxl-12 {
      width: 100% !important;
    }
  }

  .element {
    .create-user-btn {
      margin-bottom: 15px;
      cursor: pointer;
      color: #fff;
      border: none;
      opacity: 1;
      border-radius:2px;
      padding:10px 30px;
      transition: all 0.2s ease;

      &:hover {
        opacity: 0.85;
      }
    }

    a {
      color: #747474;
      font-size: 10pt;
      text-decoration: underline;
      margin-left: 5px;
    }

    .info {
      margin-bottom: 30px;

      .icon {
        font-size: 32pt;
        margin-right: 10px;
      }
    }

    .users {
      > .card {
        overflow: hidden;
      }
    }

    .action {
      .icon {
        cursor: pointer;
        color: #747474;
        font-size: 20pt;
        margin: 8px 0;
      }
    }

    .content {
      .headline {
        display: block;
        margin-bottom: 10px;
      }

      .password {
        .preview {
          float: left;

          font-size: 16pt;
          letter-spacing: 6px;
        }

        a {
          float: left;

          font-size: 10pt;
          letter-spacing: initial;
          margin: 3px;
        }
      }
    }

    .email-editable {
      padding: 10px;
      border-radius: 5px;
      border: 1px solid #e8e8e8;
      margin-bottom: 5px;
      margin-top: 5px;
      width: 100%;
    }

    .mail-sent {
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      text-align: center;
      animation-name: mail-sent;
      animation-duration: 2s;
      animation-iteration-count: 1;
      font-size: 1pt;
    }

    @keyframes mail-sent {
      0% {
        font-size: 1pt;
        margin-top: 0;
        left: 0;
      }

      60% {
        font-size: 100pt;
        margin-top: -80px;
        left: 0;
      }

      99% {
        left: 200%;
      }
    }
  }
</style>

<script>
  import LogoLoader from '@/app/shared/components/LogoLoader'
  import Card from '@/app/shared/components/Card'
  import Modal from '@/app/layout/components/Modal'
  import UserCreate from '@/app/users/components/Create'
  import Warning from '@/app/shared/global/warnings/Warning'

  import NotificationCenterBackend from '@/app/users/components/NotificationCenterBackend'
  import {mapActions, mapGetters} from 'vuex'

  const OrganizationService = require('@/services/customers/OrganizationService')
  const ClientService = require('@/services/users/ClientService')
  const UserService = require('@/services/users/UserService')
  const LanguageService = require('@/services/i18n/LanguageService')

  export default {
    data() {
      return {
        hasLoaded: false,
        users: [],
        customerId: null,
        sendingMailTo: null,
        visibleUserNotifications: [],
        languages: [],
        userBeingEdited: {},
        mailChangeInput: '',
        error: null,
        organizations: []
      }
    },

    mounted() {
      this.customerId = this.$route.params.id

      this.loadLanguages()
      this.loadOrganizations()
    },

    computed: mapGetters('identity', {
      identity: 'getIdentity'
    }),

    methods: {
      ...mapActions("ghost", ["logInAsGhost"]),

      async getMagicLink(user_id) {
        const {data} = await axios.get(`/system/get-magic-link/${user_id}`)
        return data
      },

      onCopy (e) {
        this.$swal({
          text: 'Magic link kopieret',
          type: 'success'
        })
      },

      load() {
        this.hasLoaded = false
        this.users = []

        const options = {
          includes: ['notificationSubscriptions', 'language'],
          filter_groups: [
            {
              filters: [
                {
                  key: 'customers',
                  operator: 'ct',
                  value: this.customerId
                },
                {
                  key: 'temporary',
                  operator: 'eq',
                  value: false
                }
              ]
            }
          ]
        }

        ClientService.findAll(options, response => {
          const body = response.data

          const users = body.rows

          body.rows.forEach(user => {
            this.$set(this.userBeingEdited, user.id, {})
            this.$set(this.userBeingEdited[user.id], 'isEditing', false)
            this.$set(this.userBeingEdited[user.id], 'language', {
              label: user.language.name,
              value: user.language.id
            })
          })

          users.forEach(async (user) => {
            if(this.identity.role === 'employee-super-admin') {
			  const magicLink = await this.getMagicLink(user.id)

              user.magicLink = `${magicLink}&customer=${this.customerId}`
            }
            this.users.push(user)
          })


          this.hasLoaded = true
        })
      },

      loadLanguages() {
        LanguageService.findAll({}, response => {
          const body = response.data
          this.languages = body.rows.map(language => {
            return {
              label: language.name,
              value: language.id
            }
          })

          this.load()
        }, error => {
          this.load()
        })
      },

      loadOrganizations() {
        OrganizationService.findAll({}, ({data}) => {
          this.organizations = data.rows
        }, err => {
        })
      },

      remove(index) {
        let user = this.users[index]
        let deletedUserId = user.id

        this.$swal({
          text: $t('users.backend.delete.areYouSure'),
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: $t('users.backend.delete.confirm'),
          cancelButtonText: $t('users.backend.delete.regret')
        }).then(() => {
          UserService.remove(user.id, () => {
            this.users.filter(user => user.id !== deletedUserId)
          })
        })
      },

      create() {
        if (!this.$refs['create-user'].$el) {
          setTimeout(this.create, 200)
        }

        jQuery(this.$refs['create-user'].$el).modal('show')
      },

      created() {
        if (!this.$refs['create-user'].$el) {
          setTimeout(this.created, 200)
        }

        jQuery(this.$refs['create-user'].$el).modal('hide')

        this.load()
      },

      async signIn(email) {
        await this.logInAsGhost({email})

        this.$router.push({path: '/'})
      },

      reset(user, index) {
        this.$swal({
          text: 'Vil du nulstille og gensende velkomst e-mailen?',
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Ja',
          cancelButtonText: 'Fortryd'
        }).then(() => {
          this.sendingMailTo = index

          setTimeout(() => {
            this.sendingMailTo = null
          }, 3000)

          ClientService.reset(user.id, response => {
          })
        })
      },

      isSendingEmailTo(user) {
        return this.sendingMailTo === user
      },

      showUserNotificationSettings(index) {
        !this.visibleUserNotifications.includes(index) ?
          this.visibleUserNotifications.push(index) :
          this.visibleUserNotifications = this.visibleUserNotifications.filter(user => user !== index)
      },

      updateUserEmail(user) {
        UserService.put(user.id, {
          email: this.mailChangeInput
        }, response => {
          this.userBeingEdited[user.id].isEditing = false
          eventHub.$emit('user.email.changed')
        }, error => {
          const response = error.response
          const data = response.data

          if (response.status === 422) {
            const errors = data.errors[0].title
            const keys = Object.keys(errors)

            this.error = data.errors[0].title[keys[0]][0]
          }

          this.userBeingEdited[user.id].isEditing = false
        })
      },

      updateUserLanguage(selectedOption, userId) {
        UserService.put(userId, {
          language: selectedOption.value
        }, response => {
          this.load()
        })
      },

      updateUserOrganization(selectedOption, userId) {
        UserService.put(userId, {
          organization: selectedOption.value
        }, response => {
          this.load()
        })
      }
    },
    components: {
      LogoLoader,
      Card,
      Modal,
      UserCreate,
      NotificationCenterBackend,
      Warning
    }
  }
</script>

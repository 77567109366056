<template>
	<div class="create-backend-keywords">
		<h2>Create bulk keywords</h2>

		<div class="keywords">
			<textarea v-for="n in internalKeywords.length"
					  :key="`keyword-${n}`"
					  class="form-control"
					  :class="{ occupied: isOccupied(internalKeywords[n - 1]) }"
					  placeholder="Type a new keyword"
					  :value="internalKeywords[n - 1]"
					  @keyup="$event => onUpdate(n - 1, $event.target.value)"
			/>
		</div>

		<h3>Location</h3>

		<multiselect v-model="location"
					 :customLabel="locationLabel"
					 track-by="id"
					 :placeholder="$t('seo.createKeywords.chooseLocation')"
					 open-direction="bottom"
					 :options="locations"
					 :searchable="true"
					 selectedLabel=""
					 :loading="isLoadingLocations"
					 :internal-search="false"
					 :clear-on-select="true"
					 :close-on-select="true"
					 :max-height="300"
					 :show-no-results="false"
					 :allow-empty="false"
					 :deselect-label="$t('seo.createKeywords.mustChooseLocation')"
					 :select-label="$t('seo.createKeywords.chooseLocation')"
					 @search-change="searchLocations">

			<template slot="option" slot-scope="props">
				<span class="badge__name">{{locationLabel(props.option)}}</span>
				<span class="badge__type">{{$t('seo.createKeywords.locationType.' + props.option.type.toLowerCase())}}</span>
			</template>

			<template slot="clear" slot-scope="props">
				<div class="multiselect__clear" v-if="location" @mousedown.prevent.stop="clearLocation()"></div>
			</template>

			<template slot="noResult">
                    <span v-if="isLoadingLocations">
                        {{$t('seo.createKeywords.loadingLocations')}}
                        <loader :inline="true" />
                    </span>

				<span v-else>{{$t('seo.createKeywords.noLocationsFound')}}</span>
			</template>
		</multiselect>

		<button class="btn"
				@click="onSubmit"
				:disabled="! canSubmit"
				:style="organizationColor" >
			<template v-if="!isSaving">
				{{$t('seo.createKeywords.createSelected')}}
			</template>

			<template v-else>
				{{$t('seo.createKeywords.saving')}} <loader :inline="true" />
			</template>
		</button>
	</div>
</template>

<style lang="scss" scoped>
.create-backend-keywords {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 10px;

	> div.keywords {
		display: flex;
		flex-direction: column;
		gap: 5px;

		> textarea {
			width: 300px;
			height: 40px;
			max-width: calc(100vw - 30px);

			&.occupied {
				color: #c03131;
				border-color: #c03131;
			}
		}
	}

	h2 {
		color: #000;
		font-size: 18px;
		font-weight: 500;
		margin: 0;
		padding: 0;
	}

	h3 {
		color: #343434;
		font-size: 14px;
		font-weight: 500;
		margin: 0;
		padding: 0;
	}
}
</style>

<script>
const LocationService = require('@/services/seo/LocationService')
const KeywordService = require('@/services/seo/KeywordService')

export default {
	props: {
		keywords: {
			type: Array,
			required: true
		},

		customerId: {
			required: true
		}
	},

	data: () => ({
		internalKeywords: [''],
		location: null,
		locations: [],
		isLoadingLocations: false,
		isSaving: false
	}),

	computed: {
		canSubmit() {
			return this.nonOccupiedKeywords.length > 0 && !! this.location && ! this.isSaving
		},

		organizationColor() {
			return {
				color: $org('colors.standard.button.outlined'),
				border: `1px solid ${$org('colors.standard.button.outlined')}`
			}
		},

		nonOccupiedKeywords() {
			return this.internalKeywords.slice(0, -1).filter(keyword => ! this.isOccupied(keyword))
		}
	},

	methods: {
		onSubmit() {
			if (! this.canSubmit) {
				return
			}

			this.isSaving = true

			const promises = this.nonOccupiedKeywords.map(keyword => {
				return new Promise((resolve, reject) => {
					const options = {
						keyword,
						location: this.location.id,
						customerId: this.customerId
					}

					KeywordService.create(options, response => {
						eventHub.$emit('keyword.created', response.data)
						resolve()
					}, error => {
						resolve()
					})
				})
			})

			Promise.all(promises).then(() => {
				this.internalKeywords = ['']
				this.location = null

				this.isSaving = false
			})
		},

		isOccupied(keyword) {
			return this.keywords.find(item => String(item.keyword).toLocaleLowerCase() === String(keyword).toLocaleLowerCase())
		},

		onUpdate(index, value) {
			const parts = value.split('\n')

			let copy = JSON.parse(JSON.stringify(this.internalKeywords))
			copy[index] = parts[0]

			// Check for multi keywords

			if (parts.length > 1) {
				copy.splice(index + 1, 0, ...parts.slice(1))
			}

			this.internalKeywords = [
				...copy.filter(item => String(item || '').length > 0),
				''
			]
		},

		searchLocations(query) {
			this.cachedQuery = query

			if (query.length < 3) {
				this.isLoadingLocations = false
				return
			}

			this.isLoadingLocations = true;

			(queryParameter => {
				setTimeout(() => {
					if (queryParameter !== this.cachedQuery) {
						return;
					}

					const filters = []

					query.split(' ').forEach(queryWord => {
						filters.push({
							key: 'nameCanonical',
							operator: 'ct',
							value: queryWord
						})
					})

					this.locations = []

					LocationService.findAll({
						limit: 3,

						filter_groups: [
							{
								filters: filters
							},
							{
								filters: [
									{
										key: 'type',
										operator: 'eq',
										value: 'Country'
									}
								]
							}
						]
					}, (response) => {
						const data = response.data

						let locations = data.rows

						LocationService.findAll({
							limit: 7,

							filter_groups: [
								{
									filters: filters
								},
								{
									filters: [
										{
											key: 'type',
											operator: 'eq',
											value: 'Country',
											not: true
										}
									]
								}
							]
						}, (response) => {
							const data = response.data

							data.rows.forEach(location => {
								locations.push(location)
							})

							this.locations = locations
							this.isLoadingLocations = false
						})
					})
				}, 800)
			})(query)
		},

		clearLocation () {
			this.location = null
		},

		locationLabel(location) {
			return `${location.name} (${location.nameCanonical})`
		}
	}
}
</script>

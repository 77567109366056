const EmployeeService = require('@/services/users/EmployeeService')

export default function (cb, preset) {
    let value = null;

    const data = {
        includes: ['department'],
        sort: [{
            key: 'email',
            direction: 'ASC'
        }],
        filter_groups: [
            {
                filters: [
                    {
                        key: 'terminated',
                        value: 0,
                        operator: 'eq'
                    }
                ]
            }
        ]
    }

    EmployeeService.findAll(data, (response) => {
        const body = response.data;

        const results = body.rows.map(function (employee) {
            return {
                label: `${employee.email} - ${employee.name}`,
                value: employee.id
            }
        });

        for (var i = 0; i < results.length; i++) {
            if (results[i].value != preset) {
                continue;
            }

            value = preset;
        }

        cb(results, value);
    });
}

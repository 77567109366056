const BaseService = require('@/services/BaseService')

const namespace = 'users/employees'

const find = (id, options, cb) => {
  BaseService.get(`${namespace}/${id}`, options, cb);
}

const findAll = (options, cb) => {
  BaseService.get(namespace, options, cb);
}

const remove = (id, cb) => {
  BaseService.remove(`${namespace}/${id}`, cb);
}

const create = (options, cb, errorCb) => {
  BaseService.post(namespace, options, cb, errorCb);
}

export {
  find,
  findAll,
  remove,
  create
}

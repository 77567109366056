const BaseService = require('@/services/BaseService')

const namespace = 'search-local-grid'

const fetch = (options, cb, errorCb) => {
    BaseService.post(namespace, options, cb, errorCb)
}

export default {
  fetch
}

const BaseService = require('@/services/BaseService')

const namespace = 'mail-chimp'

const find = (id, options, cb, errorCb) => {
    BaseService.get(namespace + '/' + id, options, cb, errorCb)
}

const campaigns = (options, cb, errorCb) => {
    BaseService.get(namespace + '/campaigns', options, cb, errorCb)
}

const reportCampaign = (id, options, cb, errorCb) => {
    BaseService.get(namespace + '/campaigns/report/' + id, options, cb, errorCb)
}

const report = (options, cb, errorCb) => {
    BaseService.get(namespace + '/campaigns/report', options, cb, errorCb)
}

export default {
    find,
    campaigns,
    reportCampaign,
    report
}

<template>
    <div class="banner text-center" :style="{'backgroundImage': backgroundImage}" v-if="active">
        <div class="row">
            <div class="col-sm-8 col-sm-offset-2">
                <h1>{{headline}}</h1>
                <p><slot></slot></p>
                <a class="btn btn-inverse" @click="close()">{{buttonText}}</a>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    .banner {
        padding: 30px;
        background-size: cover;
        background-repeat: no-repeat;
        margin-bottom: 30px;
        border-radius: 4px;
        color: white;
        position: relative;
        overflow: hidden;

        > div {
            z-index: 2;
            position: relative;
        }

        &::after {
            content: "";
            opacity: 0.85;
            background-color: #1c5899;
            position: absolute;
            width: 100%;
            height: 100%;
            display: block;
            z-index: 1;
            top: 0;
            left: 0;
        }

        h1 {
            margin-top: 15px;
            font-size: 24px;
            font-weight: 400;
        }

        p {
            margin-bottom: 20px;
        }
    }
</style>

<script>
    export default {
      props: {
        headline: String,
        image: String,
        buttonText: String,
        slug: String
      },

      data() {
        return {
          active: true,
          localStoragePrefix: 'onboarding-banner-'
        }
      },

      computed: {
        backgroundImage() {
          return 'url(\'' + this.image + '\')';
        },
        localStorageId() {
          return this.localStoragePrefix + this.slug
        }
      },

      beforeMount() {
        this.active = (this.$localStorage.get(this.localStorageId) != 'closed')
      },

      methods: {
        close() {
            this.$localStorage.set(this.localStorageId, 'closed');

            this.active = false
        }
      }
    }
</script>
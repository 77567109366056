import Vue from "vue";

Vue.filter("capitalize", function(value) {
    if (!value) {
        return "";
    }

    value = value.toString();

    return value.charAt(0).toUpperCase() + value.slice(1);
});

Vue.filter("strtolower", function(value, decimals) {
    if (!value) return value;
    return value.toLowerCase();
});

Vue.filter("fixedNumber", function(value, decimals) {
    if (!value) return value;
    return parseFloat(value).toFixed(decimals);
});

Vue.filter("substr", function(value, from, to) {
    if (!value) {
        return "";
    }

    return value.substr(from, to);
});

Vue.filter("numberFormat", function(value) {
    // Check if the value is numeric
    if (!(!isNaN(parseFloat(value)) && isFinite(value))) {
        return value;
    }

    value = value.toString();

    const opposite = $t("i18n.thousandSeparator") === "." ? "," : ".";

    value = value.replace($t("i18n.thousandSeparator"), "!");

    var parts = value.split("#");

    parts[0] = parts[0].replace(
        /\B(?=(\d{3})+(?!\d))/g,
        $t("i18n.thousandSeparator")
    );

    return parts.join("#").replace("!", opposite);
});

Vue.filter("duration", function(value) {
    var sec_num = parseInt(value, 10);
    var hours = Math.floor(sec_num / 3600);
    var minutes = Math.floor((sec_num - hours * 3600) / 60);
    var seconds = sec_num - hours * 3600 - minutes * 60;

    if (hours < 10) hours = "0" + hours;
    if (minutes < 10) minutes = "0" + minutes;
    if (seconds < 10) seconds = "0" + seconds;

    return hours + ":" + minutes + ":" + seconds;
});

Vue.filter("decode", function(value) {
    let elem = document.createElement("textarea");
    elem.innerHTML = value;

    let decoded = elem.value;

    return decoded;
});

Vue.filter("prefixNumberWithZeros", function(value, totalDigits) {
    const digitsInNumber = value.toString().length;

    const zerosToAdd = totalDigits - digitsInNumber;
    let result = value.toString();

    for (let i = 0; i < zerosToAdd; i++) {
        result = "0" + result;
    }

    return result;
});

Vue.filter("percent", function(value) {
    const percent = Math.round(value * 1000) / 10;

    return percent + "%";
});

Vue.filter("country", function(value) {
    if (!value) return value;
    switch (value.toLowerCase()) {
        case "dk":
            return "Danmark";
    }
});

const validateImage = (file, minSize) => {
    return new Promise(resolve => {
        const adjustedSize = Number(minSize) * 1024

        return resolve({
            valid: file.size >= adjustedSize
        })
    });
};

export default (files, [minSize]) => {
    const list = []

    for (let i = 0; i < files.length; i++) {
        // if file is not an image, reject.
        if (! /\.(jpg|svg|jpeg|png|bmp|gif)$/i.test(files[i].name)) {
            return false
        }

        list.push(files[i])
    }

    return Promise.all(list.map(file => validateImage(file, minSize)))
};

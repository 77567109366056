<template>
    <div class="maintenance-container">
        <div class="message-box">
            <h1 v-text="$t('maintenancePage.title')" />
            <p v-text="$t('maintenancePage.updating')"/>
            <p v-text="$t('maintenancePage.backSoon')" />
        </div>
        <div class="image-box"></div>
    </div>
</template>

<style lang="scss" scoped>
.maintenance-container {
    display:flex;
    align-items:center;
    justify-content:center;
    width:100vw;
    height:100vh;

    .message-box{
        padding:10px 20px;
        border-radius:4px;
    }

    .image-box{
        background-image: url('/images/opn/pink-asset.png');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        width:25%;
        height:100%;
    }

    @media screen and (max-width: 768px) {
        flex-direction:column;

        .image-box{
            display:none;
        }
    }
}
</style>

<script>
export default {
    name: "MaintenancePage",
}
</script>
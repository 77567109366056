<template>
  <div class="range">
    <hr v-if="includeDivider" :class="{'quickview': quickview}" />

    <small class="text-muted">
      <span class="fa fa-clock-o fa-fw"></span>
      &nbsp;
      <template v-if="quickview">
        <template v-if="!pointInTime">
          <span>{{from.format('LL')}} - {{to.format('LL')}}</span>
          <span class="small-button" style="float: right;">
            <a href="#">{{cta}}</a>
          </span>
        </template>
        <template v-else>
          <span>{{pointInTime}}</span>
          <span class="small-button" style="float: right;">
            <a href="#">{{cta}}</a>
          </span>
        </template>
      </template>

      <template v-else>
        {{$t('range.period')}}
        <template v-if="from">{{$t('range.from')}} {{from | moment(format) }}</template>
        <template v-if="to">{{$t('range.to')}} {{to | moment(format)}}</template>
      </template>
    </small>
  </div>
</template>

<style lang="scss">
.range {
  .small-button {
    margin-top: 3px;
    a {
      color: #4096ee;
      &:hover {
        color: #3380ce;
      }
    }
  }

  .quickview {
    margin-top: 15px;
    margin-bottom: 5px;
  }
}
</style>

<script>
export default {
  props: {
    from: Object,
    to: Object,
    pointInTime: null,
    includeTime: {
      type: Boolean,
      default: false
    },
    includeDivider: {
      type: Boolean,
      default: true
    },
    quickview: {
      type: Boolean,
      default: false
    },
    cta: {
      type: String,
      default: null
    }
  },
  computed: {
    format() {
      if (!this.includeTime) {
        return $t('i18n.dateFormat')
      }

      return $t('i18n.dateTimeFormat')
    }
  }
}
</script>

<template>
	<tr>
		<template v-if="hasLoaded">
			<td>
				{{data.campaign_title}}<br />
				<small class="text-muted">
					{{$t('mailChimp.created')}} {{campaign.create_time | moment('from', 'now')}}
				</small>
			</td>

			<td>
				{{data.emails_sent}}<br />
				<small class="text-muted">
					{{bounces}} {{$tc('mailChimp.bouncesPlural', bounces)}}
				</small>
			</td>

			<td>
				{{data.opens.unique_opens}}&nbsp;

				<span class="text-muted" v-if="data.emails_sent && data.opens.unique_opens">
                    ({{data.opens.unique_opens / data.emails_sent * 100 | fixedNumber(0)}}%)
                </span>
			</td>

			<td>
				{{data.clicks.clicks_total}}
			</td>

			<td style="text-align: right">
				<a :href="campaign.long_archive_url" target="_blank" rel="nofollow noreferrer" class="btn btn-sm btn-default btn-fill">
					{{$t('mailChimp.openInMailChimp')}}
				</a>
			</td>
		</template>

		<template v-else>
			<td class="mock">
				campaign title<br />
				<small class="text-muted">
					muted text
				</small>
			</td>

			<td class="mock">
				mails sent<br />
				<small class="text-muted">
					bounces
				</small>
			</td>

			<td class="mock">
				opens
			</td>

			<td class="mock">
				clicks
			</td>

			<td></td>
		</template>
	</tr>
</template>

<style lang="scss" scoped>
tr {
	td {
		vertical-align: top;
	}
}

.btn-link {
	color: #000;
	border: 0;
}
</style>

<script>
import MailChimpService from '@/services/mail-chimp/MailChimpService'

export default {
	props: {
		campaign: Object
	},

	data() {
		return {
			hasLoaded: false,
			data: {}
		}
	},

	computed: {
		bounces() {
			if (!this.hasLoaded) {
				return 0
			}

			const bounces = this.data.bounces

			return bounces.hard_bounces + bounces.soft_bounces
		}
	},

	mounted() {
		this.load()
	},

	methods: {
		load() {
			MailChimpService.reportCampaign(this.campaign.id, {
				fields: []
			}, (response) => {
				const data = response.data

				this.data = data
				this.hasLoaded = true
			})
		}
	}
}
</script>


export default {
  backend: [
    {
      path: 'abm',
      name: 'backend.abm',
      component: () => import(/* webpackChunkName: "LeadEnhancer" */ './LeadEnhancer')
    }
  ],
  frontend: [
    {
      path: '/abm',
      name: 'frontend.abm',
      component: () => import(/* webpackChunkName: "LeadEnhancer" */ './LeadEnhancer')
    }
  ]
}

const BaseService = require('@/services/BaseService')

const namespace = 'users'

const find = (id, options, cb) => {
  BaseService.get(namespace + '/' + id, options, cb);
}

const findAll = (options, cb) => {
  BaseService.get(namespace, options, cb);
}

const identity = (options, cb, errorCb) => {
  BaseService.get(`${namespace}/identity`, options, cb, errorCb);
}

const remove = (id, cb) => {
  BaseService.remove(`${namespace}/${id}`, {}, cb);
}

const put = (id, data, cb, errorCb) => {
  BaseService.put(`${namespace}/${id}`, data, cb, errorCb);
}

const password = (password, cb, errorCb) => {
  BaseService.put(`${namespace}/password`, {password: password}, cb, errorCb);
}

export {
  find,
  findAll,
  identity,
  remove,
  password,
  put
}

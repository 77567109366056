const BaseService = require('@/services/BaseService')

const namespace = 'notifications'

const find = (id, options, cb, errorCb) => {
  BaseService.get(namespace + '/' + id, options, cb, errorCb)
}

const findAll = (options, cb, errorCb) => {
  BaseService.get(namespace, options, cb, errorCb)
}

const subscribe = (userId, options, cb, errorCb) => {
  BaseService.post(`users/${userId}/subscriptions`, options, cb, errorCb)
}

const unsubscribe = (userId, id, options, cb, errorCb) => {
  BaseService.remove(`users/${userId}/subscriptions/${id}`, options, cb, errorCb)
}

const unsubscribeAll = (userId, options, cb, errorCb) => {
  BaseService.post(`users/${userId}/unsubscribe`, options, cb, errorCb)
}

export {
  find,
  findAll,
  subscribe,
  unsubscribe,
  unsubscribeAll
}

import identity from './identity.store'
import customer from './customer.store'
import employee from './employee.store'
import ghost from './ghost.store'

export default {
  identity,
  customer,
  employee,
  ghost
}

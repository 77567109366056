const BaseService = require('@/services/BaseService')

export default class OAuth2IsAccessService {
    constructor(namespace) {
        this.namespace = namespace
    }

    url(service, redirectUri, cb, errorCb){
        BaseService.get(`${this.namespace}/oauth2/connect`, { redirectUri: redirectUri, state: service }, cb, errorCb)
    }

    callback(service, redirectUri, code, state, cb, errorCb) {
		BaseService.post(`${this.namespace}/oauth2/callback`, {
          state: state,
          code: code,
          redirectUri: redirectUri,
        }, cb, errorCb)
	}

    mccCallback(redirectUri, code, state, cb, errorCb) {
        BaseService.post(`${this.namespace}/mcc/mcc-callback`, {
          state: state,
          code: code,
          redirectUri: redirectUri,
        }, cb, errorCb)
    }

    accounts(service, options, cb, errorCb) {
        const endUrl = options.endUrl
        BaseService.get(`${this.namespace}/oauth2/${endUrl}`, {}, cb, errorCb)
    }

    integrate(service, locationName, id, cb, errorCb) {
        BaseService.post(`${this.namespace}/oauth2/integrate`, { id: id, location_name: locationName }, cb, errorCb)
    }

    integrateLocation(service, locationName, cb, errorCb) {
        BaseService.post(`${this.namespace}/oauth2/integrate`, { location_name: locationName }, cb, errorCb)
    }

    remove(integrationId, cb, errorCb) {
        BaseService.remove(`${this.namespace}/oauth2/integrations/${integrationId}`, {}, cb, errorCb)
    }
}

export default [
  {
    path: 'conversions',
    name: 'frontend.reporting.conversions',
    component: () => import(/* webpackChunkName: "Conversions" */ './Conversions'),
    meta: {
      headline: () => $t('navigation.statistics.alternativeConversions'),
      description: () => $t('topBar.descriptions.reporting.alternativeConversions'),
      tracking: 'Alternative Conversions - Page view - Main page',
      datepicker: true
    }
  },
]

<template>
	<div class="date-picker">
		<div class="options">
			<div :class="{ checked: empty }"
				 @click="setEmpty(true)">
				<div class="radio-btn" />
				<div class="label">{{ emptyOption }}</div>
			</div>

			<div :class="{ checked: ! empty }"
				 @click="setEmpty(false)">
				<div class="radio-btn" />
				<div class="label">Pick date</div>
			</div>
		</div>

		<div v-if="! empty"
			 class="date">
			<input type="date"
				   v-model="internalValue"
				   class="form-control"
				   :disabled="disabled"
			/>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.date-picker {
	> div.options {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		gap: 15px;
		margin-top: 6px;

		> div {
			cursor: pointer;
			display: flex;
			align-items: center;

			> div.radio-btn {
				position: relative;
				width: 27px;
				height: 27px;
				min-width: 27px;
				min-height: 27px;
				border: 2px solid #000;
				border-radius: 50%;
			}

			> div.label {
				color: #000;
				font-size: 15px;
				font-weight: 500;
			}

			&.checked {
				> div.radio-btn {
					&:after {
						position: absolute;
						top: 3px;
						right: 3px;
						bottom: 3px;
						left: 3px;
						content: '';
						border-radius: 50%;
						background-color: #000;
					}
				}
			}
		}
	}

	> div.date {
		margin-top: 20px;
	}
}
</style>

<script>
export default {
	props: {
		value: {},

		emptyOption: {
			type: String,
			required: true
		},

		disabled: {
			type: Boolean,
			required: false,
			default: false
		}
	},

	data: () => ({
		empty: true,
	}),

	computed: {
		internalValue: {
			get() {
				return this.value
			},

			set(value) {
				this.$emit('input', value)
			}
		}
	},

	watch: {
		empty() {
			if (! this.empty) {
				return
			}

			this.internalValue = null
		},

		value() {
			this.initiate()
		}
	},

	mounted() {
		this.initiate()
	},

	methods: {
		initiate() {
			this.empty = this.value === null
		},

		parse(value, direction) {
			if (value === null) {
				return null
			}

			switch (direction) {
				case 'in':
					return

				case 'out':
					return
			}
		},

		setEmpty(value) {
			if (this.disabled) {
				return
			}

			this.empty = value
		}
	}
}
</script>

<template>
    <div class="create-keywords-form">
        <single-input-form @create="addKeyword($event)"
                           @clearError="clearErrors"
                           :errors="errors"
                           :clearOnCreate="true"
                           :buttonText="$t('seo.createKeywords.select')"
                           :inputPlaceholder="$t('seo.createKeywords.inputField')"
                           id="keyword"
                           :disabled="inputDisabled"
                           :maxLength="125">
        </single-input-form>

        <div class="selected-keywords" v-if="selectedKeywords.length">
            <tag v-for="(keyword, i) in selectedKeywords"
                 @remove="removeKeyword(keyword)"
                 :key="i"
                 :text="keyword.keyword"
                 :type="keywordExists(keyword) ? 'danger' : 'info'"
            />

            <div class="clearfix" />
        </div>

        <p v-if="hasDuplicateKeywords"
           class="text-danger"
           v-text="$t('seo.createKeywords.existing')"
        />

        <template v-if="requireLocation">
            <multiselect v-model="location"
                         :customLabel="locationLabel"
                         track-by="id"
                         :placeholder="$t('seo.createKeywords.chooseLocation')"
                         open-direction="bottom"
                         :options="locations"
                         :searchable="true"
                         selectedLabel=""
                         :loading="isLoadingLocations"
                         :internal-search="false"
                         :clear-on-select="true"
                         :close-on-select="true"
                         :max-height="300"
                         :show-no-results="false"
                         :allow-empty="false"
                         :deselect-label="$t('seo.createKeywords.mustChooseLocation')"
                         :select-label="$t('seo.createKeywords.chooseLocation')"
                         @search-change="searchLocations">

                <template slot="option" slot-scope="props">
                    <span class="badge__name">{{locationLabel(props.option)}}</span>
                    <span class="badge__type">{{$t('seo.createKeywords.locationType.' + props.option.type.toLowerCase())}}</span>
                </template>

                <template slot="clear" slot-scope="props">
                    <div class="multiselect__clear" v-if="location" @mousedown.prevent.stop="clearAll()"></div>
                </template>

                <template slot="noResult">
                    <span v-if="isLoadingLocations">
                        {{$t('seo.createKeywords.loadingLocations')}}
                        <loader :inline="true" />
                    </span>

                    <span v-else>{{$t('seo.createKeywords.noLocationsFound')}}</span>
                </template>
            </multiselect>

            <br>

            <template v-if="mustChooseLocation">
              <p class="text-danger">{{$t('seo.createKeywords.mustChooseLocation')}}</p>
            </template>
        </template>

        <warning v-if="!isSaving && failedKeywords.length" type="error">
            <p style="margin-bottom: 10px;" v-html="$t('seo.createKeywords.creatingKeywordFailed')" />

            <strong>{{$t('seo.createKeywords.failedKeywordList')}}</strong>

            <ul>
                <li v-for="keyword in failedKeywords">
                    {{keyword.keyword}}
                </li>
            </ul>
        </warning>

        <button class="btn" @click="createKeywords" :disabled="submitDisabled" :style="organizationColor" >
            <template v-if="!isSaving">
                {{$t('seo.createKeywords.createSelected')}}
            </template>

            <template v-else>
                {{$t('seo.createKeywords.saving')}} <loader :inline="true" />
            </template>
        </button>
    </div>
</template>

<style lang="scss" scoped>
    @import '~@/assets/scss/_custom.scss';

    .badge__type {
        vertical-align: middle;
        float: right;
        display: inline-block;
        background-color: #676767;
        padding: 2px 5px;
        color: #fff;
        font-weight: 600;
        letter-spacing: 0.3px;
        border-radius: 4px;
        font-size: 11px;
    }

    .selected-keywords {
        margin-bottom: 10px;
    }

    .label-info {
      background: #f9f9f9;
      color: #333;
      font-weight: 400;
    }

    .badge__name {
        vertical-align: middle;
        display: inline-block;
        margin-left: 5px;
        float: left;
    }
</style>

<script>
import SingleInputForm from '@/app/seo/components/SingleInputForm'
import Tag from '@/app/layout/components/Tag'
import Loader from '@/app/shared/components/Loader'
import Warning from '@/app/shared/global/warnings/Warning'

const KeywordService = require('@/services/seo/KeywordService')
const LocationService = require('@/services/seo/LocationService')

export default {
    props: {
        keywords: {
            type: Array,
            required: true,
        },

        limit: {
            type: Number,
            required: false,
        },

        requireLocation: {
            type: Boolean,
            required: false,
            default: false,
        },

        customerId: {
            type: String,
            required: false
        }
    },

    data () {
        return {
            cachedQuery: '',
            errors: [],
            selectedKeywords: [],
            location: null,
            locations: [],
            isLoadingLocations: false,
            isSaving: false,
            savedKeywords: [],
            failedKeywords: []
        }
    },

    watch: {
        selectedKeywords() {
            this.$emit('selectedKeywords', this.selectedKeywords)
        },

        savedKeywords() {
            this.checkIfKeywordsAreProcessed()
        },

        failedKeywords() {
            this.checkIfKeywordsAreProcessed()
        }
    },

    computed: {
        userAddedKeywords() {
            return this.keywords.filter(keyword => {
                return keyword.tags.includes('usertag')
            })
        },

        organizationColor() {
            return {
                color: $org('colors.standard.button.outlined'),
                border: `1px solid ${$org('colors.standard.button.outlined')}`
            }
        },

        remainingKeywords() {
            const userAddedKeywords = this.userAddedKeywords

            return this.limit - userAddedKeywords.length - this.selectedKeywords.length
        },

        inputDisabled() {
            return this.limit ? this.remainingKeywords <= 0 : false
        },

        hasDuplicateKeywords() {
            const matches = this.selectedKeywords.filter(keyword => {
                return this.keywordExists(keyword)
            })

            return matches.length > 0
        },

        mustChooseLocation() {
            return this.requireLocation ? this.location === null : false
        },

        submitDisabled() {
            return this.errors.length > 0 ||
                this.hasDuplicateKeywords ||
                this.selectedKeywords.length < 1 ||
                this.mustChooseLocation ||
                this.isSaving
        }
    },

    methods: {
        checkIfKeywordsAreProcessed () {
            if (!this.isSaving) {
                return
            }

            if (this.savedKeywords.length + this.failedKeywords.length !== this.selectedKeywords.length) {
                return
            }

            this.isSaving = false
            this.selectedKeywords = []
        },

        clearErrors () {
            this.errors = []
        },

        createKeywords() {
            const selectedKeywords = this.selectedKeywords
            const customerId = this.customerId
            const location = this.location

            this.clearErrors()

            this.savedKeywords = []
            this.failedKeywords = []
            this.isSaving = true

            selectedKeywords.forEach(keyword => {
                const options = {
                     keyword: keyword.keyword
                }

                if (location) {
                    options['location'] = location.id
                }

                if (customerId) {
                    options['customerId'] = customerId
                }

                KeywordService.create(options, response => {
                    this.savedKeywords.push(keyword)

                    eventHub.$emit('keyword.created', response.data)
                }, error => {
                    this.failedKeywords.push(keyword)

                    if (error.response && error.response.status === 422) {
                        this.errors = error.response.data.errors[0].title
                    }
                })
            })
        },

        addKeyword(keyword) {
            if (!keyword.trim().length || this.keywordIsSelected(keyword)) {
                return
            }

            this.selectedKeywords.push({
                keyword: keyword
            })
        },

        keywordIsSelected(keyword) {
            const match = this.selectedKeywords.find(selectedKeyword => {
                return selectedKeyword.keyword === keyword
            })

            return match !== undefined
        },

        removeKeyword(keyword) {
            const keywords = this.selectedKeywords

            keywords.splice(keywords.indexOf(keyword), 1)
        },

        keywordExists(keyword) {
            const location = this.location

            const match = this.keywords.find((currentKeyword) => {
                const searchLocation = currentKeyword.searchLocation

                if (currentKeyword.keyword === keyword.keyword) {
                    if (!location || !searchLocation) {
                        return true
                    }

                    return searchLocation.id === location.id
                }

                return false
            })

            return !!match
        },

        searchLocations(query) {
            this.cachedQuery = query

            if (query.length < 3) {
                this.isLoadingLocations = false
                return
            }

            this.isLoadingLocations = true;

            (queryParameter => {
                setTimeout(() => {
                    if (queryParameter !== this.cachedQuery) {
                        return;
                    }

                    const filters = []

                    query.split(' ').forEach(queryWord => {
                        filters.push({
                            key: 'nameCanonical',
                            operator: 'ct',
                            value: queryWord
                        })
                    })

                    this.locations = []

                    LocationService.findAll({
                        limit: 3,

                        filter_groups: [
                            {
                                filters: filters
                            },
                            {
                                filters: [
                                    {
                                        key: 'type',
                                        operator: 'eq',
                                        value: 'Country'
                                    }
                                ]
                            }
                        ]
                    }, (response) => {
                        const data = response.data

                        let locations = data.rows

                        LocationService.findAll({
                            limit: 7,

                            filter_groups: [
                                {
                                    filters: filters
                                },
                                {
                                    filters: [
                                        {
                                            key: 'type',
                                            operator: 'eq',
                                            value: 'Country',
                                            not: true
                                        }
                                    ]
                                }
                            ]
                        }, (response) => {
                            const data = response.data

                            data.rows.forEach(location => {
                                locations.push(location)
                            })

                            this.locations = locations
                            this.isLoadingLocations = false
                        })
                    })
                }, 800)
            })(query)
        },

        clearAll () {
            this.location = null
        },

        locationLabel(location) {
            return `${location.name} (${location.nameCanonical})`
        }
    },

    components: {
        Loader,
        SingleInputForm,
        Tag,
        Warning
    },
}
</script>

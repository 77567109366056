export default [
  {
    path: "wordpress",
    name: "frontend.wordpress",
    component: () => import(/* webpackChunkName: "WordPress" */ './WordPress'),
    meta: {
	  headline: () => $t("wordpress.title"),
	  description: () => $t("wordpress.description"),
      tracking: "WordPress"
    }
  }
];

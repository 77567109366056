import ApiService from '@/services/_app/ApiService'

const namespace = 'analytics'

export default class AnalyticsService {
  static sessions (from, to, cache, id) {
    // return ApiService.get(namespace + '/sessions/' + ApiService.formatToUrl(from, to) + "?cache=" + cache + "&id=" + id)
    return ApiService.get(namespace + '/sessions/' + ApiService.formatToUrl(from, to))
  }

  static sessionsWithSources (from, to, sort, cache, id, config) {
    // return ApiService.get(namespace + '/sources/' + ApiService.formatToUrl(from, to) + '/' + sort + "?cache=" + cache + "&id=" + id, config)
    return ApiService.get(namespace + '/sources/' + ApiService.formatToUrl(from, to) + '/' + sort, config)
  }

  static sessionsWithSourcesAndPagePath (from, to, sort) {
    return ApiService.get(namespace + '/sources-with-page-path/' + ApiService.formatToUrl(from, to) + '/' + sort)
  }

  static sessionsWithPlatforms (from, to, cache, id) {
    // return ApiService.get(namespace + '/sessions/' + ApiService.formatToUrl(from, to) + '/null/platform' + "?cache=" + cache + "&id=" + id)
    return ApiService.get(namespace + '/sessions/' + ApiService.formatToUrl(from, to) + '/null/platform')
  }

  static sessionsUserType (from, to) {
    return ApiService.get(namespace + '/sessions/' + ApiService.formatToUrl(from, to) + '/null/userType')
  }

  static sessionsUserGender (from, to) {
    return ApiService.get(namespace + '/sessions/' + ApiService.formatToUrl(from, to) + '/null/userGender')
  }

  static sessionsUserAgeBracket (from, to) {
    return ApiService.get(namespace + '/sessions/' + ApiService.formatToUrl(from, to) + '/null/userAgeBracket')
  }

  static cities (from, to) {
    return ApiService.get(namespace + '/cities/' + ApiService.formatToUrl(from, to))
  }

  static pages (from, to) {
    return ApiService.get(namespace + '/most-visited-pages/' + ApiService.formatToUrl(from, to))
  }

  static averageDuration (from, to) {
    return ApiService.get(namespace + '/average-duration/' + ApiService.formatToUrl(from, to))
  }

  static averagePagesVisited (from, to) {
    return ApiService.get(namespace + '/average-pages-visited/' + ApiService.formatToUrl(from, to))
  }

  static locations (from, to) {
    return ApiService.get(namespace + '/locations/' + ApiService.formatToUrl(from, to) + '/500/default')
  }

  static referrals (from, to) {
    return ApiService.get(namespace + '/referrals/' + ApiService.formatToUrl(from, to) + '/500/default')
  }

  static accounts () {
    return ApiService.get(namespace + '/accounts')
  }

  static profile () {
    return ApiService.get(namespace + '/profile')
  }

  static trackingCode (customerId) {
    return ApiService.get(namespace + '/tracking-code/' + customerId)
  }

  static integrate = (options) => {
    return ApiService.post(namespace + '/integrate', options)
  }
}

<template>
	<tr>
		<td>
			{{flow.name}}<br />

			<small class="text-muted">
				{{$t('klaviyo.created')}} {{flow.created | moment('from', 'now')}}
			</small>
		</td>

		<td>{{ flow.metrics.openedEmail }}</td>
		<td>{{ flow.metrics.clicks }}</td>
		<td>{{ amount(flow.metrics.revenue) }}</td>
	</tr>
</template>

<style lang="scss" scoped>
tr {
	td {
		vertical-align: top;
	}
}

.btn-link {
	color: #000;
	border: 0;
}
</style>

<script>
export default {
	props: {
		flow: Object
	},

	mounted() {
		this.load()
	},

	methods: {
		amount(amount) {
			const locale = this.$i18n.locale.replace('_', '-')
			return Number(amount).toLocaleString(locale)
		}
	}
}
</script>

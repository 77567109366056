import CustomerService from '@/services/_app/customers/CustomerService'
import StorageService from '@/services/_app/storage/StorageService'

export const STORAGE_CUSTOMER_KEY = '_customerKey'

const getDefaultState = () => {
  return {
    customer: null,
    customers: [],
    includingInternationalTraffic: false
  }
}

const getters = {
  getCustomer: state => state.customer,
  getCustomers: state => state.customers,
  includeInternationalTraffic: state => state.includingInternationalTraffic
}

const mutations = {
  resetState (state) {
    Object.assign(state, getDefaultState())
  },

  setCustomer (state, customer) {
    !! customer
	  ? StorageService.set(STORAGE_CUSTOMER_KEY, customer.id)
	  : StorageService.delete(STORAGE_CUSTOMER_KEY)

    state.customer = customer

    state.includingInternationalTraffic = !! customer
	  ? customer.includingInternationalTraffic
	  : false
  },

  setCustomers (state, customers) {
    state.customers = customers
  }
}

const actions = {
  setCustomer ({ commit }, customer) {
    commit('setCustomer', customer)
  },

  setCustomers ({ commit }, customers) {
    commit('setCustomers', customers)
  },

  async saveCustomer ({ state }) {
    const { name, domain, phoneNumber, contactPerson, industry, id } = state.customer

    const params = {
      name, domain, phoneNumber, contactPerson, industry: industry.id
    }

    return await CustomerService.put(id, params)
  },

  async includeInternationalTraffic ({ commit, dispatch, state }, includingInternationalTraffic) {
    await CustomerService.put(state.customer.id, { includingInternationalTraffic })
    return await dispatch('identity/loadIdentity', null, { root: true })
  }
}

export default {
  namespaced: true,
  state: getDefaultState(),
  getters,
  mutations,
  actions
}

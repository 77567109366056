import Newsletters from '@/app/newsletters/Newsletters'

export default [
  {
    path: '/mailchimp',
    name: 'frontend.newsletters',
    component: () => import(/* webpackChunkName: "Newsletters" */ './Newsletters'),
    meta: {
      headline: () => $t('navigation.statistics.newsletters'),
      description: () => $t('topBar.descriptions.newsletters'),
      tracking: 'Newsletters - Page view'
    }
  },
]

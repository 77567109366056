import Vue from 'vue'

// TODO
class StorageService {
    /**
     * Check if value is string or number,
     * if not, convert to string
     * @param value
     * @returns {string|number}
     */
    static checkValue(value) {
        if (typeof value !== "string" && typeof value !== "number") {
            return JSON.stringify(value);
        }

        return value;
    }

    /**
     * Save to localStorage
     *
     * @param key
     * @param value
     */
    static set(key, value) {
        try {
            localStorage.setItem(key, StorageService.checkValue(value));
        } catch (e) {
            throw e;
        }
    }

    /**
     * Delete sored item from localStorage
     *
     * @param key
     */
    static delete(key) {
        try {
            localStorage.removeItem(key);
        } catch (e) {
            throw e;
        }
    }

    /**
     * Retrieve stored item, parse through JSON.parse
     *
     * @param key
     * @returns {string|number|array|object}
     */
    static get(key) {
        const item = localStorage.getItem(key);

        try {
            return JSON.parse(item);
        } catch (e) {
            return item;
        }
    }

    static has (key){
      return (localStorage.getItem(key) !== null)
    }

    /**
     * Clear out all stored values
     **/
    static clear() {
        try {
            localStorage.clear();
        } catch (e) {
            throw e;
        }
    }

    install (){
        Vue.prototype.$storage = {
            get: StorageService.get,
            clear: StorageService.clear,
            delete: StorageService.delete,
            set: StorageService.set
        }
    }
}

export default StorageService;

<template>
	<div class="element">
		<div class="row">
			<div class="col-lg-12">
				<h4>Subscriptions</h4>

				<div class="subscriptions">
					<div class="headline">
						<div class="plan">
							Plan
						</div>

						<div class="interval">
							Price
						</div>

						<div class="actions">
							Actions
						</div>
					</div>
				</div>

				<div class="subscriptions">
					<div v-for="subscription in sortedSubscriptions"
						 :key="`subscription-${subscription.id}`"
						 class="subscription">
						<div class="plan">
							<div class="labels">
								<div class="name">{{ subscription.plan.title }}</div>

								<div v-if="!! subscription.externalId"
									 class="type">
									Stripe
								</div>

								<div v-if="! subscription.is_active"
									 class="inactive">
									Inaktiv
								</div>
							</div>

							<div class="dates">
								<div>
									<i class="fa fa-clock-o fa-fw" />
									<span>{{ formatDate(subscription.startDate) }} - <template v-if="!! subscription.endDate">{{ formatDate(subscription.endDate) }}</template><template v-else>unlimited</template></span>
								</div>
							</div>
						</div>

						<div class="interval">
							{{ subscription.price }} {{ subscription.currency.toUpperCase() }} / <template v-if="Number(subscription.intervalCount) !== 1">every {{ subscription.intervalCount }}.</template> {{ subscription.interval }}
						</div>

						<div class="actions">
							<button v-if="! subscription.externalId"
									@click="onShowEdit(subscription.id)"
									type="button">
								<i class="fa fa-pencil" />
								<span>Edit</span>
							</button>
						</div>
					</div>

					<logo-loader v-if="! hasLoaded"
								 :height="355"
					/>

					<div v-if="hasLoaded && subscriptions.length === 0"
						 class="no-subscriptions">
						No active subscriptions
					</div>

					<button type="button"
							@click="onShowCreate"
							class="add-subscription">
						<i class="fa fa-plus-circle fa-fw" />
						<span>Add new subscription</span>
					</button>
				</div>
			</div>
		</div>

		<form @submit.prevent="onCreate">
			<modal ref="createModal"
				   title="Add new subscription">
				<div class="modal-body">
					<div class="form-group">
						<label for="title">Plan</label>

						<select v-model="create.plan_id"
								class="form-control"
								:disabled="create.creating">
							<option v-for="plan in plans"
									:key="`plan-${plan.id}`"
									:value="plan.id">
								{{ plan.title }}
							</option>
						</select>
					</div>

					<div class="form-group">
						<label for="title">Start date</label>

						<subscription-date-picker v-model="create.start_date"
												  empty-option="Now"
												  :disabled="create.creating"
						/>
					</div>

					<div class="form-group">
						<label for="title">End date</label>

						<subscription-date-picker v-model="create.end_date"
												  empty-option="Unlimited"
												  :disabled="create.creating"
						/>
					</div>
				</div>

				<div class="modal-footer">
					<button v-if="! create.creating"
							:disabled="! canCreate"
							type="button"
							@click="onCreateTemporary"
							class="btn btn-dark">
						Grant temporary access
					</button>

					&nbsp;

					<button :disabled="! canCreate"
							type="submit"
							class="btn btn-primary">
						<i v-if="create.creating"
						   class="fa fa-spinner fa-spin mr-2"
						/>
						Add subscription
					</button>
				</div>
			</modal>
		</form>

		<form v-if="edit !== null"
			  @submit.prevent="onEdit">
			<modal ref="editModal"
				   title="Edit subscription">
				<div class="modal-body">
					<div class="form-group">
						<label for="title">Plan</label>

						<select v-model="edit.plan_id"
								class="form-control">
							<option v-for="plan in plans"
									:key="`plan-${plan.id}`"
									:value="plan.id">
								{{ plan.title }}
							</option>
						</select>
					</div>

					<div class="form-group">
						<label for="title">Start date</label>

						<subscription-date-picker v-model="edit.start_date"
												  empty-option="Now"
						/>
					</div>

					<div class="form-group">
						<label for="title">End date</label>

						<subscription-date-picker v-model="edit.end_date"
												  empty-option="Unlimited"
						/>
					</div>
				</div>

				<div class="modal-footer">
					<button :disabled="! canEdit"
							type="submit"
							class="btn btn-primary">
						<i v-if="edit.editing"
						   class="fa fa-spinner fa-spin mr-2"
						/>
						Update subscription
					</button>
				</div>
			</modal>
		</form>
	</div>
</template>

<script>
import moment from 'moment'

import LogoLoader from "@/app/shared/components/LogoLoader";

const PlanService = require("@/services/billing/PlanService");
const SubscriptionService = require("@/services/billing/SubscriptionService");
const CustomerService = require("@/services/customers/CustomerService");

import Modal from '@/app/layout/components/Modal'

import { mapGetters } from 'vuex'
import SubscriptionDatePicker from "@/app/customers/components/SubscriptionDatePicker";

const CREATE_TEMPLATE = {
	plan_id: null,
	start_date: null,
	end_date: null,
	creating: false
}

export default {
	data() {
		return {
			hasLoaded: false,
			edit: null,
			subscriptions: [],
			plans: [],
			create: CREATE_TEMPLATE,
		};
	},

	computed: {
		...mapGetters("identity", {
			identity: "getIdentity"
		}),

		customerId() {
			return this.$route.params.id
		},

		canCreate() {
			return !! this.create.plan_id && ! this.create.creating
		},

		canEdit() {
			return !! this.edit.plan_id && ! this.edit.editing
		},

		createTemplate() {
			return {
				...CREATE_TEMPLATE,
				plan_id: !! this.plans[0] ? this.plans[0].id : null
			}
		},

		sortedSubscriptions() {
			return this.subscriptions.sort((a, b) => {
				if (a.is_active === b.is_active) {
					return moment(b.created.date).isAfter(a.created.date)
						? 1
						: -1
				}

				return b.is_active
					? 1
					: -1
			})
		}
	},

	mounted() {
		this.loadSubscriptions()
		this.loadPlans()
	},

	methods: {
		loadSubscriptions() {
			this.hasLoaded = false

			SubscriptionService.findAll({
				includes: ['plan'],

				sort: [{
					key: 'created',
					direction: 'DESC'
				}],

				filter_groups: [{
					filters: [{
						key: 'customer',
						operator: 'eq',
						value: this.customerId
					}]
				}]
			}, ({ data }) => {
				this.subscriptions = data.rows.map(subscription => {
					let is_active = moment(subscription.startDate.date) < moment() && (
						subscription.endDate === null ||
						moment(subscription.endDate.date) > moment()
					)

					return {
						...subscription,
						is_active
					}
				})

				this.hasLoaded = true
			}, () => {
			})
		},

		loadPlans() {
			PlanService.findAll({}, ({ data }) => {
				this.plans = data.rows
			}, () => {
				// error
			})
		},

		onShowCreate() {
			if (this.create.creating) {
				return
			}

			this.create = JSON.parse(JSON.stringify(this.createTemplate))

			this.$refs['createModal'].show()
		},

		onCreate() {
			this.$set(this.create, 'creating', true)

			const { start_date, end_date, plan_id } = this.create

			SubscriptionService.manageCreate({
				customer_id: this.customerId,

				plan_id,
				start_date: !! start_date ? moment(start_date).format('YYYY-MM-DD') : null,
				end_date: !! end_date ? moment(end_date).format('YYYY-MM-DD') : null,
			}, () => {
				this.$refs['createModal'].close()
				this.$set(this.create, 'creating', false)

				this.loadSubscriptions()
			}, () => {
				this.$set(this.create, 'creating', false)
			})
		},

		onCreateTemporary() {
			this.$set(this.create, 'creating', true)

			const endDate = moment()
			endDate.add(2, 'days')

			this.$set(this.create, 'start_date', null)
			this.$set(this.create, 'end_date', endDate.format('YYYY-MM-DD'))

			const { start_date, end_date, plan_id } = this.create

			SubscriptionService.manageCreate({
				customer_id: this.customerId,

				plan_id,
				start_date: !! start_date ? moment(start_date).format('YYYY-MM-DD') : null,
				end_date: !! end_date ? moment(end_date).format('YYYY-MM-DD') : null,
			}, () => {
				this.$refs['createModal'].close()
				this.$set(this.create, 'creating', false)

				this.loadSubscriptions()
			}, () => {
				this.$set(this.create, 'creating', false)
			})
		},

		onEdit() {
			this.$set(this.edit, 'editing', true)

			const { start_date, end_date, plan_id } = this.edit

			SubscriptionService.manageUpdate(this.edit.id, {
				plan_id,
				start_date: !! start_date ? moment(start_date).format('YYYY-MM-DD') : null,
				end_date: !! end_date ? moment(end_date).format('YYYY-MM-DD') : null,
			}, () => {
				this.$refs['editModal'].close()

				this.edit = null

				this.loadSubscriptions()
			}, () => {
				this.$set(this.edit, 'editing', false)
			})
		},

		onShowEdit(id) {
			SubscriptionService.find(id, {
				includes: ['plan']
			}, ({ data }) => {
				this.edit = {
					id: data.id,
					plan_id: data.plan.id,
					start_date: data.startDate !== null ? moment(data.startDate.date).format('YYYY-MM-DD') : null,
					end_date: data.endDate !== null ? moment(data.endDate.date).format('YYYY-MM-DD') : null,
					editing: false
				}

				console.log(data)
				console.log(this.edit)

				this.$nextTick(() => {
					this.$refs['editModal'].show()
				})
			})
		},

		formatDate(date) {
			return !! date.date
				? moment(date.date).format('LL')
				: null
		}
	},

	components: {
		SubscriptionDatePicker,
		LogoLoader,
		Modal,
	},
};
</script>

<style lang="scss" scoped>
.subscriptions {
	display: flex;
	flex-direction: column;

	> * {
		display: flex;
		align-items: center;
		gap: 20px;

		> div.plan {
			flex-basis: 40%;
		}

		> div.interval {
			flex-basis: 35%;
		}

		> div.actions {
			flex-basis: 25%;
			text-align: right;
		}

		> * {
			color: #000;
			font-size: 15px;
			font-weight: 600;

			padding: 8px 0;
		}
	}

	> div.subscription {
		padding: 15px 0;

		display: flex;
		align-items: center;
		gap: 20px;

		> div.plan {
			display: flex;
			flex-direction: column;
			gap: 10px;

			> div.labels {
				display: flex;
				align-items: center;
				flex-wrap: wrap;
				gap: 6px;

				> div.name {
					padding: 4px 6px;
					font-size: 14px;
					font-weight: 500;
					letter-spacing: -.1px;
					color: #343434;
					background-color: #eee;
					border-radius: 6px;
				}

				> div.type {
					padding: 4px 6px;
					font-size: 14px;
					font-weight: 500;
					letter-spacing: -.1px;
					color: #cb36c1;
					background-color: #fae3f6;
					border-radius: 6px;
				}

				> div.inactive {
					padding: 4px 6px;
					font-size: 14px;
					font-weight: 500;
					letter-spacing: -.1px;
					color: #cb3636;
					background-color: #fae3e3;
					border-radius: 6px;
				}
			}

			> div.dates {
				display: flex;
				align-items: center;
				gap: 6px;

				> * {
					color: #9d9d9d;
					font-weight: 400;
					font-size: 12px;
				}
			}
		}

		> div.interval {
			color: #000;
			font-weight: 300;
			font-size: 16px;
		}

		> div.actions {
			display: flex;
			align-items: center;
			justify-content: flex-end;
			gap: 10px;

			> button {
				color: #000;
				font-weight: 500;
				font-size: 14px;
				padding: 8px 14px;
				border-radius: 6px;
				text-decoration: none;
				border: 0;
				box-shadow: none;
				outline: 0;

				display: flex;
				align-items: center;
				gap: 4px;
			}
		}
	}

	> *:not(button) {
		border-top: 1px solid #eee;

		&:last-child {
			border-bottom: 1px solid #eee;
		}
	}

	> div.no-subscriptions {
		flex-basis: 100%;
		padding: 15px 0;
		color: #9a9a9a;
		font-size: 14px;

		display: flex;
		justify-content: center;
	}

	> button.add-subscription {
		display: flex;
		justify-content: center;
		gap: 6px;

		color: #000;
		font-weight: 400;
		font-size: 14px;

		border: 0;
		outline: 0;
		box-shadow: none;

		padding: 15px 20px;

		border-top: 1px solid #eee;
		background-color: #fff;
	}
}
</style>

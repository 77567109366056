<template>
    <div class="card" :class="{'center-content': center, 'is-from-facebook-ads' : isFromFacebookAds}" v-if="visible">

        <card-tooltip v-if="tooltip" :tooltip="tooltip"/>

        <div class="close" v-if="close">
            <span class="pe-7s-close" @click="remove"></span>
        </div>

        <div class="header" v-if="hasHeader()" :class="{'header-icon': icon != null}">
            <!-- Icon -->
            <span v-if="icon" class="icon">
                <fa :icon="icon" v-if="isFontAwesome"/>
                <span v-else :class="icon"/>
            </span>

            <!-- Headline -->
            <div v-if="headline" class="headline pull-left" :class="{oneHeadline: !description}">
                <span v-html="headline" />

                <!-- Description -->
                <p class="category" v-if="description" v-html="description" />
            </div>

            <!-- Actions -->
            <div class="right">
                <slot name="header"/>
            </div>

            <div v-if="hasToggle" class="toggle">
                <switch-toggle @toggle="toggle" v-model="toggled" color="#134b61">
                    <small v-text="toggleButtonLabel" />
                </switch-toggle>
            </div>
        </div>

        <div class="clearfix"></div>

        <div :class="{'is-center': center, 'content-padding': padding}" class="content" :style="[ {'min-height': minHeight}, {'position': position} ]">
                <div>
                    <slot/>
                </div>
        </div>

        <div class="clearfix"></div>
    </div>
</template>

<style lang="scss" scoped>
    @import '~@/assets/scss/_vars.scss';
    @import "~@/assets/scss/variables/all";

    .card {
        position: relative;
        padding: $boxSpacing / 2;
        width: 100%;
        border-radius:2px;

        &:not(&:has(.multiselect)) {
            overflow: hidden;
        }

        .close {
            position: absolute;
            color: #000;
            right: 10px;
            top: 10px;
            opacity: .5;
            font-size: 18pt;
        }

        .header {
            display: flex;

            .right {
                margin-left: auto;
            }

            ~ .content {
                height: calc(100% - 50px) !important;
            }
        }

        .header-icon {
            .headline {
                font-size: 11pt;
            }

            .icon {
                float: left;
                font-size: 25px;
                margin-right: 10px;
                display: flex;

                span {
                    font-size: 30px;
                }
            }

            > div {
                float: left;

                font-size: 11pt;
                font-weight: 400;
                margin-top: -4px;

                p {
                    font-size: 10pt;
                }
            }
        }

        .content {
            height: 100%;
            padding: 0;

            &.content-padding {
                padding: $boxSpacing / 2;

                button{
                  border-radius:2px;
                  border-color:$signal-orange;
                  color:$signal-orange;
                }
            }

            &.is-center {
                width: 100%;
                justify-content: center;
                align-items: center;
                display: flex;
                flex-direction: column;
            }
        }

        .footer {
            padding: 10px 0 0 0;
            margin: 0;
        }

        .headline {
            &.oneHeadline {
                margin-top: 5px;
            }
        }

        hr {
            margin: 20px -30px;
        }
    }
    .is-from-facebook-ads{
      box-shadow: none;
    }
</style>

<script>
  import CardTooltip from '@/app/shared/components/CardTooltip'
  import SwitchToggle from "@/app/shared/components/SwitchToggle";

  export default {
    props: {
      icon: {
        type: String|Array
      },
      headline: {
        type: String
      },
      description: {
        type: String
      },
      close: {
        type: Boolean,
        default: false
      },
      center: {
        type: Boolean,
        default: false
      },
      minHeight: {
        type: String,
        default: '0px'
      },
      padding: {
        type: Boolean,
        default: true
      },
      position: {
        type: String,
        default: 'relative'
      },
      tooltip: {
        type: Object
      },
      isFromFacebookAds: {
        type: Boolean,
        default: false
      },
      hasToggle: {
        type: Boolean,
        default: false
      },
      toggleButtonLabel: {
        type: String,
        default: ''
      }
    },

    data () {
      return {
        visible: true,
        toggled: false
      }
    },

    computed: {
      isFontAwesome () {
        return !this.icon.includes('pe-7s')
      }
    },

    methods: {
      remove () {
        this.visible = false
      },

      hasHeader () {
        return this.icon || this.headline || this.description
      },

      toggle () {
        this.$emit('toggle')
      }
    },

    components: {
      CardTooltip,
      SwitchToggle
    }
  }
</script>

export default [
  {
    path: '/google-ads/ads',
    name: 'frontend.googleAds.ads',
    component: () => import(/* webpackChunkName: "Google" */ './pages/GoogleAds'),
    meta: {
      headline: () => $t('navigation.google.ads'),
      description: () => $t('topBar.descriptions.google.ads'),
      tracking: 'Google - Page view - Ads',
      datepicker: true
    }
  },
  {
    path: '/google-ads/profile',
    name: 'frontend.googleAds.profile',
    component: () => import(/* webpackChunkName: "Google" */ './pages/Profile'),
    meta: {
      headline: () => $t('navigation.google.profile'),
      description: () => $t('topBar.descriptions.google.profile'),
      tracking: 'Google - Page view - MyBusiness - Profile'
    }
  },
  {
    path: '/google-ads/profile/:page',
    name: 'frontend.googleAds.profile.page',
    component: () => import(/* webpackChunkName: "Google" */ './pages/Profile'),
    meta: {
      headline: () => $t('navigation.google.profile'),
      description: () => $t('topBar.descriptions.google.profile'),
      tracking: 'Profile - Page view - MyBusiness - Tab',
      datepicker: true
    }
  },
  {
    path: '/google-ads/posts',
    name: 'frontend.googleAds.posts',
    component: () => import(/* webpackChunkName: "Google" */ './pages/Posts'),
    meta: {
      headline: () => $t('navigation.google.posts'),
      description: () => $t('topBar.descriptions.google.posts'),
      tracking: 'Google - Page view - Posts'
    },
    children: [
      {
        path: 'overview',
        name: 'frontend.googleAds.posts.overview',
        component: () => import(/* webpackChunkName: "Google" */ './views/Overview'),
        meta: {
          headline: () => $t('navigation.google.posts'),
          description: () => $t('topBar.descriptions.google.posts'),
          tracking: 'Google - Page view - Posts - Overview'
        }
      },
      {
        path: 'event/create',
        name: 'frontend.googleAds.posts.create.event',
        component: () => import(/* webpackChunkName: "Google" */ './views/EventCreate'),
        meta: {
          headline: () => $t('navigation.google.posts'),
          description: () => $t('topBar.descriptions.google.posts'),
          tracking: 'Google - Page view - Posts - Event - Create'
        }
      },
      {
        path: 'event/edit/:name',
        name: 'frontend.googleAds.posts.edit.event',
        component: () => import(/* webpackChunkName: "Google" */ './views/EventEdit'),
        meta: {
          headline: () => $t('navigation.google.posts'),
          description: () => $t('topBar.descriptions.google.posts'),
          tracking: 'Google - Page view - Posts - Event - Edit'
        },
        props: true,
      },
      {
        path: 'post/create',
        name: 'frontend.googleAds.posts.create.post',
        component: () => import(/* webpackChunkName: "Google" */ './views/PostCreate'),
        meta: {
          headline: () => $t('navigation.google.posts'),
          description: () => $t('topBar.descriptions.google.posts'),
          tracking: 'Google - Page view - Posts - Post - Create'
        }
      },
      {
        path: 'post/edit/:name',
        name: 'frontend.googleAds.posts.edit.post',
        component: () => import(/* webpackChunkName: "Google" */ './views/PostEdit'),
        meta: {
          headline: () => $t('navigation.google.posts'),
          description: () => $t('topBar.descriptions.google.posts'),
          tracking: 'Google - Page view - Posts - Edit - Post'
        },
        props: true,
      }
    ]
  },
]

<template>
    <div class="id">
        <div class="form-group">
            <label>{{$t('integrations.backend.id')}}</label>
            <input type="text" class="form-control" v-model="id">
        </div>

        <button type="button" class="btn btn-success btn-block" @click="create">
            <span v-if="creatingIntegration" class="fa fa-spin fa-spinner"></span>
            {{$t('integrations.backend.add')}}
        </button>
    </div>
</template>

<script>
    import Loader from '@/app/shared/components/Loader'

    const IntegrationService = require('@/services/integrations/IntegrationService')

    export default {
        props: {
            service: Object,
            customer: Object
        },
        data() {
            return {
                creatingIntegration: false,
                id: null
            }
        },
        watch: {
            service() {
                this.reset();
            }
        },
        methods: {
            create() {
                if (!this.id) {
                    return;
                }

                this.creatingIntegration = true;

                var data = {
                    service: this.service.id,
                    customer: this.customer.id,
                    data: this.id,
                    connectedAccount: this.id
                };

                IntegrationService.create(data, (response) => {
                    this.creatingIntegration = false;
                    this.reset();

                    eventHub.$emit('integration.created', response.data);
                });
            },

            reset() {
                this.id = null;
                this.creatingIntegration = false;
            }
        },
        components: {
            Loader
        }
    }
</script>

<template>
	<div class="switch-international" v-if="customer">
		<a
			@click.prevent="toggleInternational"
			:class="{ 'is-active': isInternational }">
			<span class="hidden-sm hidden-xs">International data</span>
			<div v-if="hasLoaded" class="icon">
				<fa icon="globe-americas" v-if="isInternational" />
				<fa icon="globe-europe" v-else />
			</div>
			<div v-else class="icon">
				<fa icon="circle-notch" spin />
			</div>
		</a>
	</div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
	name: "SwitchInternational",
	data() {
		return {
			hasLoaded: true,
		};
	},
	computed: {
		...mapGetters("identity", {
			identity: "getIdentity",
		}),

		...mapGetters("customer", {
			customer: "getCustomer",
		}),

		isInternational() {
			if (!this.customer) {
				return;
			}

			return this.customer.includingInternationalTraffic;
		},
		from() {
			return this.isInternational ? "verden" : "Danmark";
		},
	},
	methods: {
		...mapActions("identity", {
			reloadIdentity: "loadIdentity",
		}),
		async toggleInternational() {
			if (!this.hasLoaded) return;
			this.hasLoaded = false;

			try {
				await this.$store.dispatch(
					"customer/includeInternationalTraffic",
					!this.isInternational
				);
				await this.reloadIdentity();
				this.hasLoaded = true;
			} catch (e) {
				this.hasLoaded = true;
				throw e;
			}
		},
	},
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/variables/all";
@import "~@/assets/scss/variables/colors";

.switch-international{
	a {
		display:flex;
		align-items:center;
		justify-content:center;
		gap:10px;
		color: $digital-blue;
		border-radius:2px;
		border: 1px solid $digital-blue;
		padding:5px 20px;
		font-size:12px;

		&.is-active {
			background-color: $digital-blue;
			color: $white;
		}

		.icon {
			margin:0 !important;
			font-size:11px;
		}
	}
}
</style>

import Vue from 'vue'
import Vuex from 'vuex'
import { NODE_ENV } from '@/config/env'
import AppStore from "@/app/app.store"

Vue.use(Vuex)

export default new Vuex.Store({
  ...AppStore,
  strict: NODE_ENV !== 'production'
})

import ApiService from '@/services/_app/ApiService'

const namespace = 'contacts/statuses'

export default class StatusService {
  static find (id, options) {
    return ApiService.get(namespace + '/' + id, options)
  }

  static findAll (options) {
    return ApiService.get(namespace, options)
  }

  static update (contactId, statusId) {
    return ApiService.post('contacts/' + contactId + '/status', { status: statusId })
  }
}

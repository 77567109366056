import * as TourService from '@/services/onboarding/TourService'
const introJs = require('intro.js')

import { mapGetters } from 'vuex'

require('intro.js/introjs.css')

export default {
  data () {
    return {
      tourMixinTourRunning: false,
    }
  },

  computed: {
    isOnboarding() {
      if (!this.$route) {
        return
      }

      if (!this.$route.query) {
        return
      }

      if (!this.$route.query.onboarding) {
        return
      }

      return this.$route.query.onboarding
    },
    ...mapGetters('ghost', ['isGhostIdentity']),
  },

  methods: {
    /**
     * @param slug
     * @param tourConfig
     */
    startTour(slug, tourConfig) {
      // If ghostIdentity do not start tour
      if (this.isGhostIdentity) {
          return
      }

      const options = {
        filter_groups: [
          {
            filters: [
              {
                key: 'slug',
                value: slug,
                operator: 'eq',
              }
            ]
          }
        ],
      }

      TourService.findAll(options, (response) => {
        const data = response.data

        if (data.total > 0) {
          return
        }

        // Create tour

        TourService.create({slug: slug}, () => {
          this.tourMixinStartTour(tourConfig)
        })
      })
    },

    /**
     * @param tourConfig
     */
    tourMixinStartTour(tourConfig) {
      if (this.tourMixinTourRunning) {
        return
      }

      if (window.innerWidth < 992) {
        return
      }

      this.tourMixinTourRunning = true

      const intro = introJs.introJs()

      intro.setOptions({
          scrollTo: 'tooltip',
          showStepNumbers: false,
          nextLabel: 'Næste',
          prevLabel: 'Tilbage',
          skipLabel: 'Luk',
          doneLabel: 'Kom i gang',
          disableInteraction: true,
      })

      intro.setOptions(tourConfig)

      intro.start()
    }
  }
}

import TokenService from '@/services/_app/storage/TokenService'
import Vue from 'vue'
import Qs from 'qs'

import store from '@/store'

export default class ApiService {
    static formatToUrl (_from, _to) {
        const from = _from.format('YYYY-MM-DD')
        const to = _to.format('YYYY-MM-DD')

        return `${from}/${to}`
    }

    static setHeader() {
        const token = TokenService.getToken()
        if (token) {
            Vue.axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        } else {
            ApiService.removeHeader()
        }
    }

    static removeHeader() {
        Vue.axios.defaults.headers.common["Authorization"] = null;
    }

    static get(resource, params, withData) {
        return ApiService.request({
            method: "GET",
            url: resource + `?${Qs.stringify(this.resolveData(params))}`,
            withData
        });
    }

    static post(resource, data, withData) {
        return ApiService.request({
            method: "POST",
            url: resource,
			data: this.resolveData(data),
            withData
        });
    }

    static put(resource, data, withData) {
        return ApiService.request({
            method: "PUT",
            url: resource,
            data: this.resolveData(data),
			withData
        });
    }

    static delete(resource, withData) {
        return ApiService.request({
            method: "DELETE",
            url: resource
        });
    }

    /**
     * Perform a custom Axios request.
     *
     * data is an object containing the following properties:
     *  - method
     *  - url
     *  - data ... request payload
     **/
    static async request(data, withData = false) {
        try {
            const response = await Vue.axios(data);

            if (!response.data || withData) {
                return Promise.resolve(response);
            }

            return Promise.resolve(response.data);
        } catch (e) {
            throw e;
        }
    }

	static resolveData(data) {
		const customer = store.getters['customer/getCustomer']
		const isDemo = store.getters['ghost/isDemo']

		if (customer) {
			data = {
				customerId: customer.id,
				...data
			}
		}

		if (isDemo) {
			data = {
				demo: 1,
				...data
			}
		}

		return data
	}
}

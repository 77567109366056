import { render, staticRenderFns } from "./FacebookAds.vue?vue&type=template&id=286f68af&scoped=true&"
import script from "./FacebookAds.vue?vue&type=script&lang=js&"
export * from "./FacebookAds.vue?vue&type=script&lang=js&"
import style0 from "./FacebookAds.vue?vue&type=style&index=0&id=286f68af&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "286f68af",
  null
  
)

export default component.exports
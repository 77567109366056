<template>
	<div class="tip"
		 :style="style">
		<div v-for="(tip, index) in shuffledTips"
			 :ref="`tip-${index}`"
			 :class="{
				 active: i === index,
				 before: i < index || i === (tips.length - 1 && i !== index)
			 }"
			 :key="`tip-${index}`">
			<strong v-text="tip.title" />

			<div class="description"
				 v-text="tip.tip"
			/>
		</div>
	</div>
</template>

<script>
export default {
	data: () => ({
		i: -1,
		interval: null
	}),

	computed: {
		tips() {
			return this.$t('serpGrid.tips')
		},

		shuffledTips() {
			const tips = JSON.parse(JSON.stringify(this.tips))

			for (let i = tips.length - 1; i > 0; i--) {
				const j = Math.floor(Math.random() * (i + 1));
				[tips[i], tips[j]] = [tips[j], tips[i]];
			}

			return tips
		},

		style() {
			const $el = (this.$refs[`tip-${this.i}`] || [])[0] || null

			if (! $el) {
				return
			}

			const boundaries = $el.getBoundingClientRect()

			return {
				width: `${boundaries.width}px`,
				height: `${boundaries.height}px`
			}
		}
	},

	mounted() {
		this.interval = setInterval(this.tick, 4000)
		this.tick()
	},

	beforeDestroy() {
		clearInterval(this.interval)
	},

	methods: {
		tick() {
			this.i = this.i === this.tips.length - 1
				? 0
				: this.i + 1
		}
	}
}
</script>

<style lang="scss" scoped>
.tip {
	position: relative;
	width: 100%;
	max-width: 500px;
	border-radius: 8px;
	background-color: #fff;
	overflow: hidden;

	transition: all 1s ease-in-out;

	box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;

	> div {
		position: absolute;
		padding: 20px;

		width: 100%;
		max-width: 500px;

		opacity: 0;
		transform: translateY(-100%);

		transition: opacity .5s ease-in-out, transform 1s ease-in-out;

		&.before {
			transform: translateY(100%);
			transition: opacity .5s ease-in-out, transform 1s ease-in-out .15s;
		}

		&.active {
			opacity: 1;
			transform: translateY(0);
		}

		strong {
			color: #000;
			font-size: 14px;
			font-weight: 500;
		}

		> div.description {
			color: #363636;
			font-size: 13px;
			font-weight: 400;
		}
	}
}
</style>

const BaseService = require('@/services/BaseService')

const namespace = 'billing/plans'

const find = (id, cb, errorCb) => {
  BaseService.get(`${namespace}/${id}`, {}, cb, errorCb)
}

const findAll = (body, cb, errorCb) => {
  BaseService.get(namespace, body, cb, errorCb)
}

const update = (id, body, cb, errorCb) => {
  BaseService.put(`${namespace}/${id}`, body, cb, errorCb)
}

export {
  find,
  findAll,
  update
}

import Adversus from '@/app/leads/Adversus'
import Leads from '@/app/leads/Leads'

export default [
	{
		path: '/backend/adversus',
		name: 'backend.adversus',
		component: Adversus,
	},
	{
		path: '/backend/leads',
		name: 'backend.leads',
		component: Leads,
	},
]

<template>
    <div class="customer-view">
        <loader v-if="!hasLoaded"></loader>

        <!-- Customer not found -->
        <div class="container-fluid" v-if="hasLoaded">
            <div class="row">
                <template v-if="!customer">
                    <div class="col-md-12">
                        <div class="alert alert-danger text-center">
                            {{$t('customers.notFound')}}
                        </div>
                    </div>
                </template>

                <template v-else>
                    <!-- Info -->
                    <div class="col-xxl-6 col-lg-4 col-md-12 col-sm-12 col-xs-12">
                        <card icon="pe-7s-user" :headline="customer.name" :description="customer.domain">
                            <div class="row">
                                <customer-meta-data :customer="customer"
													:subscriptions="subscriptions"
								/>
                            </div>
                        </card>

                        <card class="hidden-md hidden-sm hidden-xs">
                            <call-tracking/>
                        </card>

                        <card class="hidden-md hidden-sm hidden-xs">
                            <tokens/>
                        </card>
                    </div>

                    <!-- Pages -->
                    <div class="col-xxl-6 col-lg-8 col-md-12 col-sm-12 col-xs-12" v-if="identity.role !== 'employee-restricted'">
                        <tab-navigation>
                            <ul>
                                <li>
                                    <router-link :to="{ name: 'backend.customers.view.users' }">
                                        {{$t('customers.navigation.users')}}
                                    </router-link>
                                </li>

                                <li>
                                    <router-link :to="{ name: 'backend.customers.view.integrations' }">
                                        {{$t('customers.navigation.integrations')}}
                                    </router-link>
                                </li>

                                <li>
                                    <router-link :to="{ name: 'backend.customers.view.keywords' }">
                                        {{$t('customers.navigation.keywords')}}
                                    </router-link>
                                </li>

                                <li>
                                    <router-link :to="{ name: 'backend.customers.view.settings' }">
                                        {{$t('customers.navigation.settings')}}
                                    </router-link>
                                </li>

                                <li>
                                    <router-link :to="{ name: 'backend.customers.view.copilot' }">
                                        Co-pilot
                                    </router-link>
                                </li>

                                <li>
                                    <router-link :to="{ name: 'backend.customers.view.subscriptions' }">
                                        Subscriptions
                                    </router-link>
                                </li>

                                <li>
                                    <router-link :to="{ name: 'backend.customers.view.reports' }">
                                        {{$t('customers.navigation.reports')}}
                                    </router-link>
                                </li>

                                <li>
                                    <router-link :to="{ name: 'backend.customers.view.xtract' }">
                                        Xtract
                                    </router-link>
                                </li>
                            </ul>

                            <hr/>

                            <router-view />
                        </tab-navigation>
                    </div>

                    <div class="hidden-lg col-md-12 col-sm-12 col-xs-12">
                        <card>
                            <call-tracking/>
                        </card>

                        <card>
                            <tokens/>
                        </card>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    @import '~@/assets/scss/_vars.scss';

    @media screen and (min-width: 1200px) and (max-width: 1530px) {
        .col-xxl-6 {
            width: 50% !important;
        }
    }

    .customer-view {
        .info {
            float: left;
            margin-top: 10px;

            .icon {
                color: #3587ff;
                font-size: 30pt;
                margin-right: 20px;
            }

            h1 {
                font-size: 13pt;
                margin: 0;
                padding: 0;
                margin-top: 2px;
            }

            .domain {
                color: #898989;
            }
        }

        .attention {
            display: inline-block;
            background: $primaryAlert;
            color: $tertiaryAlert;
            padding: 3px 7px;
            border-radius: 3px;
            margin-top: 10px;
            font-size: 10px;
            font-weight: 500;
        }

        .actions {
            float: right;
            text-align: right;

            .synchronized {
                display: block;
                color: #aeaeae;
                font-size: 9pt;
                margin-top: -10px;
            }

            .btn-success {
                text-transform: uppercase;
                font-weight: 500;
                font-size: 9pt;
                margin-top: 15px;
            }
        }

        .managers {
            display: flex;

            .manager {
                display: flex;
                flex-grow: 1;
                flex-basis: 0;

                > div {
                    margin-right: 15px;

                    &.image {
                        width: 50px;
                        height: 50px;
                        border-radius: 50%;
                        border: 1px solid #ccc;
                    }

                    &.info {
                        margin-top: 6px;

                        .name {
                            display: block;
                            letter-spacing: 0.5px;
                            font-size: 11pt;
                        }

                        .position {
                            color: #898989;
                            font-size: 10pt;
                        }

                        > span {
                            white-space: nowrap;
                            text-overflow: ellipsis;
                        }
                    }
                }
            }
        }
    }
</style>

<script>
  import Loader from '@/app/shared/components/Loader'
  import Card from '@/app/shared/components/Card'
  import TabNavigation from '@/app/layout/components/TabNavigation'
  import ClientCategory from '@/app/customers/components/ClientCategory'
  import CustomerMetaData from '@/app/customers/components/backend/CustomerMetaData'

  import CallTracking from '@/app/customers/components/CallTracking'
  import Tokens from '@/app/customers/components/Tokens'
  import { mapGetters } from 'vuex'
  import * as SubscriptionService from '@/services/billing/SubscriptionService'

  const CustomerService = require('@/services/customers/CustomerService')

  export default {
    name: 'CustomerView',
    data () {
      return {
        hasLoaded: false,
        customer: null,
		subscriptions: []
      }
    },

    computed: {
      ...mapGetters('identity', {
        identity: 'getIdentity'
      }),

      id () {
        return this.$route.params.id
      },

      emails () {
        return this.customer.users
          .filter(user => user.deleted === null)
          .map(user => user.email)
      },

      oneOrMoreMissingInquiryNotificationSubscriptions () {
        const customer = this.customer

        if (!customer) {
          return true
        }

        const users = customer.users

        for (let i = 0; i < users.length; i++) {
          const user = users[i]
          const matchingSubscriptions = user.notificationSubscriptions
            .filter(subscription => subscription.slug === 'form')

          if (matchingSubscriptions.length === 0) {
            return true
          }
        }

        return false
      },

      clientManager () {
        if (!this.customer) {
          return
        }

        return this.customer.clientManager
      },

      products () {
        if (!this.customer) {
          return []
        }

        return this.customer.products
      },

      users () {
        if (!this.customer.users) {
          return []
        }

        return this.customer.users.filter(user => user.deleted === null)
      },

      routePage () {
        return this.$route.name
      }
    },

    watch: {
      id () {
        this.load()
	    this.loadSubscriptions()
      },

      routePage () {
        this.checkPage()
      }
    },

    mounted () {
      this.checkPage()
      this.load()
      this.loadSubscriptions()

      eventHub.$on('user.email.changed', this.load)
      eventHub.$on('backend.customer.updated', this.load)
    },

    destroyed () {
      eventHub.$off('user.email.changed', this.load)
      eventHub.$off('backend.customer.updated', this.load)
    },

    methods: {
	  loadSubscriptions() {
		  SubscriptionService.findAll({
			  includes: ['plan'],

			  sort: [{
				  key: 'created',
				  direction: 'DESC'
			  }],

			  filter_groups: [{
				  filters: [{
					  key: 'customer',
					  operator: 'eq',
					  value: this.id
				  }]
			  }]
		  }, ({ data }) => {
			  this.subscriptions = data.rows
		  }, () => {
		  })
	  },

      load () {
        this.hasLoaded = false
        this.customer = null

        const data = {
          includes: [
            'clientManager',
            'products',
            'industry',
            'organization',
            'users',
            'crmConnection',
            'products.responsible',
            'productResponsibles',
            'users.notificationSubscriptions',
            'integrations',
            'integrations.service',
		    'country'
          ]
        }

        CustomerService.find(this.id, data, response => {
          const body = response.data

          this.customer = body
          this.hasLoaded = true
        }, () => {
          this.hasLoaded = true
        })
      },

      checkPage () {
        if (this.routePage === 'backend.customers.view') {
          this.$router.push({
            name: 'backend.customers.view.users'
          })

          return
        }

        this.$router.push({
          name: this.routePage
        })
      }
    },

    components: {
      Loader,
      Card,
      TabNavigation,
      ClientCategory,
      CustomerMetaData,
      CallTracking,
      Tokens
    }
  }
</script>

const BaseService = require('@/services/BaseService')

const namespace = 'co-pilot/items'

const find = (id, options, cb, errorCb) => {
	BaseService.get(`${namespace}/${id}`, options, cb, errorCb)
}

const findAll = (body, cb, errorCb) => {
	BaseService.get(namespace, body, cb, errorCb)
}

const post = (data, cb, errorCb) => {
	BaseService.post(namespace, data, cb, errorCb)
}

const put = (id, data, cb, errorCb) => {
	BaseService.put(namespace + '/' + id, data, cb, errorCb)
}

const remove = (id, data, cb, errorCb) => {
	BaseService.remove(namespace + '/' + id, data, cb, errorCb)
}

const exportItem = (data, cb, errorCb, config) => {
	BaseService.get('/co-pilot/actions/export', data, cb, errorCb, config)
}

const downloadExport = (id, cb, errorCb, config = {}) => {
	BaseService.get(`/co-pilot/exports/${id}`, {}, cb, errorCb, config)
}

export default {
	find,
	findAll,
	post,
	put,
	remove,
	exportItem,
	downloadExport
}

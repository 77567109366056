<template>
    <div class="notifications">
        <div class="row" v-if="!hasLoaded">
            <div class="col-lg-12" style="height: 120px; position: relative;">
                <logo-loader />
            </div>
        </div>

        <div class="row" v-else>
            <div class="col-lg-12" style="margin-bottom: 30px;">
                <div class="col-custom-row">
                    <div class="col-custom-12"
                         style="padding: 5px;"
                         v-for="group in notificationGroups">

                        <ul class="group-list">
                            <li class="notification-type" @click="toggleGroup(group)">
                                <input type="checkbox" class="clickable" :checked="hasAllNotificationsInGroup(group)" />
                                {{$t(`profile.notificationCenter.${group.slug}.title`)}}
                            </li>

                            <li>
                                <ul>
                                    <li class="notification-type" v-for="type in group.types" @click="toggleNotification(type)">
                                        <input type="checkbox" class="clickable" :checked="subscriptions[user.id].includes(type.id)" />
                                        <span v-html="$t(`profile.notificationCenter.${group.slug}.${type.slug}`)"/>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    @import '~@/assets/scss/_vars.scss';

    .notifications {
        padding: 15px;
        .headline {
            display: block;
            font-size: 20px;
            font-weight: 500;
            margin-bottom: 10px;
        }
        .user {
            margin-bottom: 10px;
            font-weight: 500;
            text-transform: lowercase;
        }
    }

    .selector {
        margin-top: 5px;
        width: auto;
    }

    .information-label {
        background: $primaryWarning;
        color: $tertiaryWarning;
        padding: 8px 16px;
        border-radius: 3px;
        margin-top: 10px;
        display: inline-block;
        i {
            margin-right: 5px;
        }
    }

    .group-list {
        .no-indent {
            padding: 0;
        }

        &:not(.no-indent) {
            > li:first-child {
                background: #f0f0f0;
            }
        }
    }

    .notification-type {
        padding: 5px 10px;
        border-radius: 3px;
        background: #f9f9f9;
        margin-bottom: 5px;
        &:hover {
            span.plus {
                margin-right: 5px;
            }
        }
        span.plus {
            transition: all 0.8s ease;
            margin-top: 3px;
            float: right;
        }
    }

    ul {
        width: 100%;
        list-style-type: none;
        padding-left: 0;
        li {
            cursor: pointer;
            font-size: 12px;
            .clickable {
                margin-right: 10px;
                display: inline-block;
            }
        }
    }
</style>

<script>
    import LogoLoader from '@/app/shared/components/LogoLoader'

    import * as NotificationService from '@/services/notifications/NotificationService'
    import NotificationGroupService from '@/services/notifications/NotificationGroupService'

    export default {
        props: {
            user: {
                type: Object,
                required: true
            }
        },

        data() {
            return {
                subscriptions: {},
                notificationGroups: [],
                hasLoaded: false
            }
        },

        mounted() {
            this.$set(
                this.subscriptions,
                this.user.id,
                this.user.notificationSubscriptions.map(notificationSubscription => {
                    return notificationSubscription.id
                })
            )

            this.loadNotificationGroups()
        },

        methods: {
            loadNotificationGroups() {
                this.hasLoaded = false

                NotificationGroupService.findAll({
                    includes: ['types']
                }, response => {
                    const data = response.data

                    // Remove parent group 'triggerMail'
                    this.notificationGroups = data.rows.filter(group => {
                        return group.slug !== 'triggerMail'
                    }).sort((a,b) => {
                        return b.types.length - a.types.length
                    })

                    this.hasLoaded = true
                })
            },

            hasAllNotificationsInGroup(group) {
                let hasAllNotifications = true

                const subscriptions = this.subscriptions[this.user.id]

                group.types.forEach(type => {
                    if (!subscriptions.includes(type.id)) {
                        hasAllNotifications = false
                    }
                })

                return hasAllNotifications
            },

            toggleNotification(type) {
                const subscriptions = this.subscriptions[this.user.id].slice(0)

                // Unsubscribe
                if (subscriptions.includes(type.id)) {
                    if (type.important) {
                        this.$swal({
                            text: $t('profile.notificationCenter.swal.areYouCertainSingle'),
                            type: 'warning',
                            showCancelButton: true,
                            confirmButtonText: $t('profile.notificationCenter.swal.confirm'),
                            cancelButtonText: $t('profile.notificationCenter.swal.decline')
                        }).then(() => {
                            subscriptions.splice(subscriptions.indexOf(type.id), 1)
                            this.updateNotifications(subscriptions)
                        }).catch(() => {
                            this.updateNotifications(subscriptions)
                        })

                        return
                    }

                    subscriptions.splice(subscriptions.indexOf(type.id), 1)
                    this.updateNotifications(subscriptions)
                    return
                }

                subscriptions.push(type.id)

                // Subscribe
                this.updateNotifications(subscriptions)
            },

            hasAllNotificationsInGroup(group) {
                const user = this.user
                const id = user.id

                let hasAllNotifications = true

                const subscriptions = this.subscriptions[id]

                group.types.forEach(type => {
                    if (!subscriptions.includes(type.id)) {
                        hasAllNotifications = false
                    }
                })

                return hasAllNotifications
            },

            toggleGroup(group) {
                const user = this.user
                const id = user.id

                const subscriptions = this.subscriptions[id].slice(0)

                // Unsubscribe
                if (this.hasAllNotificationsInGroup(group)) {
                    const match = group.types.find(type => {
                        return type.important
                    })

                    if (match) {
                        this.$swal({
                            text: $t('profile.notificationCenter.swal.areYouCertainSeveral'),
                            type: 'warning',
                            showCancelButton: true,
                            confirmButtonText: $t('profile.notificationCenter.swal.confirm'),
                            cancelButtonText: $t('profile.notificationCenter.swal.decline')
                        }).then(() => {
                            group.types.forEach(type => {
                                if (!subscriptions.includes(type.id)) {
                                    return
                                }

                                subscriptions.splice(subscriptions.indexOf(type.id), 1)
                            })

                            this.updateNotifications(subscriptions)
                        }).catch(() => {
                            this.updateNotifications(subscriptions)
                        })
                        return
                    }

                    group.types.forEach(type => {
                        if (!subscriptions.includes(type.id)) {
                            return
                        }

                        subscriptions.splice(subscriptions.indexOf(type.id), 1)
                    })

                    this.updateNotifications(subscriptions)
                    return
                }

                // Subscribe
                group.types.forEach(type => {
                    if (subscriptions.includes(type.id)) {
                        return
                    }

                    subscriptions.push(type.id)
                })

                this.updateNotifications(subscriptions)
            },

            updateNotifications(subscriptions) {
                const user = this.user
                const id = user.id

                const currentSubscriptions = this.subscriptions[id]

                // Unsubscribe from notifications
                currentSubscriptions.forEach(notificationId => {
                    if (subscriptions.includes(notificationId)) {
                        return
                    }

                    NotificationService.unsubscribe(id, notificationId)
                })

                // Subscribe to notifications
                subscriptions.forEach(notificationId => {
                    if (currentSubscriptions.includes(notificationId)) {
                        return
                    }

                    NotificationService.subscribe(id, {typeId: notificationId})
                })

                this.$set(this.subscriptions, id, subscriptions)
            }
        },

        components: {
            LogoLoader
        }
    }
</script>

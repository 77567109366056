<template>
	<div>
		<div class="row">
			<div class="col-12 col-lg-6">
				<div><strong>From</strong></div>
				<date-picker
					:editable="false"
					:clearable="false"
					v-model="from"
					valueType="format"
					style="width: 100%;"
					:disabled="loading"
				/>
			</div>

			<div class="col-12 col-lg-6">
				<div><strong>To</strong></div>
				<date-picker
					:editable="false"
					:clearable="false"
					v-model="to"
					valueType="format"
					style="width: 100%;"
					:disabled="loading"
				/>
			</div>
		</div>
		
		<div class="row">
			<div class="col-12">
				<div class="compare-to">
					<span>Compare to:</span>
					<div @click="compareTo = 'year'" :class="{ active: compareTo === 'year' }">
						<div />
						<span>Previous year</span>
					</div>

					<div @click="compareTo = 'period'" :class="{ active: compareTo === 'period' }">
						<div />
						<span>Previous period</span>
					</div>
				</div>
			</div>
		</div>

		<div class="fields">
			<div v-for="key in Object.keys(groups)"
				 :key="`group-${key}`"
				 class="group">
				<div class="title">
					<i :class="groups[key].icon" class="fa-fw" />
					<span @click="toggleGroup(key)">{{ groups[key].label }}</span>

					<div class="right">
						<button type="button"
								@click="toggleGroupMetrics(key)"
								class="toggle-all">
							<span v-if="groupStatus(key).active !== groupStatus(key).total">Select all</span>
							<span v-else>Unselect all</span>
						</button>

						<div class="status"><span>{{ groupStatus(key).active }}</span> / {{ groupStatus(key).total }}</div>

						<button type="button"
								@click="toggleGroup(key)"
								class="toggle">
							<i v-if="visibleGroups.includes(key)" class="far fa-chevron-down fa-fw" />
							<i v-else class="far fa-chevron-up fa-fw" />
						</button>
					</div>
				</div>

				<div v-if="visibleGroups.includes(key)" class="metrics">
					<div v-for="metricKey in Object.keys(groups[key].metrics)"
						 :key="`metric-${metricKey}`"
						 class="metric">
						<input :id="`metric-${metricKey}`"
							   type="checkbox"
							   v-model="fields"
							   :value="metricKey"
						/>

						<label :for="`metric-${metricKey}`">
							<i :class="groups[key].metrics[metricKey].icon" class="fa-fw" />
							<span>{{ groups[key].metrics[metricKey].label }}</span>
						</label>
					</div>
				</div>
			</div>
		</div>

		<button @click="load" :disabled="! canSubmit" class="btn mt-3">Generate Xtract</button>

		<div class="report" v-html='text' />
	</div>
</template>

<style lang="scss" scoped>
.fields {
	border: 1px solid #e3e3e3;
	border-radius: 8px;
	margin: 20px 0;

	> div.group {
		border-bottom: 1px solid #e3e3e3;

		&:last-child {
			border-bottom: 0;
		}

		> div.title {
			display: flex;
			align-items: center;
			gap: 10px;
			padding: 20px;

			> span {
				cursor: pointer;
				user-select: none;
				color: #343434;
				font-weight: 500;
				font-size: 16px;
			}

			> div.right {
				margin-left: auto;

				display: flex;
				align-items: center;
				gap: 10px;

				> button.toggle-all {
					color: #636363 !important;
					background-color: #eee !important;
					border-radius: 6px;
					font-size: 12px;
					font-weight: 500;
					padding: 3px 6px !important;
					letter-spacing: -.5px;
					border: 0;

					&:hover {
						background-color: #ccc !important;
					}
				}

				> div.status {
					background-color: #eee;
					border-radius: 6px;
					font-size: 12px;
					font-weight: 500;
					padding: 3px 6px;
					letter-spacing: -.5px;
					color: #636363;

					> span {
						font-weight: 700;
					}
				}

				> button.toggle {
					display: flex;
					align-items: center;
					justify-content: center;
					width: 34px;
					height: 34px;
					color: #000 !important;
					padding: 0 !important;
					border: 0;
					background-color: transparent !important;
					border-radius: 50%;
					margin-left: auto;

					&:hover {
						background-color: #eee !important;
					}
				}
			}
		}

		> div.metrics {
			padding: 30px 20px;

			border-top: 1px solid #eee;

			display: grid;
			gap: 20px;
			grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));

			> div.metric {
				display: flex;
				align-items: center;
				gap: 12px;
				line-height: 100%;

				input {
					margin: 0;
				}

				label {
					display: flex;
					align-items: center;
					gap: 8px;

					color: #000;
					line-height: 100%;
					margin: 0;
					padding: 0;
					font-weight: 400;
				}
			}
		}
	}
}

button {
	color: #fff !important;
	border: 0 !important;
	font-weight: 500 !important;
	padding: 12px 30px !important;
	background-color: #000 !important;
}


.report {
	font-size: 16px;
	line-height: 160%;
	margin-top: 25px;
	font-weight: 400;

	::v-deep * {
		font-size: 16px;
	}

	::v-deep li {
		margin: 10px 0;
	}
}

.compare-to {
	display: flex;
	align-items: center;
	gap: 20px;
	flex-wrap: wrap;
	margin: 20px;

	> span {
		color: #000;
		font-size: 14px;
		font-weight: 400;
	}

	> div {
		display: flex;
		align-items: center;
		gap: 6px;

		opacity: .6;

		transition: all .25s ease-in-out;

		user-select: none;
		cursor: pointer;

		> div {
			position: relative;
			width: 20px;
			height: 20px;
			border: 1px solid #000;
			border-radius: 50%;
		}

		> span {
			color: #000;
			font-size: 14px;
			font-weight: 500;
		}

		&:hover {
			opacity: 1;
		}

		&.active {
			opacity: 1;

			> div {
				&:after {
					position: absolute;
					content: '';
					top: 2px;
					right: 2px;
					bottom: 2px;
					left: 2px;
					background-color: #000;
					border-radius: 50%;
				}
			}
		}
	}
}
</style>

<script>
import moment from 'moment'
import DatePicker from 'vue2-datepicker'
import Qs from 'qs'
import {API_URL} from '@/config/env'
import TokenService from '@/services/_app/storage/TokenService'
import XhrStream from 'xhr-stream'
import FacebookService from '@/services/_app/meta/FacebookService'

import marketingMixin from '@/mixins/marketingMixin'

export default {
	mixins: [
		marketingMixin
	],

	data: () => ({
		from: null,
		to: null,
		fields: [],

		visibleGroups: ['default'],

		loading: false,
		reportData: {},
		report: null,
		stream: null,

		compareTo: 'year'
	}),

	computed: {
		daysBetween() {
			const from = this.from
			const to = this.to

			if (! from || ! to) {
				return 0
			}

			return Math.abs(moment(to).diff(moment(from), 'days'))
		},

		previousFrom() {
			if (! this.from) {
				return null
			}

			switch (this.compareTo) {
				case 'period':
					return moment(this.from).subtract(this.daysBetween, 'days')

				default:
				case 'year':
					return moment(this.from).subtract(1, 'year')
			}
		},

		previousTo() {
			if (! this.to) {
				return null
			}

			switch (this.compareTo) {
				case 'period':
					return moment(this.to).subtract(this.daysBetween, 'days')

				default:
				case 'year':
					return moment(this.to).subtract(1, 'year')
			}
		},

		metrics() {
			return Object.values(this.groups)
				.reduce(
					(carry, group) => ({
						...carry,
						...Object.keys(group.metrics).reduce(
							(c, key) => ({
								...c,
								[key]: {
									...group.metrics[key],
									group: group.label
								}
							}),
							{}
						)
					}),
					{}
				)
		},

		groups() {
			return {
				default: {
					icon: 'far fa-list fa-fw',
					label: this.$t('meta.ads.views.default'),
					metrics: {
						impressions: {
							icon: 'far fa-eye',
							field: 'impressions',
							type: 'number',
							label: this.$t(`meta.ads.insights.impressions`)
						},
						reach: {
							icon: 'far fa-users',
							field: 'reach',
							type: 'number',
							label: this.$t(`meta.ads.insights.reach`)
						},
						clicks: {
							icon: 'far fa-arrow-right-arrow-left',
							field: 'clicks',
							type: 'number',
							label: this.$t(`meta.ads.insights.clicks`)
						},
						inline_link_clicks: {
							icon: 'far fa-mouse-pointer',
							field: 'inline_link_clicks',
							type: 'number',
							label: this.$t(`meta.ads.insights.outbound_clicks`)
						},
						cost_per_inline_link_click: {
							icon: 'far fa-dollar',
							field: 'cost_per_inline_link_click',
							type: 'currency',
							label: this.$t(`meta.ads.insights.cost_per_inline_link_click`)
						},
						spend: {
							icon: 'far fa-piggy-bank',
							field: 'spend',
							type: 'currency',
							label: this.$t(`meta.ads.insights.spend`)
						}
					}
				},

				efficiency: {
					icon: 'far fa-mouse-pointer fa-fw',
					label: this.$t('meta.ads.views.efficiency'),

					metrics: {
						inline_link_clicks: {
							icon: 'far fa-mouse-pointer',
							field: 'inline_link_clicks',
							type: 'number',
							label: this.$t(`meta.ads.insights.outbound_clicks`)
						},
						cost_per_inline_link_click: {
							icon: 'far fa-dollar',
							field: 'cost_per_inline_link_click',
							type: 'currency',
							label: this.$t(`meta.ads.insights.cost_per_outbound_click`)
						},
						inline_link_click_ctr: {
							icon: 'far fa-dollar',
							field: 'inline_link_click_ctr',
							type: 'percentage',
							label: this.$t(`meta.ads.insights.outbound_clicks_ctr`)
						},
						frequency: {
							icon: 'far fa-wave-pulse',
							field: 'frequency',
							type: 'number',
							label: this.$t(`meta.ads.insights.frequency`)
						},
						reach: {
							icon: 'far fa-users',
							field: 'reach',
							type: 'number',
							label: this.$t(`meta.ads.insights.reach`)
						},
						spend: {
							icon: 'far fa-piggy-bank',
							field: 'spend',
							type: 'currency',
							label: this.$t(`meta.ads.insights.spend`)
						}
					}
				},

				branding: {
					icon: 'far fa-certificate fa-fw',
					label: this.$t('meta.ads.views.branding'),

					metrics: {
						reach: {
							icon: 'far fa-users',
							field: 'reach',
							type: 'number',
							label: this.$t(`meta.ads.insights.reach`)
						},
						impressions: {
							icon: 'far fa-eye',
							field: 'impressions',
							type: 'number',
							label: this.$t(`meta.ads.insights.impressions`)
						},
						cpm: {
							icon: 'far fa-eyes',
							field: 'cpm',
							type: 'currency',
							label: this.$t(`meta.ads.insights.cpm`)
						},
						frequency: {
							icon: 'far fa-wave-pulse',
							field: 'frequency',
							type: 'number',
							label: this.$t(`meta.ads.insights.frequency`)
						},
						video_play_actions: {
							icon: 'far fa-play',
							field: 'video_play_actions',
							type: 'number',
							label: this.$t(`meta.ads.insights.video_play_actions`)
						},
						spend: {
							icon: 'far fa-piggy-bank',
							field: 'spend',
							type: 'currency',
							label: this.$t(`meta.ads.insights.spend`)
						}
					}
				},

				video: {
					icon: 'far fa-video fa-fw',
					label: this.$t('meta.ads.views.video'),

					metrics: {
						video_play_actions: {
							icon: 'far fa-play',
							field: 'video_play_actions',
							type: 'number',
							label: this.$t(`meta.ads.insights.video_play_actions`)
						},
						reach: {
							icon: 'far fa-users',
							field: 'reach',
							type: 'number',
							label: this.$t(`meta.ads.insights.reach`)
						},
						video_p100_watched_actions: {
							icon: 'far fa-hundred-points',
							field: 'video_p100_watched_actions',
							type: 'number',
							label: this.$t(`meta.ads.insights.video_p100_watched_actions`)
						},
						full_play_rate: {
							icon: 'far fa-percentage',
							type: 'percentage',
							subtype: 'calculation',
							calculation(insights) {
								const fullPlays = Number((((insights.video_p100_watched_actions || [])[0]) || {}).value || 0)
								const plays = Number((((insights.video_play_actions || [])[0]) || {}).value || 0)

								if (! fullPlays || ! plays) {
									return null
								}

								return fullPlays / plays * 100
							},
							label: this.$t(`meta.ads.insights.fullPlayRate`)
						},
						cost_per_thruplay: {
							icon: 'far fa-coin',
							type: 'currency',
							subtype: 'calculation',
							calculation(insights) {
								const plays = Number((((insights.video_p100_watched_actions || [])[0]) || {}).value || 0)
								const spend = Number(insights.spend || 0)

								if (! plays || ! spend) {
									return null
								}

								return spend / plays
							},
							label: this.$t(`meta.ads.insights.cost_per_thruplay`)
						},
						spend: {
							icon: 'far fa-piggy-bank',
							field: 'spend',
							type: 'currency',
							label: this.$t(`meta.ads.insights.spend`)
						}
					}
				},

				ecommerce: {
					icon: 'far fa-shopping-cart fa-fw',
					label: this.$t('meta.ads.views.ecommerce'),

					metrics: {
						add_to_cart: {
							icon: 'far fa-shopping-cart',
							field: 'add_to_cart',
							type: 'number',
							subtype: 'action',
							label: this.$t(`meta.ads.insights.add_to_cart`)
						},
						purchase: {
							icon: 'far fa-check',
							field: 'purchase',
							type: 'number',
							subtype: 'action',
							label: this.$t(`meta.ads.insights.purchase`)
						},
						omni_purchase: {
							icon: 'far fa-dollar',
							field: 'omni_purchase',
							type: 'currency',
							subtype: 'action_value',
							label: this.$t(`meta.ads.insights.action_values:omni_purchase`)
						},
						purchase_conversation_rate: {
							icon: 'far fa-percent',
							type: 'percentage',
							subtype: 'calculation',
							calculation(insights) {
								const actions = insights.actions || []
								const purchases = Number((actions.find(action => action.action_type === 'purchase') || {}).value || 0)
								const clicks = insights.clicks

								if (! purchases || ! clicks) {
									return null
								}

								return purchases / clicks * 100
							},
							label: this.$t(`meta.ads.insights.conversionRate`)
						},
						website_purchase_roas: {
							icon: 'far fa-rotate',
							field: 'website_purchase_roas',
							type: 'number',
							label: this.$t(`meta.ads.insights.website_purchase_roas`)
						},
						spend: {
							icon: 'far fa-piggy-bank',
							field: 'spend',
							type: 'currency',
							label: this.$t(`meta.ads.insights.spend`)
						}
					}
				},

				leads: {
					icon: 'far fa-filter fa-fw',
					label: this.$t('meta.ads.views.leads'),

					metrics: {
						leads: {
							icon: 'far fa-filter',
								field: 'lead',
							type: 'number',
							subtype: 'action',
							label: this.$t(`meta.ads.insights.leads`)
						},
						cost_per_lead: {
							icon: 'far fa-coin',
							field: 'lead',
							type: 'currency',
							subtype: 'cost_per_action_type',
							label: this.$t(`meta.ads.insights.pricePerLead`)
						},
						lead_conversation_rate: {
							icon: 'far fa-percent',
							type: 'percentage',
							subtype: 'calculation',
								calculation(insights) {
								const actions = insights.actions || []
								const leads = Number((actions.find(action => action.action_type === 'lead') || {}).value || 0)
								const clicks = insights.clicks

								if (! leads || ! clicks) {
									return null
								}

								return leads / clicks * 100
							},
							label: this.$t(`meta.ads.insights.conversionRate`)
						},
						spend: {
							icon: 'far fa-piggy-bank',
							field: 'spend',
							type: 'currency',
							label: this.$t(`meta.ads.insights.spend`)
						}
					}
				},

				contact: {
					icon: 'far fa-envelope fa-fw',
					label: this.$t('meta.ads.views.contact'),

					metrics: {
						conversions: {
							icon: 'far fa-envelope',
							field: 'contact_total',
							type: 'number',
							subtype: 'conversions',
							label: this.$t(`meta.ads.insights.contact`)
						},
						contact_total: {
							icon: 'far fa-coin',
							field: 'contact_total',
							type: 'currency',
							subtype: 'cost_per_conversion',
							label: this.$t(`meta.ads.insights.pricePerContact`)
						},
						contact_conversion_rate: {
							icon: 'far fa-percentage',
							type: 'percentage',
							subtype: 'calculation',
							calculation(insights) {
								const conversions = insights.conversions || []
								const contacts = Number((conversions.find(action => action.action_type === 'contact_total') || {}).value || 0)
								const clicks = insights.clicks

								if (! contacts || ! clicks) {
									return null
								}

								return contacts / clicks * 100
							},
							label: this.$t(`meta.ads.insights.conversionRate`)
						},
						spend: {
							icon: 'far fa-piggy-bank',
							field: 'spend',
							type: 'currency',
							label: this.$t(`meta.ads.insights.spend`)
						}
					}
				},

				registrations: {
					icon: 'far fa-user-plus fa-fw',
					label: this.$t('meta.ads.views.registrations'),

					metrics: {
						complete_registration: {
							icon: 'far fa-user-plus',
							field: 'complete_registration',
							type: 'number',
							subtype: 'action',
							label: this.$t(`meta.ads.insights.complete_registration`)
						},
						cost_per_registration: {
							icon: 'far fa-coin',
							field: 'complete_registration',
							type: 'currency',
							subtype: 'cost_per_action_type',
							label: this.$t(`meta.ads.insights.pricePerRegistration`)
						},
						registration_conversation_rate: {
							icon: 'far fa-percentage',
							type: 'percentage',
							subtype: 'calculation',
							calculation(insights) {
								const actions = insights.actions || []
								const registrations = Number((actions.find(action => action.action_type === 'complete_registration') || {}).value || 0)
								const clicks = insights.clicks

								if (! registrations || ! clicks) {
									return null
								}

								return registrations / clicks * 100
							},
							label: this.$t(`meta.ads.insights.conversionRate`)
						},
						spend: {
							icon: 'far fa-piggy-bank',
							field: 'spend',
							type: 'currency',
							label: this.$t(`meta.ads.insights.spend`)
						}
					}
				}
			}
		},

		customerId() {
			return this.$route.params.id
		},

		canSubmit() {
			return !! this.from && !! this.to && ! this.loading
		},

		text() {
			let text = String(this.report || '')

			if (this.loading) {
				text = `${text} <i class="far fa-spinner-third fa-spin"></i>`
			}

			return text
		}
	},

	watch: {
		from() {
			this.to = null
		},

		type() {
			this.fields = []
		}
	},

	mounted() {
		this.from = moment().subtract(30, 'days').format('YYYY-MM-DD')

		this.$nextTick(() => {
			this.to = moment().format('YYYY-MM-DD')
		})
	},

	methods: {
		toggleGroup(group) {
			const index = this.visibleGroups.indexOf(group)

			index !== -1
				? this.visibleGroups.splice(index, 1)
				: this.visibleGroups.push(group)
		},

		async load() {
			if (this.loading || ! this.canSubmit) {
				return
			}

			this.loading = true

			this.reportData = {}
			this.report = null


			// Fetch data for report

			try {
				await this.loadReportData()
			} catch (throwable) {
				// @TODO: ON error
				this.loading = false
				return
			}

			const query = Qs.stringify({
				from: this.from,
				to: this.to,
				previous_from: this.previousFrom,
				previous_to: this.previousTo,
				current: this.prepareReport('current'),
				previous: this.prepareReport('previous'),
				customer_id: this.customerId
			})

			const xhr = new XMLHttpRequest()

			xhr.open('POST', `${API_URL}/facebook-ads/xtract?${query}`, true)
			xhr.setRequestHeader('Authorization', `Bearer ${TokenService.getToken()}`)

			const stream = new XhrStream( xhr )

			let text = ''

			stream.on('data', (response) => {
				let additional = response.toString()
				text = `${text}${additional}`

				this.report = text
			})

			stream.on('end', () => {
				this.loading = false
			})

			stream.on('error', () => {
				this.loading = false
			})
		},

		prepareReport(dataSet) {
			return this.fields.reduce(
				(carry, field) => ({
					...carry,
					[field]: {
						label: this.metrics[field].label,
						group: this.metrics[field].group,
						value: this.formatValue(
							dataSet,
							this.metrics[field].field,
							this.metrics[field].type,
							this.metrics[field].subtype,
							this.metrics[field].calculation
						)
					}
				}),
				{}
			)
		},

		formatValue(dataSet, field, type, subtype, calculation) {
			if (! this.reportData[dataSet]) {
				return null
			}

			let value = null

			if (subtype === 'calculation') {
				value = !! calculation
					? calculation(JSON.parse(JSON.stringify(this.reportData[dataSet])))
					: null
			} else if (subtype === 'action') {
				const actions = this.reportData[dataSet].actions || []
				const match = actions.find(action => action.action_type === field)

				value = !! match ? match.value : null
			} else if (subtype === 'action_value') {
				const actions = this.reportData[dataSet].action_values || []
				const match = actions.find(action => action.action_type === field)

				value = !! match ? match.value : null
			} else if (subtype === 'conversions') {
				const conversions = this.reportData[dataSet].conversions || []
				const match = conversions.find(conversion => conversion.action_type === field)

				value = !! match ? match.value : null
			} else if (subtype === 'cost_per_conversion') {
				const conversions = this.reportData[dataSet].cost_per_conversion || []
				const match = conversions.find(conversion => conversion.action_type === field)

				value = !! match ? match.value : null
			} else if (subtype === 'cost_per_action_type') {
				const conversions = this.reportData[dataSet].cost_per_action_type || []
				const match = conversions.find(conversion => conversion.action_type === field)

				value = !! match ? match.value : null
			} else {
				if (typeof this.reportData[dataSet][field] === 'object') {
					value = Array.isArray(this.reportData[dataSet][field])
						? this.reportData[dataSet][field][0].value
						: this.reportData[dataSet][field].value
				} else {
					value = this.reportData[dataSet][field]
				}
			}

			if (! value) {
				return null
			}

			switch (type) {
				case 'currency':
					return this.marketingFormat(
						Number(value),
						type,
						{ currency: this.reportData[dataSet].account_currency }
					)

				default:
					return this.marketingFormat(
						value,
						type
					)
			}
		},

		groupStatus(group) {
			return {
				active: Object.keys(this.groups[group].metrics)
					.filter(key => this.fields.includes(key))
					.length,
				total: Object.keys(this.groups[group].metrics).length
			}
		},

		toggleGroupMetrics(group) {
			const status = this.groupStatus(group)

			if (status.active === status.total) {
				this.fields = this.fields.filter(key => ! Object.keys(this.groups[group].metrics).includes(key))
				return
			}

			this.fields = [
				...this.fields,
				...Object.keys(this.groups[group].metrics)
			]
		},

		loadReportData() {
			const promises = []

			promises.push(
				new Promise((resolve) => {
					FacebookService.accountInsightsTotal({
						from: moment(this.from).format('YYYY-MM-DD'),
						to: moment(this.to).format('YYYY-MM-DD'),
						customerId: this.customerId,

						fields: [
							'action_values',
							'outbound_clicks',
							'outbound_clicks_ctr',
							'inline_link_clicks',
							'inline_link_click_ctr',
							'cost_per_inline_link_click',
							'cost_per_outbound_click',
							'clicks',
							'reach',
							'impressions',
							'spend',
							'website_purchase_roas',
							'frequency',
							'cpm',
							'video_play_actions',
							'video_p100_watched_actions',
							'cost_per_thruplay',
							'action_values',
							'actions',
							'account_currency',
							'conversions',
							'cost_per_conversion',
							'cost_per_action_type'
						]
					}).then(response => {
						this.$set(this.reportData, 'current', response.insights[0] || {})
						resolve()
					})
				})
			)

			promises.push(
				new Promise((resolve) => {
					FacebookService.accountInsightsTotal({
						from: this.previousFrom.format('YYYY-MM-DD'),
						to: this.previousTo.format('YYYY-MM-DD'),
						customerId: this.customerId,

						fields: [
							'action_values',
							'outbound_clicks',
							'outbound_clicks_ctr',
							'inline_link_clicks',
							'inline_link_click_ctr',
							'cost_per_inline_link_click',
							'cost_per_outbound_click',
							'clicks',
							'reach',
							'impressions',
							'spend',
							'website_purchase_roas',
							'frequency',
							'cpm',
							'video_play_actions',
							'video_p100_watched_actions',
							'cost_per_thruplay',
							'action_values',
							'actions',
							'account_currency',
							'conversions',
							'cost_per_conversion',
							'cost_per_action_type'
						]
					}).then(response => {
						this.$set(this.reportData, 'previous', response.insights[0] || {})
						resolve()
					})
				})
			)

			return Promise.all(promises)
		}
	},

	components: {
		DatePicker
	}
}
</script>

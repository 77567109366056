import * as IndustryService from '@/services/customers/IndustryService'

export default function (cb, preset, locale = 'da') {
  let value = null

  const data = {
    sort: [
        {
          key: 'parent.title',
          direction: 'ASC'
        },
        {
          key: 'title',
          direction: 'ASC'
        },
    ],

    includes: ['parent']
  }

  IndustryService.findAll(data, (response) => {
    const body = response.data;

    const results = body.rows.map(industry => ({
		label: (industry.translations || {})[locale] || industry.title,
		value: industry.id
	}))

    for (var i = 0; i < results.length; i++) {
      if (results[i].value !== preset) {
        continue
      }

      value = preset
    }

    cb(results, value)
  })
}

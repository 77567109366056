import ApiService from "@/services/_app/ApiService";

const namespace = 'facebook-ads'

class FacebookService {
  static campaigns (options) {
    return ApiService.get(namespace + '/campaigns', options)
  }

  static adSets (campaignId) {
    return ApiService.get(namespace + '/campaigns/' + campaignId + '/ad-sets' )
  }

  static ads (adSetId, campaignId, options) {
    return ApiService.get(namespace + '/campaigns/' + campaignId + '/ad-sets/' + adSetId + '/ads', options)
  }

  static events ( options) {
    return ApiService.get(namespace + '/events', options)
  }

  static previews (adId) {
    return ApiService.get(namespace + '/ad/' + adId + '/previews' )
  }

  static adInsights (adId, options) {
    return ApiService.get(namespace + '/ad/' + adId + '/insights' , options)
  }

  static adInsightsTotal (adId, options) {
    return ApiService.get(namespace + '/ad/' + adId + '/insights-total' , options)
  }

  static adSetInsights (campaignId, adSetId, options) {
    return ApiService.get(namespace + '/campaigns/' + campaignId + '/ad-sets/' + adSetId + '/insights', options)
  }

  static adSetInsightsTotal (campaignId, adSetId, options) {
    return ApiService.get(namespace + '/campaigns/' + campaignId + '/ad-sets/' + adSetId + '/insights-total', options)
  }

  static campaignInsights (campaignId, options) {
    return ApiService.get(namespace + '/campaigns/' + campaignId + '/insights' , options)
  }

  static campaignInsightsTotal (campaignId, options) {
    return ApiService.get(namespace + '/campaigns/' + campaignId + '/insights-total' , options)
  }

  static accountInsights (options) {
    return ApiService.get(namespace + '/account-insights/', options)
  }

  static accountInsightsTotal (options) {
    return ApiService.get(namespace + '/account-insights-total/', options)
  }

  static campaignsTotals (options) {
    return ApiService.get(namespace + '/campaigns-totals/', options)
  }

  static cpc (from, to) {
    return ApiService.get(namespace + '/cpc/' + ApiService.formatToUrl(from, to))
  }

  static ctr (from, to) {
    return ApiService.get(namespace + '/ctr/' + ApiService.formatToUrl(from, to))
  }

  static spend (from, to) {
    return ApiService.get(namespace + '/spend/' + ApiService.formatToUrl(from, to))
  }

  static clicks (from, to) {
    return ApiService.get(namespace + '/clicks/' + ApiService.formatToUrl(from, to))
  }

  static integrate = (options, cb, errorCb) => {
    return ApiService.post(namespace + '/integrate', options, cb, errorCb)
  }
}

export default FacebookService

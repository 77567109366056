const BaseService = require('@/services/BaseService')

const namespace = 'access-google-ppc/mcc'

const find = (id, options, cb, errorCb) => {
    BaseService.get(`${namespace}/${id}`, options, cb, errorCb)
}

const findAll = (options, cb, errorCb) => {
    BaseService.get(`${namespace}/search-accounts`, options, cb, errorCb)
}

const integrate = (options, cb, errorCb) => {
    BaseService.post(`${namespace}/integrate`, options, cb, errorCb)
}

const remove = (id, cb, errorCb) => {
    BaseService.remove(`${namespace}/integrations/${id}`, {}, cb, errorCb)
}

export default {
    find,
    findAll,
    integrate,
    remove
}

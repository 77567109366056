import {mapActions, mapGetters} from 'vuex'

export default {
  computed: mapGetters('datepicker', {
    interval: 'getInterval',
    range: 'getRange',
    from: 'getFrom',
    to: 'getTo'
  }),
  watch: {
    interval: 'load',
    range: 'load',
  },
  methods: {
    ...mapActions('datepicker', ['setInterval']),
    load () {
    },
    getLabel (date) {

      let label

      switch (this.interval) {
        case 'day':
          label = date.format($t('i18n.dateFormat'))
          break

        case 'week':
          label = $t('date.week') + ' ' + date.format('W') + ' \'' + date.format('YY')
          break

        case 'month':
          label = this.$options.filters.capitalize(date.format('MMMM \'YY'))
          break

        case 'year':
          label = date.format('YYYY')
          break
      }

      return label
    }
  }
}

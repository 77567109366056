export default {
	methods: {
		marketingFormat(value, format, args = {}) {
			const $locale = (this.$i18n || {}).locale || 'da-DK'

			switch (format) {
				case 'currency':
					return (new Intl.NumberFormat($locale, { style: 'currency', currency: args.currency })).format(value)

				case 'number':
					return (new Intl.NumberFormat($locale)).format(value)

				case 'integer':
					return parseInt(value)

				case 'percentage':
					return `${(new Intl.NumberFormat($locale, { maximumSignificantDigits: 2, maximumFractionDigits: 2 })).format(value)}%`

				case 'percentage-formatted':
					return `${(new Intl.NumberFormat($locale, { maximumSignificantDigits: 2, maximumFractionDigits: 2 })).format(Number(value) * 100)}%`

				default:
					return value
			}
		}
	}
}

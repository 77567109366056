// TODO: Replace old with new
import ApiService from '@/services/_app/ApiService'

const namespace = 'inquiries/forms'

export default class FormService {
  static find (id, options) {
    return ApiService.get(namespace + '/' + id, options)
  }

  static findAll (options) {
    return ApiService.get(namespace, options)
  }

  static list (options) {
    return ApiService.get(namespace + '/list', options)
  }

  static integrate (body) {
    return ApiService.post(namespace + '/integrate', body)
  }

  static countBySources (options) {
    return ApiService.get(namespace + '/count/sources', options)
  }
}

import ApiService from '@/services/_app/ApiService'
import TokenService from '@/services/_app/storage/TokenService'
import StorageService from '@/services/_app/storage/StorageService'

const namespace = 'auth'

export default class SocialService {
  /**
   * Login the user and store the access token to TokenService.
   *
   * @param provider
   * @param redirect
   * @returns {Promise<string>}
   */
  static async getProviderUrl (provider, redirect) {

    const response = await ApiService.get(
      `${namespace}/${provider}`,
      {
        redirect: window.location.origin
      }
    )
    return response.url
  }

  static async login (provider, query) {
    const urlParameters = {
      redirect: window.location.origin,
      ...query
    }

    const response = await ApiService.post(
      'auth/' + provider + '/token',
      urlParameters
    )

    if (!response.success) {
      throw { status: 200, response }
    }

    TokenService.saveToken(response)

    return response.access_token
  }
}

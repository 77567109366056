// TODO: Rename identity to user
import Vue from 'vue'
import UserService from '@/services/_app/users/UserService'
import ClientService from '@/services/_app/users/ClientService'
import FeatureService from '@/services/billing/FeatureService'
import StorageService from '@/services/_app/storage/StorageService'
import {STORAGE_CUSTOMER_KEY} from "@/app/users/customer.store";

const getDefaultState = () => {
  return {
    identity: null,
    description: null,
    organization: 'sgme',
    isLoading: false,
	features: []
  }
}

const getters = {
  isLoading: state => state.isLoading,
  hasIdentity: state => !!state.identity,
  isAdmin: state => state.description === 'employee',
  isSuperAdmin: state => state.role === 'employee-super-admin',
  isEmployee: state => state.description === 'employee',
  isClient: state => state.description === 'client',
  getIdentity: state => state.identity,
  getFeatures: state => state.features,
  includeInternationalTraffic: (state, getters, rootState) => {
    const customer = rootState.customer.customer

    if (! customer) {
      return null
    }

    return customer.includingInternationalTraffic
  },
}

const mutations = {
  resetState (state) {
    Object.assign(state, getDefaultState())
  },
  setIdentity (state, identity) {
    state.identity = identity
    state.description = identity.discr
  },
  setFeatures(state, features) {
	state.features = features
  },
  setOrganization (state, identity) {
    let org = 'sgme'

    const customer = this.state.customer.customer

    if (customer && customer.organization) {
      org = customer.organization.slug
    }
    Vue.config.org = org
    state.organization = org
  },
  loading(state, value){
    state.isLoading = value
  },
}

const actions = {
  /**
   * Get client and customer, if not already loaded
   *
   * @param commit
   * @param dispatch
   * @param id
   * @returns {Promise<*>}
   */
  async getClient ({ commit, dispatch }, id) {
    // Load identity and customer
    const includes = [
      'language',
      'customers',
      'customers.organization',
      'customers.industry',
      'customers.products',
      'customers.phoneNumbers',
      'customers.integrations',
      'customers.organization',
      'customers.country',
      'country.currency',
      'customers.oauth2Accounts',
      'integrations.service'
    ]
    // Load client dependent data
    await dispatch('trafficSources/setSources', null, { root: true });
    await dispatch('contacts/loadStatuses', null, { root: true });
    await dispatch('datepicker/reset', null, { root: true });

    const identity = await ClientService.find(id, { includes });

    const customers = identity.customers

	const customerId = StorageService.get(STORAGE_CUSTOMER_KEY)

	const fallback = customers[0] || null

	const match = customers.find(item => {
		return item.id === customerId
	})

    const customer = match || fallback

    // Set organization
    // TODO: Find better solution
    Vue.config.org = customer && customer.organization ? customer.organization.slug : 'sgme';

    commit('setIdentity', identity);

    dispatch('customer/setCustomers', customers, { root: true });
    dispatch('customer/setCustomer',customer, { root: true });

    dispatch('loadFeatures');

    return identity;
  },

  async loadFeatures({ commit, dispatch }) {
	  FeatureService.features({}, response => {
		  const data = response.data
		  commit('setFeatures', data.rows)
	  }, response => {
	  })
  },

  /**
   * Load logged in user
   *
   * @param commit
   * @param dispatch
   * @returns {Promise<*>}
   */
  async loadIdentity ({ commit, dispatch }) {
    commit('loading', true);

    const includes = ['language'];
    let identity = await UserService.get({ includes });

    if (identity.deleted) {
      return dispatch('resetState', null, { root: true });
    }

    dispatch('setLanguage', identity.language, { root: true });
    commit('setOrganization', identity);

    if (identity.discr === 'employee') {
      commit('setIdentity', identity);
	  dispatch('customer/setCustomers', [], { root: true });
	  dispatch('customer/setCustomer', null, { root: true });
    } else {
      identity = await dispatch('getClient', identity.id);
      commit('setIdentity', identity);
    }

    commit('loading', false);
    return identity;
  }
}

export default {
  namespaced: true,
  state: getDefaultState(),
  getters,
  mutations,
  actions
}

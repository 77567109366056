import InstagramService from '@/services/_app/meta/InstagramService'

const getDefaultState = () => {
  return {
    media: null,
    stories: null,
    lifetime: null,
    metadata: null,
    recent: null,
    mentions: null,
    hasInstagram: false
  }
}

const getters = {
  hasInstagram: state => state.hasInstagram,
  getMedia: state => state.media,
  getInsightsAge: state => state.insightsAge,
  getInsightsGender: state => state.insightsGender,
  getInsightsCity: state => state.insightsCity,
  getInsightsCountry: state => state.insightsCountry,
  getUser: state => state.metadata,
  getRecent: state => state.recent,
  getMentions: state => state.mentions
}

const mutations = {
  resetState (state) {
    Object.assign(state, getDefaultState())
  },
  hasInstagram: (state, value) => {
    state.hasInstagram = value
  },
  setMedia: (state, media) => {
    state.media = media
  },
  setInsightsAge: (state, data) => {
    state.insightsAge = data
  },
  setInsightsGender: (state, data) => {
    state.insightsGender = data
  },
  setInsightsCity: (state, data) => {
    state.insightsCity = data
  },
  setInsightsCountry: (state, data) => {
    state.insightsCountry = data
  },
  setUserData: (state, metadata) => {
    state.metadata = metadata
  },
  setRecent: (state, recent) => {
    state.recent = recent
  },
  setMentions: (state, mentions) => {
    state.mentions = mentions
  }
}

const actions = {
  reset ({ commit }) {
    commit('resetState')
  },

  async checkForIntegration ({ commit }, id) {
    const data = await InstagramService.integration(id)
    commit('hasInstagram', data)
    return data
  },

  async fetchData ({ commit }) {
    const response = await InstagramService.fetchData()

    commit('setMentions', [])
    commit('setInsightsAge', response['insights-age'].data)
    commit('setInsightsGender', response['insights-gender'].data)
    commit('setInsightsCity', response['insights-city'].data)
    commit('setInsightsCountry', response['insights-country'].data)
    commit('setUserData', response.user)
    commit('setRecent', response.recent.data)
    commit('setMedia', response.media)
  },

  fetchMorePosts (context, next) {
    return InstagramService.loadMorePosts(next)
  },

  fetchPostInsights (context, payload) {
    return InstagramService.fetchMediaObjectInsights(payload)
  }
}

export default {
  namespaced: true,
  state: getDefaultState(),
  getters,
  mutations,
  actions
}

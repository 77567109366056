export default [
  {
    path: '/backend/system',
    name: 'backend.system',
    component: () => import(/* webpackChunkName: "System" */ './System'),
    children: [
      {
        name: 'backend.system.mails',
        path: 'mails',
        component: () => import(/* webpackChunkName: "System" */ './pages/Mails')
      },
      {
        name: 'backend.system.mails.quarantine',
        path: 'quarantine',
        component: () => import(/* webpackChunkName: "System" */ './pages/Quarantine')
      },
      {
        name: 'backend.system.mails.queue',
        path: 'mails/:queue',
        component: () => import(/* webpackChunkName: "System" */ './pages/Mails')
      },
      {
        name: 'backend.system.jobs',
        path: 'jobs',
        component: () => import(/* webpackChunkName: "System" */ './pages/Jobs')
      }
    ]
  },
]

import StatusService from '@/services/_app/contacts/StatusService'

const defaultState = () => {
  return {
    statuses: []
  }
}

const getters = {
  getStatuses: state => state.statuses
}

const mutations = {
  resetState(state) {
    Object.assign(state, defaultState())
  },

  setStatuses (state, statuses) {
    state.statuses = statuses
  },

  addStatus (state, status) {
    state.statuses.push(status)
  }
}

const actions = {
  async loadStatuses ({ commit }) {
    const response = await StatusService.findAll({
      sort: [{
        key: 'order',
        direction: 'ASC'
      }]
    })

    commit('setStatuses', response.rows)
  }
}

export default {
  namespaced: true,
  state: defaultState(),
  getters,
  mutations,
  actions
}

const BaseService = require('@/services/BaseService')

const namespace = 'notifications/groups'

const find = (id, options, cb, errorCb) => {
    BaseService.get(namespace + '/' + id, options, cb, errorCb)
}

const findAll = (options, cb, errorCb) => {
    BaseService.get(namespace, options, cb, errorCb)
}

export default {
    find,
    findAll
}

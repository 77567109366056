<template>
    <div class="list-item">
        <router-link :to="{name: 'backend.customers.view.users', params: {id: customer.id}}">
            <div class="item">
                <div class="row">
                    <div class="type-container">
                        <div class="type" :style="categoryStyle">
                            <span>{{customer.clientCategory ? customer.clientCategory : '-'}}</span>
                        </div>
                    </div>
                    <div class="content-container">
                        <span>{{customer.name}}</span>

                        <div class="additional">
                            <span class="info text-muted">
                                {{customer.domain}}
                            </span>

                            <span class="info text-muted last-update">
                                <span class="fa fa-user-plus"></span>
                                {{customer.created.date | moment('from', 'now') | capitalize}}
                            </span>

                            <div class="products">
                                <span class="product" v-for="product in activeProducts">
                                    {{product.discr}}
                                </span>
                            </div>
                        </div>
                    </div>

                    <div class="cm-container image-container" :style="{ backgroundImage: 'url(' + clientManagerImage + ')' }"/>
                </div>
            </div>
        </router-link>

        <hr/>
    </div>
</template>

<style lang="scss" scoped>
    @import '~@/assets/scss/_mixins.scss';

    .image-container {
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        float: left;
        margin: 0 5px;
    }

    .products {
        display: block;
    }

    .product {
        padding: 3px 6px 3px 6px;
        border-radius: 3px;
        color: #ffffff;
        margin-right: 5px;
        font-size: 12px;
        background: #ccc;
        display: inline-block;
        margin-top: 10px;
    }

    .list-item {
        a {
            color: initial;
        }

        .content-container {
            width: calc(100% - 196px);
            padding-top: 4px;
            padding-left: 20px;
            float: left;
        }

        .type-container {
            float: left;
            padding: 0;

            .type {
                width: 76px;
                position: relative;

                &:before {
                    content: '';
                    display: block;
                    padding-top: 100%;
                }

                span {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    color: #ffffff;
                    font-weight: 500;
                    font-size: 16px;
                }
            }
        }

        .item {
            padding: 10px 30px;

            .row {
                margin: 0;
            }

            &:hover {
                background-color: rgba(0, 0, 0, 0.05);
            }

            .additional {
                font-size: 10pt;

                .info {
                    margin-right: 10px;

                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }

        &:last-child {
            hr {
                display: none;
            }
        }
    }

    @media screen and (max-width: 768px) {
        .image-container {
            display: none;
        }

        .favicon {
            display: none;
        }

        .list-item {
            .content-container {
                @include ellipsis;
                width: calc(100% - 76px);
            }

            .last-update {
                display: none;
            }
        }
    }
</style>

<script>
  import ClientCategory from '@/app/customers/components/ClientCategory'

  export default {
    props: {
      customer: Object
    },

    computed: {
      clientManagerImage () {
        if (!this.customer.clientManager ||
          !this.customer.clientManager.photo
        ) {
          return
        }

        return '/images/employees/' + this.customer.clientManager.photo
      },

      categoryStyle () {
        switch (this.customer.clientCategory) {
          case 'A+':
            return 'background: #007c65;'
          case 'A':
            return 'background: #00af8e;'
          case 'B':
            return 'background: #fac725;'
          case 'C':
            return 'background: #9c61ff;'
          default:
            return 'background: #cccccc;'
        }
      },

      activeProducts () {
        const customer = this.customer

        if (!customer.products) {
          return []
        }

        const uniqueProducts = []

        return customer.products.filter(product => {
          return product.active
        }).filter(product => {
          if (uniqueProducts.includes(product.discr)) {
            return false
          }

          uniqueProducts.push(product.discr)

          return true
        })
      }
    },

    components: {
      ClientCategory
    }
  }
</script>

import TokenService from '@/services/_app/storage/TokenService'
import ApiService from '@/services/_app/ApiService'
import StorageService from '@/services/_app/storage/StorageService'

export default class AuthService {

  static async Response (response, redirect = null){
    if (redirect) {
      StorageService.delete('redirect')
      StorageService.set('redirect', redirect)
    }

    TokenService.saveToken(response)

    return response.access_token
  }

  /**
   *
   * @param code
   * @param redirect
   * @returns {Promise<*>}
   */
  static async magicLink ({code, redirect}) {
    const response = await ApiService.post('magic-link', { code })

    return AuthService.Response(response, redirect)
  }

  /**
   * Login the user and store the access token to TokenService.
   *
   * @param email
   * @param password
   * @returns string
   */
  static async login ({ email, password, redirect }) {
    const response = await ApiService.post('login', {
      email,
      password
    })

    return AuthService.Response(response, redirect)
  }

  /**
   * Log user out
   *
   * @returns boolean
   */
  static async logout () {
    await ApiService.post('logout')
    TokenService.removeToken()
    ApiService.removeHeader()
    StorageService.delete('authState')
    return true
  }

  /**
   *  Register new User
   * @param data
   * @returns string magicLink
   */
  static async register (data) {
    const response = await ApiService.post('webhooks/customer-leads', data)
    return response.magicLink
  }

  static redirectTo (discr) {
    const storedRedirect = StorageService.get('redirect')
    StorageService.delete('redirect')

    if (storedRedirect) {
      return storedRedirect
    }

    return {
      name: discr === 'client'
        ? 'frontend.overview'
        : 'backend.customers'
    }
  }
}

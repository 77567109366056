const PlanService = require('@/services/billing/PlanService')

export default function (cb, preset) {
    let value = null;

    const options = {
        sort: [{
            key: 'title',
            direction: 'ASC'
        }]
    }

	PlanService.findAll(options, response => {
        const body = response.data

        const results = body.rows.map(plan => {
            return {
                label: plan.title,
                value: plan.id
            }
        })

        for (var i = 0; i < results.length; i++) {
            if (results[i].value !== preset) {
                continue
            }

            value = preset
        }

        cb(results, value)
    });
}

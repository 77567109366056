<template>
    <div class="customer-list">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12">
                    <card icon="pe-7s-users" :headline="$t('customers.headline')" :description="$t('customers.description')">
                        <!-- Settings -->
                        <div class="controls" slot="header">
                            <div class="layout pull-right">
                                <span class="setting fa fa-th fa-fw" :class="{active: isBoxLayout}" @click="setBoxLayout(true)"></span>&nbsp;
                                <span class="setting fa fa-list fa-fw" :class="{active: !isBoxLayout}" @click="setBoxLayout(false)"></span>
                            </div>
                        </div>

                        <hr />

                        <search class="search" :options="filters" @filters-updated="updateFilters"></search>

                        <hr />

                        <!-- Customers -->
                        <template v-if="isBoxLayout">
                            <div class="row box-layout">
                                <div class="col-lg-3 col-md-6 box-dummy" v-for="n in limit" v-if="!hasLoaded">
                                    <card>
                                        <div class="text-center content">
                                            <h1 class="mock">Abxde</h1>

                                            <br />

                                            <span class="mock">domain</span>

                                            <br />
                                            <br />
                                            <br />

                                            <h1 class="mock">A+</h1>
                                        </div>

                                        <hr />

                                        <span class="bottom">
                                            <span class="mock">ab cdef ghij</span>
                                        </span>
                                    </card>
                                </div>

                                <template v-if="customers.length">
                                    <div class="col-lg-3 col-md-6" v-for="customer in customers" :key="customer.id">
                                        <customer-box :customer="customer"></customer-box>
                                    </div>
                                </template>
                            </div>
                        </template>

                        <template v-else>
                            <div class="list-group" style="margin: -20px -30px;">
                                <div class="list-dummy" v-for="n in limit" v-if="!hasLoaded">
                                    <span class="mock">company name</span><br />
                                    <small class="mock">X abc.com&nbsp;&nbsp;Y abc def ghijkl</small>

                                    <div class="clearfix"></div>
                                </div>

                                <template v-if="customers.length">
                                    <div class="customer-row" v-for="customer in customers" :key="customer.id">
                                        <customer-list-item :customer="customer" />
                                    </div>
                                </template>
                            </div>
                        </template>

                        <hr />

                        <div class="pull-right">
                            <pagination :total="total" :limit="limit" :page="page" v-on:paginate="paginate" />
                        </div>

                        <div class="clearfix"></div>
                    </card>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    .search {
        margin-bottom: 30px;
    }

    .customer-row {
        &:nth-child(even) {
            background: #f9f9f9;
        }
    }

    .customer-list {
        .controls {
            margin-top: 18px;
            .layout {
                .setting {
                    cursor: pointer;
                    color: #ccc;
                    font-size: 13pt;

                    &.active {
                        color: #4d91ff;
                    }
                }
            }
        }

        .box-layout {
            .box-dummy {
                h1 {
                    display: block;
                    margin: -10px auto;
                }

                .bottom {
                    float: left;
                    margin: -7px;
                }

                .content {
                    height: 197px;
                }
            }
        }

        .list-dummy {
            line-height: 29px;
            padding: 20px 45px;
            border-bottom: 1px solid #eee;

            &:last-child {
                hr {
                    display: none;
                }
            }
        }
    }
</style>

<script>
    import Loader from '@/app/shared/components/Loader'
    import Card from '@/app/shared/components/Card'
    import Search from '@/app/shared/global/search/Search'
    import ClientCategory from '@/app/customers/components/ClientCategory'
    import Pagination from '@/app/layout/components/paginator/Pagination'

    import CustomerBox from '@/app/customers/components/list/Box'
    import CustomerListItem from '@/app/customers/components/list/ListItem'

    const CustomerService = require('@/services/customers/CustomerService')
    const FilterService = require('@/services/filters/FilterService')

    import EmployeesFilter from '@/services/filters/custom/Employees'
    import OrganizationFilter from '@/services/filters/custom/Organization'
    import IndustryFilter from '@/services/filters/custom/Industry'
    import PlanFilter from '@/services/filters/custom/Plan'

    import {mapGetters} from 'vuex'

    export default {
        data() {
            return {
                hasLoaded: false,
                customers: [],
                total: 0,
                isBoxLayout: false,
                filtersLoaded: 0,
                numberOfFilters: 4,
                page: 1,
                filters: {
                    query: null,
                    filters: [
                        {
                            key: 'caseStatuses',
                            empty: $t('customers.filters.caseStatuses.empty'),
                            value: null,
                            prefix: $t('customers.filters.caseStatuses.prefix'),
                            options: [
                                {
                                    label: $t('customers.filters.caseStatuses.unique'),
                                    value: $t('customers.filters.caseStatuses.unique')
                                },
                                {
                                    label: $t('customers.filters.caseStatuses.seo'),
                                    value: $t('customers.filters.caseStatuses.seo')
                                },
                                {
                                    label: $t('customers.filters.caseStatuses.adwords'),
                                    value: $t('customers.filters.caseStatuses.adwords')
                                },
                                {
                                    label: $t('customers.filters.caseStatuses.facebook'),
                                    value: $t('customers.filters.caseStatuses.facebook')
                                },
                                {
                                    label: $t('customers.filters.caseStatuses.website'),
                                    value: $t('customers.filters.caseStatuses.website')
                                }
                            ]
                        },
						{
                            key: 'customerStatus',
                            empty: $t('customers.filters.customerStatus.title'),
                            prefix: $t('customers.filters.customerStatus.title'),
                            value: null,
                            options: [
                                {
                                    label: $t('customers.filters.customerStatus.all'),
                                    value: 'all'
                                },
                                {
                                    label: $t('customers.filters.customerStatus.client'),
                                    value: 'client'
                                },
                                {
                                    label: $t('customers.filters.customerStatus.lead'),
                                    value: 'lead'
                                }
                            ]
                        },
						{
                            key: 'source',
                            empty: $t('customers.filters.source.empty'),
                            prefix: null,
                            value: null,
                            options: [
                                {
                                    label: 'CRM',
                                    value: 'crm'
                                },
                                {
                                    label: 'MakHub',
                                    value: 'makhub'
                                },
                                {
                                    label: 'GNRX',
                                    value: 'sgme'
                                },
                                {
                                    label: 'Adversus',
                                    value: 'adversus'
                                },
                            ]
                        }
                    ]
                }
            }
        },

        computed: {
          ...mapGetters('identity', {
            identity: 'getIdentity'
          }),

            limit() {
                return (this.isBoxLayout) ? 8 : 20
            }
        },

        watch: {
            limit() {
                this.page = 1
                if (this.filtersLoaded === this.numberOfFilters) {
                    this.load()
                }
            },

            filters() {
                if (this.filtersLoaded === this.numberOfFilters) {
                    this.load()
                }
            }
        },

        mounted() {
            this.loadFilters()
        },

        methods: {
            load() {
                this.hasLoaded = false
                this.customers = []

                const options = {
                    sort: [{
                        key: 'created',
                        direction: 'DESC'
                    }],

                    includes: [
                        'industry',
                        'organization',
                        'clientManager',
                        'products'
                    ],

                    page: this.page,
                    limit: this.limit,

                    filter_groups: FilterService.toRequest(this.filters, [
                        'name',
                        'domain',
                        'clientManager',
                    ])
                }

                CustomerService.findAll(options, response => {
                    const body = response.data

                    this.customers = body.rows
                    this.total = body.total
                    this.hasLoaded = true
                }, error => {
                    this.hasLoaded = true

                    eventHub.$emit('ajax-error', error.response)
                })
            },

            paginate(page) {
                this.page = page
                this.load()
            },

            setBoxLayout(isBoxLayout) {
                this.isBoxLayout = isBoxLayout
            },

            updateFilters(filters) {
                this.filters = filters

                this.page = 1;
            },

            loadFilters () {
                const identity = this.identity
                const id = identity.id

                OrganizationFilter((options, value) => {
                    this.filtersLoaded++

                    this.filters.filters.push({
                        key: 'organization',
                        empty: $t('filters.empty'),
                        value: value,
                        prefix: $t('customers.filters.organization.prefix'),
                        options: options
                    })
                }, null)

                EmployeesFilter((options, value) => {
                    this.filtersLoaded++

                    this.filters.filters.push({
                        key: 'clientManager',
                        empty: $t('filters.empty'),
                        value: value,
                        prefix: $t('integrationCheck.filters.accountManager'),
                        options: options
                    })
                }, null)

                IndustryFilter((options, value) => {
                    this.filtersLoaded++

                    this.filters.filters.push({
                        key: 'industry',
                        empty: $t('filters.empty'),
                        value: value,
                        prefix: $t('customers.filters.business.prefix'),
                        options: options
                    })
                }, id, this.$i18n.locale)

				PlanFilter((options, value) => {
                    this.filtersLoaded++

                    this.filters.filters.push({
                        key: 'plan',
                        empty: $t('filters.empty'),
                        value: value,
                        prefix: $t('customers.filters.subscription.prefix'),
                        options: options
                    })
                }, id)
            }
        },

        components: {
            Loader,
            Card,
            ClientCategory,
            Pagination,
            CustomerBox,
            CustomerListItem,
            Search
        }
    }
</script>

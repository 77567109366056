export default {
	friendly: {
		icon: '😊',
	},
	formal: {
		icon: '👔',
	},
	informal: {
		icon: '👋',
	},
	informing: {
		icon: '☝️',
	},
	encouraging: {
		icon: '💡',
	}
}

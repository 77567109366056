const BaseService = require('@/services/BaseService')

const url = (service, redirectUri, cb, errorCb) => {
  BaseService.get(service + '/oauth2/connect', { redirectUri: redirectUri, state: service }, cb, errorCb)
}

const callback = (service, redirectUri, code, state, cb, errorCb, isMccAccount) => {
  BaseService.get(service + '/oauth2/callback', {
    redirectUri: redirectUri,
    state: service,
    code: code,
    isMccAccount: isMccAccount === true
  }, cb, errorCb)
}

const accounts = (service, options, cb, errorCb) => {
  BaseService.get(service + '/oauth2/accounts', options, cb, errorCb)
}

const integrate = (service, name, id, cb, errorCb) => {
  BaseService.get(service + '/oauth2/integrate', { id: id, name: name }, cb, errorCb)
}

export {
  url,
  callback,
  accounts,
  integrate,
}

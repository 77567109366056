export default {
    organizations() {
        return {
          name: "Generaxion",
          altName: "SGME",
          slug: "sgme",
          phoneNumbers: {
            sales: "-",
            info: "-"
          },
          logos: {
            standard: "/images/spotonlive-logo-color.svg",
            inverted: "/images/spotonlive-logo-white.svg",
            pinkBackground: '/images/opn/Generaxion_OPN_logo_pink_transparent_background.svg',
            whiteLogoOnBlack: '/images/opn/Generaxion_OPN_Bomærke_Hvid_Pink.svg',
            wordmarkWhite: "/images/opn/Generaxion_OPN_Logo-17.svg",
            arrowRight: "/images/opn/arrow-right.svg",
            closeIconWhite: "/images/opn/close_icon_white.svg",
            pinkStone: '/images/opn/pink-stone.png',
            pinkStairway: '/images/opn/pink-stairway.png',
            alt: "/images/sl-alt-logo.svg",
            loader: {
              top: "/images/loader/sgme-blue.png",
              right: "/images/loader/sgme-green.png",
              arc: "/images/loader/sgme-lightblue.png",
              opn: "/images/loader/OPN_logo_192x192.png"
            }
          },
          colors: {
            standard: {
              primary: {
                hex: "#12374c",
                darkHex: "#0b222f"
              },
              secondary: {
                hex: "#e9a838"
              },
              tertiary: {
                hex: "#0b222f"
              },
              link: {
                primary: {
                  hex: "#e9a838"
                },
                secondary: {
                  hex: "#12374c"
                }
              },
              button: {
                outlined: "#12374c",
                filled: "#e9a838"
              },
              label: {
                filled: "#e9a838"
              },
              card: {
                background: "#12374c",
                backgroundMock: "#0b222f"
              },
              tooltip: {
                primary: "#e9a838"
              },
              integrations: {
                connect: "#12374c"
              },
              statuses: {
                positive: {
                  primary: "#dcefdc",
                  secondary: "#b6ccb6",
                  tertiary: "#8a998a",
                  focus: "#6baa6b"
                },
                neutral: {
                  primary: "#f7f6e2",
                  secondary: "#e0dfc9",
                  tertiary: "#99978a",
                  focus: "#dbc258"
                },
                negative: {
                  primary: "#f2dede",
                  secondary: "#ccb4b4",
                  tertiary: "#9b8989",
                  focus: "#9b5353"
                },
                focus: {
                  primary: "#e9a838",
                  secondary: "#b8b8cc",
                  tertiary: "#8c8d99",
                  focus: "#6c6ca8"
                }
              }
            },
            inquiries: {
              calls: "#00438f",
              emails: "#00c49f"
            },
            trafficSources: {
              facebook: "#4096ee",
              adwords: "#eab557",
              direct: "#e26a1a",
              organic: "#12374c",
              referral: "#90bbd6",
              total: "#d11e48"
            }
          }
        };
    }
}

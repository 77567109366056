import ApiService from '@/services/_app/ApiService'

const namespace = 'benchmarks'

export default class BenchmarkService {
  static find (id, options) {
    return ApiService.get(namespace + '/' + id, options)
  }

  static findAll (options) {
    return ApiService.get(namespace, options)
  }
}

<template>
  <div>
    <template v-if="!loadingIntegration">
      <div class="alert alert-danger" v-if="errorMessage">
        Der skete en fejl: {{ errorMessage }}
      </div>
      <card v-if="integration" minHeight="68px">
        <button class="btn btn-danger btn-on-mobile" @click="disconnect">
          <template v-if="!isRemoving">{{
            $t("backend.OAuth2.remove")
          }}</template>
          <loader v-else />
        </button>

        <template v-if="customer && trackingCode">
          <span v-text="integration.connectedAccount" />
          <br />

          <small class="text-muted" v-if="account">
            {{ account.name }} - {{ trackingCode }}
          </small>

          <span v-if="unhealthy" class="unhealthy">
            <i class="fa fa-warning" /><br />
            {{ $t("backend.OAuth2.removedDueToHealth") }}
          </span>
        </template>

        <loader :inline="true" v-else />
      </card>

      <template v-else>
        <div class="form-group">
          <label>{{ $t("backend.OAuth2.searchByAccountName") }}</label>
          <input v-model="query" class="form-control" />
        </div>

        <template v-if="!loading">
          <hr v-if="results.length" />

          <card
            v-for="(result, i) in filteredResults"
            minHeight="68px"
            :key="i"
          >
            <button class="btn btn-primary pull-right" @click="connect(result)">
              {{ $t("backend.OAuth2.connect") }}
            </button>

            <span v-text="result.name" style="word-break: break-all" /><br />

            <small class="text-muted" v-if="account && result.account">
              {{ result.account.name }}
            </small>
          </card>

          <template v-if="total">
            <pagination
              :total="total"
              :limit="limit"
              :page="page"
              @paginate="paginate"
              :showFirstAndLast="false"
              size="small"
              :keyboardControl="true"
            />
            <small class="text-muted current-pages">{{
              currentlyShowing
            }}</small>
          </template>
        </template>

        <loader v-else />
      </template>
    </template>

    <loader v-else />

    <div class="form-group">
      <br /><br />
      <label>Skriv Google Analytics visning (view) ID ind direkte</label>
      <div class="row">
        <div class="col-md-10">
          <input v-model="manualAccountId" type="text" name="test" id="" />
        </div>
        <div class="col-md-2">
          <button
            @click.prevent="manualAccountAdd"
            style="margin-top: 4px"
            :class="loading ? 'disabled' : null"
            :style="loading ? 'opacity:.6' : null"
            class="btn btn-primary btn-sm"
          >
            Tilføj
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "~@/assets/scss/_vars.scss";

.btn-on-mobile {
  float: right;
}

@media screen and (max-width: 992px) {
  .btn-on-mobile {
    display: block;
    margin-bottom: 10px;
    float: none;
  }
}

.unhealthy {
  text-align: center;
  margin: 10px 0;
  padding: 5px 40px;
  display: block;
  background: $focusAlert;
  border-radius: 3px;
  color: #ffffff;
}

.dark {
  padding: 0;
  color: #000;
  text-shadow: none;

  .card {
    padding: 0;
    margin-bottom: 15px;
  }

  .form-group label {
    color: #fff;
    text-transform: none;
    font-size: 14px;
    font-weight: 600;
    text-shadow: 1px 1px 2px #000;
  }

  hr {
    border-top: #000;
    display: none;
  }
}
</style>

<script>
import Card from "@/app/shared/components/Card";
import Pagination from "@/app/layout/components/paginator/Pagination";
import Loader from "@/app/shared/components/Loader";

import AnalyticsService from "@/services/_app/google-analytics/AnalyticsService";
import CachedAnalyticsAccountService from "@/services/_app/google-analytics/CachedAnalyticsAccountService";
import * as IntegrationService from "@/services/integrations/IntegrationService";
import * as CustomerService from "@/services/customers/CustomerService";

export default {
  props: {
    customerId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      errorMessage: "",
      manualAccountId: null,
      integration: null,
      loading: false,
      loadingIntegration: true,
      isRemoving: false,
      query: null,
      trackingCode: null,
      results: [],
      customer: null,
      limit: 4,
      page: 1,
    };
  },

  computed: {
    total() {
      return this.results.length;
    },

    currentlyShowing() {
      return $t("pagination.showing", {
        current: this.page,
        total: Math.ceil(this.total / 4),
      });
    },

    filteredResults() {
      if (!this.results.length) {
        return [];
      }

      return this.results.slice(
        (this.page - 1) * this.limit,
        this.limit * this.page
      );
    },

    unhealthy() {
      const integration = this.integration;
      return integration && !this.integration.healthy;
    },

    account() {
      const customer = this.customer;
      const oauth2Accounts = customer.oauth2Accounts;

      const account = oauth2Accounts.find((account) => {
        return account.provider === "googleAnalytics";
      });

      return account === undefined ? null : account;
    },
  },

  watch: {
    query() {
      this.search(this.query);
    },
    integration() {
      this.errorMessage = "";
    },
  },

  mounted() {
    this.loadCustomer();
  },

  methods: {
    loadIntegration() {
      const customer = this.customer;
      const integrations = customer.integrations;

      const integration = integrations.find((integration) => {
        if (integration.service.name === "Google analytics") {
          return integration;
        }
      });

      if (!integration) {
        this.loadingIntegration = false;

        this.search(customer.domain);
        return;
      }

      this.integration = integration;
      this.loadingIntegration = false;
      this.setTrackingCode();
    },

    async search(query) {
      this.page = 1;
      this.results = [];

      if (!query || query.length < 3) {
        return;
      }

      this.loading = true;

      const options = {
        includes: ["account"],

        filter_groups: [
          {
            or: true,
            filters: [
              {
                key: "name",
                operator: "ct",
                value: query,
              },
              {
                key: "view",
                operator: "eq",
                value: query,
              },
            ],
          },
        ],
      };

      const data = await CachedAnalyticsAccountService.findAll(options);
      this.results = data.rows;
      this.loading = false;
    },

    async connect(result) {
      this.loadingIntegration = true;

      await CachedAnalyticsAccountService.integrate({
        customerId: this.customerId,
        accountId: result.id,
      });
      this.query = null;
      this.results = [];
      this.loadCustomer();
      this.$emit("integrationStateChange", this.customerId);
      this.loadingIntegration = false;
    },

    async setTrackingCode() {
      const response = await AnalyticsService.trackingCode(this.customer.id);
      this.trackingCode = response.trackingCode;
    },

    loadCustomer() {
      const options = {
        includes: ["integrations", "integrations.service", "oauth2Accounts"],
      };

      CustomerService.find(this.customerId, options, (response) => {
        this.customer = response.data;

        this.loadIntegration();
      });
    },

    disconnect() {
      this.isRemoving = true;

      IntegrationService.remove('integrations', this.integration.id, () => {
        this.integration = null;

        this.loadCustomer();

        this.$emit("integrationStateChange", this.customerId);

        this.isRemoving = false;
      });
    },

    paginate(page) {
      this.page = page;
    },

    manualAccountAdd() {
      if (!this.manualAccountId) {
        return;
      }

      AnalyticsService.integrate({
        externalId: this.manualAccountId,
        customerId: this.customer.id,
      })
        .then((response) => {
          this.query = null;
          this.results = [];

          this.loadCustomer();

          this.$emit("integrationStateChange", this.customerId);

          this.loading = false;
          this.manualAccountId = null;
        })
        .catch((error) => {
          this.loading = false;
          this.errorMessage = error.message;
        });
    },
  },

  components: {
    Card,
    Loader,
    Pagination,
  },
};
</script>
